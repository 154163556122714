import React from 'react'
import { Footer } from '../../shared/Footer'
import Header from '../../shared/Header'

class ShakeUp extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>

          <div className="container">
            <div className="row justify-content-between align-items-center py-4 py-lg-5">
              <div className="col-lg-6 col-xl-6 mb-4">
                <p className="lead">
                  Hello to all of our friends from Kasey Bell’s Shake Up
                  Learning Show!
                </p>
                <p className="mb-1">
                  By accessing this link, you’ve earned yourself 3 months of
                  FREE access to our Unlimited tier. You’ll be able to:
                </p>
                <ul className="mt-1">
                  <li>Get 90 seconds per voice note</li>
                  <li>Transcribe your notes</li>
                  <li>Translate for ELL students</li>
                  <li>Store motes in your motebook</li>
                  <li>Talk math and science with STEM mode</li>
                </ul>

                <p className="lead">
                  Ready to go? Click below, sign in and get moting!
                </p>
                <div className="row ml-0 mr-0">
                  <a href="/r/xgtzjhn" className="btn btn-primary mr-2">
                    Let's go!
                  </a>
                </div>
              </div>

              <div className="d-lg-none mb-1 ml-3">
                <img
                  width="300"
                  alt="alice says hi"
                  src="/images/illustrations/kasey.png"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></img>
              </div>
              <div className="d-none d-lg-block col-lg-6 col-xl-5 mb-1 justify-content-center text-lg-left">
                <img
                  width="500"
                  alt="alice"
                  src="/images/illustrations/kasey.png"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></img>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    )
  }
}

export default ShakeUp
