import React from 'react'

export const Pause = ({ color = '#fff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="none">
      <rect width="5" height="22" x="13" y="10" fill={color} rx="2.5"></rect>
      <rect width="5" height="22" x="24.53" y="10" fill={color} rx="2.5"></rect>
    </svg>
  )
}
