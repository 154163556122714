import React from 'react'
import { useHistory } from 'react-router-dom'

import { useRecorder } from '@mote/business'
import { useTrackMutation } from '@mote/business/src/api/requests/tracking/useTrackMutation'
import { Recorder as WebRecorder } from '@mote/business'

export const Recorder = () => {
  const history = useHistory()
  const { start, stop, pause, resume, status } = useRecorder()

  // // Method 1
  const { mutate: track } = useTrackMutation()

  // // Method 2
  // const {
  //   data,
  //   error,
  //   loading,
  //   mutate: track
  // } = useTrackMutation('s/track/<specific-route>')

  // console.log({ data, error, loading })

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      track({
        variables: {
          event: 'Mote Landing Page Audio Started or Restarted',
          content_asset_id: 123
        }
      }) // usage method 1
    }, 2000)
    // track({ variables: {content_asset_id: 123}}, 's/track/<specific-route>) // usage method 2

    return () => {
      clearTimeout(timeout)
    }
  }, [track])

  return (
    <div>
      <div>
        <div>Recorder status: {status}</div>
        <button onClick={() => start()}>Record</button>
        <button onClick={() => pause()}>Pause</button>
        <button onClick={() => resume()}>Resume</button>
        <button onClick={() => stop()}>Stop</button>
      </div>

      <WebRecorder />

      <button onClick={(_) => history.push('/')}>Redirect to home page</button>
    </div>
  )
}
