import { reallyTruthy } from './Utils'

export const CANONICAL_DOMAIN = 's.justmote.me'
export const NETWORK_TIMEOUT = 12 * 1000
export const SHORT_LINK_DOMAIN =
  process.env.REACT_APP_SHORT_LINK_DOMAIN || 'https://mote.fyi/'
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || `https://${CANONICAL_DOMAIN}/`
export const CWS_URL =
  'https://chrome.google.com/webstore/detail/mote-voice-notes/ajphlblkfpppdpkgokiejbjfohfohhmk'
export const STATUS_URL = 'https://mote.statuspage.io/'
export const SUBPROCESSOR_URL =
  'https://support.mote.com/hc/en-us/articles/360045255892'
export const TAW_URL =
  'https://docs.google.com/presentation/u/1/d/1w3z1etlCqo9rPzcb5Paky4SuXmKIOZiroM-mjidPDwo/template/preview'
export const CAREERS_URL = 'https://apply.workable.com/mote/'
export const WEATHERS_URL =
  '/docs/Educational_impact_of_Mote__by_John_Weathers_PhD.pdf'
export const PRICING_URL = 'https://www.justmote.me/pricing'
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI
export const GOOGLE_BUTTON_OPTIONS = {
  width: 240,
  height: 50,
  longtitle: true,
  theme: 'dark'
}
export const GOOGLE_BUTTON_ID = 'google-login'
export const LEGALS_VERSION = 1
export const COUNTRY_DEFAULT = 'us'
export const CURRENCY_DEFAULT = 'usd'

const K_PREFIX = 'mote-inMarket-'
export const K_EXPIRES = K_PREFIX + 'expires'
export const K_COUNTRY = K_PREFIX + 'country'
export const K_CURRENCY = K_PREFIX + 'currency'
export const K_IN_MARKET = K_PREFIX + 'bool'
export const AU_LAUNCH_TIME = 1591560000
export const K_SHOW_LOGIN_NAV = 'mote-showLogin'

export const ADMIN_ALLOW_LIST = [
  'fitzwarren@gmail.com',
  'motetester@gmail.com',
  'willmjackson@gmail.com',
  'albert@mote.com',
  'alex@justmote.me',
  'alex@mote.com',
  'andreas@mote.com',
  'chris@mote.com',
  'emir@mote.com',
  'eric@mote.com',
  'jon@mote.com',
  'nathan@mote.com',
  'petros@mote.com',
  'rob@mote.com',
  'will@justmote.me',
  'will@mote.com'
]

export const PULLDOWN_REGIONS = {
  us: {
    name: 'State',
    items: [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming'
    ]
  },
  au: {
    name: 'State or territory',
    items: [
      'Australian Capital Territory',
      'New South Wales',
      'Northern Territory',
      'Queensland',
      'South Australia',
      'Tasmania',
      'Victoria',
      'Western Australia'
    ]
  },
  gb: {
    name: 'County',
    items: [
      'Aberdeenshire',
      'Angus',
      'Antrim',
      'Argyll',
      'Armagh',
      'Avon',
      'Ayrshire',
      'Banffshire',
      'Bedfordshire',
      'Berkshire',
      'Berwickshire',
      'Buckinghamshire',
      'Bute',
      'Caithness',
      'Cambridgeshire',
      'Cheshire',
      'Clackmannanshire',
      'Cleveland',
      'Clwyd',
      'Cornwall',
      'Cumbria',
      'Derbyshire',
      'Devon',
      'Dorset',
      'Down',
      'Dumfriesshire',
      'Dunbartonshire',
      'Durham',
      'Dyfed',
      'East Lothian',
      'East Sussex',
      'Essex',
      'Fermanagh',
      'Fife',
      'Gloucestershire',
      'Gwent',
      'Gwynedd',
      'Hampshire',
      'Herefordshire',
      'Hertfordshire',
      'Inverness-shire',
      'Isle of Wight',
      'Kent',
      'Kincardineshire',
      'Kinross-shire',
      'Kirkcudbrightshire',
      'Lanarkshire',
      'Lancashire',
      'Leicestershire',
      'Lincolnshire',
      'London',
      'Londonderry',
      'Merseyside',
      'Mid Glamorgan',
      'Middlesex',
      'Midlothian',
      'Moray',
      'Nairnshire',
      'Norfolk',
      'North Humberside',
      'North Yorkshire',
      'Northamptonshire',
      'Northumberland',
      'Nottinghamshire',
      'Orkney',
      'Oxfordshire',
      'Peeblesshire',
      'Perthshire',
      'Powys',
      'Renfrewshire',
      'Ross-shire',
      'Roxburghshire',
      'Rutland',
      'Selkirkshire',
      'Shetland',
      'Shropshire',
      'Somerset',
      'South Glamorgan',
      'South Humberside',
      'South Yorkshire',
      'Staffordshire',
      'Stirlingshire',
      'Suffolk',
      'Surrey',
      'Sutherland',
      'Tyne and Wear',
      'Tyrone',
      'Warwickshire',
      'West Glamorgan',
      'West Lothian',
      'West Midlands',
      'West Sussex',
      'West Yorkshire',
      'Wigtownshire',
      'Wiltshire',
      'Worcestershire'
    ]
  }
}

export const SERVER_ERROR =
  'Oops, there was a problem updating your account. ' +
  "It's probably a problem on our side. Try again later. " +
  "If it still doesn't work, contact support@mote.com"

export const DEV_FORCE_LOGIN =
  reallyTruthy(process.env.REACT_APP_DEV_FORCE_LOGIN) || false

export const CLIPBOARD_ANIMATION_TIMEOUT = 1000
