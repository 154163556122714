import React from 'react'

function Loading() {
  return (
    <div style={{ padding: '8em 0em 14em 0em' }}>
      <div className="login-spinner text-center justify-content-center">
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
    </div>
  )
}

export default Loading
