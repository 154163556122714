import React from 'react'
import { styled, css } from 'twin.macro'

export const ButtonIcon = ({ icon, inverted, onClick }) => {
  return (
    <Root inverted={inverted} onClick={onClick}>
      <IconContainer>{icon}</IconContainer>
    </Root>
  )
}

const Root = styled.button`
  display: inline-flex;
  flex: 0 0 auto;
  outline: none;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: center;
  touch-action: manipulation;
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 50%;
  background: rgba(35, 35, 35, 0.05);

  span {
    color: #232323;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &:hover {
    background: #232323;
    span {
      color: white;
    }
  }

  ${({ inverted }) =>
    inverted &&
    css`
      background: rgba(255, 255, 255, 0.1);
      span {
        color: white;
      }

      &:hover {
        background: white;
        span {
          color: #232323;
        }
      }
    `}
`

const IconContainer = styled.span``
