import React from 'react'

function Chevron() {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.56836 6.81259C5.79688 7.04111 6.17773 7.04111 6.40625 6.81259L11.3574 1.88681C11.5859 1.6329 11.5859 1.25204 11.3574 1.02353L10.7734 0.439545C10.5449 0.211029 10.1641 0.211029 9.91016 0.439545L6 4.3497L2.06445 0.439545C1.81055 0.211029 1.42969 0.211029 1.20117 0.439545L0.617188 1.02353C0.388672 1.25204 0.388672 1.6329 0.617188 1.88681L5.56836 6.81259Z"
        fill="#5F5F5F"
      />
    </svg>
  )
}

export default Chevron
