import React from 'react'

import GetMoteLink from '../shared/GetMoteLink'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class Elementary extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>

          <div className="container">
            <div className="row justify-content-between align-items-center py-4 py-lg-4">
              <div className="col-lg-6 col-xl-6 mb-4">
                <h3 className="lg-display-4-mb-4-mt">
                  Remote learning &amp; feedback for Elementary &amp; Primary
                  School students
                </h3>
                <p className="lead">
                  Learn how <b className="mote-purple">mote</b> can help ease
                  your workload, and help your students stay connected.
                </p>
              </div>

              <div className="d-lg-none mb-2 justify-content-center">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/elementary.jpg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>Photo credit: CDC, via Unsplash</small>
                  </figcaption>
                </figure>
              </div>

              <div className="d-none d-lg-block col-lg-6 col-xl-5 mb-2 justify-content-center text-lg-left">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/elementary.jpg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>Photo credit: CDC, via Unsplash</small>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="teacher-page-intro">
              <p>
                To stay on track while learning remotely, Elementary &amp;
                Primary School students - and parents - are looking for regular
                check-ins with teachers.
              </p>

              <p>
                Zoom calls don’t work well for all learners, or for all
                occasions. Use <b className="mote-purple">mote</b> to quickly
                and easily leave Private Comment voice notes for individual
                students within Google Classroom, or to leave your entire class
                voice notes within the Google Classroom Stream.{' '}
              </p>
              <p>
                We’ve now heard from hundreds of teachers about how they - and
                their students - have felt more connected by using{' '}
                <b className="mote-purple">mote</b> voice notes to communicate.
              </p>
            </div>
          </div>

          <div
            className="bg-cover position-relative"
            style={{
              backgroundImage: 'url(images/logos/mote-background.png',
              backgroundSize: 'auto'
            }}
          >
            <div className="container mote-brand">
              <h3 className="h4 mb-4">
                Here's what we've heard about{' '}
                <b className="mote-purple">mote</b> from Elementary teachers
              </h3>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “<b className="mote-purple">Mote</b> has majorly eased the
                      arduous task of providing quality feedback to 35
                      5th-graders during distance learning. It is vital to guide
                      students through all their daily assignments in math,
                      language arts, social studies, and science."
                    </p>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “During this incredibly difficult time in our teaching
                      world this is a game changer. My students can hear my
                      voice and stay connected to me. My crew is only 7-8 years
                      old and they need that desperately. Thank you. I actually
                      started crying when I heard my voice back.“
                    </p>
                  </blockquote>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "I have shared this with all of the teachers at school!
                      This resource has allowed me to provide valuable feedback
                      to each of my students. I teach kindergarten, they cannot
                      read yet, so without mote I would be providing feedback
                      just for the practice of it. Feedback needs to be
                      intentional and Mote allows me to provide my students with
                      easy, productive feedback! THANK YOU!”
                    </p>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “Distance learning can feel pretty impersonal to
                      elementary school students. Anything that can help them
                      feel like their teacher is personally involved in their
                      learning is helpful. It also makes giving detailed
                      feedback easier. An added bonus is that it adds editing
                      functionality to private comments in GC.“
                    </p>
                  </blockquote>
                </div>
              </div>

              <div className="container py-1">
                <div className="text-center">
                  <h2 className="mb-4">Start your free trial</h2>
                  <GetMoteLink recipe="atc" />
                </div>
              </div>

              <div className="container mb-lg-3"></div>

              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Elementary
