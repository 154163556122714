import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import * as serviceWorker from './infra/serviceWorker'
import { UserAgentProvider } from '@quentin-sommer/react-useragent'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { checkAndUpdateStorageVersion } from '@mote/business'

import { error } from '@mote/common'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'
// import { createBrowserHistory } from "history";

import Account from './dynamic/account/index/Account'
import Activity from './dynamic/account/activity/Activity'
import Add from './static/Add'
import Amplifier from './static/Amplifier'
import Alice from './static/campaigns/Alice'
import AmbassadorSurvey from './static/AmbassadorSurvey'
import AlexTheSadEngineer from './static/AlexTheSadEngineer'
import App from './static/App'
import BeUnderstood from './static/BeUnderstood'
import Blog from './static/Blog'
import BookPD from './static/BookPD'
import Bugs from './static/Bugs'
// import Careers from "./static/Careers";
import Checkout from './dynamic/Checkout'
import CheckoutSchools from './dynamic/CheckoutSchools'
import Community from './static/Community'
import Contact from './static/Contact'
import DebugSAK from './dynamic/DebugSAK'
import DemoVideos from './static/DemoVideos'
import Educators from './static/Educators'
import ELA from './static/ELA'
import Elementary from './static/Elementary'
import EmailDomains from './dynamic/admin/EmailDomains'
import FeedbackLoop from './static/FeedbackLoop'
import ForceError from './dynamic/ForceError'
import GetStarted from './static/GetStarted'
import Gift from './dynamic/Gift'
import Gmail from './static/Gmail'
import GoogleTrainers from './static/GoogleTrainers'
import HistoryAnnotation from './static/HistoryAnnotation'
import JulyPricing from './static/JulyPricing'
import InstitutionalUsers from './dynamic/account/users/InstitutionalUsers'
import Labs from './dynamic/Labs'
import Languages from './static/Languages'
import Login from './dynamic/login/Login'
import { NewMote } from './dynamic/mote-landing/NewMote'
import MoteCarousel from './shared/MoteCarousel'
import MoteLoginScreen from './shared/MoteLoginScreen'
import Music from './static/Music'
import MyEdTechBundle from './static/campaigns/MyEdTechBundle'
import NotificationPreferences from './dynamic/account/np/NotificationPreferences'
import OG from './dynamic/admin/OG'
import PilotPreSurvey from './static/PilotPreSurvey'
import Pleased from './static/Pleased'
import Press from './static/Press'
import Pricing from './static/Pricing'
import Privacy from './static/Privacy'
import PrivacyCenter from './static/PrivacyCenter'
import DataSecurityPolicy from './static/DataSecurityPolicy'
import Release0317 from './static/Release0317'
import Sandbox from './static/Sandbox'
import SchoolsInfo from './static/SchoolsInfo'
import ShakeUp from './static/campaigns/ShakeUp'
import Shifting from './static/campaigns/Shifting'
import SpringCUE from './static/campaigns/SpringCUE'
import SlideProbe from './dynamic/SlideProbe'
import StemUpgrade from './static/StemUpgrade'
import StudentUpgrades from './static/StudentUpgrades'
import STEM from './static/STEM'
import Survey from './static/Survey'
import Teams from './static/Teams'
import TOS from './static/TOS'
import Utb from './static/campaigns/Utb'
import Webinars from './static/Webinars'
import UKWebinars from './static/UKWebinars'
import WebinarFeedback from './static/WebinarFeedback'

import { identifyMaybe } from './shared/Auth'
import { CWS_URL } from './shared/Constants'
import { STATUS_URL } from './shared/Constants'
import { SUBPROCESSOR_URL } from './shared/Constants'
import { WEATHERS_URL } from './shared/Constants'
import { TAW_URL } from './shared/Constants'
import { CAREERS_URL } from './shared/Constants'
import { maybeWWWRedirect } from './shared/Utils'
import { css } from 'twin.macro'
import { Global } from '@emotion/react'
import { LocaleProvider } from '@mote/l10n/provider/LocaleProvider'
import { Recorder } from './dynamic/recorder/Recorder'
import {
  RecordReplySidebarContext,
  useRecordReplySidebarState
} from '@mote/business'
import { Homepage } from './static/homepage'


ReactGA.initialize('UA-156510801-1')
ReactGA.pageview(window.location.pathname + window.location.search)

if (!process.env.REACT_APP_NO_TELEMETRY) {
  Sentry.init({
    dsn: 'https://70d7009086954f558c4f2f3e4e977bb6@sentry.io/5181853',
    beforeSend(event, hint) {
      event.tags = event.tags || {}
      event.tags['mote.pre.processed'] = true
      try {
        var matches = {}
        const candidates = [
          'Object._updateMetadata',
          'Extension context invalidated',
          'chrome-extension'
        ]
        var checkIn = []
        const oe = hint.originalException
        if (oe.message) {
          checkIn.push(oe.message)
        }
        if (oe.title) {
          checkIn.push(oe.title)
        }
        if (oe.culprit) {
          checkIn.push(oe.culprit)
        }
        if (oe.metadata) {
          for (var key of Object.keys(oe.metadata)) {
            checkIn.push(oe.metadata[key])
          }
        }
        if (oe.breadcrumbs && oe.values) {
          for (var crumb of oe.values) {
            if (crumb && crumb.message) {
              checkIn.push(crumb.message)
            }
          }
        }

        for (var value of checkIn) {
          for (var candidate of candidates) {
            if (value.includes(candidate)) {
              matches[candidate] = 1
            }
          }
        }
        if (
          oe.extra &&
          oe.extra.body &&
          +oe.extra.body.sourceFile &&
          oe.extra.body.sourceFile.startsWith('chrome-extension')
        ) {
          matches['chrome-extension'] = 1
        }
        for (var m of Object.keys(matches)) {
          var key2 = 'matched.' + m
          key2 = key2.toLowerCase()
          key2 = key2.replace(' ', '.')
          event.tags[key2] = true
        }
        if (Object.keys(matches).length > 2) {
          return null
        }
      } catch (e) {
        error('Failed to parse Sentry error: ', e, event, hint)
        event.tags['matching.errors'] = 'failed to parse error: ' + e.toString()
      }
      // return null;
      return event
    }
  })
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

// const history = createBrowserHistory();

// history.listen((location) => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// });

// TODO - Replace the following with the GlobalStyles component
// TODO - After we remove the boost package
const CustomGlobalStyles = css`
  :root {
    --color-primary: #ac0ae8;
    --color-secondary: #65008a;
  }
`

const Routing = () => {
  const [loading, setLoading] = React.useState(true)
  const replyRecordSidebarState = useRecordReplySidebarState()

  React.useEffect(() => {
    checkAndUpdateStorageVersion().then(() => setLoading(false))
  }, [])

  if (loading) return null

  return (
    <Router>
      <Global styles={CustomGlobalStyles} />
      <Elements stripe={stripePromise}>
        <UserAgentProvider ua={window.navigator.userAgent}>
          <RecordReplySidebarContext.Provider value={replyRecordSidebarState}>
            <LocaleProvider>
              <div>
                <Switch>
                  <Route exact path="/" component={App} />
                  <Route exact path="/homepage" component={Homepage} />
                  <Route exact path="/account" component={Account} />
                  <Route exact path="/recorder" component={Recorder} />
                  <Route exact path="/alice" component={Alice} />
                  <Route exact path="/account/activity" component={Activity} />
                  <Route
                    exact
                    path="/account/notifications"
                    component={NotificationPreferences}
                  />
                  <Route exact path="/add" component={Add} />
                  <Route exact path="/admin" component={OG} />
                  <Route exact path="/admin/debugSAK" component={DebugSAK} />
                  <Route exact path="/amplifier" component={Amplifier} />
                  <Route exact path="/beunderstood" component={BeUnderstood} />
                  <Route exact path="/blog" component={Blog} />
                  <Route exact path="/bookPD" component={BookPD} />
                  <Route exact path="/bugs" component={Bugs} />
                  <Route exact path="/carousel" component={MoteCarousel} />
                  <Route exact path="/checkout" component={Checkout} />
                  <Route
                    exact
                    path="/checkoutSchools"
                    component={CheckoutSchools}
                  />
                  <Route exact path="/quote" component={CheckoutSchools} />
                  <Route exact path="/community" component={Community} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/demovideos" component={DemoVideos} />
                  <Route exact path="/educators" component={Educators} />
                  <Route exact path="/ela" component={ELA} />
                  <Route exact path="/elementary" component={Elementary} />
                  <Route exact path="/feedbackloop" component={FeedbackLoop} />
                  <Route exact path="/forceerror" component={ForceError} />
                  <Route exact path="/gmail" component={Gmail} />
                  <Route exact path="/shiftingschools" component={Shifting} />
                  <Route exact path="/springCUE" component={SpringCUE} />
                  <Route exact path="/shakeuplearning" component={ShakeUp} />
                  <Route
                    exact
                    path="/ambassadorsurvey"
                    component={AmbassadorSurvey}
                  />
                  <Route
                    exact
                    path="/googletrainers"
                    component={GoogleTrainers}
                  />
                  <Route exact path="/getstarted" component={GetStarted} />
                  <Route
                    exact
                    path="/historyannotation"
                    component={HistoryAnnotation}
                  />
                  <Route exact path="/julypricing" component={JulyPricing} />
                  <Route exact path="/labs" component={Labs} />
                  <Route exact path="/languages" component={Languages} />
                  <Route exact path="/login" component={Login} />
                  <Route
                    exact
                    path="/moteloginscreen"
                    component={MoteLoginScreen}
                  />
                  <Route exact path="/music" component={Music} />
                  <Route
                    exact
                    path="/pilotpresurvey"
                    component={PilotPreSurvey}
                  />
                  <Route
                    exact
                    path="/myedtechbundle"
                    component={MyEdTechBundle}
                  />
                  <Route exact path="/pleased" component={Pleased} />
                  <Route exact path="/press" component={Press} />
                  <Route exact path="/pricing" component={Pricing} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route
                    exact
                    path="/privacy/dataSecurity"
                    component={DataSecurityPolicy}
                  />
                  <Route
                    exact
                    path="/privacycenter"
                    component={PrivacyCenter}
                  />
                  <Route exact path="/release0317" component={Release0317} />
                  <Route exact path="/sandbox" component={Sandbox} />
                  <Route exact path="/schools" component={SchoolsInfo} />
                  <Route exact path="/schoolsinfo" component={SchoolsInfo} />
                  <Route exact path="/stem" component={STEM} />
                  <Route exact path="/stemupgrade" component={StemUpgrade} />
                  <Route
                    exact
                    path="/studentupgrades"
                    component={StudentUpgrades}
                  />
                  <Route
                    exact
                    path="/support/testSlides"
                    component={SlideProbe}
                  />
                  <Route exact path="/survey" component={Survey} />
                  <Route exact path="/teams" component={Teams} />
                  <Route exact path="/tos" component={TOS} />
                  <Route
                    exact
                    path="/uninstalled"
                    component={AlexTheSadEngineer}
                  />
                  <Route exact path="/UTB" component={Utb} />
                  <Route exact path="/webinars" component={Webinars} />
                  <Route exact path="/UKwebinars" component={UKWebinars} />
                  <Route
                    exact
                    path="/webinarfeedback"
                    component={WebinarFeedback}
                  />
                  <Route
                    path="/account/users/:pageIndex"
                    component={InstitutionalUsers}
                  />
                  <Route
                    path="/account/activity/:pageIndex"
                    component={Activity}
                  />
                  <Route
                    path="/admin/emailDomains/:pageIndex"
                    component={EmailDomains}
                  />
                  <Route path="/g/:giftCode" component={Gift} />
                  <Route path="/m/:moteId" component={NewMote} />
                  <Route path="/r/:referrerCode" component={Login} />
                  <Route
                    path="/chrome"
                    component={() => {
                      window.location.href = CWS_URL
                      return null
                    }}
                  />
                  <Route
                    path="/taw"
                    component={() => {
                      window.location.href = TAW_URL
                      return null
                    }}
                  />
                  <Route
                    path="/status"
                    component={() => {
                      window.location.href = STATUS_URL
                      return null
                    }}
                  />
                  <Route
                    path="/careers"
                    component={() => {
                      window.location.href = CAREERS_URL
                      return null
                    }}
                  />
                  <Route
                    path="/impact"
                    component={() => {
                      window.location.href = WEATHERS_URL
                      return null
                    }}
                  />
                  <Route
                    path="/subprocessors"
                    component={() => {
                      window.location.href = SUBPROCESSOR_URL
                      return null
                    }}
                  />
                  <Route component={App} /> {/* Default if not found */}
                </Switch>
              </div>
            </LocaleProvider>
          </RecordReplySidebarContext.Provider>
        </UserAgentProvider>
      </Elements>
    </Router>
  )
}

ReactDOM.render(<Routing />, document.getElementById('root'))
maybeWWWRedirect()
identifyMaybe()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
