import React from 'react'

import GetMoteLink from '../shared/GetMoteLink'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class Languages extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>

          <div className="container">
            <div className="row justify-content-between align-items-center py-4">
              <div className="col-lg-6 col-xl-6 mb-4">
                <h3 className="lg-display-4-mb-4-mt">
                  Remote learning &amp; feedback for ESL &amp; Modern Languages
                  students
                </h3>
                <p className="lead">
                  Learn how <b className="mote-purple">mote</b> can help ease
                  your workload, and help your students stay connected.
                </p>
              </div>

              <div className="d-lg-none mb-2 justify-content-center">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/languages.jpg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>Photo credit: Ben Mullins, via Unsplash</small>
                  </figcaption>
                </figure>
              </div>

              <div className="d-none d-lg-block col-lg-6 col-xl-5 mb-3 justify-content-center text-lg-left">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/languages.jpg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>Photo credit: Ben Mullins, via Unsplash</small>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="teacher-page-intro">
              <p>
                Voice feedback is especially powerful for language learning,
                because teachers can provide guidance on pronunciation in either
                native language, learned language or a mixture of both.
              </p>
              <p>
                <b className="mote-purple">Mote</b> supports transcription in
                more than twenty languages, and it’s easy to switch between
                languages from voice note to voice note, or to temporarily
                disable transcription if desired.
              </p>
            </div>
          </div>

          <div
            className="bg-cover position-relative"
            style={{
              backgroundImage: 'url(images/logos/mote-background.png',
              backgroundSize: 'auto'
            }}
          >
            <div className="container mote-brand">
              <h3 className="h4 mb-4">
                Here's what we've heard about{' '}
                <b className="mote-purple">mote</b> from ESL and Modern
                Languages teachers
              </h3>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "Writing the same or similar feedback to the different
                      students takes a toll. Being able to share ideas through a
                      simple recording and for them to either read through or
                      listen as an option is awesome. It really helps out my
                      English Language Learners."
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “It makes giving feedback easier. It's an excellent way to
                      differentiate for students who need to HEAR the feedback.
                      Also, I can speak another language better than I can write
                      it so I use to speak Spanish to my students who can't
                      speak English.
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “I teach Chinese as a second language. Being able to
                      provide oral feedback to the students is absolutely
                      necessary especially during the online learning.”
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "Its an awesome tool to give students instant feedback and
                      allows them to hear me speak in te reo Māori and English."
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
              </div>

              <div className="container py-1">
                <div className="text-center">
                  <h2 className="mb-4">Start your free trial</h2>
                  <GetMoteLink recipe="atc" />
                </div>
              </div>

              <div className="container mb-lg-3"></div>

              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Languages
