import React from 'react'
import PropTypes from 'prop-types'

const chevronSVG = (
  <svg
    className="tw-w-4 tw-h-4 tw-fill-current"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
  </svg>
)

export const Select = ({ currentValue, options, onChange, disabled }) => {
  const renderOptions = () =>
    options.map(({ value, text }) => (
      <option key={value} value={value}>
        {text}
      </option>
    ))

  const disabledClasses = disabled ? 'tw-opacity-50 tw-cursor-not-allowed' : ''

  return (
    <div className={`tw-relative tw-inline-block tw-w-full ${disabledClasses}`}>
      <select
        value={currentValue}
        onChange={(e) => onChange(e)}
        className="tw-block tw-appearance-none tw-w-full tw-bg-white tw-border tw-border-gray-400 tw-hover:border-gray-500 tw-px-4 tw-py-2 tw-pr-8 tw-rounded tw-shadow tw-leading-tight tw-focus:outline-none"
        disabled={disabled}
      >
        {renderOptions()}
      </select>
      <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-px-2 tw-text-gray-700">
        {chevronSVG}
      </div>
    </div>
  )
}

Select.propTypes = {
  currentValue: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

Select.defaultProps = {
  disabled: false
}
