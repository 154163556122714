import React from 'react'
import GetMoteLink from '../shared/GetMoteLink'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class Teams extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>

          {/* <div className="container">
                        <div className="row justify-content-between align-items-center py-4 py-lg-5"> */}
          {/* <div className="col-lg-6 col-xl-6 mb-4">
                                <h1 className="lg-display-4-mb-4-mt">Voice commenting for Google Docs</h1>
                                <h3><b className="mote-purple">mote</b>: the faster, friendlier way to comment and feedback</h3>
                                <div className="row ml-0 mr-0">
                                    <GetMoteLink recipe="atc" />

                                </div>
                            </div> */}
          {/*
                            <div className="d-lg-none mb-2 justify-content-center">
                                <figure className="figure">

                                    <img height="400" src="videos/demo0403.gif" title="mote demo" alt="mote demo"></img>
                                    <figcaption className="figure-caption text-center"><small>Create voice notes within Docs, Slides, Sheets and Classroom</small></figcaption>
                                </figure></div> */}

          {/*
                            <div className="d-none d-lg-block col-lg-6 col-xl-5 mb-3 justify-content-center text-lg-left">
                                <figure className="figure">

                                    <img height="400" src="videos/demo0403.gif" title="mote demo" alt="mote demo"></img>
                                    <figcaption className="figure-caption text-center"><small>Create voice notes within Docs, Slides, Sheets and Classroom</small></figcaption>
                                </figure></div> */}
          {/* </div>
                    </div> */}

          <div
            className="bg-cover position-relative"
            style={{
              backgroundImage: 'url(images/logos/mote-background.png',
              backgroundSize: 'auto'
            }}
          >
            <div className="container mote-brand">
              {/* <div className="row" style={{padding: "0em 0em 0em 1em"}}>

                                <h1 className="lg-display-4-mb-2-mt">More about <b className="mote-purple">mote</b></h1></div>
                            <h3 className="py-4"><b className="mote-purple">mote</b> is a Chrome extension for adding voice notes and emojis to comments within Google Docs, Slides, Sheets and Classroom. </h3>
                            <div className="row" style={{padding: "0em 0em 0em 1em"}}>

                                <h1 className="lg-display-4-mb-2-mt"><b className="mote-purple">Mote</b> is...</h1></div>
                            <div className="row py-2 mb-lg-5">
                                <div className="row py-2 mb-lg-3">
                                    <div className="col-md-4 mb-4">
                                        <div className="text-center px-lg-3">
                                            <span className="icon icon-primary icon-lg mb-3">
                                                <i style={{padding: "12px 0px 0px 0px",fontSize: "32pt"}} className="material-icons mote-purple">speed</i>
                                            </span>
                                            <h2 className="h4 text-left">Fast</h2>
                                            <p className="mb-md-0 text-left">We type around forty words per minute - but speak up to three times faster.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <div className="text-center px-lg-3">
                                            <span className="icon icon-primary icon-lg mb-3">
                                                <i style={{padding: "12px 0px 0px 0px",fontSize: "32pt"}} className="material-icons mote-purple">insert_emoticon</i>
                                            </span>
                                            <h2 className="h4 text-left">Friendly</h2>
                                            <p className="text-left mb-md-0">Stay connected with colleagues, wherever and whenever you're all working.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <div className="text-center px-lg-3">
                                            <span className="icon icon-primary icon-lg mb-3">
                                                <i style={{padding: "12px 0px 0px 0px",fontSize: "32pt"}} className="material-icons mote-purple">subject</i>
                                            </span>
                                            <h2 className="h4 text-left">Flexible</h2>
                                            <p className="text-left mb-md-0">Opt to transcribe your voice notes, so that colleagues can choose to read, listen or do both.</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              <section className="container mb-4">
                <div className="row">
                  <h1 className="lg-display-4-mb-2-mt pt-4">
                    Teams use <b className="mote-purple">mote</b> to stay
                    connected
                  </h1>
                </div>
                <div className="row justify-content-lg-between align-items-lg-center py-4 py-lg-5">
                  <div className="col-lg-5 mb-4 mb-lg-0">
                    <h2 className="h3">Together Alone</h2>
                    <p className="mb-4">
                      We've designed <b className="mote-purple"> mote</b> to
                      help you work remotely, and on your own schedule, without
                      sacrificing human connection.
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <figure>
                      <img
                        src="images/photos/CrowdedHouse.jpg"
                        className="img-fluid"
                        width="500"
                        alt="Crowded House"
                      />
                      <figcaption className="figure-caption text-center">
                        <small>Crowded House in 1993</small>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="col-lg-5 order-lg-2 mb-4 mb-lg-0">
                    <h2 className="h3">Don't let me be misunderstood</h2>
                    <p className="mb-4">
                      You're just a soul whose intentions are good, but
                      sometimes words on a screen just don't quite capture what
                      you want to say.
                    </p>
                    <a className="link-cta" href="/beunderstood">
                      Read more
                    </a>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <figure className="figure">
                      <img
                        src="images/photos/nina-simone-please-dont-let-me.jpg"
                        className="img-fluid"
                        width="500"
                        alt="Nina Simone"
                      />
                      <figcaption className="figure-caption text-center">
                        <small>Nina Simone in 1964</small>
                      </figcaption>
                    </figure>
                  </div>
                </div>
                <div className="row justify-content-lg-between align-items-lg-center py-4 py-lg-5">
                  <div className="col-lg-5 mb-4 mb-lg-0">
                    <h2 className="h3">Pleased to meet you...</h2>
                    <p className="mb-4">
                      But perhaps next time, we can work this through online and
                      save a bunch of time and delay? With{' '}
                      <b className="mote-purple"> mote</b>, everyone can read
                      and summarize their feedback quickly and easily.
                    </p>
                    <a className="link-cta" href="/pleased">
                      Read more
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <figure>
                      <img
                        src="images/photos/Stones1982RotterdamMarcelAntonisse.jpg"
                        className="img-fluid"
                        width="500"
                        alt="Mick Jagger"
                      />
                      <figcaption className="figure-caption text-center">
                        <small>Mick Jagger in 1982</small>
                      </figcaption>
                    </figure>
                  </div>
                </div>
                <div className="row justify-content-lg-between align-items-lg-center py-4 py-lg-5">
                  <div className="col-lg-5 order-lg-2 mb-4 mb-lg-0">
                    <h2 className="h3">9 to 5</h2>
                    <p className="mb-4">
                      What a way to make a livin' ... but modern teams operate
                      around the globe, around the clock and however works best.{' '}
                      <b className="mote-purple">mote</b> is a great tool to
                      help empower your distributed team to succeed.{' '}
                    </p>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <figure>
                      <img
                        src="images/photos/dolly-parton-9to5.png"
                        className="img-fluid"
                        width="500"
                        alt="Dolly Parton"
                      />
                      <figcaption
                        className="figure-caption text-center"
                        width="500"
                      >
                        <small>Dolly Parton in 1980</small>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </section>
              <div className="container py-1">
                <div className="text-center">
                  <div className="d-none d-md-block">
                    {/* <iframe title="Add" className="airtable-embed" src="https://airtable.com/embed/shrFRSUoOOVUnYeZa?backgroundColor=pink" frameborder="0" onmousewheel="" width="100%" height="450" styleName="background: transparent; border: 1px solid #ccc;"></iframe> */}

                    <h2 className="mb-4">Are you ready to start?</h2>
                    <GetMoteLink recipe="atc" />
                  </div>
                </div>
              </div>
              <div className="container py-1">
                <div className="text-center">
                  <div className="d-block d-md-none">
                    <h2 className="mb-4">
                      Try <b className="mote-purple">mote</b>
                    </h2>
                    <GetMoteLink recipe="atc" />
                  </div>
                </div>
              </div>
              <div className="container mb-lg-3"></div>

              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Teams
