import tw, { styled, css } from 'twin.macro'
import { palest_grey } from '@mote/config/defaults/colors'
import { P } from '@mote/config/defaults/typography'

const GOLDEN_RATIO = {
  width: 287,
  height: 177
}

export const Hero = styled.div(() => [
  tw`w-full px-6 pt-8 pb-12 lg:px-20 bg-gray-50`,
  css`
    border-bottom-left-radius: 50% 15%;
    border-bottom-right-radius: 50% 15%;
  `
])

export const Notice = tw.div`flex justify-center font-medium mt-8 px-6 text-center`

export const PreviewContainer = tw.div`sm:flex overflow-hidden border-2 border-gray-100 rounded-xl mt-8 max-w-xs mx-auto sm:max-w-full`

export const PreviewBody = styled.div(() => [
  tw`flex flex-col justify-between min-w-0 p-4 space-y-2 sm:space-y-0`,
  css`
    height: 183px;
  `
])

export const PreviewImage = styled.img(() => [
  tw`object-cover`,
  css`
    min-width: 324px;
    width: 324px;
    height: 183px;
  `
])

export const PreviewLink = tw.a`text-primary truncate hover:text-primary hover:underline`

export const PreviewTitle = tw.span`text-lg sm:text-xl font-medium leading-6 truncate`

export const PreviewDescription = styled.div(() => [
  tw`block text-sm truncate sm:text-base sm:whitespace-normal`,
  css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `
])

export const ExtensionBox = styled.div(() => [
  tw`items-center max-w-xs p-6 mx-auto my-8 space-y-4 text-center sm:space-x-4 sm:space-y-0 sm:justify-between sm:p-10 sm:text-left sm:flex rounded-xl bg-primary sm:max-w-full`
])

export const Container = styled.div(() => [tw`max-w-screen-lg px-4 m-auto`])

export const MainContent = styled.div(() => [tw`w-full`])
export const Root = styled.div(() => [tw`flex flex-row w-full`])

export const TitleContainer = tw.div`text-center mt-4 mb-8 text-2xl sm:text-3xl h-12`

export const Logo = tw.img`w-28`

export const LogoLink = tw.a`w-28 hidden sm:block`

export const Heading = tw.div`flex flex-row w-full justify-between items-center`

export const HeroContentContainer = tw.div`flex flex-col items-center justify-center space-y-4 justify-center sm:space-x-4 sm:space-y-0 sm:flex`

export const ButtonContainer = tw.div`sm:text-right sm:w-60`

export const TranscriptionWrapper = styled.div(
  ({ width = `${GOLDEN_RATIO.width}px` }) => [
    css`
      width: ${width};

      @media (max-width: 640px) {
        display: none;
      }
    `
  ]
)

export const Transcription = styled.div(
  ({ height = `${GOLDEN_RATIO.height}px` }) => [
    tw`p-3 overflow-x-auto text-gray-600 bg-gray-200 border rounded-lg`,

    css`
      background: ${palest_grey};
      width: 100%;
      height: ${height};
    `
  ]
)

export const CardContainer = styled.div(() => [
  css`
    width: fit-content;
  `
])

export const Footer = tw.div`flex space-x-1 items-center text-gray-500 mt-48`

export const Error = tw.div`py-40`

export const AudioIssuePrompt = tw.div`flex justify-center mt-2 text-xs cursor-pointer text-violet-500 hover:text-violet-400`

export const TransitionContainer = tw.div`h-screen p-4 flex flex flex-col items-center justify-center`

export const TransitionContentContainer = styled.div(
  tw`flex flex-col items-center justify-center p-8`,
  `
  border-radius: 10px;
  background: #F9F9FB;
  box-shadow: 0 14px 24px 0 rgba(0,0,0,0.14);
  width: 621px;
  height: 328px;
  `
)

export const TransitionText = styled(P)`
  width: 100%;
  max-width: 474px;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  margin-top: 32px;
`

export const MoteCardContainer = tw.div`flex flex-row justify-center gap-4`
