import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'

class GoogleTrainers extends React.Component {
  render() {
    return (
      <ReactTypeformEmbed url="https://will838622.typeform.com/to/yQYGQ7O6" />
    )
  }
}

export default GoogleTrainers
