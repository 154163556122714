import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'

class Pleased extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <div className="container">
          <main id="main">
            <div className="container">
              <div className="row justify-content-center my-5">
                <div className="col-lg-9">
                  <img
                    src="/images/photos/Stones1982RotterdamMarcelAntonisse.jpg"
                    className="img-fluid img-thumbnail"
                    alt="Mick Jagger"
                  />
                </div>
              </div>
            </div>

            <div className="bg-skew bg-skew-light">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <article className="pb-2">
                      <h1 className="text-center">Pleased to meet you...</h1>
                      <p className="small mb-5 text-center">
                        <span className="text-secondary">published on </span>
                        <span className="text-uppercase">JAN 22, 2020</span>
                      </p>
                      <p className="lead">
                        This post is inspired by a{' '}
                        <a href="https://distributed.blog/podcast">podcast</a>{' '}
                        conversation between Automattic Founder/CEO Matt
                        Mullenweg and producer Mark Armstrong.
                      </p>

                      <p>
                        <b>In some organizations,</b> meetings have become the
                        only way that decisions can be made, documents can be
                        reviewed and people can receive recognition.
                      </p>

                      <p>
                        We started <b className="mote-purple">mote </b>because
                        we believe that organizations need better tools to
                        collaborate without meetings. We believe that the future
                        of work will be one where geography and time zone will
                        be far less important, and where the companies that win
                        will be those who harness both unique resources of major
                        urban hubs (e.g. venture capital in Silicon Valley,
                        showbiz in Hollywood) AND the vast pool of talented and
                        experienced engineers, designers and other professionals
                        around the globe.
                      </p>
                      <p>
                        As a distributed team working together across time
                        zones, we developed the{' '}
                        <b className="mote-purple">mote </b> Chrome extension
                        for own use, because we were frustrated by the
                        experience of using text comments in collaborative
                        documents and tools.
                      </p>
                      <div className="row justify-content-center">
                        <figure className="figure mt-2">
                          <img
                            src="images/photos/mote-screenshot.png"
                            className="img-fluid shadow"
                            alt="mote"
                          />
                          <figcaption className="figure-caption p-2 text-center">
                            The <b className="mote-purple">mote</b> Chrome
                            extension
                          </figcaption>
                        </figure>
                      </div>
                      <h2 className="h2 mt-4">The true cost of meetings</h2>
                      <p>
                        While many meetings are useful, and some may even be
                        great, hidden in the shadows are meeting costs so large
                        that they may ultimately contribute overwhelmingly to
                        the downfall of otherwise healthy companies.
                      </p>

                      <h3 className="h3 mt-4">Cost 1: People Time</h3>
                      <p>
                        The most obvious cost of meetings is the lost
                        productivity of the time of the people sitting around
                        the table or trying to stay engaged on the conference
                        call.
                      </p>
                      <blockquote className="blockquote">
                        <p className="mb-2">
                          ...the most important thing to unlock is this idea of
                          getting everyone around the table [being] the best way
                          to solve a problem. [Being] in person and being
                          synchronous is definitely a way to solve a problem,
                          but I see many companies, particularly older companies
                          or managers with experience in older-style companies,
                          see that as the only way to solve those problems.
                        </p>
                        <footer className="blockquote-footer">
                          Matt Mullenweg, CEO and Founder of Automattic
                        </footer>
                      </blockquote>

                      <h2 className="h3 mt-4">Cost 2: Delay</h2>
                      <p>
                        The second cost is the cost of delay, and of a culture
                        that accepts the premise of a trade-off between
                        alignment and time to market.
                      </p>
                      <blockquote className="blockquote">
                        <p className="mb-2">
                          I think a lot about speed of iteration and anything
                          that introduces any lag time into particularly
                          decision-making slows companies down. And you really
                          start to look at places that are moving slower than
                          they need to, you often find these little things,
                          these little one-day, two-day delays that just add up
                          to be weeks and months and then eventually years of
                          things moving slower than a more agile team would be
                          able to.
                        </p>
                        <footer className="blockquote-footer">
                          Matt Mullenweg, CEO and Founder of Automattic
                        </footer>
                      </blockquote>
                      <h2 className="h3 mt-4">Cost 3: Talent</h2>
                      <p>
                        The third cost is most intangible but most profound. In
                        a globalizing world, with incredible talent in every
                        city, state and country, the greatest companies that
                        will energe over the next few decades will operate in
                        distributed, decentalized and asynchronous ways. For
                        these companies, meetings will be just one tool to be
                        used selectively, mindfully, alongside other tools for
                        collaboration, alignment and decision making.
                      </p>

                      <blockquote className="blockquote">
                        <p className="mb-2">
                          "I do think that there are asynchronous ways...that
                          can actually be far superior in many situations to
                          solve many or most problems that businesses face. I
                          don’t consider it my personal mission to switch all
                          companies to be able to do this but partially that’s
                          because I feel that companies that don’t do this will
                          die off.
                        </p>
                        <footer className="blockquote-footer">
                          Matt Mullenweg, CEO and Founder of Automattic
                        </footer>
                      </blockquote>

                      <p>
                        We know that <b className="mote-purple">mote </b>is not
                        a panacea, at least not today. But we've set our sights
                        on helping those organizations that are focused on
                        achieving both alignment <strong>and</strong> agility,
                        human connection <strong>and</strong> hard deadlines. If
                        this sounds like you, or if you have other ideas for how
                        to keep meetings focused on what matters most, then we
                        hope you’ll check us out!
                      </p>

                      <div className="media my-5">
                        <img
                          className="avatar avatar-lg mr-3"
                          src="images/photos/Will.jpg"
                          alt="Post author"
                        />
                        <div className="media-body">
                          <h3 className="h5 mt-0">Will Jackson</h3>
                          <p className="mb-0 text-secondary">
                            Will is the co-founder of{' '}
                            <b className="mote-purple">mote</b>, and is based in
                            the SF Bay Area.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <div className="container py-1">
                <div className="text-center">
                  <div className="d-none d-md-block">
                    <h2 className="mb-4">Are you ready to start?</h2>
                    <a href="/chrome" className="btn btn-primary mr-2">
                      Add to Chrome
                    </a>
                  </div>
                </div>
              </div>
              <div className="container py-1">
                <div className="text-center">
                  <div className="d-block d-md-none">
                    <h2 className="mb-4">
                      Try <b className="mote-purple">mote</b>
                    </h2>
                    <a href="/chrome" className="btn btn-primary mr-2">
                      Send me a link
                    </a>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default Pleased
