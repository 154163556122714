import React from 'react'
import { useGenericRequestHandler } from '../../../handlers/useGenericRequestHandler'

export const useMoteConversationMutation = (
  moteId,
  refetchInterval = false
) => {
  return useGenericRequestHandler({
    route: `m/conversation/${moteId}`,
    method: 'POST',
    clientType: 'private',
    refetchInterval
  })
}
