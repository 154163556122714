import React from 'react'
import { styled, css } from 'twin.macro'
import { mote_primary } from '@mote/config/defaults/colors'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ArrowIcon } from '@mote/icons'
import AcademicBatGirlPNG from './assets/AcademicBatGirl.png'
import DarenWhitePNG from './assets/DarenWhite.png'
import JenaBrennanPNG from './assets/JenaBrennan.png'
import KarinaQuilantanPNG from './assets/KarinaQuilantan.png'
import MichaelBuistPNG from './assets/MichaelBuist.png'
import NicoleReynoldsPNG from './assets/NicoleReynolds.png'
import SarahKieferPNG from './assets/SarahKiefer.png'
import ScottEarlPNG from './assets/ScottEarl.png'

const testimonials = [
  {
    firstName: 'Jena',
    lastName: 'Brennan',
    title: 'HS History Teacher, Scotland',
    image: JenaBrennanPNG,
    description:
      'It allows me to make work more acccesible by listening to information, rather than reading it.',
    mote: 'https://mote.fyi/26wzsey'
  },
  {
    firstName: 'Michael',
    lastName: 'Buist',
    title: 'HS Math teacher, USA',
    image: MichaelBuistPNG,
    description:
      'Written instructions do not convey my tone or the meaning as much as the sound of my voice.',
    mote: 'https://mote.fyi/bjxfyr6'
  },
  {
    firstName: 'Sarah',
    lastName: 'Kiefer',
    title: 'Elementary Tech Integration Specialist, USA',
    image: SarahKieferPNG,
    description:
      'Mote has allowed me to quickly and easily share audio for little learners.',
    mote: 'https://mote.fyi/5xnruf3'
  },
  {
    firstName: 'Scott',
    lastName: 'Earl',
    title: 'HS English Teacher, USA',
    image: ScottEarlPNG,
    description:
      'Mote helps my students better understand my explanations; they also appreciate the fact that they can hear it.',
    mote: 'https://mote.fyi/q55ssyt'
  },
  {
    firstName: '@AcademicBatgirl',
    lastName: '',
    title: 'Professor, Ontario Tech University, Canada',
    image: AcademicBatGirlPNG,
    description:
      'With Mote, students get a more personalised and sincere connection to my comments.',
    mote: 'https://mote.fyi/mjhxepg'
  },
  {
    firstName: 'Karina',
    lastName: 'Quilantan',
    title: 'Middle School Library Media Specialist, USA',
    image: KarinaQuilantanPNG,
    description:
      "Mote has made a huge impact - I can answer over 100 notifications a day! It's been an efficient way to provide daily communication with my students.",
    mote: 'https://mote.fyi/ezaqa6f'
  },
  {
    firstName: 'Daren',
    lastName: 'White',
    title: 'HS Learning Technologies Leader and Spanish Teacher, UK',
    image: DarenWhitePNG,
    description:
      'Mote enables me to connect and communicate better with students and colleagues.',
    mote: 'https://mote.fyi/7jwu7t2'
  },
  {
    firstName: 'Nicole',
    lastName: 'Reynolds',
    title: 'Tech Director, Adjunct Professor, USA',
    image: NicoleReynoldsPNG,
    description:
      'What a wonderful way to be authentic and be culturally inclusive with the translation. Amazing!',
    mote: 'https://mote.fyi/fuag5xm'
  }
]

const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(android)|(webOS)/i)

export const TestimonialsSection = () => {
  const slider = React.useRef(null)
  const [centerSlide, setCenterSlide] = React.useState(0)
  const [fade, setFade] = React.useState(true)

  const settings = {
    centerMode: true,
    arrows: false,
    dots: isMobile,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    adaptiveHeight: true,
    appendDots: customDots,
    beforeChange: onTransition
  }

  function customDots(dots) {
    return (
      <Dots>
        {dots.map((dot) => {
          return <Dot active={dot.props.className} />
        })}
      </Dots>
    )
  }

  function onTransition(current, next) {
    setFade(false)

    const timeout = setTimeout(() => {
      setFade(true)
      setCenterSlide(next)
      clearTimeout(timeout)
    }, 500)
  }

  function renderTestimonials() {
    return testimonials.map((testimonial, index) => {
      const min = centerSlide - 2
      const max = centerSlide + 2

      return (
        <div onMouseDown={() => setFade(false)} key={testimonial.mote}>
          <TestimonialCard
            firstName={testimonial.firstName}
            lastName={testimonial.lastName}
            title={testimonial.title}
            image={testimonial.image}
            description={testimonial.description}
            mote={testimonial.mote}
            faded={
              fade &&
              (index === (min > -1 ? min : testimonials.length + min) ||
                index === (max < 8 ? max : max - testimonials.length))
            }
          />
        </div>
      )
    })
  }

  return (
    <Container>
      <Background />

      <Content>
        <Header>Our community ❤️ Mote</Header>
        <Subheader>Join the conversation on social using #whyimote</Subheader>

        <TestimonialsContainer>
          <Slider ref={slider} {...settings}>
            {renderTestimonials()}
          </Slider>

          {!isMobile && (
            <Controls>
              <div onClick={() => slider.current.slickPrev()}>
                <ArrowIcon color="white" />
              </div>
              <div onClick={() => slider.current.slickNext()}>
                <ArrowIcon color="white" />
              </div>
            </Controls>
          )}
        </TestimonialsContainer>
      </Content>
    </Container>
  )
}

const TestimonialCard = ({
  description,
  firstName,
  lastName,
  title,
  image,
  mote,
  faded
}) => {
  return (
    <CardContainer>
      <Card faded={faded}>
        <Description>{description}</Description>

        <div>
          <Avatar src={image} />
          <Name>
            {firstName} {lastName}
          </Name>
          <Title>{title}</Title>
          <Link href={mote}>
            Hear more from {firstName} <ArrowIcon />
          </Link>
        </div>
      </Card>
    </CardContainer>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  font-family: DM Sans;
`

const Content = styled.div`
  z-index: 1;
`

const Background = styled.div`
  position: absolute;
  background: ${mote_primary};
  height: 800px;
  width: 100vw;

  @media (max-width: 1199px) {
    height: 620px;
  }

  @media (max-width: 991px) {
    height: 570;
  }
`

const TestimonialsContainer = styled.div`
  width: 1150px;
  height: 600px;
`

const CardContainer = styled.div`
  padding: 0 10px;
`

const Card = styled.div`
  background: white;
  border-radius: 10px;
  padding: 24px;
  width: 334px;
  height: 353px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  cursor: pointer;

  ${({ faded }) =>
    faded &&
    css`
      opacity: 0.5;

      > div {
        display: none;
      }
    `};
`

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-bottom: 8px;
`

const Description = styled.div`
  font-size: 18px;
  line-height: 150%;
`

const Name = styled.div`
  font-size: 16px;
`

const Title = styled.div`
  font-size: 14px;
  margin-bottom: 24px;
`

const Link = styled.a`
  color: ${mote_primary};
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;

  :hover {
    color: ${mote_primary};

    > * {
      transform: translateX(10px);
    }
  }

  > * {
    margin-left: 10px;
    transition: transform 0.2s;
  }
`

const Header = styled.div`
  color: white;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 96px;

  @media (max-width: 1199px) {
    font-size: 28px;
    margin-top: 80px;
  }

  @media (max-width: 991px) {
    font-size: 24px;
    margin-top: 60px;
  }

  @media (max-width: 639px) {
    font-size: 21px;
    margin-top: 40px;
  }
`

const Subheader = styled.div`
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 80px;

  @media (max-width: 1199px) {
    font-size: 16px;
    margin-bottom: 60px;
  }

  @media (max-width: 991px) {
    margin-bottom: 48px;
  }

  @media (max-width: 639px) {
    margin-bottom: 32px;
  }
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  > * {
    transform: scale(2);
    cursor: pointer;

    &:first-of-type {
      transform: scale(2) rotate(180deg);
      margin-top: 11px;
      margin-right: 50px;
    }
  }
`

const Dots = styled.ul`
  bottom: -50px;
`

const Dot = styled.li`
  background: white;
  border-radius: 100%;
  width: 8px !important;
  height: 8px !important;
  opacity: 0.5;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `};
`
