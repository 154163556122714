import React from 'react'

export const useHover = () => {
  const [isHovered, setIsHovered] = React.useState(false)
  const hoverRef = React.useRef(null)

  const handleMouseOver = () => setIsHovered(true)
  const handleMouseOut = () => setIsHovered(false)

  React.useEffect(() => {
    const node = hoverRef.current

    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseout', handleMouseOut)

      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseout', handleMouseOut)
      }
    }
  }, [hoverRef.current])

  return { hoverRef, isHovered }
}
