import React from 'react'
import { styled } from 'twin.macro'
import { useHistory } from 'react-router-dom'

import { Tooltip } from '@mote/ui'
import { mote_primary } from '@mote/config/defaults/colors'
import { useMoteConversationMutation } from '@mote/business'
import { track } from 'web/src/shared/AnalyticsHelpers'

import { useRecordReplySidebar } from './RecordReplySidebar.context'
import { MoteRepliesList } from './moteRepliesList'
import { Recorder } from '../webRecorder/Recorder'
import { Playback } from './moteReply/Playback'
import { ChevronRightIcon } from './ChevronRightIcon'
import { RecorderSpinner } from './Spinner'
import { getAccessToken } from '../../../../../apps/web/src/shared/Auth'

export const RecordReplySidebar = () => {
  const [mounted, setMounted] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [playbackMote, setPlaybackMote] = React.useState(null)
  const [refetchConversation, setRefetchConversation] = React.useState(false)
  const {
    state,
    toggle,
    addNewReply,
    removeLastReply
  } = useRecordReplySidebar()
  const history = useHistory()

  const {
    data: conversationData,
    mutate: fetchConversation
  } = useMoteConversationMutation(
    state.mote?.parentMote.moteId,
    refetchConversation ? 2000 : false
  )

  React.useEffect(() => {
    setMounted(true)
  }, [])

  React.useEffect(() => {
    if (!conversationData || !conversationData.replyMotes) return

    const pendingFinalization = conversationData.replyMotes.some(
      (reply) => !reply.isPlayable && !reply.hasFailed
    )

    if (!pendingFinalization) {
      const newReply =
        conversationData.replyMotes[conversationData.replyMotes.length - 1]

      setPlaybackMote(newReply)
      setRefetchConversation(false)
      setLoading(false)
      addNewReply(newReply)

      track('Mote Created Audio', {
        content_asset_id: newReply.moteId,
        is_reply_mote: true
      })
    }
  }, [conversationData])

  function onRecorderDone() {
    fetchConversation()
    setRefetchConversation(true)
  }

  if (!mounted) return null

  function renderRecorder() {
    return (
      <>
        {!playbackMote && (
          <HiddenElement hidden={loading}>
            <Recorder
              parentMoteId={state?.mote?.parentMote.moteId}
              onFinalize={onRecorderDone}
              onStop={() => setLoading(true)}
            />
          </HiddenElement>
        )}

        {playbackMote && (
          <HiddenElement hidden={loading}>
            <Playback
              moteId={playbackMote.moteId}
              audioUrl={playbackMote.playbackLocation}
              onReset={() => {
                setPlaybackMote(null)
              }}
              onDelete={() => {
                setPlaybackMote(null)
                removeLastReply()
              }}
            />
          </HiddenElement>
        )}
      </>
    )
  }

  return (
    <Root open={state.isOpen} mounted={mounted}>
      <Header open={state.isOpen}>
        <ChevronRightIconStyled
          open={state.isOpen}
          onClick={toggle}
          tooltipText="Close"
        />

        <ReplyingTo>
          Reply to {state.mote?.parentMote.createdByGreeting}
        </ReplyingTo>

        {getAccessToken() ? (
          renderRecorder()
        ) : (
          <LoginPrompt>
            <LoginButton
              onClick={() =>
                history.push(`/login?returnTo=${window.location.pathname}`)
              }
            >
              Login to reply
            </LoginButton>
          </LoginPrompt>
        )}

        {loading && <RecorderSpinner />}
      </Header>

      <ReplyingToContainer open={state.isOpen}>
        <MoteRepliesList
          replies={state.mote?.replyMotes}
          isOpen={state.isOpen}
        />
        <Tooltip />
      </ReplyingToContainer>
    </Root>
  )
}

const Root = styled.div`
  height: 0px;
  background: white;
  opacity: 0;
  width: 1px;
  flex: 0 0 1px;
  border-left: 1px solid #d5d4da;
  transition: all 150ms ease;
  display: none;

  ${({ mounted }) => mounted && `display: initial;`}

  ${({ open }) =>
    open &&
    `
    height: 100vh;
    opacity: 1;
    width: 375px;
    flex: 0 0 375px;
  `};

  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
  }
`

const Header = styled.header`
  position: relative;
  background: #f9f9fb;
  padding: 25px;
  display: none;

  ${({ open }) =>
    open &&
    `
    display: block;
  `};
`

const ReplyingToContainer = styled.div`
  position: relative;

  ${({ open }) =>
    open &&
    `
    padding: 0 25px;
  `};
`

const ReplyingTo = styled.p`
  text-align: center;
  line-height: 19px;
`

const ChevronRightIconStyled = styled(ChevronRightIcon)`
  position: absolute;
  top: 25px;
  left: 25px;
`

const HiddenElement = styled.div`
  ${({ hidden }) => (hidden ? `display: none;` : `display: block`)}
`

const LoginPrompt = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '75px 0 50px 0'
})

const LoginButton = styled.a({
  borderRadius: '6px',
  background: mote_primary,
  color: 'white !important',
  padding: '8px 40px',
  fontSize: '16px'
})
