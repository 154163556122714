export const CANONICAL_DOMAIN = 's.justmote.me'
export const SHORT_LINK_DOMAIN =
  process.env.REACT_APP_SHORT_LINK_DOMAIN || 'https://mote.fyi/'
export const TEST_BASE_URL = 'https://dev.s.justmote.me/'
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || `https://${CANONICAL_DOMAIN}/`
export const SHORT_LINK_BASE_URL = SHORT_LINK_DOMAIN
export const SITE_URL =
  process.env.REACT_APP_SITE_URL || `https://www.justmote.me/`
export const LOGIN_URL = SITE_URL + 'login'
export const PRICING_URL = SITE_URL + 'pricing'
export const ACCOUNT_URL = SITE_URL + 'account'
export const ACTIVITY_URL = SITE_URL + 'account/activity'
export const FORCE_LOGIN_URL = SITE_URL + 'login?force=1'
export const SUPPORT_URL = 'https://support.mote.com/'
export const UNINSTALL_URL = 'https://www.justmote.me/uninstalled'
export const STEM_INFO_URL =
  'https://support.mote.com/hc/en-us/articles/360051688971'
export const INSTALL_URL = SITE_URL + 'login?welcome'
export const GDOCS = 'gdocs'
export const GSLIDES = 'gslides'
export const GSHEETS = 'gsheets'
export const GFORMS = 'gforms'
export const GCLASSROOM = 'gclassroom'
export const GCLASSROOM_PRIVATE_COMMENTS = GCLASSROOM + '-private-comments'
export const GCLASSROOM_STUDENT_WORK = GCLASSROOM + '-student-work'
export const GCLASSROOM_INSTRUCTIONS = GCLASSROOM + '-instructions'
export const GCLASSROOM_HOME = GCLASSROOM + '-home'
export const NETWORK_TIMEOUT = 5 * 1000
export const NETWORK_TIMEOUT_LONG = 15 * 1000
export const NETWORK2_QUEUE_LIMIT = 4
export const NETWORK2_CONSECUTIVE_MOTE_FAILURE_LIMIT = 5
export const NETWORK2_RECENCY_THRESHOLD = 60 * 1000
export const NETWORK2_MINIMUM_FAF_SPACING = 2000
export const MILLISECONDS_PER_CHUNK = 250
// export const MAX_SECONDS_PER_MOTE=5;
export const FALLBACK_MAX_SECONDS_PER_MOTE = 180
export const MAX_ADDITIONAL_SECONDS_BEFORE_AUTO_CANCELLING = 2
export const TRACKING_BUFFER_TIMEOUT = 60 * 1000
export const MAXIMUM_TRACKING_BUFFER_LENGTH = 10

export const DOM_MUTATION_HYSTERISIS = 100
export const DOM_MUTATION_QUIESCENCE = 1000
export const URL_CHANGE_POLLING_FREQUENCY = 2000

export const TRANSCRIPTION_LANGUAGES = [
  ['en-US', 'English (US)'],
  ['en-GB', 'English (UK)'],
  ['en-IN', 'English (IN)'],
  ['en-AU', 'English (AU)'],
  ['en-NZ', 'English (NZ)'],
  ['es-US', 'Spanish (US)'],
  ['es-ES', 'Spanish (Spain)'],
  ['ar-LB', 'Arabic (Lebanon)'],
  ['ar-AE', 'Arabic (UAE)'],
  ['hy-AM', 'Armenian'],
  ['eu-ES', 'Basque'],
  ['ca-ES', 'Catalan'],
  ['cs-CZ', 'Czech'],
  ['da-DK', 'Danish'],
  ['nl-NL', 'Dutch'],
  ['fi-FI', 'Finnish'],
  ['fr-CA', 'French (Canada)'],
  ['fr-FR', 'French (France)'],
  ['de-DE', 'German'],
  ['el-GR', 'Greek'],
  ['iw-IL', 'Hebrew'],
  ['hu-HU', 'Hungarian'],
  ['id-ID', 'Indonesian'],
  ['it-IT', 'Italian'],
  ['ja-JP', 'Japanese'],
  ['ko-KR', 'Korean'],
  ['zh-CN', 'Mandarin (Simplified)'],
  ['zh-TW', 'Mandarin (Traditional)'],
  ['fa-IR', 'Persian'],
  ['pl-PL', 'Polish'],
  ['pt-BR', 'Portuguese (Brazil)'],
  ['pt-PT', 'Portuguese (Portugal)'],
  ['ro-RO', 'Romanian'],
  ['sw-TZ', 'Swahili (Tanzania)'],
  ['sv-SE', 'Swedish'],
  ['th-TH', 'Thai'],
  ['tr-TR', 'Turkish']
]

export const TRANSLATION_LOCALES = [
  ['es', 'Spanish'],
  ['en', 'English'],
  ['ar', 'Arabic'],
  ['hy', 'Armenian'],
  ['nl', 'Dutch'],
  ['fr', 'French'],
  ['de', 'German'],
  ['el', 'Greek'],
  ['ht', 'Haitian Creole'],
  ['iw', 'Hebrew'],
  ['hu', 'Hungarian'],
  ['id', 'Indonesian'],
  ['it', 'Italian'],
  ['ja', 'Japanese'],
  ['ko', 'Korean'],
  ['ms', 'Malay'],
  ['ps', 'Pashto'],
  ['fa', 'Persian'],
  ['zh-CN', 'Mandarin (Simplified)'],
  ['zh-TW', 'Mandarin (Traditional)'],
  ['pt', 'Portuguese'],
  ['ro', 'Romanian'],
  ['sm', 'Samoan'],
  ['sw', 'Swahili'],
  ['mi', 'Te reo (Maori)'],
  ['th', 'Thai'],
  ['ur', 'Urdu'],
  ['vi', 'Vietnamese'],
  ['cy', 'Welsh']
]

export const SAMPLEABLE_EVENT_WHITELIST = [
  'Supported Application Page Loaded',
  'Hiding Mote Icon'
]
export const SAMPLE_CHARS = 'AZ09'.split('')
export const DEFAULT_TRANSCRIPTION_LANGUAGE = TRANSCRIPTION_LANGUAGES[0][0]

export const NOTIFICATION_ONBOARDING = 'OnboardingNotification'
export const NOTIFICATION_APP_UPGRADED = 'UpgradeNotification'
export const NOTIFICATION_NEWEST_LEGALS = 'LegalsNotification'
export const REQUIRED_LEGALS_VERSION = 1
export const LAST_UPGRADE_MESSAGE_VERSION = '0.0.4.1'
export const ERROR_HAS_FAILED = 'has failed'
export const ERROR_TOO_MANY_POLLS = 'too many polls'
export const DIAGNOSTICS_EMAIL = 'diagnostics@justmote.me'
export const DIAGNOSTICS_SUBJECT = 'Mote%20diagnostics%20report'

// only update above if there is a new version and you want users to
// see the UpgradeNotification message.
// Can be any non-repeating series, including manifest version numbers
// (though not all extension versions merit a distinct upgrade message,
// which would cause upgrade fatigue)
