import React from 'react'

export const FacebookIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M7.21472 5.35296H4.98987V3.88584C4.98987 3.63864 5.05973 3.45592 5.19945 3.33769C5.30694 3.25171 5.44129 3.20871 5.60251 3.20871H7.18248V0.790393L5.00599 0.774271C4.26437 0.774271 3.65173 0.94624 3.16807 1.29018C2.77039 1.56963 2.47481 1.95656 2.28135 2.45097C2.13087 2.84865 2.05564 3.27858 2.05564 3.74074V5.35296H0.669134V7.83577H2.05564V14.8811H4.98987V7.83577H6.95677L7.21472 5.35296Z"
        fill="currentColor"
      />
    </svg>
  )
}
