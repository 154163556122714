import React from 'react'
import { useMediaUploader } from '@mote/business'

const MILLISECONDS_PER_CHUNK = 250

export const RECORDER_STATUS = Object.freeze({
  inactive: 'inactive',
  recording: 'recording',
  paused: 'paused',
  stopped: 'stopped',
  cancelled: 'cancelled'
})

const defaultOptions = { parentMoteId: null, maxSeconds: 30 }

export const useRecorder = (options = defaultOptions) => {
  const mergedOptions = Object.assign(defaultOptions, options)
  const {
    register,
    sendChunk,
    finalize,
    cleanup: uploaderCleanup,
    error: uploaderError
  } = useMediaUploader(mergedOptions.parentMoteId)

  const [status, setStatus] = React.useState(RECORDER_STATUS.inactive)
  const [error, setError] = React.useState(null)

  let stream = React.useRef(null)
  let recorder = React.useRef(null)
  let chunks = React.useRef([])
  let chunkCount = React.useRef(null)

  const maxChunkCount =
    (mergedOptions.maxSeconds * 1000) / MILLISECONDS_PER_CHUNK

  async function getStream() {
    try {
      const audioStream = await window.navigator.mediaDevices.getUserMedia({
        audio: true
      })

      stream.current = audioStream
    } catch (error) {
      setError(error)
    }
  }

  async function start() {
    register()

    if (!stream.current) await getStream()
    chunks.current = []

    recorder.current = new MediaRecorder(stream.current)
    recorder.current.addEventListener('dataavailable', handleChunk)
    recorder.current.addEventListener('stop', stop)
    recorder.current.start(MILLISECONDS_PER_CHUNK)
    setStatus(RECORDER_STATUS.recording)
  }

  function pause() {
    if (recorder.current?.state !== RECORDER_STATUS.recording) return
    recorder.current.pause()
    setStatus(RECORDER_STATUS.paused)
  }

  function resume() {
    if (recorder.current?.state !== RECORDER_STATUS.paused) return
    recorder.current.resume()
    setStatus(RECORDER_STATUS.recording)
  }

  async function stop() {
    if (
      !recorder.current ||
      recorder.current.state === RECORDER_STATUS.inactive
    )
      return
    recorder.current.stop()

    setStatus(RECORDER_STATUS.stopped)

    await finalize()
    cleanup()
  }

  function cancel() {
    if (!recorder.current) return
    recorder.current.stop()

    setStatus(RECORDER_STATUS.cancelled)
    cleanup()
  }

  function handleChunk(e) {
    if (!e.data || !e.data.size) return
    if (uploaderError) {
      cleanup()
      setError('Media uploader failure!')
    }

    chunkCount.current++

    const hitChunkLimit = chunkCount.current >= maxChunkCount
    hitChunkLimit ? stop() : sendChunk(e.data)
  }

  function cleanup() {
    setError(null)
    uploaderCleanup()

    if (recorder.current) {
      recorder.current.removeEventListener('dataavailable', handleChunk)
      recorder.current.removeEventListener('stop', stop)
      recorder.current = null
    }

    chunks.current = []
    chunkCount.current = null

    if (stream.current) {
      stream.current.getTracks().forEach((track) => track.stop())
      stream.current = null
    }
  }

  return {
    getStream,
    start,
    pause,
    stop,
    cancel,
    resume,
    status,
    error,
    cleanup
  }
}
