import React from 'react'

export const RewindPassive = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#E9E9E9" />
    <path
      d="M12.5711 7.50334V4.76353L9.14636 8.18829L12.5711 11.6131V8.87325C14.8383 8.87325 16.6808 10.7158 16.6808 12.983C16.6808 15.2501 14.8383 17.0927 12.5711 17.0927C10.3039 17.0927 8.46141 15.2501 8.46141 12.983H7.0915C7.0915 16.0104 9.54363 18.4626 12.5711 18.4626C15.5986 18.4626 18.0507 16.0104 18.0507 12.983C18.0507 9.95547 15.5986 7.50334 12.5711 7.50334Z"
      fill="#C4C4C4"
    />
    <path
      d="M11.6739 13.5994L11.8451 12.1131H13.4822V12.5994H12.3178L12.2424 13.2295C12.263 13.2158 12.2904 13.209 12.3178 13.1953C12.3452 13.1816 12.3794 13.1679 12.4205 13.161C12.4616 13.1542 12.5027 13.1405 12.5438 13.1336C12.5849 13.1268 12.6328 13.1199 12.6808 13.1199C12.8246 13.1199 12.9479 13.1405 13.0575 13.1884C13.1671 13.2364 13.263 13.298 13.3383 13.3802C13.4137 13.4624 13.4753 13.5652 13.5096 13.6885C13.5438 13.8117 13.5712 13.9487 13.5712 14.0994C13.5712 14.2296 13.5507 14.3529 13.5096 14.4693C13.4685 14.5857 13.4068 14.6885 13.3246 14.7775C13.2424 14.8666 13.1397 14.9419 13.0164 14.9899C12.8931 15.0378 12.7493 15.0721 12.578 15.0721C12.4547 15.0721 12.3315 15.0515 12.215 15.0173C12.0986 14.983 11.9958 14.9214 11.8999 14.8529C11.804 14.7844 11.7355 14.6885 11.6807 14.5857C11.626 14.483 11.5917 14.3597 11.5917 14.2227H12.1671C12.1808 14.346 12.2219 14.4419 12.2972 14.5035C12.3726 14.5652 12.4684 14.6063 12.5849 14.6063C12.6602 14.6063 12.7219 14.5926 12.7698 14.5652C12.8178 14.5378 12.8657 14.4967 12.8931 14.4488C12.9205 14.4008 12.9479 14.346 12.9685 14.2775C12.989 14.209 12.989 14.1405 12.989 14.0652C12.989 13.9898 12.9822 13.9213 12.9616 13.8528C12.9411 13.7844 12.9137 13.7364 12.8726 13.6885C12.8315 13.6405 12.7835 13.6063 12.7287 13.5857C12.6739 13.5652 12.5986 13.5515 12.5232 13.5515C12.4684 13.5515 12.4205 13.5583 12.3862 13.5652C12.352 13.572 12.3109 13.5857 12.2835 13.5994C12.2561 13.6131 12.2287 13.6337 12.2013 13.6474C12.1739 13.6611 12.1534 13.6885 12.1328 13.709L11.6739 13.5994Z"
      fill="#C4C4C4"
    />
  </svg>
)

export const RewindActive = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#E9E9E9" />
    <path
      d="M12.5711 7.50334V4.76353L9.14635 8.18829L12.5711 11.6131V8.87325C14.8383 8.87325 16.6808 10.7158 16.6808 12.983C16.6808 15.2501 14.8383 17.0927 12.5711 17.0927C10.3039 17.0927 8.4614 15.2501 8.4614 12.983H7.09149C7.09149 16.0104 9.54362 18.4626 12.5711 18.4626C15.5986 18.4626 18.0507 16.0104 18.0507 12.983C18.0507 9.95547 15.5986 7.50334 12.5711 7.50334Z"
      fill="#AC0AE8"
    />
    <path
      d="M11.6739 13.5994L11.8451 12.1131H13.4821V12.5994H12.3177L12.2424 13.2295C12.2629 13.2158 12.2903 13.209 12.3177 13.1953C12.3451 13.1816 12.3794 13.1679 12.4205 13.161C12.4616 13.1542 12.5027 13.1405 12.5438 13.1336C12.5849 13.1268 12.6328 13.1199 12.6807 13.1199C12.8246 13.1199 12.9479 13.1405 13.0575 13.1884C13.1671 13.2364 13.263 13.298 13.3383 13.3802C13.4136 13.4624 13.4753 13.5652 13.5095 13.6885C13.5438 13.8117 13.5712 13.9487 13.5712 14.0994C13.5712 14.2296 13.5506 14.3529 13.5095 14.4693C13.4684 14.5857 13.4068 14.6885 13.3246 14.7775C13.2424 14.8666 13.1397 14.9419 13.0164 14.9899C12.8931 15.0378 12.7492 15.0721 12.578 15.0721C12.4547 15.0721 12.3314 15.0515 12.215 15.0173C12.0985 14.983 11.9958 14.9214 11.8999 14.8529C11.804 14.7844 11.7355 14.6885 11.6807 14.5857C11.6259 14.483 11.5917 14.3597 11.5917 14.2227H12.167C12.1807 14.346 12.2218 14.4419 12.2972 14.5035C12.3725 14.5652 12.4684 14.6063 12.5849 14.6063C12.6602 14.6063 12.7218 14.5926 12.7698 14.5652C12.8177 14.5378 12.8657 14.4967 12.8931 14.4488C12.9205 14.4008 12.9479 14.346 12.9684 14.2775C12.989 14.209 12.989 14.1405 12.989 14.0652C12.989 13.9898 12.9821 13.9213 12.9616 13.8528C12.941 13.7844 12.9136 13.7364 12.8725 13.6885C12.8314 13.6405 12.7835 13.6063 12.7287 13.5857C12.6739 13.5652 12.5986 13.5515 12.5232 13.5515C12.4684 13.5515 12.4205 13.5583 12.3862 13.5652C12.352 13.572 12.3109 13.5857 12.2835 13.5994C12.2561 13.6131 12.2287 13.6337 12.2013 13.6474C12.1739 13.6611 12.1533 13.6885 12.1328 13.709L11.6739 13.5994Z"
      fill="#AC0AE8"
    />
  </svg>
)

export const SkipToStart = () => (
  <svg
    className="recorder-icon icon-skip-to-start"
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    fill="none"
    viewBox="0 0 51 51"
  >
    <circle
      className="icon-background"
      cx="25.5"
      cy="25.5"
      r="25"
      fill="#fff"
      stroke="#E9E9E9"
    ></circle>
    <path
      className="icon-outline"
      fill="#333"
      d="M19.27 32.397c0 .266.194.455.467.455h1.87c.234 0 .468-.19.468-.455v-6.635l7.596 6.825c.819.644 2.065.113 2.065-.948V17.08c0-1.023-1.247-1.554-2.065-.91l-7.596 6.863v-6.711c0-.228-.234-.455-.468-.455h-1.87a.467.467 0 00-.467.455v16.075z"
    ></path>
  </svg>
)

export const Play = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="17.5" fill="white" stroke="#E9E9E9" />
    <path
      d="M23.827 16.9555L14.7199 11.7357C13.9696 11.3091 12.8571 11.7357 12.8571 12.7646V23.2041C12.8571 24.1577 13.892 24.7349 14.7199 24.2581L23.827 19.0383C24.629 18.5615 24.629 17.4323 23.827 16.9555Z"
      fill="#AC0AE8"
    />
  </svg>
)

export const Pause = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="17.5" fill="#AC0AE8" />
    <circle cx="18" cy="18" r="18" fill="white" fillOpacity="0.95" />
    <rect x="13" y="11" width="4" height="14" fill="#AC0AE8" />
    <rect x="19" y="11" width="4" height="14" fill="#AC0AE8" />
  </svg>
)

export const Delete = () => (
  <svg
    className="recorder-icon icon-delete"
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    fill="none"
    viewBox="0 0 51 51"
  >
    <circle
      className="icon-background"
      cx="25.5"
      cy="25.5"
      r="25"
      fill="#fff"
      stroke="#E9E9E9"
    ></circle>
    <path
      className="icon-outline"
      fill="#333"
      d="M33.79 17.395h-3.306l-1.355-2.232c-.319-.518-1.036-.956-1.634-.956h-4.024c-.598 0-1.315.438-1.634.956l-1.354 2.232h-3.267a.655.655 0 00-.638.637v.637a.63.63 0 00.638.638h.637l.837 13.507c.04.996.916 1.793 1.912 1.793h9.762c.996 0 1.873-.797 1.913-1.793l.876-13.507h.638a.655.655 0 00.637-.637v-.638c0-.319-.319-.637-.637-.637zm-10.319-1.276h4.024l.757 1.276h-5.538l.757-1.276zm6.893 16.575h-9.762l-.836-13.387H31.2l-.837 13.387z"
    ></path>
  </svg>
)

export const Volume = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.16767 2.2203L4.202 4.99999H0.8C0.358 4.99999 0 5.33562 0 5.74999V10.25C0 10.6641 0.358 11 0.8 11H4.202L7.16767 13.7797C7.66867 14.2494 8.53333 13.9194 8.53333 13.2494V2.75062C8.53333 2.07999 7.668 1.75124 7.16767 2.2203ZM16 7.99999C16 6.01468 14.9313 4.18937 13.141 3.11749C12.768 2.89437 12.2733 2.99812 12.037 3.35062C11.8007 3.70312 11.911 4.16968 12.284 4.39312C13.609 5.18655 14.4 6.53468 14.4 7.99999C14.4 9.4653 13.609 10.8134 12.284 11.6069C11.911 11.83 11.8007 12.2966 12.037 12.6494C12.254 12.9731 12.741 13.1225 13.141 12.8825C14.9313 11.8106 16 9.9853 16 7.99999ZM11.2743 5.5978C10.8883 5.39999 10.4013 5.5303 10.1873 5.89312C9.97433 6.25593 10.1153 6.71187 10.5023 6.91218C10.9327 7.13374 11.2 7.55093 11.2 7.99999C11.2 8.44937 10.9327 8.86624 10.5027 9.0878C10.1157 9.28812 9.97467 9.74406 10.1877 10.1069C10.402 10.4712 10.8893 10.6006 11.2747 10.4022C12.2157 9.91624 12.8003 8.99593 12.8003 7.99968C12.8003 7.00343 12.2157 6.08343 11.2743 5.5978Z"
      fill="#AC0AE8"
    />
  </svg>
)

export const Mute = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M6.71969 2.2203L3.93937 4.99999H0.75C0.335625 4.99999 0 5.33562 0 5.74999V10.25C0 10.6641 0.335625 11 0.75 11H3.93937L6.71969 13.7797C7.18937 14.2494 8 13.9194 8 13.2494V2.75062C8 2.07999 7.18875 1.75124 6.71969 2.2203ZM14.4263 7.99999L15.8525 6.57374C16.0494 6.37687 16.0494 6.05749 15.8525 5.86062L15.1394 5.14749C14.9425 4.95062 14.6231 4.95062 14.4263 5.14749L13 6.57374L11.5737 5.14749C11.3769 4.95062 11.0575 4.95062 10.8606 5.14749L10.1475 5.86062C9.95063 6.05749 9.95063 6.37687 10.1475 6.57374L11.5737 7.99999L10.1478 9.42593C9.95094 9.62281 9.95094 9.94218 10.1478 10.1391L10.8609 10.8522C11.0578 11.0491 11.3772 11.0491 11.5741 10.8522L13 9.42624L14.4263 10.8525C14.6231 11.0494 14.9425 11.0494 15.1394 10.8525L15.8525 10.1394C16.0494 9.94249 16.0494 9.62312 15.8525 9.42624L14.4263 7.99999Z"
        fill="#AC0AE8"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
