import React from 'react'

function calculateBreakpoint(breakpoints = [], currentBreakpoint = null) {
  if (!breakpoints.length) return null

  const windowWidth = window.innerWidth
  if (windowWidth > (breakpoints[0] || currentBreakpoint)) return breakpoints[0]

  return breakpoints.reduce((acc, breakpoint) => {
    if (windowWidth < breakpoint) {
      acc = breakpoint
    }
    return acc
  }, null)
}

export const useMediaQuery = (breakpoints = []) => {
  const sortedBreakpoints = React.useMemo(
    () => (breakpoints ?? []).sort((a, b) => b - a),
    [breakpoints]
  )
  const [breakpoint, setBreakpoint] = React.useState(
    calculateBreakpoint(sortedBreakpoints, null)
  )

  React.useEffect(() => {
    function handleWindowResize() {
      const newBreakpoint = calculateBreakpoint(sortedBreakpoints, breakpoint)

      if (breakpoint !== newBreakpoint) {
        setBreakpoint(newBreakpoint)
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [sortedBreakpoints, breakpoint])

  return breakpoint
}
