import React from 'react'
import { beginAnimation, endAnimation } from './animationHelper'
import { css, styled } from 'twin.macro'
import { mote_primary } from '@mote/config/defaults/colors'

export const AudioAnimation = ({ audioStream, audioContext, children }) => {
  const [amplitude, setAmplitude] = React.useState(0)
  const childRef = React.useRef(null)

  React.useEffect(() => {
    if (!beginAnimation || !endAnimation) return

    beginAnimation(audioStream, audioContext, setAmplitude)

    return () => {
      setAmplitude(0)
      endAnimation()
    }
  }, [audioStream, audioContext])

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'relative', zIndex: '1' }} ref={childRef}>
        {children}
      </div>

      <ReactiveBGCircle
        width={childRef.current?.clientWidth}
        height={childRef.current?.clientHeight}
        style={{ transform: `scale(${limitAmplitude(amplitude)})` }}
      />
    </div>
  )
}

const limitAmplitude = (amplitude, amplitudeMax = 1.8) => {
  const newAmplitude = 1 + amplitude * 3
  if (newAmplitude > amplitudeMax) {
    return amplitudeMax
  }
  return newAmplitude
}

const ReactiveBGCircle = styled.div(({ width = 0, height = 0 }) => [
  css`
    width: ${width}px;
    height: ${height}px;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: ${mote_primary};
    border-radius: ${width}px;
  `
])
