import React from 'react'
import { styled } from 'twin.macro'
import { useAudioPlayer } from 'react-use-audio-player'

import { Tooltip } from '@mote/ui'

import { Scrubber } from './Scrubber'
import { DurationIndicator } from './DurationIndicator'
const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(android)|(webOS)/i)

export const ReplyPlayer = React.memo(
  ({ audioUrl, onCopyToClipboard, replyIsMine }) => {
    const { play, pause, playing } = useAudioPlayer({
      src: audioUrl,
      html5: isMobile,
      format: ['mp3'],
      autoplay: false
    })

    return (
      <Root>
        {!playing && (
          <IconContainer
            onClick={(e) => {
              e.stopPropagation()
              play()
            }}
            data-testid="play-button"
          >
            <Play />
          </IconContainer>
        )}

        {playing && (
          <IconContainer
            onClick={(e) => {
              e.stopPropagation()
              pause()
            }}
            data-testid="pause-button"
          >
            <Pause onClick={pause} />
          </IconContainer>
        )}

        <ScrubberDurationContainer>
          <Scrubber replyIsMine={replyIsMine} />
          <DurationIndicator replyIsMine={replyIsMine} />
        </ScrubberDurationContainer>

        {onCopyToClipboard && (
          <CopyLinkContainer data-tip="Copy link" onClick={onCopyToClipboard}>
            <CopyLink replyIsMine={replyIsMine} />
          </CopyLinkContainer>
        )}

        <Tooltip />
      </Root>
    )
  }
)

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
`

const ScrubberDurationContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-left: 13px;
`

const CopyLinkContainer = styled.div`
  cursor: pointer;
  margin-left: 16px;
  margin-top: -10px;
`

const IconContainer = styled.div`
  cursor: pointer;
`

export const Play = () => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24.3877" r="24" fill="white" />
    <circle
      cx="24"
      cy="24.3877"
      r="23.5"
      stroke="#D5D4DA"
      strokeOpacity="0.5"
    />
    <path
      d="M32.2722 23.2812L21.2538 17.1915C20.346 16.6938 19 17.1915 19 18.3919V30.5713C19 31.6838 20.2521 32.3572 21.2538 31.8009L32.2722 25.7112C33.2426 25.155 33.2426 23.8375 32.2722 23.2812Z"
      fill="#AC0AE8"
    />
  </svg>
)

export const Pause = () => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24.3877" r="24" fill="white" />
    <circle
      cx="24"
      cy="24.3877"
      r="23.5"
      stroke="#D5D4DA"
      strokeOpacity="0.5"
    />
    <rect
      x="19"
      y="16.3877"
      width="2.97"
      height="15.84"
      rx="1.485"
      fill="#AC0AE8"
    />
    <rect
      x="25.9302"
      y="16.3876"
      width="2.97"
      height="15.84"
      rx="1.485"
      fill="#AC0AE8"
    />
  </svg>
)

export const CopyLink = ({ replyIsMine }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.97469 1.80003C6.97469 1.1787 7.47838 0.675012 8.09971 0.675012H16.1999C16.8212 0.675012 17.3249 1.1787 17.3249 1.80003V9.90018C17.3249 10.5215 16.8212 11.0252 16.1999 11.0252H8.09971C7.47838 11.0252 6.97469 10.5215 6.97469 9.90018V1.80003Z"
        stroke={replyIsMine ? 'white' : '#AC0AE8'}
        strokeWidth="1.35002"
      />
      <rect
        x="0.675012"
        y="6.97469"
        width="10.3502"
        height="10.3502"
        rx="1.12502"
        stroke={replyIsMine ? 'white' : '#AC0AE8'}
        strokeWidth="1.35002"
      />
    </svg>
  )
}
