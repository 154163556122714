import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'

class Sandbox extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <h1>Hello World</h1>
        <p>How about them apples?</p>
        <Footer />
      </div>
    )
  }
}

export default Sandbox
