import React from 'react'
import { Emoji } from '@mote/ui'
import { RECORDER_STATUS, useRecorder, useCountdownTimer } from '@mote/business'
import {
  Container,
  Text,
  Controls,
  MainControlWrapper,
  ControlWrapper,
  PulsingDot
} from './elements'
import { Cancel, Complete, Resume, Pause, Microphone, Error } from './icons'
import { secondsToString } from '../../components/moteCard/utilities'
import { Hover } from './components/Hover'
import { StartRecording } from './components/StartRecording'
import { Notice } from './components/Notice'
import { black, mote_red, light_silver } from '@mote/config/defaults/colors'
import { AudioAnimation } from '../audioAnimation/audioAnimation'
import { useAudioStream } from '../audioAnimation/useAudioStream'
import { useMicrophone, useMicrophonePermission } from '@mote/business'
import { Tooltip } from '@mote/ui'

export const Recorder = ({ parentMoteId, onStop, onFinalize }) => {
  const { start, stop, pause, resume, cancel, status, error, cleanup } =
    useRecorder({
      parentMoteId
    })
  const { seconds, toggle, reset } = useCountdownTimer(30)
  const [startAudioStream, stopAudioStream, audioContext, audioStream] =
    useAudioStream()
  const { permissionStatus, permissionResponseReceived } =
    useMicrophonePermission()
  const { askForMicrophoneAccess } = useMicrophone()
  const [microphoneError, setMicrophoneError] = React.useState(null)

  const isSafari = /apple/i.test(navigator.vendor)

  async function startRecording() {
    if (!isSafari) {
      if (!permissionResponseReceived) return

      if (permissionStatus === 'prompt') {
        const micError = await askForMicrophoneAccess()
        if (micError) setMicrophoneError(micError)
        return
      }

      if (permissionStatus === 'denied') {
        setMicrophoneError({ name: 'NotAllowedError' })
        return
      }
    }

    toggle()
    start()

    if (!isSafari) startAudioStream()
  }

  function pauseRecording() {
    toggle()
    pause()
    if (!isSafari) stopAudioStream()
  }

  function resumeRecording() {
    toggle()
    resume()
    if (!isSafari) startAudioStream()
  }

  async function stopRecording() {
    toggle()
    reset()
    if (!isSafari) stopAudioStream()
    onStop()

    await stop()
    onFinalize()
  }

  function cancelRecording() {
    toggle()
    reset()
    cancel()
    if (!isSafari) stopAudioStream()
  }

  function isIdle() {
    return [
      RECORDER_STATUS.inactive,
      RECORDER_STATUS.stopped,
      RECORDER_STATUS.cancelled
    ].includes(status)
  }

  function resetError() {
    setMicrophoneError(null)
    cleanup()
  }

  function renderCancel() {
    if (isIdle()) return

    return (
      <ControlWrapper onClick={cancelRecording} data-tip="Cancel">
        <Hover
          prop={(isHovered) => ({
            color: isHovered ? mote_red : light_silver
          })}
        >
          <Cancel />
        </Hover>

        <Tooltip />
      </ControlWrapper>
    )
  }

  function renderStart() {
    if (!isIdle()) return

    return (
      <div onClick={startRecording}>
        <StartRecording />
      </div>
    )
  }

  function renderComplete() {
    if (isIdle()) return

    return (
      <div onClick={stopRecording} data-tip="Complete">
        <AudioAnimation audioContext={audioContext} audioStream={audioStream}>
          <MainControlWrapper>
            <Complete />
          </MainControlWrapper>
        </AudioAnimation>

        <Tooltip />
      </div>
    )
  }

  function renderPause() {
    if (status !== RECORDER_STATUS.recording) return

    return (
      <ControlWrapper
        onClick={isSafari ? () => {} : pauseRecording}
        isActive={!isSafari}
        data-tip={isSafari ? 'Disabled on Safari' : 'Pause'}
      >
        <Hover
          prop={(isHovered) => ({
            color: isHovered ? black : light_silver
          })}
        >
          <Pause />
        </Hover>

        <Tooltip />
      </ControlWrapper>
    )
  }

  function renderResume() {
    if (status !== RECORDER_STATUS.paused) return

    return (
      <ControlWrapper onClick={resumeRecording} data-tip="Resume">
        <Hover
          prop={(isHovered) => ({
            color: isHovered ? black : light_silver
          })}
        >
          <Resume />
        </Hover>

        <Tooltip />
      </ControlWrapper>
    )
  }

  function renderRecorder() {
    return (
      <Container>
        <Controls>
          {renderCancel()}

          {renderStart()}

          {renderComplete()}

          {renderPause()}

          {renderResume()}
        </Controls>

        {isIdle() && (
          <Text active={false}>
            Click <Emoji>☝️</Emoji> to record
          </Text>
        )}

        {status === RECORDER_STATUS.recording && (
          <Text>
            <PulsingDot />
            {secondsToString(seconds)}
          </Text>
        )}

        {status === RECORDER_STATUS.paused && <Text>Paused</Text>}
      </Container>
    )
  }

  function showError(error) {
    switch (error?.name) {
      case 'NotAllowedError':
        return (
          <Notice
            Icon={Microphone}
            title="Oh no!"
            description="Please check that access to your microphone is allowed for this website and try again."
            reset={resetError}
          />
        )

      case 'NotFoundError':
        return (
          <Notice
            Icon={Microphone}
            title="Microphone missing"
            description="We were unable to detect your microphone, please check your connection and try again."
            reset={resetError}
          />
        )

      default:
        return (
          <Notice
            Icon={Error}
            title="Oh no!"
            description="We encountered an unexpected error whilst recording your mote. Please try again."
            reset={resetError}
          />
        )
    }
  }

  return error || microphoneError
    ? showError(error || microphoneError)
    : renderRecorder()
}
