import React from 'react'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class Webinars extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className=" container mote-brand">
          <Header />
          <h1 className="mote-login-title">
            Join a webinar and raise your{' '}
            <span className="mote-purple">Mote</span> game!
          </h1>
          <p className="lead">
            Schedule of upcoming events - click the links to sign up!
          </p>
          <iframe
            title="Webinars"
            className="airtable-embed"
            src="https://airtable.com/embed/shr00o5BfoX5qyp50?backgroundColor=teal&viewControls=on"
            frameborder="0"
            onmousewheel=""
            width="100%"
            height="1700"
            styleName="background: transparent; border: 1px solid #ccc"
          />

          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

export default Webinars
