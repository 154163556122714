import React from 'react'
import CookieConsent from 'react-cookie-consent'
import './App.css'
import { LanguagePicker } from './languagePicker/LanguagePicker'

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <footer className="site-footer mt-5">
      <div className="container mote-brand">
        <hr />
        <div className="row justify-content-md-between">
          <div className="col-sm-12 col-md-3 mb-1">
            <h3 className="h5 mb-1">Mote's mission</h3>
            <p>
              We're making voice messaging faster and more useful for busy
              people everywhere.
            </p>
          </div>
          <div className="col-4 col-md-2 mb-1">
            <h3 className="h5 mb-1">Customers</h3>
            <ul className="nav flex-column">
              <li className="mb-1">
                <a href="/community">Community</a>
              </li>
              <li className="mb-1">
                <a href="/PrivacyCenter">Privacy</a>
              </li>
              <li className="mb-1">
                <a href="https://learn.justmote.me">Learning & ideas</a>
              </li>
            </ul>
          </div>
          <div className="col-4 col-md-2 mb-1">
            <h3 className="h5 mb-1">Company</h3>
            <ul className="nav flex-column">
              <li className="mb-1">
                <a href="/Careers">Careers</a>
              </li>
              <li className="mb-1">
                <a href="https://www.notion.so/Mote-Press-1b8d3ace9bb44ccd97cec2c4d0e5d432">
                  Press
                </a>
              </li>
              <li className="mb-1">
                <a href="/contact">Contact us</a>
              </li>
            </ul>
          </div>
          <div className="col-4 col-md-2 mb-1">
            <h3 className="h5 mb-1">Access</h3>
            <ul className="nav flex-column">
              <li className="mb-1">
                <LanguagePicker />
              </li>
              <li className="mb-1">
                <a href="https://support.mote.com/hc">Support</a>
              </li>
              <li className="mb-1">
                <a href="https://mote.canny.io/feature-requests">Requests</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-center mt-1">
          <p className="small text-muted">
            &copy; Mote Technologies, Inc. {currentYear}. Brought to you with{' '}
            <i
              style={{ color: '#ac0ae8', fontSize: '14pt' }}
              className="material-icons"
            >
              favorite
            </i>{' '}
            from our global team.
          </p>
          <ul className="d-none d-md-block list-inline">
            <li className="list-inline-item">
              <a
                className="icon icon-sm icon-secondary"
                href="https://twitter.com/justmoteHQ"
              >
                <i className="icon-inner fab fa-twitter" aria-hidden="true" />
                <span className="sr-only">Twitter</span>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="icon icon-sm icon-secondary"
                href="https://www.linkedin.com/company/motehq/"
              >
                <i
                  className="icon-inner fab fa-linkedin-in"
                  aria-hidden="true"
                />
                <span className="sr-only">LinkedIn</span>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="icon icon-sm icon-secondary"
                href="https://www.facebook.com/moteHQ/"
              >
                <i
                  className="icon-inner fab fa-facebook-f"
                  aria-hidden="true"
                />
                <span className="sr-only">Facebook</span>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="icon icon-sm icon-secondary"
                href="https://www.instagram.com/justmotehq/"
              >
                <i className="icon-inner fab fa-instagram" aria-hidden="true" />
                <span className="sr-only">Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        className="mote-brand"
        buttonClasses="btn btn-primary"
        style={{ color: '#FFFFFF', background: '#222222' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
      >
        <b className="mote-purple">Mote </b>uses cookies to ensure we give you
        the best experience on our website.{' '}
        <span style={{ fontSize: '10px' }}>
          <br />
          For information about cookie settings, see{' '}
          <a
            style={{ color: 'white', textDecoration: 'underline' }}
            href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
          >
            here.
          </a>{' '}
          If you continue without changing your settings, we'll assume you're
          happy to receive cookies on the <b className="mote-purple">mote </b>{' '}
          website.
        </span>
      </CookieConsent>
    </footer>
  )
}
