import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AudioPlayerProvider } from 'react-use-audio-player'
import tw, { styled, css } from 'twin.macro'
import AugmentedControls from '../../Controls/AugmentedControls'
import Durations from '../../Durations'
import AugmentedScrubber from '../../Scrubber/AugmentedScrubber'

export const MiniCard = ({
  audioUrl,
  moteId,
  isMiniCard,
  totalTime,
  scrubberPercentage,
  disabled,
  loading,
  onPlay,
  onPause,
  onEnd
}) => {
  const [currentTime, setCurrentTime] = useState(0)
  const [forcePause, setForcePause] = useState(false)

  useEffect(() => {
    const currTime = +totalTime * (+scrubberPercentage / 100)
    setCurrentTime(currTime)
    if (+scrubberPercentage) setForcePause(true)
  }, [scrubberPercentage, totalTime])

  return (
    <AudioPlayerProvider>
      <PlayerAdjustment
        data-mote-id={moteId}
        isMiniCard={isMiniCard}
        onClick={(e) => e.stopPropagation()}
      >
        <AugmentedControls
          disabled={disabled}
          loading={loading}
          audioUrl={audioUrl}
          forcePause={forcePause}
          onPlay={onPlay}
          onPause={onPause}
          onEnd={onEnd}
        />

        <ScrubberAdjustment isMiniCard={isMiniCard}>
          {!isMiniCard ? (
            <Durations currentTime={currentTime} totalTime={totalTime} />
          ) : null}
          <AugmentedScrubber
            loading={loading}
            forcedProgress={scrubberPercentage}
          />
        </ScrubberAdjustment>
      </PlayerAdjustment>
    </AudioPlayerProvider>
  )
}

const PlayerAdjustment = styled.div(({ isMiniCard }) => [
  isMiniCard
    ? [
        tw`p-2 border border-gray-200 border-solid rounded-lg`,
        css`
          width: 260px;
        `
      ]
    : tw`mt-4 mb-6`
])

const ScrubberAdjustment = styled.div(({ isMiniCard }) => [
  isMiniCard ? tw`mx-5 mt-2` : tw`mx-12 -mt-2`
])

MiniCard.propTypes = {
  audioUrl: PropTypes.string.isRequired,
  moteId: PropTypes.string,
  isMiniCard: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnd: PropTypes.func
}
