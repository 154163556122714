import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'

class BeUnderstood extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <div className="container">
          <main id="main">
            <div className="container">
              <div className="row justify-content-center my-5">
                <div className="col-lg-9">
                  <figure>
                    <img
                      src="images/photos/nina-simone-please-dont-let-me.jpg"
                      className="img-fluid img-thumbnail"
                      alt="Nina Simone"
                    />
                    <figcaption className="figure-caption text-center">
                      <small>
                        Nina Simone, singing in London, September 1968
                      </small>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>

            <div className="bg-skew bg-skew-light">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <article className="pb-2">
                      <h1 className="text-center">
                        Please don't let me be misunderstood
                      </h1>
                      <p className="small mb-5 text-center">
                        <span className="text-secondary">published on </span>
                        <span className="text-uppercase">JAN 22, 2020</span>
                      </p>
                      <p className="lead">
                        Sometimes words on a screen just don't quite capture
                        what you want to say. Don't risk being misunderstood:
                        leave a voice note with{' '}
                        <b className="mote-purple">mote</b>.
                      </p>

                      <p>
                        Online collaboration tools, such as Google docs, Figma
                        and Notion, mark a huge leap forward for collaborative
                        creation. But in this leap, we seem to have missed an
                        important opportunity to improve collaboration: comments
                        don't need to be written.
                        <b className="mote-purple"> mote</b> is a tool that can
                        help teams improve their feedback using voice notes,
                        which we've found to be a much faster and more human way
                        to collaborate.{' '}
                      </p>
                      <div className="row justify-content-center">
                        <figure className="figure mt-2">
                          <img
                            src="images/photos/mote-screenshot.png"
                            className="img-fluid shadow"
                            alt="mote"
                          />
                          <figcaption className="figure-caption p-2 text-center">
                            The <b className="mote-purple">mote</b> Chrome
                            extension
                          </figcaption>
                        </figure>
                      </div>
                      <h2 className="h3 mt-4">The art of a great comment</h2>
                      <p>
                        We’re grateful to those who read and contribute to our
                        documents, and almost all comments and suggestions are
                        useful. But there's some people who seem to have a knack
                        for giving great feedback. What's their secret?
                      </p>

                      <ol>
                        <li>
                          <strong>Context.</strong> Feedback that sticks usually
                          comes wrapped in a contextual narrative. "I liked this
                          part because of A, but then this part confused me
                          because of B." Not only does context demonstrate the
                          commenter's thought process, it also makes the message
                          much easier to digest.{' '}
                          <b className="mote-purple">mote</b> makes it easier to
                          provide great context, because we can speak up to 3X
                          faster than we write.
                        </li>
                        <li>
                          <strong>Empathy.</strong> Great contributors focus on
                          the question of "what is the most helpful input I can
                          offer?" They understand and respect the rights of the
                          document owner, and they don't frame contributions in
                          terms of right/wrong. This approach minimizes the risk
                          that feedback will be 'taken the wrong way' or simply
                          ignored. In person feedback is perhaps the best way to
                          convey empathy, but{' '}
                          <b className="mote-purple">mote</b> can deliver many
                          of the same benefits without the need to schedule
                          meetings or travel.
                        </li>
                        <li>
                          <strong>Credibility.</strong> Domain expertise and a
                          demonstrated level of engagement with this particular
                          situation are two essential ingredients for
                          establishing and enhancing contributor credibility. By
                          making it easier and quicker to provide specific and
                          well reasoned feedback,{' '}
                          <b className="mote-purple">mote </b>can help everyone
                          become a more credible contributor.
                        </li>
                      </ol>

                      <p>
                        As a distributed team, we have created mote to solve a
                        problem we experienced ourselves, and we’re excited to
                        see how <b className="mote-purple">mote</b> is used by
                        others. If you’ve got a story to share about how you
                        handle document feedback, or how you’ve used{' '}
                        <b className="mote-purple">mote</b>, we’d love to hear
                        from you!
                      </p>

                      <div className="media my-5">
                        <img
                          className="avatar avatar-lg mr-3"
                          src="images/photos/Will.jpg"
                          alt="Post author"
                        />
                        <div className="media-body">
                          <h3 className="h5 mt-0">Will Jackson</h3>
                          <p className="mb-0 text-secondary">
                            Will is the co-founder of{' '}
                            <b className="mote-purple">mote</b>, and is based in
                            the SF Bay Area.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <div className="container py-1">
                <div className="text-center">
                  <div className="d-none d-md-block">
                    <h2 className="mb-4">Are you ready to start?</h2>
                    <a href="/chrome" className="btn btn-primary mr-2">
                      Add to Chrome
                    </a>
                  </div>
                </div>
              </div>
              <div className="container py-1">
                <div className="text-center">
                  <div className="d-block d-md-none">
                    <h2 className="mb-4">
                      Try <b className="mote-purple">mote</b>
                    </h2>
                    <a href="/chrome" className="btn btn-primary mr-2">
                      Send me a link
                    </a>
                  </div>
                </div>
              </div>
              <div className="container mb-lg-3"></div>
              <Footer />
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default BeUnderstood
