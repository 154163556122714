import React, { useEffect, useState } from 'react'

import { afterMoteAuth, getAccessToken } from '../../../shared/Auth'
import { BASE_URL, NETWORK_TIMEOUT } from '../../../shared/Constants'
import { Footer } from '../../../shared/Footer'
import Header from '../../../shared/Header'
import Loading from '../../../shared/Loading'
import { error } from '@mote/common'
import {
  isDevForceLogin,
  fiveTimesOutOfSix,
  noLog as log
} from '../../../shared/Utils'

const axios = require('axios').default
const axiosInstance = axios.create({
  baseURL: BASE_URL + 'i/',
  timeout: NETWORK_TIMEOUT
})

const OPT_OUT_KEY = 'opt-out-all'

const FAKE_DATA = {
  columns: [{ email: { en: 'Email' } }],
  map: {
    'activity-summaries': { email: true },
    'getting-started': { email: true },
    'opt-out-all': { email: false },
    'product-news': { email: true },
    milestones: { email: true }
  },
  rows: [
    { key: 'opt-out-all', strings: { en: 'Opt out of everything' } },
    { key: 'getting-started', strings: { en: 'Getting started' } },
    {
      key: 'activity-summaries',
      strings: { en: 'Daily mote activity summaries' }
    },
    { key: 'product-news', strings: { en: 'Product news' } },
    { key: 'milestones', strings: { en: 'Milestones and badges' } }
  ]
}

function NotificationPreferences() {
  const [hasAuthed, setHasAuthed] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [updateMessage, setUpdateMessage] = useState('')
  const [sadMessage, setSadMessage] = useState('')
  const [notificationPreferences, setNotificationPreferences] = useState({})
  const [networkError, setNetworkError] = useState('')

  useEffect(() => {
    log('some update')
    if (hasAuthed) return

    if (isDevForceLogin()) {
      setTimeout(() => {
        setHasAuthed(true)
        if (fiveTimesOutOfSix()) {
          setIsLoggedIn(true)
          _readWrite('get')
        } else {
          setIsLoading(false)
        }
      }, 1000)
    } else {
      afterMoteAuth(
        () => {
          setIsLoggedIn(true)
          setHasAuthed(true)
          _readWrite('get')
        },
        () => {
          setIsLoading(false)
          setHasAuthed(true)
        }
      )
    }
  })

  function _readWrite(mode) {
    if (isDevForceLogin()) {
      log('loading experimental data')
      setTimeout(() => {
        setIsLoading(false)
        setIsSaving(false)
        if (fiveTimesOutOfSix()) {
          log('setting not pref to: ', FAKE_DATA)
          setNotificationPreferences(FAKE_DATA)
        } else {
          log('setting network error')
          setNetworkError('Someting bad on network')
        }
      }, 1000)
    } else {
      axiosInstance
        .post('notifications', {
          accessToken: getAccessToken(),
          mode: mode,
          notificationPreferences: notificationPreferences
        })
        .then((response) => {
          setIsLoading(false)
          setIsSaving(false)
          setNotificationPreferences(response.data.notificationPreferences)
          if (mode === 'set') {
            setUpdateMessage('Your changes have been saved!')
            setTimeout(() => setUpdateMessage(''), 3000)
          }
        })
        .catch((e) => {
          setIsLoading(false)
          setIsSaving(false)
          error('Network error:' + e)
          var verb
          if (mode === 'get') {
            verb = 'read'
          } else {
            verb = 'update'
          }
          setNetworkError(
            `Sorry, we could not ${verb} your notification preferences. Please try again later or contact support@mote.com`
          )
        })
    }
  }

  function updatePreference(key, e) {
    log('I was updated ... ', key, e)
    var np = Object.assign({}, notificationPreferences)
    const newValue = e.target.checked
    np.map[key].email = newValue
    if (key === OPT_OUT_KEY && newValue) {
      setSadMessage(
        "OK, we're sad to see you go, but you can always change your mind later"
      )
      setTimeout(() => {
        setSadMessage('')
      }, 3000)

      for (let otherKey of Object.keys(np.map)) {
        if (otherKey !== OPT_OUT_KEY) {
          np.map[otherKey].email = false
        }
      }
    } else if (newValue && np.map[OPT_OUT_KEY].email) {
      np.map[OPT_OUT_KEY].email = false
    }
    setNotificationPreferences(np)
    log(e.target)
    log(e.target.type)
    log(e.target.checked)
  }

  function savePreferences() {
    setNetworkError()
    setIsSaving(true)
    _readWrite('set')
  }

  function renderLoggedIn() {
    log('notification preferences are: ', notificationPreferences)

    var hasPreferences = notificationPreferences && notificationPreferences.rows
    return (
      <div>
        <h1 className="mote-logged-in-title">Your notification preferences</h1>
        <p className="lead">
          Which types of emails and notifications would you like to receive from
          us?
        </p>

        {hasPreferences && (
          <div className="pt-3">
            <table>
              <tbody>
                {notificationPreferences.rows.map((r) => {
                  log(
                    'mapping ',
                    r.key,
                    ' to ... ',
                    r,
                    ' to ',
                    notificationPreferences.map[r.key].email
                  )
                  return (
                    <tr key={r.key}>
                      <label className="login-checkbox">
                        <td>{r.strings.en}</td>
                        <td>
                          <input
                            type="checkbox"
                            onChange={(event) => updatePreference(r.key, event)}
                            checked={notificationPreferences.map[r.key].email}
                          />
                          <span className="checkmark"></span>
                          {r.key === OPT_OUT_KEY && sadMessage && (
                            <font color="red">&nbsp; {sadMessage}</font>
                          )}
                        </td>
                      </label>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <p>
              <button
                className="pay-button"
                onClick={savePreferences}
                disabled={isSaving}
              >
                {isSaving ? 'Saving ...' : 'Save changes'}
              </button>
            </p>

            {updateMessage && (
              <p>
                <font color="green">{updateMessage}</font>
              </p>
            )}
          </div>
        )}

        {networkError && (
          <p>
            <font color="red">{networkError}</font>
          </p>
        )}
      </div>
    )
  }

  return (
    <div className="container mote-brand">
      <Header />
      <main id="main">
        {isLoading && <Loading />}
        {!isLoading && isLoggedIn && renderLoggedIn()}
        {!isLoading && !isLoggedIn && renderNotLoggedIn()}
      </main>
      <Footer />
    </div>
  )
}

function renderNotLoggedIn() {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-8 px-lg-4">
        <h1 className="mote-logged-in-title">Please sign in</h1>
        <p className="title">
          You're not logged in, please sign in first to update your notification
          preferences.
        </p>
        <br />
        <button className="pay-button">
          <a href="/login?returnTo=/account/notificationPreferences">
            Let's do it
          </a>
        </button>
      </div>
    </div>
  )
}

export default NotificationPreferences
