import React from 'react'
import GetMoteLink from '../shared/GetMoteLink'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class Gmail extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>

          <div className="container">
            <div className="row justify-content-between align-items-center py-4 py-lg-5">
              <div className="col-lg-6 col-xl-6 mb-4">
                <h1 className="lg-display-4-mb-4-mt">
                  <b className="mote-purple">mote</b> for Gmail is here!
                </h1>
                <h3>Record and insert voice messages within Gmail</h3>
                <div className="row ml-0 mr-0">
                  <GetMoteLink recipe="atc" />
                </div>
              </div>

              <div className="d-lg-none mb-1 ml-3">
                <iframe
                  width="400"
                  height="225"
                  title="mote-minute"
                  src="https://www.youtube.com/embed/wXCBAc1NyCs"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="d-none d-lg-block col-lg-6 col-xl-5 mb-1 justify-content-center text-lg-left">
                <iframe
                  width="480"
                  height="270"
                  title="mote-minute"
                  src="https://www.youtube.com/embed/wXCBAc1NyCs"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>

          <section className="container mb-4">
            <div className="row">
              <h3 className="lg-display-4-mb-2-mt pt-4">
                Send voice messages within Gmail with the mote Chrome extension
              </h3>
              <p className="lead">Get started in three easy steps:</p>
            </div>
            <div className="row justify-content-lg-between align-items-lg-center py-4 py-lg-5">
              <div className="col-lg-5 mb-2 mb-lg-4">
                <h2 className="h3">
                  <span class="mote-number">1) </span>Add the extension
                </h2>
                <p className="mb-4">
                  If you've somehow missed all the buttons prompting you to do
                  that,{' '}
                  <a href="/chrome" className="mote-boiler-link">
                    here's another link
                  </a>{' '}
                  in case you're still unsure where to do this!
                </p>
              </div>
              <div className="col-lg-6">
                <figure>
                  <a href="/chrome">
                    <img
                      src="images/photos/chromeStore.png"
                      className="img-fluid"
                      width="500"
                      alt="Chrome Store"
                    />
                  </a>
                </figure>
              </div>
              <div className="col-lg-5 order-lg-2 mb-2 mb-lg-4">
                <h2 className="h3">
                  <span class="mote-number">2) </span>Confirm permissions
                </h2>
                <p className="mb-4">
                  Once the extension is added, you'll click the mote icon in
                  your browser bar and be prompted to grant more permissions.
                  This takes less than 5 seconds.
                </p>
              </div>
              <div className="col-lg-6 order-lg-1">
                <figure className="figure">
                  <img
                    src="images/photos/gmailPermissions.png"
                    className="img-fluid"
                    width="400"
                    alt="Permissions"
                  />
                </figure>
              </div>
            </div>
            <div className="row justify-content-lg-between align-items-lg-center py-4 py-lg-5">
              <div className="col-lg-5 mb-4 mb-lg-0">
                <h2 className="h3">
                  <span class="mote-number">3) </span>Click to record
                </h2>
                <p className="mb-4">
                  You'll now see the <span className="mote-purple">mote</span>{' '}
                  icon in your Gmail 'compose' view whenever you start or
                  respond to an email. So if you're not in a typing mood, just
                  hit <span className="mote-purple">mote</span>.
                </p>
              </div>
              <div className="col-lg-6">
                <figure>
                  <img
                    src="images/photos/recordGmail.png"
                    className="img-fluid"
                    width="500"
                    alt="Recording a mote"
                  />
                </figure>
              </div>
            </div>
          </section>
          <div className="container py-1">
            <div className="text-center">
              <div className="d-none d-md-block">
                {/* <iframe title="Add" className="airtable-embed" src="https://airtable.com/embed/shrFRSUoOOVUnYeZa?backgroundColor=pink" frameborder="0" onmousewheel="" width="100%" height="450" styleName="background: transparent; border: 1px solid #ccc;"></iframe> */}

                <h2 className="mb-4">Are you ready to start?</h2>
                <GetMoteLink recipe="atc" />
              </div>
            </div>
          </div>
          <div className="container py-1">
            <div className="text-center">
              <div className="d-block d-md-none">
                <h2 className="mb-4">
                  Try <b className="mote-purple">mote</b>
                </h2>
                <GetMoteLink recipe="atc" />
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

export default Gmail
