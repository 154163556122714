import React from 'react'
import GetMoteLink from '../shared/GetMoteLink'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

export const App = () => {
  return (
    <React.Fragment>
      <div className="mote-brand">
        <div className="container">
          <Header />
        </div>

        <div className="container">
          {/* <div className="row align-items-right">
              <div className="col-md-9 col-xl-10"></div>
              <a
                href="/checkoutschools"
                className="d-none d-md-block btn btn-primary"
              >
                Get a quote
              </a>
            </div> */}
          <div className="row justify-content-between align-items-center py-4 py-lg-5">
            <div className="col-lg-6 col-xl-6 mb-4">
              <h2 className="lg-display-4-mb-4-mt">
                <b className="mote-purple">mote</b> enables voice note feedback
                within Google Classroom, Google Docs &amp; more.
              </h2>
              <div className="row ml-0 mr-0">
                <GetMoteLink recipe="atc" />
              </div>
            </div>
            <div className="d-lg-none mb-1 ml-3">
              <iframe
                width="400"
                height="225"
                title="mote-minute"
                src="https://www.youtube.com/embed/6cd508WxDBk"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="d-none d-lg-block col-lg-6 col-xl-5 mb-1 justify-content-center text-lg-left">
              <iframe
                width="480"
                height="270"
                title="mote-minute"
                src="https://www.youtube.com/embed/6cd508WxDBk"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>

        <div
          className="bg-cover position-relative"
          style={{
            backgroundImage: 'url(images/logos/mote-background.png',
            backgroundSize: 'auto'
          }}
        >
          <div className="container mote-brand">
            <div className="row" style={{ padding: '0em 0em 0em 1em' }}>
              <h1 className="lg-display-4-mb-2-mt">
                more about <b className="mote-purple">mote</b>
              </h1>
            </div>
            <p className="lead">
              <b className="mote-purple">mote</b> is a Chrome Extension that
              makes it easy for anyone to add voice notes and feedback to
              documents, assignments and emails{' '}
              <a href="/Gmail" className="mote-boiler-link">
                (NEW!!!)
              </a>
              .
            </p>
            <p className="lead">
              With <b className="mote-purple">mote</b>, we've created the
              fastest, friendliest way to share voice notes and feedback.
            </p>
            <div className="row" style={{ padding: '0em 0em 0em 1em' }}>
              <h1 className="lg-display-4-mb-2-mt">
                <b className="mote-purple">mote</b> is
              </h1>
            </div>
            <div className="row py-2 mb-lg-5">
              <div className="row py-2 mb-lg-3">
                <div className="col-10 col-md-4 mb-4">
                  <div className="text-center px-lg-3">
                    <span className="icon icon-primary icon-lg mb-3">
                      <i
                        style={{
                          padding: '12px 0px 0px 0px',
                          fontSize: '32pt'
                        }}
                        className="material-icons mote-purple"
                      >
                        speed
                      </i>
                    </span>
                    <h2 className="h4 text-center">fast</h2>
                    <p className="mb-md-0 text-left ml-3 ml-md-0">
                      Voice comment up to 3 X faster than typing.
                    </p>
                  </div>
                </div>
                <div className="col-10 col-md-4 mb-4">
                  <div className="text-center px-lg-3">
                    <span className="icon icon-primary icon-lg mb-3">
                      <i
                        style={{
                          padding: '12px 0px 0px 0px',
                          fontSize: '32pt'
                        }}
                        className="material-icons mote-purple"
                      >
                        insert_emoticon
                      </i>
                    </span>
                    <h2 className="h4 text-center">friendly</h2>
                    <p className="text-left ml-3 ml-md-0 mb-md-0">
                      Express yourself clearly and stay better connected.
                    </p>
                  </div>
                </div>
                <div className="col-10 col-md-4 mb-4">
                  <div className="text-center px-lg-3">
                    <span className="icon icon-primary icon-lg mb-3">
                      <i
                        style={{
                          padding: '12px 0px 0px 0px',
                          fontSize: '32pt'
                        }}
                        className="material-icons mote-purple"
                      >
                        check
                      </i>
                    </span>
                    <h2 className="h4 text-center">free</h2>
                    <p className="text-left ml-3 ml-md-0 mb-md-0">
                      Get started for free, upgrade for{' '}
                      <a href="/pricing" className="mote-boiler-link">
                        awesome premium features
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-cover position-relative"
              style={{
                backgroundImage: 'url(images/logos/mote-background.png',
                backgroundSize: 'auto'
              }}
            >
              <div className="container mote-brand">
                <h3 className="h4 mb-4">
                  Teachers tell us that they love using{' '}
                  <b className="mote-purple">mote</b> to connect with students
                </h3>
                <div className="row">
                  <div className="col-md">
                    <blockquote className="bg-white rounded shadow mb-3 p-4">
                      <p className="text-secondary">
                        “I teach high school students, and I freaking LOVE
                        this!!! This is a game changer for me in soooo many ways
                        (grading, instructing, etc) Bravo!”
                      </p>
                      <cite className="text-uppercase">RH</cite>
                      <br></br>
                      <br></br>
                      <br></br>
                      <div className="quote-heart-div">
                        <span className="material-icons quote-heart">
                          favorite
                        </span>
                      </div>
                    </blockquote>
                  </div>
                  <div className="col-md">
                    <blockquote className="bg-white rounded shadow mb-3 p-4">
                      <p className="text-secondary">
                        “During this incredibly difficult time in our teaching
                        world this is a game changer. My students can hear my
                        voice and stay connected to me. My crew is only 7-8
                        years old and they need that desperately. Thank you. I
                        actually started crying when I heard my voice back.“
                      </p>
                      <cite className="text-uppercase">KP</cite>
                      <div className="quote-heart-div">
                        <span className="material-icons quote-heart">
                          favorite
                        </span>
                      </div>
                    </blockquote>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <blockquote className="bg-white rounded shadow mb-3 p-4">
                      <p className="text-secondary">
                        "Mote is Lovely. It saves me so much time, even the
                        small things like this help to keep that personal
                        connection with my students while we're learning from
                        home.”
                      </p>
                      <cite className="text-uppercase">Sarah Martin</cite>
                      <br></br>
                      <br></br>
                      <div className="quote-heart-div">
                        <span className="material-icons quote-heart">
                          favorite
                        </span>
                      </div>
                    </blockquote>
                  </div>
                  <div className="col-md">
                    <blockquote className="bg-white rounded shadow mb-3 p-4">
                      <p className="text-secondary">
                        “THIS IS AMAZING!!!! THANK YOU THANK YOU THANK YOU! It
                        cut my grading time to a fraction of what it has been
                        because I can just speak my corrections! Also my
                        students are loving hearing my voice! I will be sharing
                        with ALL of my fellow teachers!“
                      </p>
                      <cite className="text-uppercase">Cindy Lamphere</cite>
                      <div className="quote-heart-div">
                        <span className="material-icons quote-heart">
                          favorite
                        </span>
                      </div>
                    </blockquote>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <blockquote className="bg-white rounded shadow mb-3 p-4">
                      <p className="text-secondary">
                        "I was adding the feedback to a student assignment in
                        google docs - I've been trying to find something that
                        would work for DAYS. The unexpected shift to all online
                        teaching and learning has created lots of challenges and
                        frustrations. Being able to give voice feedback cuts
                        down my workload tremendously! And, it allows students
                        to hear my voice, and feel more connected. The included
                        transcript is ideal for diverse learners and those who
                        have English as an additional language. The transcript
                        was surprisingly accurate! Thank you so much for this
                        app!!!”
                      </p>
                      <cite className="text-uppercase">
                        Emily Wickwire-Foster
                      </cite>
                      <div className="quote-heart-div">
                        <span className="material-icons quote-heart">
                          favorite
                        </span>
                      </div>
                    </blockquote>
                  </div>
                  <div className="col-md">
                    <blockquote className="bg-white rounded shadow mb-3 p-4">
                      <p className="text-secondary">
                        “I could CRY I am so excited about this extension!!!!!!
                        I have been able to give personal feedback on all 85 of
                        my kids' work today in under 90 minutes. I am THRILLED
                        that they can hear the tone of my voice when I am giving
                        feedback!! I can't wait to hear their reaction when they
                        can actually HEAR me critique their work. I CANNOT wait
                        to share this with my school leadership team and
                        colleagues!!! THANK YOU!!!!!!!!!
                        <span role="img" aria-hidden="true">
                          🙌
                        </span>
                        ”
                      </p>
                      <br></br>
                      <br></br>
                      <br></br>
                      <cite className="text-uppercase">Karen Ervin</cite>
                      <div className="quote-heart-div">
                        <span className="material-icons quote-heart">
                          favorite
                        </span>
                      </div>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <section className="container">
              <h3 className="text-center mt-5 mb-4">
                Choose the plan that suits your needs
              </h3>
              <div className="row py-5">
                <div className="col-lg-4">
                  <div className="card card-hover card-price shadow-lg mb-4">
                    <div className="card-header">
                      <h3 className="h4 font-weight-normal">Free</h3>
                    </div>
                    <div className="card-body">
                      <ul className="list-unstyled mb-4">
                        <li className="py-2 text-secondary">
                          Unlimited voice notes
                        </li>
                        <li className="py-2 text-secondary">30 second limit</li>
                        <li className="py-2 text-secondary">
                          No transcription
                        </li>
                        <li className="py-2 text-secondary"> </li>
                      </ul>
                      <a
                        className="btn btn-outline-primary mb-3"
                        href="/pricing"
                      >
                        Get started
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="card card-hover card-price card-featured shadow-lg mb-4">
                    <div className="card-header">
                      <h3 className="h4 font-weight-normal">Unlimited</h3>
                    </div>
                    <div className="card-body">
                      <ul className="list-unstyled mb-4">
                        <li className="py-2 text-secondary">
                          Unlimited voice notes
                        </li>
                        <li className="py-2 text-secondary">Transcription</li>
                        <li className="py-2 text-secondary">Reactions</li>
                      </ul>
                      <a className="btn btn-primary m-3" href="/pricing">
                        See details
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card card-hover card-price shadow-lg mb-4">
                    <div className="card-header">
                      <h3 className="h4 font-weight-normal">Essential</h3>
                    </div>
                    <div className="card-body">
                      <ul className="list-unstyled mb-4">
                        <li className="py-2 text-secondary">
                          Unlimited voice notes
                        </li>
                        <li className="py-2 text-secondary">90 second limit</li>
                        <li className="py-2 text-muted">No transcription</li>
                      </ul>
                      <a
                        className="btn btn-outline-primary mb-3"
                        href="/pricing"
                      >
                        See details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="container py-1">
              <div className="text-center">
                <div className="d-none d-md-block">
                  <h2 className="mb-4">Start your free trial</h2>
                  <GetMoteLink recipe="atc" />
                </div>
              </div>
            </div>
            <div className="container py-1">
              <div className="text-center">
                <div className="d-block d-md-none">
                  <h2 className="mb-4">
                    Try <b className="mote-purple">mote</b>
                  </h2>
                  <GetMoteLink recipe="atc" />
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default App
