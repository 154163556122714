import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import tw from 'twin.macro'
import { Tooltip } from '../../../../../../mote-ui'

Emoji.propTypes = {
  emoji: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  isEmojiPickerItem: PropTypes.bool,
  children: PropTypes.string,
  style: PropTypes.object
}

Emoji.defaultProps = {
  isEmojiPickerItem: false
}

const EmojiContainer = styled.span(() => [tw`transform hover:scale-150`])

const EmojiContainerWithScale = styled.div(() => [
  tw`transform hover:scale-150`
])

function Emoji({ children, label, message, style, isEmojiPickerItem }) {
  return (
    <span
      role="img"
      aria-label={label || 'emoji'}
      className="tw-mx-0.5 has-tooltip"
      style={{ ...style }}
    >
      {isEmojiPickerItem ? (
        <EmojiContainerWithScale data-tip={message}>
          {children}
        </EmojiContainerWithScale>
      ) : (
        <EmojiContainer data-tip={message}>{children}</EmojiContainer>
      )}

      <Tooltip />
    </span>
  )
}

export default Emoji
