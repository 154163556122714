import React from 'react'

export const Complete = ({ color = '#fff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="none">
      <rect
        width="5"
        height="24.639"
        x="31.715"
        y="11"
        fill={color}
        rx="2.5"
        transform="rotate(45 31.715 11)"
      ></rect>
      <rect
        width="5"
        height="14.777"
        x="7"
        y="21.327"
        fill={color}
        rx="2.5"
        transform="rotate(-45 7 21.327)"
      ></rect>
    </svg>
  )
}
