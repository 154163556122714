import React from 'react'
import { Footer } from '../shared/Footer'

class Bugs extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="container">
          <iframe
            title="Add"
            className="airtable-embed"
            src="https://airtable.com/embed/shrpfPLyZRyxWp2CB?backgroundColor=pink"
            frameborder="0"
            onmousewheel=""
            width="100%"
            height="1000"
            styleName="background: transparent; border: 1px solid #ccc;"
          />
        </div>
        <Footer />
      </div>
    )
  }
}

export default Bugs
