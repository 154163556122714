import React from 'react'
import { afterMoteAuth, isMinor, getProductEnum, isPaid } from '../shared/Auth'
import CardForm from './CardForm'
import Header from '../shared/Header'
import Loading from '../shared/Loading'
import { Footer } from '../shared/Footer'
import {
  listProducts,
  getSKUFromURL,
  createNewChargebeeCheckout,
  formalPrice,
  hasApplicableCoupons,
  getCouponDiscount,
  normalisedCouponId
} from '../shared/Commerce'
import { noLog as log, reallyTruthy } from '../shared/Utils'
import { canIUseCanaryChargebee } from '../shared/Canary'

const DEV_FORCE_LOGIN =
  reallyTruthy(process.env.REACT_APP_DEV_FORCE_LOGIN) || false

const MAINTENANCE_MODE = false

class Checkout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false,
      renderMode: 'Loading',
      chargebeeError: '',
      chargebeeLaunching: false,
      couponId: null,
      quantity: 1
    }
    this.schoolMode = false
  }

  componentDidMount() {
    if (MAINTENANCE_MODE) {
      this.setState({ renderMode: 'Maintenance1Hour' })
      return
    }
    document.title = 'Checkout'
    if (!DEV_FORCE_LOGIN) {
      afterMoteAuth(
        () => {
          this.setState({ loggedIn: true })
          if (isMinor()) {
            this.setState({ renderMode: 'OnlyAdults' })
          } else {
            // log("looking up sku");
            this._resolveSKU()
          }
        },
        () => {
          // log("I am not logged in");
          this.setState({ loggedIn: false })
          this.setState({ renderMode: 'NeedToLogin' })
        }
      )
    } else {
      // log("just set logged in to true");
      this.setState({ loggedIn: true })
      this._resolveSKU()
    }
  }

  render() {
    var methodName = '_render' + this.state.renderMode
    var method = this[methodName].bind(this)
    return (
      <div className="container mote-brand">
        <Header />
        <main id="main">{method()}</main>
        <Footer />
      </div>
    )
  }

  _renderMaintenance1Hour() {
    return (
      <div>
        <h2>Temporary maintenance</h2>

        <p>
          Site undergoing essential maintenance. Checkout is temporarily
          unavailable. Please try again in 1 hour.
        </p>
      </div>
    )
  }

  _renderLoading() {
    return <Loading />
  }

  _renderNoProducts() {
    return <span>Please refresh page. </span>
  }

  _renderOnlyAdults() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Checkout unavailable</h1>
          <p className="lead">
            Because you're under 18, you'll need an adult's help to purchase a
            subscription.
          </p>
          <br></br>
          <p className="lead">
            If you'd like to upgrade to a paid tier, please ask a parent or
            other responsible adult to purchase an individual or group
            subscription.
          </p>
        </div>
      </div>
    )
  }

  _renderCheckout() {
    const isChargebee = canIUseCanaryChargebee()
    return (
      <div>
        {isChargebee && this._renderCheckoutChargebee()}
        {!isChargebee && this._renderCheckoutStripe()}
      </div>
    )
  }

  _renderCheckoutChargebee() {
    const waiting =
      this.state.chargebeeLaunching || !this.state.chargebeeError.length

    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">
            {waiting
              ? 'Redirecting to payment service'
              : 'Failure connecting to payment service'}
          </h1>
          {waiting && (
            <p className="lead">
              You will be sent to our payment pages shortly ...{' '}
            </p>
          )}
          {!waiting && (
            <div>
              <p className="lead">Oh dear, something went wrong:</p>
              <p>
                <font color="red">{this.state.chargebeeError} </font>
              </p>
              <p>Contact support@mote.com if the problem persists</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  _renderCheckoutStripe() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Secure checkout</h1>

          <p className="lead">
            You selected <b>{this.state.sku.productName}</b> on the{' '}
            <b>{this.state.sku.planName}</b> plan.
          </p>

          {hasApplicableCoupons(this.state.sku) && (
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                className="coupon-box"
                autoFocus={true}
                placeholder="&nbsp;Enter discount code"
                type="text"
                name="couponId"
                onChange={(e) => this._lookupDiscount(e)}
              ></input>
              {this.state.couponId && (
                <font color="#008163">
                  <b>
                    &nbsp;Nice!{' '}
                    {parseInt(100 * getCouponDiscount(this.state.couponId))}%
                    discount applied
                  </b>
                </font>
              )}
            </form>
          )}

          <p className="lead">
            You will be charged{' '}
            <b>
              {formalPrice(
                this.state.sku,
                this.state.couponId,
                this.state.quantity
              )}
            </b>
            {this.state.couponId && (
              <span>
                {' '}
                (includes{' '}
                {parseInt(100 * getCouponDiscount(this.state.couponId))}%
                discount!){' '}
              </span>
            )}
          </p>

          {/* <a className="mote-boiler-link" href="/pricing">Change product or plan</a> */}
          <p className="lead">Enter credit card details:</p>
          <CardForm
            priceId={this.state.sku.priceId}
            couponId={this.state.couponId}
            quantity={this.state.quantity}
            onSuccess={() => this._redirectToAccount()}
          />
          <img
            className="stripe-logo"
            src="images/logos/powered_by_stripe.png"
            href="https://stripe.com"
            alt="Stripe logo"
          />
        </div>
      </div>
    )
  }

  _renderNeedToLogin() {
    const returnTo = '/checkout&' + window.location.search.replace('?', '')

    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Please sign in</h1>
          <p className="title">
            You're not logged in, please sign in to mote in order to proceed to
            checkout.
          </p>
          <br></br>
          <div className="buy-now-link">
            <a href={'/login?returnTo=' + returnTo}>Let's do it</a>
          </div>
        </div>
      </div>
    )
  }

  _renderAlreadyPaid() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Whoa! Already paid up</h1>
          <p className="title">
            We would hate to double charge you. You don't need to pay anything
            now. You can see your subscription details on the{' '}
            <a href="/account">account page</a>.
          </p>
        </div>
      </div>
    )
  }

  _renderAlreadyPaidUnlimited() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Already on Unlimited</h1>
          <p className="title">
            You tried to pay for the <b>Essential</b> plan when you are already
            on our more fully featured <b>Unlimited</b> plan. You can see your
            subscription details on the <a href="/account">account page</a>.
          </p>
        </div>
      </div>
    )
  }

  _renderConfirmUpgrade() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Whoa! Double checking ...</h1>
          <p className="title">
            You are already paid up on the <b>Essential</b> plan. Are you sure
            you want to pay to upgrade to the more fully featured{' '}
            <b>Unlimited</b> plan? You can see your subscription details on the{' '}
            <a href="/account">account page</a>.
          </p>
          <button
            className="pay-button"
            onClick={() => this._redirectToPricing()}
          >
            Not sure, take me back to pricing page
          </button>
          &nbsp;{' '}
          <button
            className="btn-secondary"
            onClick={() => {
              this.setState({ confirmedUpgrade: true, renderMode: 'Checkout' })
            }}
          >
            Yes, I'm sure; thanks for checking
          </button>
        </div>
      </div>
    )
  }

  _redirectToPricing() {
    window.location.href =
      window.location.protocol + '//' + window.location.host + '/pricing'
  }

  _resolveSKU() {
    listProducts(
      (results) => {
        this.setState(results)
        var sku = getSKUFromURL()
        if (sku) {
          const currentProductEnum = getProductEnum()
          this.setState({ sku: sku })

          log('SKU is: ' + JSON.stringify(sku))
          log('isPaid is :' + isPaid())
          log('currentProductEnum is: ' + currentProductEnum)
          log('confirmedUpgrade is: ' + this.state.confirmedUpgrade)
          if (!this.schoolMode && isPaid()) {
            if (sku.productEnum === currentProductEnum) {
              return this.setState({ renderMode: 'AlreadyPaid' })
            } else if (currentProductEnum === 'unlimited') {
              return this.setState({
                renderMode: 'AlreadyPaidUnlimited'
              })
            } else if (!this.state.confirmedUpgrade) {
              return this.setState({
                renderMode: 'ConfirmUpgrade'
              })
            }
          }
          if (canIUseCanaryChargebee()) {
            this.setState({ chargebeeLaunching: true })
            createNewChargebeeCheckout(
              this.state.sku.priceId,
              1,
              false,
              {},
              (URL) => {
                window.location.href = URL
              },
              (e) =>
                this.setState({ chargebeeLaunching: false, chargebeeError: e })
            )
          }
          this.setState({ renderMode: 'Checkout' })
        } else {
          this.setState({ renderMode: 'NoProducts' })
        }
      },
      (e) => {
        // log("Got an error loading products: ", e);
        this.setState({ renderMode: 'NoProducts' })
      }
    )
  }

  _lookupDiscount(e) {
    e.preventDefault()
    try {
      var discount = getCouponDiscount(e.target.value)
      if (discount) {
        this.setState({ couponId: normalisedCouponId(e.target.value) })
      } else {
        this.setState({ couponId: null })
      }
    } catch (e) {}
  }

  _redirectToAccount() {
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.host +
      '/account?congrats'
  }
}

export default Checkout
