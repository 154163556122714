import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import tw from 'twin.macro'

const EmojiContainer = styled.span(() => [tw`transform hover:scale-150`])

const EmojiContainerWithScale = styled.div(() => [
  tw`transform hover:scale-150`
])

export const Emoji = ({ children, label, style, isEmojiPickerItem }) => {
  return (
    <span
      role="img"
      aria-label={label || 'emoji'}
      className="tw-mx-0.5 has-tooltip"
      style={{ ...style }}
    >
      {isEmojiPickerItem ? (
        <EmojiContainerWithScale>{children}</EmojiContainerWithScale>
      ) : (
        <EmojiContainer>{children}</EmojiContainer>
      )}
    </span>
  )
}

Emoji.propTypes = {
  emoji: PropTypes.string,
  label: PropTypes.string,
  isEmojiPickerItem: PropTypes.bool,
  children: PropTypes.string,
  style: PropTypes.object
}

Emoji.defaultProps = {
  isEmojiPickerItem: false
}
