import React from 'react'
import {
  trackSchoolPurchaseFunnelStep,
  trackSchoolPurchaseFunnelFailure,
  track
} from '../shared/AnalyticsHelpers'
import {
  afterMoteAuth,
  isMinor,
  getUsersName,
  getUsersEmail
} from '../shared/Auth'
import { canIUseCanaryChargebee } from '../shared/Canary'
import CardForm from './CardForm'
import {
  CountryPulldown,
  getCurrencyFromCountry,
  COUNTRIES
} from './CountryPulldown'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'
// import IntercomWrapper from "../shared/IntercomWrapper";
import Loading from '../shared/Loading'
import { SmartRegion, getRegionFieldName, isValidRegion } from './SmartRegion'
import {
  getSchoolSaving,
  formalSchoolPrice,
  getSKUFromParams,
  listProductsForSchools,
  createNewChargebeeCheckout,
  PRODUCT_UNLIMITED_VOLUME,
  PRODUCT_ESSENTIAL_VOLUME
} from '../shared/Commerce'

import { noLog as log, isDevForceLogin } from '../shared/Utils'
import Tooltip from '@material-ui/core/Tooltip'

const Q2ProductChoice = 'Q2ProductChoice'
const Q3SomeDetailsAboutYou = 'Q3SomeDetailsAboutYou'
const Q4CalculatePrice = 'Q4CalculatePrice'
const Q5Confirm = 'Q5Confirm'
const A5PayWithCC = 'A5PayWithCC'
const A5Quote = 'A5Quote'

class CheckoutSchools extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false,
      chargebeeRedirecting: false,
      renderMode: 'Q0Loading',
      error: '',
      productEnum: null,
      quantity: '',
      schoolName: '',
      contactName: '',
      contactTitle: '',
      phoneNumber: '',
      isGoogleReferenceSchool: false,
      googleSchoolLink: '',
      institutionData: {},
      region: '',
      recommendedCurrency: 'usd',
      products: {}
    }
  }

  componentDidMount() {
    document.title = 'Schools checkout'
    trackSchoolPurchaseFunnelStep('Q0Load')
    var fetchProducts = () => {
      listProductsForSchools(
        (results) => {
          // log("got products: ", results);
          this.setState(results)
          this._switchToStep(Q2ProductChoice)
        },
        () => this._switchToFailure('A1NoProducts')
      )
    }
    if (!isDevForceLogin()) {
      afterMoteAuth(
        () => {
          this._fixURL()
          this.setState({ loggedIn: true })
          this.setState({
            contactName: getUsersName(),
            name: getUsersName(),
            primaryEmailAddress: getUsersEmail()
          })
          if (isMinor()) {
            this._switchToFailure('A1OnlyAdults')
          } else {
            fetchProducts()
          }
        },
        () => {
          this.setState({ loggedIn: false })
          log('setting to need to log in mode')
          this._switchToStep('Q1Login')
        }
      )
    } else {
      this.setState({
        contactName: 'Jane Doe',
        name: 'Jane Doe',
        primaryEmailAddress: 'Jane@bigschool.com',
        actualCountryCode: 'be'
      })
      fetchProducts()
    }
  }

  render() {
    log('rendermode is: ' + this.state.renderMode)
    var methodName = '_render' + this.state.renderMode
    var method = this[methodName].bind(this)
    return (
      <div className="container mote-brand">
        <Header />
        <main id="main">{method()}</main>
        {/* {this.state.loggedIn && <IntercomWrapper />} */}
        <Footer />
      </div>
    )
  }

  _renderQ0Loading() {
    return <Loading />
  }

  _renderQ1Login() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Please sign in</h1>
          <p className="title">
            You're not logged in, please sign in to mote in order to proceed to
            checkout.
          </p>
          <br></br>
          <button className="pay-button">
            <a href="/login?returnTo=/checkoutSchools">Let's do it</a>
          </button>
        </div>
      </div>
    )
  }

  _renderA1OnlyAdults() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Checkout unavailable</h1>
          <p className="lead">
            Because you're under 18, you can only use our free tier.
          </p>
        </div>
      </div>
    )
  }

  _renderA1NoProducts() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Sorry</h1>
          <p className="lead">
            Something seems to be broken. Try again later or get in touch with
            support@mote.com
          </p>
        </div>
      </div>
    )
  }

  _renderQ2ProductChoice() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h2 className="mote-logged-in-title">
            Hi {this.state.name}{' '}
            <span role="img" aria-label="friendly wave">
              👋
            </span>
          </h2>
          <p className="lead">Please select a product tier</p>

          <div className="row py-5">
            <div className="col-lg-6">
              <div className="card card-hover card-price card-featured shadow-lg mb-4">
                <div className="card-header">
                  <h3 className="h4 font-weight-normal">Unlimited</h3>
                </div>
                <div className="card-body">
                  <ul className="list-unstyled mb-4">
                    <li className="py-2 text-secondary">
                      Unlimited voice notes
                    </li>
                    <li className="py-2 text-secondary">
                      Transcription &amp; translation
                    </li>
                    <li className="py-2 text-secondary">
                      Save voice notes to{' '}
                      <span className="mote-purple">mote</span>
                      book
                    </li>
                    <li className="py-2 text-secondary">
                      Student emoji reactions
                    </li>
                  </ul>
                  {this._ProceedUnlimitedQ2(PRODUCT_UNLIMITED_VOLUME)}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card card-hover card-price shadow-lg mb-4">
                <div className="card-header">
                  <h3 className="h4 font-weight-normal">Essential</h3>
                </div>
                <div className="card-body">
                  <ul className="list-unstyled mb-4">
                    <li className="py-2 text-secondary">
                      Unlimited voice notes
                    </li>
                    <li className="py-2 text-muted">No transcription</li>
                    <li className="py-2 text-secondary">90 second limit</li>
                    <li className="py-2 text-secondary">&nbsp;</li>
                  </ul>
                  {this._ProceedEssentialQ2(PRODUCT_ESSENTIAL_VOLUME)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _ProceedUnlimitedQ2(productEnum) {
    return (
      <div>
        <p>
          <button
            className="btn btn-primary mt-1 m-3"
            onClick={() => {
              this.setState({
                productEnum: productEnum
              })
              this._switchToStep(Q3SomeDetailsAboutYou)
            }}
          >
            Select
          </button>
        </p>
      </div>
    )
  }

  _ProceedEssentialQ2(productEnum) {
    return (
      <div>
        <p>
          <button
            className="btn btn-outline-primary mb-3"
            onClick={() => {
              this.setState({
                productEnum: productEnum
              })
              this._switchToStep(Q3SomeDetailsAboutYou)
            }}
          >
            Select
          </button>
        </p>
      </div>
    )
  }

  _renderQ4CalculatePrice() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h2 className="mote-logged-in-title">
            Next, let's work out pricing for {this.state.schoolName}
          </h2>

          <p>
            You selected <b>{this.state.sku.productName}</b>.{' '}
          </p>

          <form>
            <div className="form-group">
              <label className="small text-uppercase">
                How many teacher licenses are you looking for?
              </label>
              <input
                type="text"
                className="form-control"
                value={this.state.quantity}
                onChange={(e) => {
                  var newValue
                  try {
                    // log("got ... " + e.target.value);
                    newValue = parseInt(e.target.value)
                  } catch (e) {}
                  if (Number.isNaN(newValue)) {
                    newValue = ''
                  }
                  this.setState({
                    quantity: newValue
                  })
                  setTimeout(
                    () =>
                      this.setState({
                        oldQuantity: newValue
                      }),
                    1000
                  )
                }}
              />
            </div>
          </form>

          {!Number.isNaN(this.state.quantity) &&
            this.state.quantity !== undefined &&
            this.state.quantity === this.state.oldQuantity &&
            !this._isQuotableQuantity() && (
              <p>
                <font color="red">
                  School purchases must be for at least 2 teachers. If you meant
                  to buy a subscription just for yourself, pick Essential or
                  Unlmited options from the <a href="/pricing">pricing page</a>.
                </font>
              </p>
            )}

          {this._isQuotableQuantity() && <div>{this._renderQuote()}</div>}
          <div className="checkout-button-row mt-4">
            {this._BackButton(Q3SomeDetailsAboutYou)}
            <div>
              <p>
                <button
                  className="btn btn-primary mt-1 mb-3"
                  disabled={!this._isQuotableQuantity()}
                  onClick={() => this._switchToStep(Q5Confirm)}
                >
                  Next
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderQuote() {
    // var saving=this._isQuotableQuantity()
    //     ? getSchoolSaving(this.state.sku,this.state.quantity)
    //     :"";
    const hasDiscount = this.hasVolumeDiscount()

    return (
      <div>
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th className="text-uppercase" scope="col">
                Item
              </th>
              <th className="text-uppercase" scope="col">
                Quantity
              </th>
              <th className="text-uppercase" scope="col">
                Price
              </th>
              <th className="text-uppercase" scope="col">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{this.state.sku.productName}</th>
              <td>{this.state.quantity}</td>
              <td>{this.getUndiscountedUnitPrice()} </td>
              <td>{this.getUndiscountedTotalPrice()} </td>
            </tr>
            <tr>
              <th scope="row">
                Group discount{' '}
                {!hasDiscount && (
                  <Tooltip
                    arrow
                    title="Discounts available for purchases of 10+ Unlimited tier licenses."
                    placement="bottom"
                    maxWidth="200"
                    backgroundColor="theme.palette.common.black"
                  >
                    <span>(?)</span>
                  </Tooltip>
                )}
              </th>
              <td>{this.state.quantity}</td>
              <td>
                {hasDiscount && <span> ({this.getUnitDiscount()}) </span>}
              </td>
              <td>
                {hasDiscount && <span> ({this.getTotalDiscount()})</span>}
              </td>
            </tr>
          </tbody>
          <tfoot className="thead-light">
            <tr>
              <th scope="row">Total</th>
              <th>
                <b>{this.state.quantity}</b>
              </th>
              <th>
                <b>{this.getDiscountedUnitPrice()}</b>
              </th>
              <th>
                <b>{this.getDiscountedTotalPrice()}</b>
              </th>
            </tr>
          </tfoot>
        </table>
        <p className="small">
          Further discounts are available for Google Reference Schools - please
          request your official quote and mention your Reference School status
          if applicable.
        </p>
      </div>
    )
  }

  _renderQ3SomeDetailsAboutYou() {
    log('country is: ' + this.state.actualCountryCode)
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h2 className="mote-logged-in-title">
            Please tell us more about your organization
          </h2>

          <form>
            <div className="form-group">
              <label className="small text-uppercase">Organization name</label>
              <input
                value={this.state.schoolName}
                onChange={(e) => {
                  log('got keystroke: ' + e.target.value)
                  this.setState({
                    schoolName: e.target.value
                  })
                }}
                className="form-control"
              ></input>
              {this.state.schoolName &&
                !this._isWesternCharsOnly(this.state.schoolName) && (
                  <p>
                    <font color="red">
                      Our team is based in Europe and US and will have
                      difficulty supporting you if they can't read your school
                      name! Please stick to Latin-based characters.{' '}
                    </font>
                  </p>
                )}
            </div>
            <div className="form-group">
              <label className="small text-uppercase">Your name</label>
              <input
                value={this.state.contactName}
                onChange={(e) => {
                  log('got keystroke: ' + e.target.value)
                  this.setState({
                    contactName: e.target.value
                  })
                }}
                className="form-control"
              ></input>
            </div>
            <div className="form-group">
              <label className="small text-uppercase">Your role or title</label>
              <input
                value={this.state.contactTitle}
                onChange={(e) => {
                  log('got keystroke: ' + e.target.value)
                  this.setState({
                    contactTitle: e.target.value
                  })
                }}
                className="form-control"
              ></input>
            </div>
            <div className="form-group">
              <label className="small text-uppercase">Phone number</label>
              <input
                value={this.state.phoneNumber}
                onChange={(e) => {
                  log('got keystroke: ' + e.target.value)
                  this.setState({
                    phoneNumber: e.target.value
                  })
                }}
                className="form-control"
              ></input>
            </div>
            <div className="form-group">
              <label className="small text-uppercase">
                {getRegionFieldName(this.state.actualCountryCode)}
              </label>
              <SmartRegion
                region={this.state.region}
                width="410px"
                countryCode={this.state.actualCountryCode}
                onChange={(region) => {
                  log('got new region: ' + region)
                  this.setState({
                    region: region
                  })
                }}
              />
            </div>
            <div className="form-group">
              <label className="small text-uppercase">Country</label>
              <CountryPulldown
                countryCode={this.state.actualCountryCode}
                onChange={(cc) => {
                  this.setState({
                    actualCountryCode: cc,
                    region: ''
                  })
                  this.forceUpdate()
                }}
              />
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={this.state.isGoogleReferenceSchool}
                onChange={(e) => {
                  const value =
                    e.target.type === 'checkbox'
                      ? e.target.checked
                      : e.target.value
                  // console.log("got event : " + value);
                  this.setState({ isGoogleReferenceSchool: value })
                }}
              ></input>
              <label className="form-check-label small">
                We're a Google Reference School
              </label>
            </div>
            {this.state.isGoogleReferenceSchool === true && (
              <div>
                <span className="small">
                  Congrats - that's awesome! Please share a link to your Google
                  Reference School details from the{' '}
                  <a
                    href="https://edudirectory.withgoogle.com/"
                    className="mote-boiler-link"
                  >
                    Google Edu Directory
                  </a>{' '}
                  to confirm status.{' '}
                </span>
                <div className="form-group">
                  <label className="small text-uppercase">
                    Google Edu Directory Page Link
                  </label>
                  <input
                    value={this.state.googleSchoolLink}
                    onChange={(e) => {
                      log('got keystroke: ' + e.target.value)
                      this.setState({
                        googleSchoolLink: e.target.value
                      })
                    }}
                    className="form-control"
                  ></input>
                </div>
              </div>
            )}
          </form>

          <div className="checkout-button-row mt-4">
            {this._BackButton(Q2ProductChoice)}
            <div>
              <p>
                <button
                  className="btn btn-primary mt-1 mb-3"
                  disabled={!this._isProgressableToQ4()}
                  onClick={() => {
                    this.setState({
                      institutionData: {
                        institutionName: this.state.schoolName,
                        institutionRegion: this.state.region,
                        institutionCountryCode: this.state.actualCountryCode
                      },
                      sku: getSKUFromParams(
                        this.state.productEnum,
                        'year',
                        getCurrencyFromCountry(this.state.actualCountryCode)
                      )
                    })
                    this._switchToStep(Q4CalculatePrice)
                  }}
                >
                  Next
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _isProgressableToQ4() {
    log('checking progressability')
    log('country: ' + this.state.actualCountryCode)
    log('region: ' + this.state.region)
    var result =
      this.state.actualCountryCode &&
      this.state.schoolName &&
      this.state.schoolName.length > 2 &&
      this.state.region &&
      isValidRegion(this.state.actualCountryCode, this.state.region) &&
      this._isWesternCharsOnly(this.state.schoolName)
    log('progressable? ' + result)
    return result
  }

  _handlePayWithCCClick() {
    if (canIUseCanaryChargebee()) {
      log('we are in chargebee mode')
      track('Launching B2B Hosted page')
      this.setState({ chargebeeLaunching: true, chargebeeError: '' })
      createNewChargebeeCheckout(
        this.state.sku.priceId,
        this.state.quantity,
        true,
        this.state.institutionData,
        (URL) => {
          window.location.href = URL
        },
        (e) => this.setState({ chargebeeLaunching: false, chargebeeError: e })
      )
    } else {
      this._switchToStep(A5PayWithCC)
    }
  }

  _renderQ5Confirm() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Confirm details</h1>

          {this._renderDetails()}

          {this._renderQuote()}
          <div className="checkout-button-row mt-4">
            {this._BackButton(Q4CalculatePrice)}
            <div>
              <p>
                <button
                  className="btn btn-primary mt-1 mb-3"
                  disabled={this.state.chargebeeLaunching}
                  onClick={() => this._handlePayWithCCClick()}
                >
                  {this.state.chargebeeLaunching
                    ? 'Preparing billing ...'
                    : 'Pay NOW with credit card'}
                </button>
              </p>
            </div>
            <div>
              <p>
                <button
                  className="btn btn-outline-primary mt-1 mb-3"
                  onClick={() => this._switchToStep(A5Quote)}
                >
                  Send me a quote
                </button>
              </p>
            </div>
            {this.state.chargebeeError && (
              <p>
                <font color="red">
                  <b>Error:</b> {this.state.chargebeeError}{' '}
                </font>
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }

  _renderDetails() {
    return (
      <table className="table">
        <tbody>
          <tr>
            <td>
              <b>Your name</b>
            </td>
            <td> {this.state.contactName}</td>
          </tr>
          <tr>
            <td>
              <b>Your role or title</b>
            </td>
            <td> {this.state.contactTitle}</td>
          </tr>
          <tr>
            <td>
              <b>Your email</b>{' '}
            </td>
            <td> {this.state.primaryEmailAddress}</td>
          </tr>
          <tr>
            <td>
              <b>Phone number</b>
            </td>
            <td> {this.state.phoneNumber}</td>
          </tr>
          <tr>
            <td>
              <b>Organization name</b>
            </td>
            <td>{this.state.schoolName}</td>
          </tr>

          <tr>
            <td>
              <b>{getRegionFieldName(this.state.actualCountryCode)} </b>
            </td>
            <td>{this.state.region}</td>
          </tr>

          <tr>
            <td>
              <b>Country </b>
            </td>
            <td>{COUNTRIES[this.state.actualCountryCode]}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  _renderA5PayWithCC() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Pay with credit card</h1>

          {this._renderDetails()}

          {this._renderQuote()}

          <CardForm
            priceId={this.state.sku.priceId}
            quantity={this.state.quantity}
            institutionMode={true}
            institutionData={this.state.institutionData}
            onSuccess={() => this._redirectToAccount()}
          />

          {this._BackButton(Q5Confirm)}
          <img
            className="stripe-logo"
            src="images/logos/powered_by_stripe.png"
            href="https://stripe.com"
            alt="Stripe logo"
          />
        </div>
      </div>
    )
  }

  _redirectToAccount() {
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.host +
      '/account?congrats'
  }

  _renderA5Quote() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">
            Boom - your quote is on its way!
          </h1>

          <p>
            Thanks for checking out <b className="mote-purple">mote</b> - now
            just check your inbox for your quote, and don't hesitate to hit
            'reply' with any follow-up questions.
          </p>
        </div>
      </div>
    )
  }

  _isWesternCharsOnly(s) {
    return s.match(
      /^[\w\d\s.,()'\-aàâäãæåāçćčèéêëēėęîïíīįìôöòóœøōõûüùúūÿžźżßśšłñń]+$/i
    )
  }

  _isQuotableQuantity() {
    const q = this.state.quantity
    if (!q || Number.isNaN(q) || q < 2) {
      return false
    } else {
      return true
    }
  }

  _BackButton(target) {
    return (
      <div>
        <p>
          <button
            className="btn btn-outline-primary mt-1 mb-3"
            onClick={() => this.setState({ renderMode: target })}
          >
            Back
          </button>
        </p>
      </div>
    )
  }

  _switchToFailure(renderMode) {
    this.setState({ renderMode: renderMode })
    trackSchoolPurchaseFunnelFailure(renderMode, this.state)
  }

  _switchToStep(renderMode) {
    log('rendermode is: ' + renderMode)
    var newState = {}
    newState.renderMode = renderMode
    const onCompletion = () => {
      log('new state before tracking: ', this.state)
      trackSchoolPurchaseFunnelStep(renderMode, this.state)
    }

    if ([A5PayWithCC, A5Quote].includes(renderMode)) {
      log('we have confirmed offer')
      newState.confirmedOffer = true
    }

    if (renderMode === A5Quote) {
      log('we have asked for quote')
      newState.quoteRequested = true
    }
    if (renderMode === A5PayWithCC) {
      log('we have started CC payment')
      newState.transactionInitiated = true
    }
    if (renderMode === Q5Confirm) {
      log('we can include pricing (before) ', this.state)
      newState.hasVolumeDiscount = this.hasVolumeDiscount()
      newState.undiscountedUnitPrice = this.getUndiscountedUnitPrice()
      newState.undiscountedTotalPrice = this.getUndiscountedTotalPrice()
      newState.unitDiscount = this.getUnitDiscount()
      newState.totalDiscount = this.getTotalDiscount()
      newState.discountedUnitPrice = this.getDiscountedUnitPrice()
      newState.discountedTotalPrice = this.getDiscountedTotalPrice()
      newState.countryName = COUNTRIES[this.state.actualCountryCode]
      newState.productName = this.state.sku.productName
      newState.quoteExpirationDate = parseInt(
        (new Date().getTime() + 30 * 86400 * 1000) / 1000
      )
    }
    this.setState(newState, () => onCompletion())
  }

  _fixURL() {
    try {
      log('Cleaning up URL: ' + window.location.href)
      window.history.replaceState(
        { page: 1 },
        'Schools checkout',
        window.location.protocol +
          '//' +
          window.location.host +
          '/checkoutSchools'
      )
    } catch (e) {}
  }

  getUndiscountedUnitPrice() {
    return formalSchoolPrice(this.state.sku, 1)
  }

  getUndiscountedTotalPrice() {
    return formalSchoolPrice(this.state.sku, 1, false, this.state.quantity)
  }

  hasVolumeDiscount() {
    return getSchoolSaving(this.state.sku, this.state.quantity) ? true : false
  }

  getUnitDiscount() {
    if (this.hasVolumeDiscount()) {
      return getSchoolSaving(this.state.sku, this.state.quantity, false, true)
    } else {
      return ''
    }
  }

  getTotalDiscount() {
    if (this.hasVolumeDiscount()) {
      return getSchoolSaving(this.state.sku, this.state.quantity)
    } else {
      return ''
    }
  }

  getDiscountedUnitPrice() {
    return formalSchoolPrice(this.state.sku, this.state.quantity, 'perUser')
  }

  getDiscountedTotalPrice() {
    return formalSchoolPrice(this.state.sku, this.state.quantity)
  }
}

export default CheckoutSchools
