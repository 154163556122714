import { v4 as uuidv4 } from 'uuid'

import { useGenericRequestHandler } from '../../handlers/useGenericRequestHandler'

const ANONYMOUS_PREFIX = 'anonymous:from_website:'
const STORAGE_PREFIX_KEYS = 'mote-auth-'

function makeAnonymousUserId() {
  const anonymousUserId = ANONYMOUS_PREFIX + uuidv4()

  window.localStorage.setItem(
    STORAGE_PREFIX_KEYS + 'anonymousUserId',
    anonymousUserId
  )
  window.localStorage.setItem(
    STORAGE_PREFIX_KEYS + 'anonymousCreatedAt',
    String(Math.floor(Date.now() / 1000))
  )

  return anonymousUserId
}

export const useTrackMutation = (route = null) => {
  return useGenericRequestHandler({
    route: route ?? 's/track/everything',
    method: 'POST',
    clientType: 'public',
    responseType: 'text',
    options: {
      json: {
        userId:
          window.localStorage?.getItem(STORAGE_PREFIX_KEYS + 'userId') ??
          makeAnonymousUserId(),
        page_context: window.location.href,
        direct: true,
        tracking_route: 'direct',
        tracking_source: 'website'
      }
    }
  })
}
