import {
  PREFERRED_LOCALE_STORAGE_KEY,
  ENGLISH_SPEAKING_LOCALES,
  DEFAULT_LOCALE
} from '../constants'

export function isChromeExtension() {
  return Boolean(window.chrome?.storage?.local)
}

export function getStorageObject() {
  return isChromeExtension() ? window.chrome.storage.local : window.localStorage
}

export const getPreferredLocaleFromStorage = async () => {
  const storage = getStorageObject()

  const locale = storage.get
    ? (await storage.get('preferredLocale'))?.preferredLocale ?? DEFAULT_LOCALE
    : storage.getItem(PREFERRED_LOCALE_STORAGE_KEY)

  return locale ?? DEFAULT_LOCALE
}

export const setPreferredLocaleInStorage = (newLocale) => {
  localStorage.setItem(PREFERRED_LOCALE_STORAGE_KEY, newLocale)
}

export const isEnglishSpeakingLocale = (locale) => {
  return ENGLISH_SPEAKING_LOCALES.includes(locale)
}
