import React from 'react'
import { EpochToPrettyDate } from '../../../shared/Utils'

function InvoiceHistory({ data }) {
  const showPaymentDetails =
    data.paymentInfoAvailableFromProvider || data.prettyAmountPaid
  return (
    <div>
      {showPaymentDetails && (
        <React.Fragment>
          {data.hasPaymentExpired && data.planExpiryEpoch && (
            <p>
              Your plan expired on {EpochToPrettyDate(data.planExpiryEpoch)}.
            </p>
          )}
          {!data.hasPaymentExpired && data.planExpiryEpoch && (
            <p>
              Your plan expires on {EpochToPrettyDate(data.planExpiryEpoch)}. We
              will send you a renewal reminder before then.
            </p>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default InvoiceHistory
