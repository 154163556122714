import React from 'react'

function Dots() {
  return (
    <svg
      width="6"
      height="16"
      viewBox="0 0 6 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.75C1.75 5.75 0.75 6.78125 0.75 8C0.75 9.25 1.75 10.25 3 10.25C4.21875 10.25 5.25 9.25 5.25 8C5.25 6.78125 4.21875 5.75 3 5.75ZM0.75 2.5C0.75 3.75 1.75 4.75 3 4.75C4.21875 4.75 5.25 3.75 5.25 2.5C5.25 1.28125 4.21875 0.25 3 0.25C1.75 0.25 0.75 1.28125 0.75 2.5ZM0.75 13.5C0.75 14.75 1.75 15.75 3 15.75C4.21875 15.75 5.25 14.75 5.25 13.5C5.25 12.2812 4.21875 11.25 3 11.25C1.75 11.25 0.75 12.2812 0.75 13.5Z"
        fill="#AC0AE8"
      />
    </svg>
  )
}

export default Dots
