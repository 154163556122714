// @flow
/* eslint-disable no-console, react/no-multi-comp */
import React from 'react'
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js'
import { createSubscription } from '../shared/Commerce'
import { noLog as log } from '../shared/Utils'
import './stripe.css'
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'DM Sans, Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

class InnerCardForm extends React.Component {
  constructor(props) {
    log('Got props:', props)
    super(props)
    this.state = { error: null, processing: false, subscription: null }
    log('props set:', this.props)
  }

  handleSubmit(event) {
    event.preventDefault()
    log('I was submitted')
    log('this.state: ', this.state)
    log('this.props: ', this.props)
    log('event: ', event)

    const stripe = this.props.stripe
    const elements = this.props.elements
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    if (this.state.processing) {
      log('I am already working on it')
      return
    }
    this.setState({ error: null, processing: true })
    this._createPaymentMethod()
  }

  _createPaymentMethod() {
    log('this.props: ', this.props)
    const stripe = this.props.stripe
    const elements = this.props.elements
    const cardElement = elements.getElement(CardElement)
    return stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement
      })
      .then((result) => {
        log('we have a promise result: ' + result)
        if (result.error) {
          this.setState({ error: result.error })
        } else {
          createSubscription(
            stripe,
            result.paymentMethod.id,
            this.props.priceId,
            this.props.couponId,
            this.props.quantity,
            this.props.institutionMode,
            this.props.institutionData,
            (subscription) => {
              this.setState({ processing: false })
              this.props.onSuccess(subscription)
            },
            (error) => this.setState({ error: error, processing: false })
          )
        }
      })
  }

  render() {
    const e = this.getErrorString()
    log('did we got an error? ', this.state.error)
    log('parsed error message: ', e)
    return (
      <form>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        <div className="pay-button-row mt-3">
          <button
            className="btn btn-primary mt-1 mb-3"
            onClick={(e) => this.handleSubmit(e)}
            type="submit"
            disabled={!this.state.processing && !this.props.stripe}
          >
            Confirm
          </button>
        </div>

        {this.state.processing && !this.state.error && <p>Processing ....</p>}
        {this.state.error && (
          <p>
            Error: <font color="red">{e}</font>
          </p>
        )}
      </form>
    )
  }

  getErrorString() {
    var e = this.state.error
    if (!e) {
      return 'Unknown error'
    } else if (e.message) {
      return e.message
    } else {
      return e
    }
  }
}

const CardForm = (props) => {
  log('Setting up form with priceId: ', props.priceId)
  log('Setting up onSuccess: ', props.onSuccess)
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <InnerCardForm
          elements={elements}
          stripe={stripe}
          priceId={props.priceId}
          couponId={props.couponId}
          institutionMode={props.institutionMode}
          institutionData={props.institutionData}
          quantity={props.quantity}
          onSuccess={props.onSuccess}
        />
      )}
    </ElementsConsumer>
  )
}

export default CardForm
