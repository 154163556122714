import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import tw, { styled, css } from 'twin.macro'
import { useAudioPlayer } from 'react-use-audio-player'

Scrubber.propTypes = {
  leftPosition: PropTypes.number.isRequired,
  clickPosition: PropTypes.func.isRequired
}

const Bar = styled.div(({ left }) => [
  tw`w-full bg-violet-500`,
  css`
    height: 6px;
    transform: translateX(${-100 + left}%);
  `
])

const BgBar = styled.div(() => [
  tw`absolute left-0 w-full bg-gray-200`,
  css`
    height: 6px;
  `
])

const Dot = styled.div(() => [
  tw`absolute w-3 h-3 rounded-full bg-violet-500`,
  css`
    top: -3px;

    :before {
      content: '';

      left: -20px;
      right: -20px;
      bottom: -20px;
      top: -20px;

      position: absolute;
      z-index: -1;
    }
  `
])

const DotWrapper = styled.div(({ left }) => [
  tw`w-full`,
  css`
    transform: translateX(${left - 2}%);
  `
])

const DotContainer = styled.div(() => [
  tw`absolute top-0 w-full mb-2 cursor-pointer`
])

const ScrubberContainer = styled.div(() => [
  tw`relative w-full mb-2 overflow-hidden cursor-pointer rounded-2xl`
])

const Durations = styled.div(() => [
  tw`flex items-center justify-between mb-2 text-xs text-gray-800`
])

const ScrubberLoadingBar = styled.div(() => [
  tw`mb-2`,
  css`
    background: rgba(92, 112, 128, 0.2);
    border-radius: 40px;
    display: block;
    height: 6px;
    overflow: hidden;
    position: relative;
    width: 100%;

    @keyframes linear-progress-bar-stripes {
      from {
        background-position: 0 0;
      }

      to {
        background-position: 29px 0;
      }
    }
  `
])

const ScrubberLoadingMeter = styled.div(() => [
  css`
    animation: linear-progress-bar-stripes 0.3s linear infinite reverse;
    background: linear-gradient(
      -45deg,
      hsla(0, 0%, 100%, 0.2) 25%,
      transparent 0,
      transparent 50%,
      hsla(0, 0%, 100%, 0.2) 0,
      hsla(0, 0%, 100%, 0.2) 75%,
      transparent 0
    );
    background-color: rgba(172, 10, 232, 0.75);
    background-size: 30px 30px;
    border-radius: 40px;
    height: 100%;
    position: absolute;
    -webkit-transition: width 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: width 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
    width: 100%;
  `
])

export function ScrubberDurations({ current, total }) {
  return (
    <Durations>
      <div>{current}</div>
      <div>{total}</div>
    </Durations>
  )
}

const isTouch = 'ontouchend' in document.documentElement

// Strict function separation of mouse/touch events otherwise things go haywire on mobile
// `preventDefault` on touchdown does not help so this is a working but more verbose alternative
function Scrubber({
  leftPosition,
  clickPosition,
  disabled = false,
  loading = false
}) {
  const { pause, play, playing } = useAudioPlayer()
  const seekBarEl = useRef(null)
  const prevPlayingState = useRef(null)

  function barPosition(e) {
    const clickPageX = e.touches ? e.touches[0].pageX : e.pageX
    const barStart =
      seekBarEl.current.getBoundingClientRect().left + window.scrollX
    const clickPos = clickPageX - barStart
    const barWidth = seekBarEl.current.clientWidth
    const percentage = clickPos / parseInt(barWidth)
    const percentageInRange = Math.min(Math.max(percentage, 0), 1)
    clickPosition(percentageInRange)
  }

  function mouseUp(e) {
    e.preventDefault()
    seekBarEl.current.removeEventListener('mousemove', barPosition)
    if (prevPlayingState.current) {
      play()
    }
  }

  function touchUp(e) {
    e.preventDefault()
    seekBarEl.current.removeEventListener('touchmove', barPosition)
    if (prevPlayingState.current) {
      play()
    }
  }

  const eventSetup = (e) => {
    e.preventDefault()
    prevPlayingState.current = playing
    pause()
    barPosition(e)

    if (isTouch) {
      seekBarEl.current.addEventListener('touchmove', barPosition)
      seekBarEl.current.addEventListener('touchend', touchUp, { once: true })
    } else {
      seekBarEl.current.addEventListener('mousemove', barPosition)
      seekBarEl.current.addEventListener('mouseup', mouseUp, { once: true })
    }
  }

  if (loading) {
    return (
      <ScrubberLoadingBar>
        <ScrubberLoadingMeter />
      </ScrubberLoadingBar>
    )
  }

  return (
    <div
      onTouchStart={isTouch ? eventSetup : () => {}}
      onMouseDown={!isTouch ? eventSetup : () => {}}
      ref={seekBarEl}
      style={{ position: 'relative', touchAction: 'none' }}
      className={disabled ? 'tw-mt-6' : null}
      data-testid="scrubber-bar"
    >
      <ScrubberContainer>
        <BgBar />
        <Bar left={leftPosition} />
      </ScrubberContainer>
      <DotContainer>
        <DotWrapper
          data-testid="scrubber-dot"
          data-value={leftPosition}
          left={leftPosition}
        >
          {!disabled && <Dot />}
        </DotWrapper>
      </DotContainer>
    </div>
  )
}

export default Scrubber
