import React from 'react'

export const HamburgerIcon = ({ color = '#AC0AE8' }) => {
  return (
    <svg
      width="30"
      height="19"
      viewBox="0 0 30 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <rect width="30" height="3" rx="1.5" fill="currentColor" />
      <rect y="8" width="30" height="3" rx="1.5" fill="currentColor" />
      <rect y="16" width="30" height="3" rx="1.5" fill="currentColor" />
    </svg>
  )
}
