export const GDOCS = 'gdocs'
export const GSLIDES = 'gslides'
export const GSHEETS = 'gsheets'
export const GFORMS = 'gforms'
export const GCLASSROOM = 'gclassroom'
export const GMAIL = 'gmail'
export const APPLICATION_NAMES = {}
APPLICATION_NAMES[GDOCS] = 'Google Docs'
APPLICATION_NAMES[GSHEETS] = 'Google Sheets'
APPLICATION_NAMES[GSLIDES] = 'Google Slides'
APPLICATION_NAMES[GCLASSROOM] = 'Google Classroom'
APPLICATION_NAMES[GFORMS] = 'Google Forms'
APPLICATION_NAMES[GMAIL] = 'Gmail'
export const GCLASSROOM_PRIVATE_COMMENTS = GCLASSROOM + '-private-comments'
export const GCLASSROOM_STUDENT_WORK = GCLASSROOM + '-student-work'
export const GCLASSROOM_INSTRUCTIONS = GCLASSROOM + '-instructions'
export const GCLASSROOM_HOME = GCLASSROOM + '-home'
export const GFORMS_CREATOR = GFORMS + '-creator'
export const GFORMS_RESPONSES = GFORMS + '-response'
export const DEFAULT_LOCALE = 'en-US'

export const PREFERRED_LOCALE_STORAGE_KEY = 'mote-auth-preferredLocale'

export const ENGLISH_SPEAKING_LOCALES = ['en-US', 'en-GB']

export const TRANSLATION_LOCALES = [
  { code: 'en-US', name: 'English (US)' },
  { code: 'en-GB', name: 'English (UK)' },
  { code: 'cy-GB', name: 'Cymraeg' },
  { code: 'ru-RU', name: 'Русский' },
  { code: 'el-GR', name: 'Ελληνικά' },
  { code: 'fr-FR', name: 'Français' },
  { code: 'it-IT', name: 'Italiano' },
  { code: 'es-ES', name: 'Español' },
  { code: 'ko', name: '한국어' },
  { code: 'pl-PL', name: 'Polski' }
]
