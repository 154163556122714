import React from 'react'

import { LandingPageMote } from './LandingPageMote'
import { useExtensionInstalled } from '@mote/helpers/hooks/useExtensionInstalled'
import tw from 'twin.macro'

import { reallyTruthy } from '../../shared/Utils'
import { track } from '../../shared/AnalyticsHelpers'
import {
  Error,
  Logo,
  TransitionContainer,
  TransitionContentContainer,
  TransitionText
} from './layout'
import { Emoji } from '@mote/ui'
import {
  addExtensionFoundListener,
  checkWildCardOriginsPermissions,
  requestWildCardOriginsPermissions
} from '../../shared/Auth'
import { broadcastExternalWebsiteRedirection } from '../../shared/Auth'

import { FAKE_MOTE } from './fakeMote'

import { MoteLandingLayout } from './MoteLandingLayout'

import { useRecordReplySidebar } from '@mote/business'
import { useMoteConversationMutation } from '@mote/business'

const DEV_FORCE_LOGIN =
  reallyTruthy(process.env.REACT_APP_DEV_FORCE_LOGIN) || false

export const NewMote = (props) => {
  const { state: sidebarState, loadMote } = useRecordReplySidebar()

  const {
    data,
    error,
    mutate: fetchConversation
  } = useMoteConversationMutation(
    props.match.params.moteId,
    sidebarState.refetchReplies ? 2000 : false
  )

  const mote = React.useMemo(() => {
    if (!data && !DEV_FORCE_LOGIN) return null
    if (DEV_FORCE_LOGIN) return FAKE_MOTE

    return data
  }, [data])

  React.useEffect(() => {
    if (DEV_FORCE_LOGIN) return

    fetchConversation()
  }, [])

  React.useEffect(() => {
    if (!mote) {
      return console.time('mote loading')
    }
    console.timeEnd('mote loading')
  }, [mote])

  React.useEffect(() => {
    if (!mote) return

    if (mote && !sidebarState.mote) {
      const isChildReply = mote.currentMoteId !== mote.parentMote.moteId

      const isOpen = isChildReply

      const options = {
        isOpen,
        highlightId: isChildReply ? mote.currentMoteId : null
      }
      loadMote(mote, options)
    }
  }, [mote, sidebarState, loadMote])

  const [
    hasRedirectionPermissions,
    setHasRedirectionPermissions
  ] = React.useState(null)

  const extensionInstalled = useExtensionInstalled()

  const shouldRedirect = React.useCallback(() => {
    if (localStorage.getItem('mote-prevent-redirection')) return false

    return extensionInstalled && hasRedirectionPermissions && mote?.canRedirect
  }, [extensionInstalled, hasRedirectionPermissions, mote])

  React.useEffect(() => {
    addExtensionFoundListener((result) => {
      setHasRedirectionPermissions(result.success)
    })

    checkWildCardOriginsPermissions()
  }, [])

  React.useEffect(() => {
    if (error || !shouldRedirect()) return

    track('Embedded Mote link redirected', {
      content_asset_id: mote.parentMote.moteId
    })
    broadcastExternalWebsiteRedirection({
      redirect: {
        moteId: mote.parentMote.moteId,
        redirectTo: mote.containingURL
      }
    })
    window.location.href = mote.containingURL
  }, [mote, shouldRedirect, error])

  function handleRequestRedirectionPermissions() {
    addExtensionFoundListener((result) => {
      setHasRedirectionPermissions(result.success)
    })
    requestWildCardOriginsPermissions()
  }

  if (error) {
    return (
      <MoteLandingLayout
        hero={
          <ErrorContainer>
            <Error className="p-2 m-2">
              Sorry... Could not load this mote! <Emoji>😢</Emoji>
            </Error>
          </ErrorContainer>
        }
      />
    )
  }

  if (mote && !shouldRedirect()) {
    return (
      <LandingPageMote
        mote={mote.parentMote}
        hasRedirectionPermissions={hasRedirectionPermissions}
        extensionInstalled={extensionInstalled}
        onRequestRedirectionPermissions={handleRequestRedirectionPermissions}
      />
    )
  }

  return (
    <TransitionContainer>
      <TransitionContentContainer>
        <Logo src="/images/logos/mote-lockup-header.png" alt="mote logo" />
        <TransitionText>Just a second...</TransitionText>
        <TransitionText className="mt-0">Checking the link.</TransitionText>
      </TransitionContentContainer>
    </TransitionContainer>
  )
}

const ErrorContainer = tw.div`flex flex flex-col items-center justify-center my-4`
