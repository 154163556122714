import React from 'react'

import { error } from '@mote/common'

import { getAccessToken } from '../../../shared/Auth'
import {
  BASE_URL,
  NETWORK_TIMEOUT,
  SERVER_ERROR,
  DEV_FORCE_LOGIN
} from '../../../shared/Constants'
import {
  // EpochToPrettyDateWithUrgency,
  // singleDice,
  coinFlip,
  getGodModeOptions,
  noLog as log,
  reallyTruthy,
  validEmail
} from '../../../shared/Utils'

const axios = require('axios').default
var axiosInstance = axios.create({
  baseURL: BASE_URL + 't/',
  timeout: NETWORK_TIMEOUT
})

class InstitutionAdminControls extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.props.data
    this.afterUserAdditionCallback = this.props.afterUserAdditionCallback
  }

  render() {
    const show = this._showInstitutionAdminControls()
    if (show) {
      return this._renderInstitutionAdminControls()
    } else {
      return <React.Fragment />
    }
  }

  componentDidMount() {
    this.setState({
      unlimitedOriginal: this.getDV('unlimited'),
      essentialOriginal: this.getDV('essential')
    })
  }

  _showInstitutionAdminControls() {
    const s = this.state
    const { canAdd } = this._getAddUserDefaults()
    return canAdd || s.institutionAdminMode
  }

  _renderInstitutionAdminControls() {
    log('state is: ', this.state)
    log(
      'state account enabled essential is: ',
      this.state.enableForcedMinorEssentialDomain
    )
    log(
      'state account enabled unlimited is: ',
      this.state.enableFallbackUnlimitedDomain
    )

    return (
      <div>
        {this._renderInstitutionAdminManageLicenses()}
        {this.state.enableFallbackUnlimitedDomain &&
          this._renderInstitutionAdminManageDomain('unlimited')}
        {this.state.enableForcedMinorEssentialDomain &&
          this._renderInstitutionAdminManageDomain('essential')}
      </div>
    )
  }

  _renderInstitutionAdminManageDomain(domainType) {
    log('domainType', domainType)
    log('state is', this.state)
    const editKey = domainType + 'Edit'
    const editMode = this.state[editKey]
    const domainValue = this.getDV(domainType)
    const domainDescription =
      domainType === 'unlimited' ? 'teacher Unlimited ' : 'student'
    const errorKey = domainType + 'Error'
    const errorText = this.state[errorKey]
    const overrideKey = domainType + 'NeedsGoogleOverride'
    const needsGoogleOverride = this.state[overrideKey]
    const confirmationKey = domainType + 'Confirmation'
    const confirmation = this.state[confirmationKey]
    const loadingKey = domainType + 'Loading'
    const loading = this.state[loadingKey]
    const originalKey = domainType + 'Original'
    const original = this.state[originalKey]
    const enableEditMode = () => this.updateDState(domainType, 'Edit', true)

    return (
      <div>
        {!editMode && !reallyTruthy(original) && (
          <p>
            <span className="lead">
              You haven't defined your {domainDescription} email domain
            </span>
            <button className="pay-button" onClick={() => enableEditMode()}>
              Let's do it!
            </button>
          </p>
        )}
        <div>
          <p>
            {(editMode || reallyTruthy(original)) && (
              <span className="lead">
                Your {domainDescription} email domain:{' '}
              </span>
            )}
            {!editMode && reallyTruthy(original) && (
              <span>
                <span className="lead">{original} </span>
                <button className="pay-button" onClick={() => enableEditMode()}>
                  Edit
                </button>{' '}
              </span>
            )}
          </p>
        </div>

        {reallyTruthy(editMode) && (
          <div>
            <p>
              <input
                type="text"
                size="40"
                value={domainValue}
                onChange={(e) => {
                  this.updateDState(domainType, 'Error', '')
                  this.updateDState(domainType, 'NeedsGoogleOverride', false)
                  this.updateDState(domainType, 'Confirmation', '')
                  this.updateDomain(domainType, e.target.value)
                }}
              />
            </p>
            {errorText && (
              <p>
                <font color="red">Error: {errorText}</font>
              </p>
            )}
            {needsGoogleOverride && (
              <div>
                <p>
                  Type the word <i>confirm</i> below if you are really sure this
                  is the correct domain:
                </p>

                <p>
                  <input
                    className="coupon-box"
                    type="text"
                    value={confirmation}
                    onChange={(e) =>
                      this.updateDState(
                        domainType,
                        'Confirmation',
                        e.target.value
                      )
                    }
                  />
                  {confirmation === 'confirm' && (
                    <font color="green">Great!</font>
                  )}
                </p>
              </div>
            )}
            <p>
              <button
                className="pay-button"
                onClick={() => this._validateDomain(domainType)}
                disabled={
                  loading || (needsGoogleOverride && confirmation !== 'confirm')
                }
              >
                {loading ? 'Updating ...' : 'Update this domain'}
              </button>
              <button
                className="cancel-button"
                onClick={() => {
                  this.updateDState(domainType, 'Edit', false)
                  this.updateDomain(
                    domainType,
                    this.getDS(domainType, 'Original')
                  )
                }}
              >
                Cancel
              </button>
            </p>
          </div>
        )}
      </div>
    )
  }

  _getAddUserDefaults() {
    const l = this.state.currentLicense
    const unusedLicenses = l.canAddEssential || l.canAddUnlimited
    const canAdd = unusedLicenses || l.canAddEssentialFreeMinors
    log('l is: ', l)
    log('unusedLicenses is: ', unusedLicenses)
    log('canAdd is: ', canAdd)
    var planMix = 0
    var productName = 0
    var productEnum = ''
    var forceMinor = false

    if (l.canAddEssential) {
      planMix++
      productName = 'Essential'
      productEnum = 'essential'
    }
    if (l.canAddUnlimited) {
      planMix++
      productName = 'Unlimited'
      productEnum = 'unlimited'
    }
    if (l.canAddEssentialFreeMinors) {
      planMix++
      productName = 'Essential (under 18 only)'
      productEnum = 'essential'
      forceMinor = true
    }
    const multiplePlans = planMix > 1 ? true : false
    return {
      unusedLicenses: unusedLicenses,
      canAdd: canAdd,
      multiplePlans: multiplePlans,
      productName: productName,
      productEnum: productEnum,
      forceMinor: forceMinor
    }
  }

  _productChoice(label, key) {
    return (
      <p>
        <label>
          <input
            onChange={(e) =>
              this.setState({ addUsersToProductEnum: e.target.value })
            }
            checked={this.state.addUsersToProductEnum === key}
            value={key}
            name="addUsersToProductEnum"
            type="radio"
          />
          &nbsp;
          {label}
        </label>
      </p>
    )
  }

  _initAddUsersForm(mode) {
    if (!mode) {
      mode = ''
    }
    this.setState({
      institutionAdminMode: mode,
      institutionAdminError: '',
      institutionAdminLoading: false,
      institutionAdminResults: undefined,
      addUsersToProductEnum: '',
      emailAddresses: ''
    })
  }

  _renderInstitutionAdminManageLicenses() {
    const {
      unusedLicenses,
      canAdd,
      multiplePlans,
      productName
    } = this._getAddUserDefaults()

    const l = this.state.currentLicense
    log('received canAdd: ', canAdd)
    return (
      <div>
        {canAdd && !this.state.institutionAdminMode && (
          <p>
            <span className="lead">
              {unusedLicenses
                ? 'You have unused licenses'
                : 'You can add free Essential under 18 users'}
            </span>
            <button
              className="pay-button"
              onClick={() => this._initAddUsersForm('addUsers0')}
            >
              Add users now!
            </button>
          </p>
        )}
        {this.state.institutionAdminMode === 'addUsers0' && (
          <div>
            <h2>Adding users</h2>
            {multiplePlans && (
              <div>
                <p>Pick a plan:</p>

                <form>
                  {l.canAddUnlimited &&
                    this._productChoice('Unlimited (adults)', 'unlimited 0')}
                  {l.canAddEssential &&
                    this._productChoice('Essential (adults)', 'essential 0')}
                  {l.canAddEssentialFreeMinors &&
                    this._productChoice(
                      'Essential (under 18s only)',
                      'essential 1'
                    )}
                </form>
              </div>
            )}
            {!multiplePlans && (
              <p>
                <b>Plan:</b> {productName}{' '}
              </p>
            )}

            <p>
              Copy and paste up to 20 email addresses of users you want to add
              (you can add more later).
            </p>

            <textarea
              rows="10"
              cols="80"
              value={this.state.emailAddresses}
              onChange={(e) =>
                this.setState({
                  emailAddresses: e.target.value
                })
              }
            />

            {!this.state.institutionAdminLoading && (
              <p>
                <button
                  className="btn btn-primary mt-1 mb-3"
                  onClick={() => this._validateUserSubmission()}
                >
                  {' '}
                  Add users
                </button>
                <button
                  className="cancel-button"
                  onClick={() =>
                    this.setState({ institutionAdminMode: undefined })
                  }
                >
                  Cancel
                </button>
              </p>
            )}
            {this.state.institutionAdminLoading && <p>Sending ...</p>}
            {this.state.institutionAdminError && (
              <p>
                <font color="red">{this.state.institutionAdminError}</font>
              </p>
            )}
          </div>
        )}
        {this.state.institutionAdminMode === 'addUsers1' &&
          this._renderUserAddResults()}
        {!canAdd && (
          <p>
            (You have used all your purchased licenses, contact
            support@mote.com to add more or reassign seats. More self-service
            features coming soon!)
          </p>
        )}
      </div>
    )
  }

  _renderUserAddResults() {
    const data = this.state
    log('account is currently: ', data)
    var ageGroupUpdates = data.ageGroupUpdates
    if (!ageGroupUpdates) {
      ageGroupUpdates = []
    }
    const addedUsers = data.added
      .concat(data.upgrades)
      .concat(data.downgrades)
      .concat(ageGroupUpdates)
    const hasAdded = addedUsers.length > 0
    const addCount = addedUsers.length
    const ignoredDuplicates = data.ignoredDuplicates.length
    const otherErrors = data.otherErrors.length
    const allAdded = data.received === addCount
    const overTheLimit = data.overTheLimit.length
    const invalidEmails = data.invalidEmails.length
    return (
      <div>
        <h2>Results of request to add users</h2>

        {hasAdded && (
          <p>
            Added {allAdded ? 'all (' + addCount + ')' : addCount} user
            {addCount > 1 ? 's' : ''} to your plan: {data.added.join(', ')}
          </p>
        )}
        {!allAdded && <p>Some users could not be added because: </p>}

        {!hasAdded && <p>No users were added because: </p>}

        {!allAdded && (
          <ul>
            {invalidEmails > 0 && (
              <li>
                <b>Invalid emails ({invalidEmails}):</b> These addresses are not
                valid: {data.invalidEmails.join(', ')}
              </li>
            )}

            {ignoredDuplicates > 0 && (
              <li>
                <b>Duplicates ({ignoredDuplicates}):</b> These addresses are
                already attached to your organization:{' '}
                {data.ignoredDuplicates.join(', ')}
              </li>
            )}

            {overTheLimit > 0 && (
              <li>
                <b>Account limit ({overTheLimit}):</b> You have run out of
                available licenses, we couldn't add these:{' '}
                {data.overTheLimit.join(', ')}
              </li>
            )}

            {otherErrors > 0 && (
              <li>
                <b>System error ({otherErrors}):</b> For reasons beyond your
                control, we couldn't add the following address. If the problem
                persists, content support@mote.com:{' '}
                {data.otherErrors.join(', ')}
              </li>
            )}
          </ul>
        )}

        <p>
          <button
            className="btn btn-primary mt-1 mb-3"
            onClick={() => this._initAddUsersForm()}
          >
            Close
          </button>
        </p>
      </div>
    )
  }

  _validateUserSubmission() {
    this.setState({ institutionAdminError: undefined })

    var selectedProduct = this.state.addUsersToProductEnum
    var selectedProductEnum = ''
    var selectedForceMinor = false
    if (!selectedProduct) {
      const {
        multiplePlans,
        productEnum,
        forceMinor
      } = this._getAddUserDefaults()
      if (multiplePlans) {
        this.setState({
          institutionAdminError: 'Pick a plan for the users you want to add'
        })
        return
      } else {
        selectedProductEnum = productEnum
        selectedForceMinor = forceMinor
      }
    } else {
      ;[selectedProductEnum, selectedForceMinor] = selectedProduct.split(' ')
      selectedForceMinor = reallyTruthy(selectedForceMinor)
    }

    var teachers = this.state.emailAddresses
    if (!teachers) {
      teachers = ''
    }
    var candidate = teachers.trim().replace(/\s+/g, ',')
    candidate = candidate.replace(/,,/g, ',')
    const emails = candidate.split(',')

    if (emails.length === 0 || (emails.length === 1 && !emails[0])) {
      this.setState({
        institutionAdminError: "You haven't entered any email addresses"
      })
      return
    }
    if (emails.length > 20) {
      this.setState({
        institutionAdminError: `You can upload 20 email addresses at a time (bulk upload coming soon). You should trim the above list from ${emails[20]}`
      })
      return
    }
    var badEmailsFound = false
    emails.forEach((element) => {
      if (!validEmail(element)) {
        this.setState({
          institutionAdminError: `At least one item above is not a valid email address - eg '${element}' - please correct before proceeding`
        })
        badEmailsFound = true
      }
    })
    if (badEmailsFound) {
      return
    }
    this.setState({ institutionAdminLoading: true })
    if (DEV_FORCE_LOGIN) {
      setTimeout(() => {
        this.setState({ institutionAdminLoading: false })
        if (coinFlip()) {
          this.setState({
            institutionAdminError: SERVER_ERROR
          })
        } else {
          this.setState({
            received: emails.length,
            added: ['foo@bar.com'],
            invalidEmails: ['blah'],
            ignoredDuplicates: ['bill@bar.com'],
            overTheLimit: ['jane@doe.com'],
            institutionAdminMode: 'addUsers1'
          })
        }
      }, 2000)
    } else {
      log('sending these email addresses: ', emails)

      var params = Object.assign({}, getGodModeOptions(), {
        accessToken: getAccessToken(),
        emailAddresses: emails,
        forceMinor: selectedForceMinor,
        productEnum: selectedProductEnum
      })

      axiosInstance
        .post('addUsers', params)
        .then((response) => {
          this.setState(response.data, () => {
            this.setState({
              institutionAdminLoading: false,
              institutionAdminMode: 'addUsers1'
            })
            this.afterUserAdditionCallback(response.data)
          })
        })
        .catch((e) => {
          error(e)
          this.setState({
            institutionAdminLoading: false,
            institutionAdminError: SERVER_ERROR
          })
        })
    }
  }

  _validateDomain(domainType) {
    const path =
      domainType === 'unlimited' ? 'domainUnlimited' : 'domainEssential'
    this.updateDState(domainType, 'Loading', true)
    var params = Object.assign({}, getGodModeOptions(), {
      googleOverride: this.getDS(domainType, 'Confirmation') === 'confirm',
      domain: this.getDV(domainType),
      accessToken: getAccessToken()
    })
    axiosInstance
      .post(path, params)
      .then((response) => {
        this.updateDState(domainType, 'Loading', false)
        this.updateDState(domainType, 'Confirmation', '')
        var d = response.data
        this.updateDState(
          domainType,
          'NeedsGoogleOverride',
          d.needsGoogleOverride
        )
        this.updateDState(domainType, 'Error', d.error)
        if (!d.error) {
          this.updateState(this.getDVK(domainType), d.domainUpdated)
          this.updateDState(domainType, 'Original', d.domainUpdated)
          this.updateDState(domainType, 'Edit', false)
        }
      })
      .catch((e) => {
        error(e)
        this.updateDState(domainType, 'Loading', false)
        this.updateDState(domainType, 'Confirmation', '')
        this.updateDState(domainType, 'NeedsGoogleOverride', false)
        this.updateDState(
          domainType,
          'Error',
          "We couldn't update your domain. It's probably a problem our end. Try again later, or if the problem persists contact support@mote.com"
        )
      })
  }
  updateDomain(domainType, v) {
    this.updateState(this.getDVK(domainType), v)
  }

  updateDState(domainType, k, v) {
    var o = {}
    o[domainType + k] = v
    this.setState(o)
  }

  updateState(k, v) {
    log('received instruction to update: ', k, this.state[k])
    log('with new value', v)
    var o = {}
    o[k] = v
    this.setState(o)
  }

  getDS(domainType, k) {
    return this.state[domainType + k]
  }

  getDVK(domainType) {
    return domainType === 'unlimited'
      ? 'fallbackUnlimitedDomain'
      : 'forcedMinorEssentialDomain'
  }

  getDV(domainType) {
    return this.state[this.getDVK(domainType)]
  }
}

export default InstitutionAdminControls
