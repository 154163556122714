import React from 'react'

import Schools from './Schools'
import Users from './Users'
import AdminHeader from './AdminHeader'
import EmailDomains from './EmailDomains'

const queryString = require('query-string')

class OG extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    this.setState(queryString.parse(window.location.search))
  }

  render() {
    // log("loginData: ", this.state.loginData);
    return (
      <div className="container mote-brand">
        <AdminHeader />
        <h1> Welcome to mote OG</h1>
        {this._renderActions()}
      </div>
    )
  }

  _renderActions() {
    return (
      <div>
        {this.state.users && this._renderUserMode()}
        {this.state.schools && <Schools />}
        {this.state.emailDomains && <EmailDomains />}
      </div>
    )
  }

  _renderUserMode() {
    return (
      <div>
        <h2>User admin</h2>
        <Users users={true} />
      </div>
    )
  }
}

export default OG
