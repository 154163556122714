const LOCAL_STORAGE_PREFIX = 'mote-auth'

export const checkIfLoggedIn = () => {
  return Boolean(
    window.localStorage.getItem(`${LOCAL_STORAGE_PREFIX}-accessToken`)
  )
}

export const getAuthAttribute = (attribute = null) => {
  if (!attribute) return ''

  return window.localStorage.getItem(`${LOCAL_STORAGE_PREFIX}-${attribute}`)
}
