import React from 'react'
import GetMoteLink from '../shared/GetMoteLink'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'
import TrackedDownloadLink from '../shared/TrackedDownloadLink'

class SchoolsInfo extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>

          <div
            className="bg-cover position-relative"
            style={{
              backgroundImage: 'url(images/logos/mote-background.png',
              backgroundSize: 'auto'
            }}
          >
            <div className="container mote-brand">
              <section className="container mb-4">
                <div className="row">
                  <h1 className="lg-display-4-mb-2-mt pt-4">
                    Find out how Mote gives teachers productivity, efficacy and
                    accessibility superpowers! 🚀
                  </h1>
                </div>
                {/* <p className="lead">
                  Schools of all sizes, age groups and learning environments
                  have deployed <b className="mote-purple">mote</b> to help
                  teachers and students connect and learn.
                </p> */}
                <div className="row justify-content-lg-between align-items-lg-center py-4 py-lg-5">
                  <div className="col-lg-6 mb-4">
                    <h2 className="h3">
                      Mote research makes strong case for audio feedback
                    </h2>
                    <ul>
                      <li>
                        Written by John Weathers PhD, this study presents a
                        strong case for audio feedback, in particular using
                        Mote.
                      </li>
                      <li>
                        After a review of the general literature on feedback in
                        education, the paper describes findings of a Fall 2020
                        study comparing Mote-using teachers with a control group
                        of non-users.
                      </li>
                      <li>
                        The research finds statistically significant differences
                        in teacher perceptions of efficacy, feedback quality,
                        feedback quantity and student motivation to learn.
                      </li>
                    </ul>
                    <TrackedDownloadLink
                      href="/docs/Educational_impact_of_Mote__by_John_Weathers_PhD.pdf"
                      label="Download research paper"
                      title="Weathers Report"
                    />
                  </div>

                  <div className="col-lg-5 ">
                    <figure className="figure">
                      <img
                        src="images/illustrations/Weathers.png"
                        className="img-fluid"
                        width="500"
                        alt="K-2 learners"
                      />
                    </figure>
                  </div>
                  <div className="col-lg-6 order-lg-2 mb-4">
                    <h2 className="h3">
                      Learn how Mote is a perfect fit for ESSER Funding
                    </h2>
                    <p className="mb-4">
                      <ul>
                        <li>
                          Support <b>all</b> students with Mote’s accessibility
                          features like transcription and translation
                        </li>
                        <li>
                          Support teacher <b>social and emotional health</b>{' '}
                          through improved productivity and perceived efficacy.
                        </li>
                        <li>
                          Address <b>learning loss and recovery</b> through
                          efficient personalized learning using Mote voice
                          feedback.
                        </li>
                        <li>
                          <b>Engage</b> students with easy, safe interactivity
                          using Mote for Google Slides and Mote for Google Forms
                        </li>
                        <li>
                          The <b>evidence</b> is clear: feedback delivers for
                          students. Supercharge your school’s feedback program
                          with a tool that works for teachers and students.
                        </li>
                      </ul>
                      <TrackedDownloadLink
                        href="/docs/moteESSER.pdf"
                        label="Get 1-pager"
                        title="ESSER 1-pager"
                      />
                    </p>
                  </div>
                  <div className="col-lg-5 order-lg-1">
                    <figure>
                      <img
                        src="images/illustrations/ESSER.png"
                        className="img-fluid"
                        width="500"
                        alt="ELL"
                      />
                    </figure>
                  </div>
                </div>

                <div className="row justify-content-lg-between align-items-lg-center py-4 py-lg-5">
                  <div className="col-lg-6 mb-4">
                    <h2 className="h3">English Learners</h2>
                    <p className="mb-4">
                      <ul>
                        <li>
                          Provide voice comments as part of your instructional
                          accommodations for students
                        </li>
                        <li>
                          Scaffold feedback with voice comments in English or
                          native language to enable students to access all
                          content and activities
                        </li>
                        <li>
                          Increase student engagement with verbal response
                          options in Google Docs, Slides, Classroom and more
                        </li>
                        <li>
                          Translate voice feedback into more than 15 world
                          languages
                        </li>
                      </ul>
                      <TrackedDownloadLink
                        href="/docs/moteEL.pdf"
                        label="Get 1-pager"
                        title="English Learners 1-pager"
                      />
                    </p>
                  </div>
                  <div className="col-lg-5">
                    <figure>
                      <img
                        src="images/photos/ELL.jpg"
                        className="img-fluid"
                        width="500"
                        alt="ELL"
                      />
                      <figcaption className="figure-caption text-center">
                        <small>
                          <span>Photo by Sean Kong on Unsplash</span>
                        </small>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="col-lg-6 order-lg-2 mb-4">
                    <h2 className="h3">Special Education</h2>
                    <ul>
                      <li>
                        Provide voice comments as part of your instructional
                        accommodations for students
                      </li>
                      <li>
                        Scaffold feedback with voice comments to enable students
                        to access content and activities in the least
                        restrictive environment
                      </li>
                      <li>
                        Modify student input and increase engagement in multiple
                        modalities with verbal response options in Google Docs,
                        Slides, Classroom and more
                      </li>
                    </ul>
                    <TrackedDownloadLink
                      href="/docs/moteSpecEd.pdf"
                      label="Get 1-pager"
                      title="Special Education 1-pager"
                    />
                  </div>
                  <div className="col-lg-5">
                    <figure>
                      <img
                        src="images/photos/specEd.jpg"
                        className="img-fluid"
                        width="500"
                        alt="Special Education"
                      />
                      <figcaption className="figure-caption text-center">
                        <small>Photo by Alireza Attari on Unsplash</small>
                      </figcaption>
                    </figure>
                  </div>
                </div>
                <div className="row justify-content-lg-between align-items-lg-center py-4 py-lg-5">
                  <div className="col-lg-6 mb-4">
                    <h2 className="h3">ESSA Funding</h2>
                    <p className="mb-4">
                      US schools may be able to access ESSA funding for their{' '}
                      <b className="mote-purple">mote</b> subscription.
                    </p>
                    <TrackedDownloadLink
                      href="/docs/moteESSA.pdf"
                      label="Get 1-pager"
                      title="ESSA funding 1-pager"
                    />
                  </div>
                  <div className="col-lg-5 order-lg-1">
                    <figure>
                      <img
                        src="images/photos/federal.jpg"
                        className="img-fluid"
                        width="500"
                        alt="ESSA funding"
                      />
                      <figcaption
                        className="figure-caption text-center"
                        width="500"
                      >
                        <small>Photo by Harold Mendoza on Unsplash</small>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="col-lg-6 order-lg-2 mb-4">
                    <h2 className="h3">K-2 Learners</h2>
                    <ul>
                      <li>
                        Scaffold activities for emerging prereaders with voice
                        instructions and feedback
                      </li>
                      <li>
                        Increase student engagement with verbal response options
                        in Google Docs, Slides, Classroom and more
                      </li>
                      <li>
                        Illuminate students' thinking with voice recording and
                        transcription
                      </li>
                    </ul>
                    <TrackedDownloadLink
                      href="/docs/moteK-2.pdf"
                      label="Get 1-pager"
                      title="K-2 Learners 1-pager"
                    />
                  </div>

                  <div className="col-lg-5 order-lg-1">
                    <figure className="figure">
                      <img
                        src="images/photos/K2.jpg"
                        className="img-fluid"
                        width="500"
                        alt="K-2 learners"
                      />
                      <figcaption className="figure-caption text-center">
                        <small>Photo by Josh Applegate on Unsplash</small>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </section>
              <div className="container py-1">
                <div className="text-center">
                  <div className="d-none d-md-block">
                    <h2 className="mb-4">Interested in a quote?</h2>

                    <a
                      className="btn btn-primary chrome-fake-button"
                      href="/checkoutschools"
                    >
                      Get a quote
                    </a>
                  </div>
                </div>
              </div>
              <div className="container py-1">
                <div className="text-center">
                  <div className="d-block d-md-none">
                    <h2 className="mb-4">
                      Try <b className="mote-purple">mote</b>
                    </h2>
                    <GetMoteLink recipe="atc" />
                  </div>
                </div>
              </div>
              <div className="container mb-lg-3"></div>

              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SchoolsInfo
