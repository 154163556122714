import React from 'react'
import { mote_primary } from '@mote/config/defaults/colors'

export const Trash = ({ color = mote_primary }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="none">
      <path
        fill={color}
        d="M17.21 19.457l.612-12.29h.035a2.14 2.14 0 001.515-.63 2.154 2.154 0 000-3.04 2.14 2.14 0 00-1.515-.63H14.13l-.308-1.239a2.15 2.15 0 00-.76-1.173A2.138 2.138 0 0011.74 0H8.29a2.138 2.138 0 00-2.07 1.592l-.34 1.275H2.144a2.14 2.14 0 00-1.515.63 2.154 2.154 0 000 3.04c.401.403.947.63 1.515.63h.035l.612 12.29A2.148 2.148 0 004.93 21.5h10.14a2.14 2.14 0 001.476-.593 2.15 2.15 0 00.663-1.45zM7.602 1.965a.717.717 0 01.69-.53h3.45a.713.713 0 01.693.543l.222.89h-5.3l.245-.903zM1.43 5.017a.718.718 0 01.714-.717h15.714a.713.713 0 01.714.717.718.718 0 01-.714.716H2.143a.713.713 0 01-.714-.716zm2.785 14.369l-.606-12.22h12.786l-.608 12.22a.717.717 0 01-.715.68H4.93a.713.713 0 01-.715-.68z"
      ></path>
      <path
        fill={color}
        d="M13.607 10.033a.713.713 0 00-.75.681l-.286 5.734a.72.72 0 00.679.752.714.714 0 00.75-.68l.286-5.734a.72.72 0 00-.679-.753zm-7.214 0a.713.713 0 00-.679.753L6 16.519a.729.729 0 00.75.681.713.713 0 00.679-.752l-.286-5.734a.722.722 0 00-.75-.68zm3.607 0a.713.713 0 00-.714.717v5.733A.718.718 0 0010 17.2a.713.713 0 00.714-.717V10.75a.718.718 0 00-.714-.717z"
      ></path>
    </svg>
  )
}
