import React from 'react'
import PropTypes from 'prop-types'
import tw, { styled } from 'twin.macro'
import ClickAwayListener from 'react-click-away-listener'
import { Localized } from '@fluent/react'
import { Button } from '@mote/ui/components/buttons'

export const InfoModal = ({ isVisible, onClose }) => {
  if (!isVisible) return null

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Root>
        <Content>
          <Localized id="slowly-translating-text-notification">
            We are slowly translating our website and chrome extension, please
            give us some time!
          </Localized>
          <ButtonWrapper>
            <Button isPrimary isSmall label="OK" onClick={() => onClose()} />
          </ButtonWrapper>
        </Content>
      </Root>
    </ClickAwayListener>
  )
}

const Root = styled.div`
  ${tw`absolute text-sm`};
  bottom: 95px;
  right: 0;
`

const Content = styled.div`
  ${tw`relative z-20 flex flex-col h-40 bg-white border border-gray-200 rounded-md shadow-md w-60 p-4`};
  color: #232323;
`
const ButtonWrapper = styled.div`
  ${tw`mt-4 flex self-center`}
`

InfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired
}
