import React from 'react'
import { styled } from 'twin.macro'

export const Content = ({ children, className = 'content' }) => {
  return <Root className={className}>{children}</Root>
}

const Root = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 60px;
`
