import React from 'react'
import { styled } from 'twin.macro'
import { Content } from '../../../../layouts/shared/content'
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon
} from '@mote/icons'
import { ButtonIcon } from '@mote/ui'

export const SocialSection = () => {
  const [init, setInit] = React.useState(false)

  const buttonRef = React.useRef(null)

  React.useEffect(() => {
    if (init || !buttonRef.current) return

    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/platform.js'
    script.async = true
    script.defer = true
    script.onload = () => {
      window.gapi?.ytsubscribe?.go(buttonRef.current.parentNode)
    }
    setInit(true)

    buttonRef.current.parentNode.appendChild(script)

    return () => {
      buttonRef.current.parentNode.removeChild(script)
    }
  }, [init, buttonRef])

  return (
    <Content>
      <Container>
        <Details>
          <Title>Get started with Mote</Title>
          <Description>
            Because Mote works inside the apps you already know and love,
            getting started is incredibly quick and easy. Watch this two-minute
            video to see for yourself! 🚀
          </Description>

          <div>
            <div
              ref={buttonRef}
              className="g-ytsubscribe"
              data-channelid="UCUQudvQCv2lzLRlS2-2UV5A"
              data-layout="default"
              data-count="default"
            />
          </div>
        </Details>

        <Video>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/Ut-nBRQfR_4"
          ></iframe>

          <Subscript>
            <Subscript>Search #moteminutes for mote tutorials</Subscript>

            <Socials>
              <ButtonIcon
                icon={<TwitterIcon />}
                onClick={(_) =>
                  window.location.replace('https://twitter.com/justmoteHQ')
                }
              />
              <ButtonIcon
                icon={<LinkedInIcon />}
                onClick={(_) =>
                  window.location.replace(
                    'https://www.linkedin.com/company/motehq/'
                  )
                }
              />
              <ButtonIcon
                icon={<FacebookIcon />}
                onClick={(_) =>
                  window.location.replace('https://www.facebook.com/moteHQ/')
                }
              />
              <ButtonIcon
                icon={<InstagramIcon />}
                onClick={(_) =>
                  window.location.replace(
                    'https://www.instagram.com/justmotehq/'
                  )
                }
              />
            </Socials>
          </Subscript>
        </Video>
      </Container>
    </Content>
  )
}

const Container = styled.div`
  display: flex;
  font-family: DM Sans;
  margin: 96px 0;
`

const Details = styled.div`
  margin-right: 42px;
  width: 331px;
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 16px;
`

const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 32px;
`

const Video = styled.div`
  width: 700px;
  height: 400px;
`

const Subscript = styled.div`
  font-size: 14px;
  color: #626a82;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Socials = styled.div`
  margin-bottom: 16px;

  button {
    margin-right: 7px;
  }
`
