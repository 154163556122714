import React from 'react'

import GetMoteLink from '../shared/GetMoteLink'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class ELA extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>

          <div className="container">
            <div className="row justify-content-between align-items-center py-4">
              <div className="col-lg-6 col-xl-6 mb-4">
                <h3 className="lg-display-4-mb-4-mt">
                  Remote learning &amp; feedback for English Language Arts &amp;
                  Humanities students
                </h3>
                <p className="lead">
                  Learn how <b className="mote-purple">mote</b> can help ease
                  your workload, and help your students stay connected.
                </p>
              </div>

              <div className="d-lg-none mb-2 justify-content-center">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/ela.jpg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>Photo credit: Kelly Sikkema, via Unsplash</small>
                  </figcaption>
                </figure>
              </div>

              <div className="d-none d-lg-block col-lg-6 col-xl-5 mb-3 justify-content-center text-lg-left">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/ela.jpg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>Photo credit: Kelly Sikkema, via Unsplash</small>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="teacher-page-intro">
              <p>
                Providing formative and summative feedback on student essays is
                easy with <b className="mote-purple">mote</b>. Voice notes
                within Google Docs comments and Google Classroom Private
                Comments are just three clicks away.{' '}
              </p>{' '}
              <p>
                Depending on your needs, and the needs of your students, choose
                between the accessibliity of automatically transcribing your
                voice notes and the simplicity of an audio-only voice note.
              </p>
            </div>
          </div>

          <div
            className="bg-cover position-relative"
            style={{
              backgroundImage: 'url(images/logos/mote-background.png',
              backgroundSize: 'auto'
            }}
          >
            <div className="container mote-brand">
              <h3 className="h4 mb-4">
                Here's what we've heard about{' '}
                <b className="mote-purple">mote</b> from English Language Arts
                and Humanities teachers
              </h3>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “I am an English teacher who knows the power of written
                      feedback, but in times like this, our students need to
                      hear our voices, too. This is a perfect add-on!"
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “I am a high school English teacher with an unmanageable
                      paper load. This add-on has increased my effectiveness
                      exponentially. I have reduced the time it takes to
                      complete the feedback cycle on student essays. Any tool
                      that places the ownership back on students and makes my
                      life easier is very much appreciated.“
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "I'm a veteran English teacher and have been waiting for
                      something like this for years. As things have moved into
                      the digital space typing and retyping the same thing is
                      mind-numbing. I appreciate that the transcription is
                      fairly spot on and that my students get to hear my voice
                      even though they can't see me.”
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “We are in an unprecedented time teaching remotely, and{' '}
                      <b className="mote-purple">mote</b> allows me the ability
                      to personally connect with students through voice. As a
                      Jr. High English teacher, I am able to give quick direct
                      feedback on skills, revisions, and editing. I will
                      continue to use <b className="mote-purple">mote</b> even
                      when we are back in the classroom.“
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
              </div>

              <div className="container py-1">
                <div className="text-center">
                  <h2 className="mb-4">Start your free trial</h2>
                  <GetMoteLink recipe="atc" />
                </div>
              </div>

              <div className="container mb-lg-3"></div>

              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ELA
