import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tw, { styled, css } from 'twin.macro'
import ClickAwayListener from 'react-click-away-listener'

import Emoji from './Emoji'
import { Emoji as EmojiIcon } from '@mote/assets/svgs/icons/index'

const emojis = [
  {
    id: 0,
    emoji: '💎',
    name: 'gem',
    message: 'I understand, and I’ll revise as suggested - thanks!'
  },
  {
    id: 1,
    emoji: '🤔',
    name: 'thinking',
    message: 'I may need help to understand this feedback'
  }
]

const EmojisSection = styled.div(({ width = 270 }) => [
  tw`flex justify-end my-1 w-full`
  // css`
  //   width: ${width}px;
  // `
])

const EmojiActivator = styled.div(() => [
  tw`relative flex items-center cursor-pointer select-none`
])

const EmojiMenu = styled.div(() => [
  tw`absolute right-0 flex items-center p-1 space-x-1 bg-white border border-gray-300 rounded-full shadow bottom-7`
])

const EmojiContainer = styled.div(({ active }) => [
  tw`flex items-center justify-center rounded w-7`,
  active && tw`bg-gray-100`
])

const ActiveEmojisList = styled.div(() => [
  tw`flex items-center justify-center w-6 pr-3 rounded cursor-pointer`
])

EmojiSelect.propTypes = {
  activeEmojis: PropTypes.array.isRequired,
  onEmoji: PropTypes.func
}

function EmojiSelect({ activeEmojis, onEmoji }) {
  const [isOpen, setIsOpen] = useState(false)

  const renderEmojis = () => {
    return emojis.map(({ id, emoji, name, message }) => {
      return (
        <div
          key={id}
          onClick={() => {
            onEmoji(name)
            setIsOpen(false)
          }}
        >
          <EmojiContainer>
            <Emoji isEmojiPickerItem message={message}>
              {emoji}
            </Emoji>
          </EmojiContainer>
        </div>
      )
    })
  }

  const renderActiveEmojis = () => {
    return emojis.map(({ id, emoji, name }) => {
      const active = activeEmojis.includes(name)

      return active ? (
        <div key={id} onClick={() => onEmoji(name)}>
          <ActiveEmojisList>
            <Emoji>{emoji}</Emoji>
          </ActiveEmojisList>
        </div>
      ) : null
    })
  }

  return (
    <EmojisSection>
      {renderActiveEmojis()}
      <EmojiActivator>
        <div onClick={() => setIsOpen(true)}>
          <EmojiIcon />
        </div>
        {isOpen && (
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <EmojiMenu>{renderEmojis()}</EmojiMenu>
          </ClickAwayListener>
        )}
      </EmojiActivator>
    </EmojisSection>
  )
}

export default EmojiSelect
