import React from 'react'
import { getApiClientConfig } from '../apiClientConfig'
import { privateApiClient, publicApiClient } from '../clients'
import { getAccessToken } from 'web/src/shared/Auth'
import { FaultInjector } from '../faultInjector'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
  fetched: false
}

export const useGenericRequestHandler = ({
  route,
  clientType = 'private',
  method = 'GET',
  options = {},
  enabled = true,
  responseType = 'json',
  abortable = true,
  refetchInterval = 0
}) => {
  const [state, setState] = React.useState(() => {
    return {
      ...INITIAL_STATE,
      loading: method === 'GET' && enabled
    }
  })
  const abortController = React.useRef(null)
  const refetchIntervalTimer = React.useRef(null)

  const apiClient = clientType === 'public' ? publicApiClient : privateApiClient
  const extendedApiClient = apiClient.extend(getApiClientConfig(route))
  let mounted = true

  const initiateRequest = React.useCallback(
    async (dynamicOptions) => {
      const defaultDynamicOptions = {
        body: null,
        variables: {},
        routeOverride: null,
        abortable: true
      }
      const customOptions = { ...defaultDynamicOptions, ...dynamicOptions }
      let error = state.error
      let data = state.data

      if (!state.loading) {
        setState((prevState) => ({
          ...prevState,
          loading: true
        }))
      }

      if (clientType === 'private') {
        options.json = {
          ...(options.json ?? {}),
          accessToken: getAccessToken()
        }
      }

      if (Object.keys(customOptions.variables).length) {
        options.json = { ...options.json, ...customOptions.variables }
      }

      if (customOptions.body) {
        delete options.json
        options.body = customOptions.body
      }

      if (abortController.current && abortable) {
        abortController.current.abort()
      }
      abortController.current = new AbortController()

      try {
        options.signal = abortController.current?.signal
        if (Boolean(process.env.REACT_APP_FAULT_INJECTION)) {
          data = await FaultInjector({
            route,
            customOptions,
            responseType,
            extendedApiClient
          })
        }
        data = await extendedApiClient[method.toLowerCase()](
          customOptions.routeOverride ?? route,
          options
        )[responseType]()
      } catch (err) {
        console.error(err?.message)
        error = err
      } finally {
        if (error?.name === 'AbortError') {
          console.log('Fetch aborted')
        }

        if (!mounted) return

        setState({
          loading: false,
          fetched: error?.name !== 'AbortError',
          error: error?.name === 'AbortError' ? null : error,
          data
        })

        abortController.current = null
      }
    },
    [state, extendedApiClient, options, method]
  )

  React.useEffect(() => {
    if (method !== 'GET' || !enabled) return

    initiateRequest()
  }, [enabled])

  React.useEffect(() => {
    return () => {
      abortController.current?.abort()
      mounted = false
    }
  }, [])

  React.useEffect(() => {
    if (!refetchInterval || state.loading) {
      clearInterval(refetchIntervalTimer.current)
      refetchIntervalTimer.current = null
      return
    }

    if (refetchIntervalTimer.current) return

    refetchIntervalTimer.current = setInterval(
      () => initiateRequest(),
      refetchInterval
    )

    return () =>
      refetchIntervalTimer.current &&
      clearInterval(refetchIntervalTimer.current)
  }, [refetchInterval, state, refetchIntervalTimer])

  return {
    ...state,
    mutate: method === 'GET' ? null : initiateRequest,
    refetch: method === 'GET' ? initiateRequest : null
  }
}
