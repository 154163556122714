import React from 'react'

function Error() {
  return (
    <div>
      <p className="lead">Oh dear</p>
      <p className="title">
        There was a problem loading this page. Please refresh.
      </p>

      <p className="title">
        If the problem persists, contact support@mote.com
      </p>
    </div>
  )
}

export default Error
