import { DEFAULT_LOCALE } from '@mote/common'
import { broadcastPreferredLocale } from 'web/src/shared/Auth'

const CURRENT_VERSION = '1'
const STORAGE_VERSION_KEY = 'mote-storage-version'

function getStorageVersion() {
  return window.localStorage.getItem(STORAGE_VERSION_KEY)
}

async function updateStorageVersion() {
  window.localStorage.setItem(STORAGE_VERSION_KEY, CURRENT_VERSION)
}

export const checkAndUpdateStorageVersion = async () => {
  const storageVersion = getStorageVersion()

  if (storageVersion && storageVersion === CURRENT_VERSION) return
  console.log('Storage version changed - updating')

  await updateStorageVersion()
  broadcastPreferredLocale(DEFAULT_LOCALE)
}
