import React from 'react'

class MoteLoginScreen extends React.Component {
  render() {
    return (
      <div className="text-center justify-content-center">
        <div className="row justify-content-center">
          <div className="col-lg-8 px-lg-5">
            <h1 className="text-center mote-login-title">
              Welcome to <b className="mote-purple">mote</b>!
            </h1>
            <p>
              Just want to hear and read <b className="mote-purple">mote</b>{' '}
              voice notes from other users? <br></br>
              Simply open and refresh ( hit{' '}
              <span class="material-icons">refresh</span> ) your
              Doc/Sheet/Slides page.
            </p>
            <p className="lead">
              To create your own <b className="mote-purple">mote</b> voice
              notes:
            </p>
            <h3>Please sign in</h3>
          </div>
          <div className="row justify-content-center mote-login-boilerplate px-lg-5">
            <p>
              {' '}
              Google will share your name, email address and profile picture
              with <b className="mote-purple">mote</b>. You can follow these
              links to look through our{' '}
              <a
                className="mote-boiler-link"
                href="https://www.justmote.me/privacy"
              >
                privacy policy
              </a>{' '}
              and{' '}
              <a
                className="mote-boiler-link"
                href="https://www.justmote.me/ToS"
              >
                terms of service
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default MoteLoginScreen
