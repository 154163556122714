import React from 'react'

import { postErrorEvent } from '../shared/AnalyticsHelpers'

class ForceError extends React.Component {
  render() {
    postErrorEvent('Force Error', 'forceError', 'This is just a test')
    return (
      <div>
        <p>
          A test page, just for its error side effects in Segment and Datadog
        </p>
        <button
          onClick={() => {
            ' '.forceError()
          }}
        >
          Break the world
        </button>
      </div>
    )
  }
}

export default ForceError
