import tw, { styled, css } from 'twin.macro'
import { IconClose } from '@mote/assets/svgs/icons'

const GOLDEN_RATIO = {
  width: 287,
  height: 177
}

export const Wrapper = styled.div(({ width = `${GOLDEN_RATIO.width}px` }) => [
  css`
    font-family: 'DM Sans', sans-serif !important;
    width: ${width};
  `
])

export const Container = styled.div(
  ({ height = `${GOLDEN_RATIO.height}px` }) => [
    tw`box-border relative block inline-block p-3 bg-white border border-gray-200 border-solid rounded-lg`,
    css`
      width: 100%;
      height: ${height};
    `
  ]
)

export const Header = styled.div(() => [tw`relative z-20 flex justify-between`])

export const Body = styled.div(() => [tw`bg-white rounded-lg`])

export const Footer = styled.div(() => [
  tw`px-3 py-2 bg-gray-100 border border-b-0 border-l-0 border-r-0 border-gray-200 border-solid rounded-t-none rounded-b-lg`,
  tw`flex items-center justify-between`,
  tw`text-sm text-gray-400`
])

export const ControlsContainer = styled.div(({ isTranscriptVisible }) => [
  isTranscriptVisible
    ? tw`flex justify-center mt-6`
    : tw`absolute inset-0 flex items-center justify-center`,
  !isTranscriptVisible
    ? css`
        height: ${GOLDEN_RATIO.height}px;
      `
    : null
])

export const ScrubberContainer = styled.div(({ isTranscriptVisible }) => [
  tw`relative`,
  isTranscriptVisible
    ? css`
        margin-top: 0;
      `
    : css`
        margin-top: 89px;
      `
])

export const ScrubberWrapper = styled.div(() => [tw`px-4`])

export const OptionWrapper = styled.div`
  ${tw`flex items-center w-full px-2 space-x-4`}
`

export const CloseIcon = styled.span`
  z-index: 30;
  cursor: pointer;
`
