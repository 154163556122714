import React from 'react'
import { styled } from 'twin.macro'

import MastHeadSVG from './assets/MastHead.svg'

export const MastHead = () => {
  return (
    <Root>
      <object type="image/svg+xml" data={MastHeadSVG}>
        Masthead animation
      </object>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  margin: 48px 0 32px 0;

  @media (max-width: 991px) {
    order: 1;
  }
  @media (max-width: 639px) {
    margin: 40px 0 24px 0;
  }
`
