import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player'
import { Howler } from 'howler'

import Controls from './Controls'
// import storage from '../../../../utilities/storage'

const REWIND_SECONDS = 5

AugmentedControls.propTypes = {
  audioUrl: PropTypes.string.isRequired,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnd: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

export default function AugmentedControls({
  audioUrl,
  onPause,
  onPlay,
  onEnd,
  disabled,
  loading
}) {
  // useEffect(() => {
  //   if (!storage.enabled) return
  //   getVolumeFromStorage()
  // }, [getVolumeFromStorage])
  const isMobile = navigator.userAgent.match(
    /(iPad)|(iPhone)|(android)|(webOS)/i
  )
  const { play, pause, player, playing, volume } = useAudioPlayer({
    src: audioUrl,
    html5: isMobile,
    format: ['mp3'],
    autoplay: false,
    onend: () => onEnd(getAudioPosition())
  })
  const { position, seek } = useAudioPosition()

  const [muted, setMuted] = useState(false)
  const [localVolume, setLocalVolume] = useState(100)
  const [preMuteValue, setPreMuteValue] = useState(100)

  const handlePlay = () => {
    play()

    onPlay && onPlay(getAudioPosition())
  }

  const handlePause = () => {
    pause()

    onPause && onPause(getAudioPosition())
  }

  const getAudioPosition = () => (position ? Math.round(position * 10) / 10 : 0)

  // const getVolumeFromStorage = useCallback(() => {
  //   if (storage.isExtensionContext) {
  //     storage.get('moteGmailVolume', (result) => {
  //       const volume = parseInt(result)

  //       if (!volume && volume !== 0) return
  //       changeVolume(volume)
  //     })
  //   } else {
  //     const volume = parseInt(storage.get('moteGmailVolume'))

  //     if (!volume && volume !== 0) return
  //     changeVolume(volume)
  //   }
  // }, [changeVolume])

  const changeVolume = useCallback(
    (value) => {
      if (player) player.mute(!value)

      setMuted(!value)
      setLocalVolume(value)
      volume(value / 100)

      // if (!storage.enabled) return
      // storage.set('moteGmailVolume', value)
    },
    [player, volume]
  )

  const toggleMute = () => {
    if (!muted && localVolume) setPreMuteValue(localVolume)
    setMuted(!muted)
    changeVolume(!muted ? 0 : preMuteValue)
    player.mute(!muted) // have to tap into Howler via 'player' since 'useAudioPlayer' exposed 'mute' function does not work on mobile
  }

  const onRewind = () => {
    seek(Math.max(0, position - REWIND_SECONDS))
  }

  return (
    <Controls
      loading={loading}
      disabled={disabled}
      isPlaying={playing}
      onRewind={onRewind}
      onPlay={handlePlay}
      onPause={handlePause}
      muted={muted}
      toggleMute={toggleMute}
      volume={localVolume}
      changeVolume={changeVolume}
      audioStream={Howler.masterGain}
      audioContext={Howler.ctx}
    />
  )
}
