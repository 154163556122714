import { coinFlip, doubleDice, fiveTimesOutOfSix } from '../../../shared/Utils'

export const DEV_FAKE_ACCOUNT_DATA = {
  isInstitution: fiveTimesOutOfSix(),
  isInstitutionalOwner: fiveTimesOutOfSix(),
  isInstitutionalAdmin: fiveTimesOutOfSix(),
  accountOwnerName: 'Bill Gates',
  accountOwnerEmail: 'bill@msft.com',
  accountPureAdminEmails: ['melinda@msft.com', 'jane@msft.com'],
  institutionName: 'Argos High',
  institutionRegion: 'Argolida',
  institutionCountryCode: 'gr',
  institutionProductSummary: 'Unlimited (5) + Essential (20)',
  currentLicense: {
    canAddHighestProductEnumUsers: true,
    canAddEssential: fiveTimesOutOfSix(),
    canAddUnlimited: coinFlip(),
    canAddEssentialFreeMinors: coinFlip(),
    highestProductEnum: 'unlimited',
    totalPaidSeats: 10,
    unusedPaidSeats: coinFlip() ? 2 : 9,
    bonusEssentialUsers: 5,
    paidUnlimited: 10,
    paidEssential: 0,
    usedUnlimited: 1,
    totalUsers: 11,
    usedEssential: coinFlip() ? 2 : 10
  },
  isMinor: doubleDice(),
  isGifted: doubleDice(),
  isPaid: coinFlip(),
  isVIP: coinFlip(),
  isInFreeTrial: coinFlip(),
  freeTrialExpired: doubleDice(),
  isOnReferralUnlimited: doubleDice(),
  wasPaid: doubleDice(),
  primaryEmailAddress: 'will@justmote.me',
  lastPaymentEpoch: 1591560000,
  nextRenewalEpoch: 1591560000,
  freeTrialExpiryEpoch: 1591560000,
  referrerCode: 'foobar',
  productName: 'Unlimited',
  planName: 'Yearly',
  referralUnlimitedExpiryEpoch: 1600025786,
  giftExpiryEpoch: 1600025786,
  giftorName: 'Mary Lou Philips',
  giftorEmail: 'blue.violets.83@gmal.com',
  enableForcedMinorEssentialDomain: fiveTimesOutOfSix(),
  enableFallbackUnlimitedDomain: coinFlip(),
  forcedMinorEssentialDomain: coinFlip() ? 'students.foo.bar.com' : '',
  fallbackUnlimitedDomain: coinFlip() ? 'foo.bar.com' : '',
  giftPayments: coinFlip()
    ? []
    : [
        {
          quantity: 1,
          lastPaymentEpoch: 1591560000
        },
        {
          quantity: 3,
          lastPaymentEpoch: 1591560000
        }
      ],
  giftees: [
    {
      giftId: '232adada',
      gifteeName: 'Jane Johnson',
      gifteeEmail: 'johnson@tinyschool.wi.us',
      giftExpiryEpoch: 1600025786
    },
    { giftId: 'b32adada', giftExpiryEpoch: 1600025786 },
    { giftId: 'c32afeda', giftExpiryEpoch: 1600025786 }
  ],
  productStates: [
    {
      productName: 'Unlimited',
      description: ['You have recorded 10 motes']
    }
  ]
}
DEV_FAKE_ACCOUNT_DATA.essentialOriginal =
  DEV_FAKE_ACCOUNT_DATA.forcedMinorEssentialDomain
DEV_FAKE_ACCOUNT_DATA.unlimitedOriginal =
  DEV_FAKE_ACCOUNT_DATA.fallbackUnlimitedDomain
