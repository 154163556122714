export const FAKE_MOTE = {
  TZOffset: -120,
  accessToken: '123',
  acquiredScopes: 'profile email',
  availableReactions: [],
  availableUnreactions: [],
  canEdit: true,
  canLink: true,
  canRedirect: true,
  canUseBook: true,
  containingURL: 'https://www.bbc.com/',
  containingURLDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  containingURLHeroImage: '',
  containingURLSiteImage: 'https://www.bbc.com/favicon.ico',
  containingURLSiteName: 'bbc.com',
  containingURLTitle:
    'BBC - Homepage Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  countryCode: 'ba',
  createdAt: 1603113401,
  createdByGreeting: 'Emir',
  createdByName: 'Emir Mehi\u0107',
  createdByUserId: 'bK7e2c7HjAOK92QKSua5AgSo6dtkaeFg',
  dominantLanguage: 'en',
  duration: 3.3,
  familyName: 'Mehi\u0107',
  givenName: 'Emir',
  hasFailed: false,
  hasTranscriptionFailed: false,
  highestError: '',
  hitBookLimit: false,
  imageURL:
    'https://lh3.googleusercontent.com/a-/AOh14GiduoPS5reVGRnKxd8zzOpFHFQbdFjuAAMypRcS=s96-c',
  inMarketBy: '0',
  isActive: false,
  isFinalPlaybackLocation: true,
  isMinor: false,
  isPlayable: true,
  isQuiescent: true,
  isUserReadable: true,
  lastTranslationLanguage: 'en',
  legals: 1,
  moteId: '47jv6a9',
  myReactions: [],
  name: 'Emir Mehi\u0107',
  needsNewGrant: false,
  playbackLocation:
    'https://media.justmote.me/dev/audio_files3/4/7/j/v/6/a/9/47jv6a9.mp3',
  preferredLocale: 'en-US',
  primaryEmailAddress: 'emir@justmote.me',
  productStates: [
    {
      canTranscribe: true,
      dateReplacements: [],
      description: ['You have recorded 1324 motes since 19 October'],
      freeTrialExpired: false,
      isGifted: false,
      isInFreeTrial: false,
      isOnReferralUnlimited: false,
      isPaid: true,
      isUpgradable: false,
      isVIP: false,
      productEnum: 'unlimited',
      productName: 'Unlimited',
      validFrom: 0,
      wasPaid: false
    }
  ],
  quotaResetsAt: '1623415110',
  referrerCode: 'ue8pmx5',
  requiredScope: '',
  shouldPollForTranscription: false,
  shouldRedirect: true,
  showStemTranscript: false,
  title: '',
  transcriptDisplay: 'This is my transcription here',
  transcriptDisplayStem: 'This is my transcription here',
  transcriptPreview: 'This is my transcription here',
  transcriptionLanguage: '',
  transcriptionOptOut: true,
  url: 'https://dev.mote.fyi/47jv6a9',
  userId: 'bK7e2c7HjAOK92QKSua5AgSo6dtkaeFg'
}
