function isTruthy() {
  return Math.round(Math.random())
}

export const FaultInjector = async ({
  route,
  customOptions,
  responseType,
  extendedApiClient
}) => {
  // throw error
  if (isTruthy()) {
    throw new Error(
      `[FaultInjection]: ${defaultDynamicOptions.routeOverride || route}`
    )
  }

  // fake responses
  if (isTruthy()) {
    switch (true) {
      case route.includes('m/status'): {
        return {
          moteId: ''
        }
      }
    }
  }

  return extendedApiClient[method.toLowerCase()](
    customOptions.routeOverride ?? route,
    options
  )[responseType]()
}
