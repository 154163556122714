import React from 'react'
// import reactCSS from 'reactcss';
// import { ReactTypeformEmbed } from 'react-typeform-embed'
// import Header from '../shared/Header';
// import Footer from '../shared/Footer';

class Survey extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1>Embed</h1>
        <iframe
          title="Airtable"
          className="airtable-embed mote-brand"
          src="https://www.bbc.co.uk/news/world-asia-56851487"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="600"
          styleName="background: transparent; border: 1px solid #ccc;"
        ></iframe>
      </React.Fragment>
    )
  }
}

export default Survey
