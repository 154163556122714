import React from 'react'
import PropTypes from 'prop-types'
import tw, { styled } from 'twin.macro'
import { useAudioPosition } from 'react-use-audio-player'
import { secondsToString } from '../utilities'
import { P } from '@mote/config/defaults/typography'

const DurationsContainer = styled.div(() => [
  tw`flex items-center justify-between mb-2 text-xs text-gray-800`
])

Durations.propTypes = {
  currentTime: PropTypes.number,
  totalTime: PropTypes.string
}

export default function Durations({ currentTime, totalTime }) {
  const { position, duration } = useAudioPosition()

  const parseTotalTime = () => {
    return totalTime === '{{ totalTime }}' || typeof totalTime === 'undefined'
      ? totalTime
      : secondsToString(totalTime)
  }

  return (
    <DurationsContainer>
      <P isSmall data-testid="current-duration">
        {secondsToString(currentTime || position)}
      </P>
      <P isSmall data-testid="total-duration">
        {parseTotalTime() || secondsToString(duration)}
      </P>
    </DurationsContainer>
  )
}
