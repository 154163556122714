import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import CustEdLink from '../shared/CustEdLink'
import { Emoji } from '@mote/ui/components/emoji'

class MoteCarousel extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="container mote-brand">
          <Carousel fade>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="images/custEd/1.png"
                alt="Welcome"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="images/custEd/2.png"
                alt="Add mote to Google Docs"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="images/custEd/3.png"
                alt="Add mote to Google Classroom"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="images/custEd/4.png"
                alt="Insert a mote within Google Slides"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="images/custEd/5.png"
                alt="Add a mote to Gmail"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="images/custEd/6.png"
                alt="Capture your voice anywhere"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="images/custEd/7.png"
                alt="Transcribe and use STEM mode"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <p className="mt-3 d-none d-lg-block lead text-center">
          <Emoji>👇</Emoji>Try our 1-minute tutorial templates to start using{' '}
          <span className="mote-purple">mote</span>
          <Emoji>👇</Emoji>
        </p>
        <div className="cust-ed-buttons container mote-brand">
          <CustEdLink
            href="https://docs.google.com/document/d/1K4QuYWjSaoH2cU5o28lsAD1uinNVNq-fe9Jj72M1Kwg/copy"
            label="Leave your first mote"
            title="First mote"
          />
          <CustEdLink
            href="https://docs.google.com/presentation/d/1Ofk-bSsT9PIxL21MjZHxljjmLUmJUbHA_dwaMu-hp3s/copy"
            label="Try Mote for slides"
            title="Mote for Slides"
          />
          <CustEdLink
            href="https://docs.google.com/forms/d/1t0hzFdH0oouns19cDBWtcaGaqHWWGku1AjURje72hYU/copy"
            label="Try Mote for Forms"
            title="Mote for Forms"
          />
        </div>
      </React.Fragment>
    )
  }
}

export default MoteCarousel
