import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'

class HistoryAnnotation extends React.Component {
  render() {
    return (
      <div className="mote-brand">
        <div className="container">
          <Header />

          {/* <div className="container"> */}
        </div>

        {/* <div className="container">
                            <div className="row justify-content-center my-5">
                                <div className="col-lg-9">
                                    <img src="/images/photos/Stones1982RotterdamMarcelAntonisse.jpg" className="img-fluid img-thumbnail" alt="Mick Jagger" />
                                </div>
                            </div>
                        </div> */}

        {/* <div className="bg-skew bg-skew-light"> */}

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h1 className="text-center pt-5">
                A history (and future) <br></br>of annotation
              </h1>
              <p className="small mb-5 text-center">
                <span className="text-secondary">published on </span>
                <span className="text-uppercase">FEB 12, 2020</span>
              </p>
              <p className="lead">
                Here at <b className="mote-purple">mote </b>we’re on a mission
                to give everyone annotation superpowers. Since every superhero
                needs a backstory, we got to thinking… what’s the backstory of
                annotation?
              </p>
            </div>
          </div>
        </div>
        <div
          className="bg-cover position-relative"
          style={{ backgroundImage: 'url(images/logos/mote-background.png' }}
        >
          <div className="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <article class="pb-2">
                  <h2 className="h2 mt-4">
                    Annotating for community: to 1500 AD
                  </h2>
                  <p>
                    People have been annotating books for almost as long as
                    books have been around. At a time when books were
                    hand-written, rare and shared, these early annotations were
                    provided primarily as a valued service for other readers,
                    maybe the earliest form of mobile, social media.
                  </p>
                  <p>
                    Margin notes written around 1000 AD by a monk in Northern
                    Spain are thought to contain the earliest known written
                    examples of the Spanish language. These kinds of margin
                    notes are known as glosses, and most typically would
                    translate or clarify the meaning of a word or words in the
                    text. Today, we use the word glossary to describe the
                    appendix of a paper or book which functions as a collection
                    of glosses, explaining key terms or names used in the text.
                  </p>
                  <div class="row justify-content-center">
                    <figure class="figure mt-2">
                      <img
                        src="/images/photos/Codiceemil.jpg"
                        width="400"
                        class="img-fluid shadow"
                        alt="The Glosas Emilianenses"
                      />
                      <figcaption class="figure-caption p-2 text-center">
                        The Glosas Emilianenses
                      </figcaption>
                    </figure>
                  </div>
                  <h2 className="h2 mt-4">
                    Annotating for inspiration: 1500-1990
                  </h2>
                  <p>
                    The printing press made books much cheaper, and less likely
                    to be shared around, so the role of annotations shifted from
                    largely communal to largely personal in nature, with readers
                    annotating texts for their own reference.
                  </p>
                  <p>
                    Fermat’s Last Theorem is probably the most famous margin
                    note in history. Unfortunately for Pierre de Fermat, a lack
                    of margin space in his copy of{' '}
                    <i>Diophantus’ Arithmetica</i> prevented him from laying out
                    his ‘truly marvelous proof’ to the famous proposition in
                    1637, but did keep mathematicians occupied for the next 350
                    years.
                  </p>
                  <p>
                    Around the same time that Fermat was scribbling in his
                    margins, a first edition of Shakespeare’s First Folio was
                    getting extensively marked up by illustrious English writer
                    John Milton, author of ‘Paradise Lost’. Milton’s comments
                    are mostly <i>aide memoires</i>, and perhaps provided the
                    author with inspiration as he worked on his own masterpiece.
                  </p>
                  <div class="row justify-content-center">
                    <figure class="figure mt-2">
                      <img
                        src="/images/photos/RJ-prologue.png"
                        class="img-fluid shadow"
                        alt="The Glosas Emilianenses"
                      />
                      <figcaption class="figure-caption p-2 text-center">
                        The prologue to Romeo and Juliet, transcribed by John
                        Milton on the last page of Titus Andronicus because it
                        was omitted from the First Folio.
                      </figcaption>
                    </figure>
                  </div>
                  <p>
                    In the 19th century, poet Edgar Allen Poe raised the margin
                    note to an art-form, even publishing an essay collection of
                    his own ‘marginalia’. According to Poe:
                  </p>
                  <blockquote className="blockquote">
                    <p className="mb-2">
                      In getting my books, I have been always solicitous of an
                      ample margin; this not so much through any love of the
                      thing in itself, however agreeable, as for the facility it
                      affords me of penciling suggested thoughts, agreements and
                      differences of opinion, or brief critical comments in
                      general.
                    </p>
                    <footer className="blockquote-footer">
                      Edgar Allen Poe
                    </footer>
                  </blockquote>

                  <h2 className="h3 mt-4">
                    Annotation and the internet: 1990-
                  </h2>
                  <p>
                    If the post-Gutenberg era represented a shift towards
                    personal annotation, the post-Berners Lee era has
                    represented a shift back towards annotation as a communal
                    activity.
                  </p>

                  <p>
                    In 1995, Microsoft built commenting into MS Word, and by the
                    early 2000s an array of sites and services emerged to help
                    teams to collaborate online, in real time. One of those was
                    the online word processing app Writely, acquired by Google
                    in 2006. The product, soon renamed and relaunched as Google
                    Docs, allowed for live, asynchronous collaboration,
                    including highlighting and commenting.
                  </p>
                  <p>
                    Today, it’s hard to think of a modern software tool not
                    designed for collaboration. We use annotation to share
                    feedback, suggestions and ideas to our workmates and, unlike
                    those medieval monks, our colleagues around the world can
                    read and react in real time.{' '}
                  </p>
                  <p>
                    But there’s an opportunity to rethink what it means to
                    annotate. Here at <b className="mote-purple">mote</b>, we
                    start with the idea that the written word should be just one
                    - not the first and only - choice in annotating documents
                    and projects. We’d love you to check out our first product,
                    the <b className="mote-purple">mote </b>Chrome extension,
                    and to tell us what annotation superpowers you’d love to
                    have.
                  </p>

                  <div className="media my-5">
                    <img
                      className="avatar avatar-lg mr-3"
                      src="images/photos/Will.jpg"
                      alt="Post author"
                    />
                    <div className="media-body">
                      <h3 className="h5 mt-0">Will Jackson</h3>
                      <p className="mb-0 text-secondary">
                        Will is the co-founder of{' '}
                        <b className="mote-purple">mote</b>, and is based in the
                        SF Bay Area.
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div className="container py-1">
              <div className="text-center">
                <h2 className="mb-4">Are you ready to start?</h2>
                <a href="/chrome" className="btn btn-primary mr-2">
                  Add to Chrome
                </a>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default HistoryAnnotation
