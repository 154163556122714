import React from 'react'

import { generateTranslationBundles } from '../helpers/generateTranslationBundles'
import {
  DEFAULT_LOCALE,
  getPreferredLocaleFromStorage,
  setPreferredLocaleInStorage,
  TRANSLATION_LOCALES
} from '@mote/common'

import { ReactLocalization, LocalizationProvider } from '@fluent/react'
import { broadcastPreferredLocale } from 'web/src/shared/Auth'
import { usePreferredLocaleMutation } from '../api/usePreferredLocaleMutation'

const LocaleContext = React.createContext({})

export const LocaleProvider = ({
  children,
  initialL10n = null,
  app = 'web'
}) => {
  const [l10n, setL10n] = React.useState(initialL10n)
  const [preferredLocale, setPreferredLocale] = React.useState()

  async function getLocaleBundles() {
    const localeBundles = await generateTranslationBundles(app, preferredLocale)

    setL10n(new ReactLocalization(await Promise.all(localeBundles)))
  }

  console.log({ preferredLocale, l10n })

  React.useEffect(() => {
    if (!preferredLocale) return

    getLocaleBundles()
  }, [preferredLocale])

  React.useEffect(() => {
    if (preferredLocale) return

    async function decideOnLocale() {
      const localeInStorage = await getPreferredLocaleFromStorage()

      if (
        !localeInStorage ||
        (localeInStorage &&
          !TRANSLATION_LOCALES.find(
            (locale) => locale.code === localeInStorage
          ))
      ) {
        setPreferredLocaleInStorage(DEFAULT_LOCALE)
        return setPreferredLocale(DEFAULT_LOCALE)
      }

      setPreferredLocale(localeInStorage)
    }
    decideOnLocale()
  }, [preferredLocale])

  if (!l10n || !preferredLocale) return null

  return (
    <LocaleContext.Provider value={{ preferredLocale, setPreferredLocale }}>
      <LocalizationProvider l10n={l10n}>{children}</LocalizationProvider>
    </LocaleContext.Provider>
  )
}

export const useLocale = () => {
  const { preferredLocale, setPreferredLocale } = React.useContext(
    LocaleContext
  )

  const { mutate: updatePreferredLocale } = usePreferredLocaleMutation()

  async function updateLocale(newLocale) {
    setPreferredLocale(newLocale)
    await updatePreferredLocale({ preferredLocale: newLocale })
    broadcastPreferredLocale(newLocale)
    setPreferredLocaleInStorage(newLocale)
  }

  return {
    locale: TRANSLATION_LOCALES.find((item) => item.code === preferredLocale),
    updateLocale
  }
}
