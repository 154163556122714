import React from 'react'

export const Cancel = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="none">
      <rect
        width="5"
        height="29.98"
        x="33.735"
        y="30.199"
        fill={color}
        rx="2.5"
        transform="rotate(135 33.735 30.2)"
      ></rect>
      <rect
        width="5"
        height="29.98"
        x="12.536"
        y="33.735"
        fill={color}
        rx="2.5"
        transform="rotate(-135 12.536 33.735)"
      ></rect>
    </svg>
  )
}
