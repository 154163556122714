import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tw, { styled, css } from 'twin.macro'

import Chevron from '@mote/assets/svgs/icons/Chevron'

const TranscriptionBox = styled.div(() => [
  tw`flex items-baseline justify-between w-full h-full pl-1 text-sm bg-gray-100 rounded-sm cursor-pointer`
])

const TextFull = styled.div(() => [tw`p-1 m-0 max-h-20`])

const TextTruncated = styled.div(({ isStem }) => [
  tw`p-1 m-0 truncate`,

  isStem &&
    css`
      margin-right: 10px;
    `
])

const ChevronUp = styled.div(() => [tw`transform rotate-180`])

Transcription.propTypes = {
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isQuiescent: PropTypes.bool,
  isLandingPage: PropTypes.bool
}

function Transcription({ text, isQuiescent, isLandingPage }) {
  const [isOpen, setIsOpen] = useState(false)
  const isStem = typeof text === 'object'
  const transcriptText = isLandingPage ? (
    text
  ) : (
    <>
      {text} {!isQuiescent ? '(still transcribing...)' : ''}
    </>
  )
  const pluginMarkup = text ? (
    <TranscriptionBox onClick={() => setIsOpen(!isOpen)}>
      {isOpen ? (
        <div
          style={{
            width: '100%',
            overflowY: 'auto',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <TextFull>{transcriptText}</TextFull>
          </div>
          <div style={{ marginRight: '10px', marginTop: '8px' }}>
            <ChevronUp>
              <Chevron />
            </ChevronUp>
          </div>
        </div>
      ) : (
        <>
          <TextTruncated isStem={isStem}>{transcriptText}</TextTruncated>

          <div style={{ marginRight: '10px' }}>
            <Chevron />
          </div>
        </>
      )}
    </TranscriptionBox>
  ) : null

  const landingMarkup = text ? (
    <TranscriptionBox>
      <div
        style={{
          width: '100%',
          overflowY: 'auto',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <TextFull>{transcriptText}</TextFull>
        </div>
      </div>
    </TranscriptionBox>
  ) : null

  const transcriptMarkup = isLandingPage ? landingMarkup : pluginMarkup

  return transcriptMarkup
}

export default Transcription
