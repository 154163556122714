import { useEffect } from 'react'

export const useKeyPress = (key, action) => {
  useEffect(() => {
    function onKeyup(e) {
      if (e.keyCode === key) action()
    }
    window.addEventListener('keyup', onKeyup)
    return () => window.removeEventListener('keyup', onKeyup)
  }, [key, action])
}
