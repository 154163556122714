import React, { useState, useEffect, useCallback } from 'react'
import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player'
import tw, { css, styled } from 'twin.macro'

const isTouch = 'ontouchend' in document.documentElement

export const Scrubber = ({ forcedProgress, replyIsMine }) => {
  const { percentComplete, duration, seek } = useAudioPosition({
    highRefreshRate: true
  })

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setProgress(percentComplete)
  }, [percentComplete])

  const goTo = useCallback(
    (percent) => {
      seek(percent * duration)
    },
    [duration, seek]
  )

  const leftPosition = parseInt(forcedProgress) || progress

  const { pause, play, playing } = useAudioPlayer()
  const seekBarEl = React.useRef(null)
  const prevPlayingState = React.useRef(null)

  function barPosition(e) {
    const clickPageX = e.touches ? e.touches[0].pageX : e.pageX
    const barStart =
      seekBarEl.current.getBoundingClientRect().left + window.scrollX
    const clickPos = clickPageX - barStart
    const barWidth = seekBarEl.current.clientWidth
    const percentage = clickPos / parseInt(barWidth)
    const percentageInRange = Math.min(Math.max(percentage, 0), 1)
    goTo(percentageInRange)
  }

  function mouseUp(e) {
    e.preventDefault()
    seekBarEl.current.removeEventListener('mousemove', barPosition)
    if (prevPlayingState.current) {
      play()
    }
  }

  function touchUp(e) {
    e.preventDefault()
    seekBarEl.current.removeEventListener('touchmove', barPosition)
    if (prevPlayingState.current) {
      play()
    }
  }

  const eventSetup = (e) => {
    e.preventDefault()
    prevPlayingState.current = playing
    pause()
    barPosition(e)

    if (isTouch) {
      seekBarEl.current.addEventListener('touchmove', barPosition)
      seekBarEl.current.addEventListener('touchend', touchUp, { once: true })
    } else {
      seekBarEl.current.addEventListener('mousemove', barPosition)
      seekBarEl.current.addEventListener('mouseup', mouseUp, { once: true })
    }
  }

  return (
    <Root
      onTouchStart={isTouch ? eventSetup : () => {}}
      onMouseDown={!isTouch ? eventSetup : () => {}}
      ref={seekBarEl}
      data-testid="scrubber-bar"
    >
      <ScrubberContainer>
        <BgBar />
        <Bar left={leftPosition} replyIsMine={replyIsMine} />
      </ScrubberContainer>
      {/*<DotContainer>*/}
      {/*  <DotWrapper*/}
      {/*    data-testid="scrubber-dot"*/}
      {/*    data-value={leftPosition}*/}
      {/*    left={leftPosition}*/}
      {/*  >*/}
      {/*    {!disabled && <Dot />}*/}
      {/*  </DotWrapper>*/}
      {/*</DotContainer>*/}
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  flex: 1;
  touch-action: none;
`

const Bar = styled.div(({ left, replyIsMine }) => [
  tw`w-full`,
  css`
    background: linear-gradient(0deg, rgba(172, 10, 232, 0.2) 0%, #ac0ae8 100%);
    background: -webkit-linear-gradient(
      0deg,
      rgba(172, 10, 232, 0.2) 0%,
      #ac0ae8 100%
    );
    opacity: 0.8;
    height: 6px;
    transform: translateX(${-100 + left}%);
  `,
  replyIsMine &&
    css`
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.3) 0%,
        #ffffff 100%
      );
      background: -webkit-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.3) 0%,
        #ffffff 100%
      );
      opacity: 0.8;
    `
])

const BgBar = styled.div(() => [
  tw`absolute left-0 w-full`,
  css`
    background-color: rgba(213, 212, 218, 0.5);
    height: 6px;
  `
])

const ScrubberContainer = styled.div(() => [
  tw`relative w-full overflow-hidden cursor-pointer rounded-2xl`
])
