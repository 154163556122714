import React from 'react'

import { PULLDOWN_REGIONS } from '../shared/Constants'
import { noLog as log } from '../shared/Utils'

class SmartRegion extends React.Component {
  render() {
    const cc = this.props.countryCode
    log('render: region is: ' + this.props.region)
    // log("foo country: " + cc);
    // log("original region: " + this.originalRegion);
    if (PULLDOWN_REGIONS[cc] && PULLDOWN_REGIONS[cc].items) {
      const items = [''].concat(PULLDOWN_REGIONS[cc].items)
      return (
        <select
          className="custom-select"
          value={this.props.region}
          onChange={(e) => {
            log('got event: ', e)
            const r = e.target.value
            log('region is: ' + r)
            this.props.onChange(r)
          }}
        >
          {items.map((item) => (
            <option key={item}>{item}</option>
          ))}
          ;
        </select>
      )
    } else {
      return (
        <input
          className="form-control"
          type="text"
          value={this.props.region}
          onChange={(e) => {
            const r = e.target.value
            log('Calling on change with: ' + r)
            this.props.onChange(r)
          }}
        />
      )
    }
  }
}

function getRegionFieldName(cc) {
  if (cc && PULLDOWN_REGIONS[cc] && PULLDOWN_REGIONS[cc].name) {
    return PULLDOWN_REGIONS[cc].name
  } else {
    return 'State, province or county'
  }
}

function isValidRegion(cc, region) {
  log(`I was called with country [${cc}] and region [${region}]`)
  if (cc && PULLDOWN_REGIONS[cc] && PULLDOWN_REGIONS[cc].items) {
    if (PULLDOWN_REGIONS[cc].items.includes(region)) {
      log("Yep, we're valid")
      return true
    } else {
      log('nope')
      return false
    }
  }
  if (region && region.length > 1) {
    log('we haz a region')
    return true
  }
  log('nope, no region')
  return false
}

export { SmartRegion, getRegionFieldName, isValidRegion }
