import { coinFlip, fiveTimesOutOfSix, randomChoice } from '../../shared/Utils'

export const FAKE_DATA = {
  loggedInButNeedsScope: fiveTimesOutOfSix(),
  renderMode: coinFlip()
    ? 'NeedsGoogleSignIn'
    : fiveTimesOutOfSix()
    ? 'LoggedIn'
    : randomChoice(['Init', 'AgeGate', 'LoggedIn', 'LoginError'])
}

if (FAKE_DATA.loggedInButNeedsScope) {
  FAKE_DATA.needsConsent = false
}

FAKE_DATA.extensionFound = coinFlip()
