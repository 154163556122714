import React from 'react'
import { useRecordReplySidebar } from './RecordReplySidebar.context'
import { Button } from '@mote/ui'

export const ToggleRecordReplySidebarButton = ({ className }) => {
  const { state, toggle } = useRecordReplySidebar()

  if (state.isOpen) return null
  if (
    window.location.host === 'www.mote.com' ||
    window.location.host === 'www.justmote.me'
  )
    return null

  return (
    <Button
      isPrimary
      label={`Reply to ${state.mote?.parentMote.createdByGreeting}`}
      onClick={toggle}
      className={className}
    />
  )
}
