import React from 'react'

export const CloseIcon = ({ color = '#AC0AE8' }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <rect
        x="0.33252"
        y="22.0461"
        width="30"
        height="3"
        rx="1.5"
        transform="rotate(-45 0.33252 22.0461)"
        fill="currentColor"
      />
      <rect
        x="2.45361"
        y="0.832764"
        width="30"
        height="3"
        rx="1.5"
        transform="rotate(45 2.45361 0.832764)"
        fill="currentColor"
      />
    </svg>
  )
}
