import React from 'react'
import { styled } from 'twin.macro'
import {
  ChevronDownIcon,
  ChromeIcon,
  CloseIcon,
  HamburgerIcon,
  Logo,
  UserIcon
} from '@mote/icons'
import { useHistory } from 'react-router-dom'
import { afterMoteAuth, logout } from 'web/src/shared/Auth'
import { ButtonV2, Dropdown } from '@mote/ui'
import { useExtensionInstalled } from '@mote/helpers/hooks/useExtensionInstalled'
import { Content } from './content'
import { getAuthAttribute, checkIfLoggedIn } from '../../auth/auth.helpers'
import { useMediaQuery } from '@mote/helpers'

const navLinks = [
  {
    name: 'For business',
    url: '/teams'
  },
  {
    name: 'Pricing',
    url: '/pricing'
  },
  {
    name: 'Contact Sales',
    url: '/quote'
  }
]

const forEducationLinks = [
  {
    name: 'Information for schools',
    url: '/schools'
  },
  {
    name: 'Learning Hub',
    url: '/the-hub'
  },
  {
    name: 'Share: Mote Amplifier',
    url: '/amplifier'
  },
  {
    name: 'Elementary',
    url: '/elementary'
  },
  {
    name: 'English & Humanities',
    url: '/ela'
  },
  {
    name: 'ESL & Modern Languages',
    url: 'languages'
  },
  {
    name: 'Science & Math',
    url: '/stem'
  },
  {
    name: 'Music & Visual Arts',
    url: '/music'
  },
  {
    name: 'Join our Community',
    url: '/community'
  }
]

const emailAddress = getAuthAttribute('primaryEmailAddress')
const isLoggedIn = checkIfLoggedIn()

export const Navbar = () => {
  const [showMobileNavItems, setShowMobileNavItems] = React.useState(false)
  const extensionInstalled = useExtensionInstalled()
  const history = useHistory()
  const mediaBreakPoint = useMediaQuery([640, 992, 1200])

  console.log('mediaBreakPoint', mediaBreakPoint)

  async function handleLogout() {
    await logout()
    history.push('/login')
  }

  function handleAccountDropdownSelection(key) {
    switch (key) {
      case 'my-account': {
        history.push('/account')
        break
      }
      case 'my-activity': {
        history.push('/account/activity')
        break
      }
      case 'notifications': {
        history.push('/account/notifications')
        break
      }
      case 'logout': {
        handleLogout()
        break
      }
      default:
      // no-op
    }
  }

  function handleForEducationDropdownSelection(key) {
    history.push(key)
  }

  return (
    <Root>
      <Desktop>
        <LeftSideContainer>
          <LogoContainer onClick={(_) => history.push('/')}>
            <Logo style={{ marginRight: 60 }} />
          </LogoContainer>

          <NavlinksContainer>
            <Dropdown
              inline={mediaBreakPoint !== 1200}
              width={240}
              onAction={handleForEducationDropdownSelection}
              triggerElement={
                <ForEducation>
                  <ForEducationText>For education</ForEducationText>
                  <ChevronDownIcon />
                </ForEducation>
              }
              placement="bottomLeft"
            >
              {forEducationLinks.map((educationLink) => (
                <Dropdown.Item key={educationLink.url}>
                  <Dropdown.ItemText>{educationLink.name}</Dropdown.ItemText>
                </Dropdown.Item>
              ))}
            </Dropdown>

            {navLinks.map((navLink) => (
              <NavLink
                key={navLink.url}
                onClick={(_) => history.push(navLink.url)}
              >
                {navLink.name}
              </NavLink>
            ))}
          </NavlinksContainer>
        </LeftSideContainer>

        <RightSideContainer>
          <DesktopContainer>
            {!isLoggedIn && (
              <NavLink
                onClick={(_) => history.push('/login?returnTo=/homepage')}
              >
                Login
              </NavLink>
            )}
            {isLoggedIn && (
              <Dropdown
                onAction={handleAccountDropdownSelection}
                triggerElement={
                  <AccountDropdown>
                    <UserIcon />
                    <AccountText>Account</AccountText>
                  </AccountDropdown>
                }
                placement="bottomRight"
              >
                <Dropdown.Item key="email-address">
                  <GivenName>{emailAddress}</GivenName>
                </Dropdown.Item>
                <Dropdown.Item key="my-account">
                  <Dropdown.ItemText>My Account</Dropdown.ItemText>
                </Dropdown.Item>
                <Dropdown.Item key="my-activity">
                  <Dropdown.ItemText>My Activity</Dropdown.ItemText>
                </Dropdown.Item>
                <Dropdown.Item key="notifications">
                  <Dropdown.ItemText>Notifications</Dropdown.ItemText>
                </Dropdown.Item>
                <Dropdown.Item key="logout">
                  <Dropdown.ItemText>Logout</Dropdown.ItemText>
                </Dropdown.Item>
              </Dropdown>
            )}
            {!extensionInstalled && (
              <Button
                variant="secondary"
                text="Add to Chrome"
                iconPosition="right"
                icon={<ChromeIcon />}
              />
            )}
          </DesktopContainer>

          <MobileContainer>
            <div onClick={(_) => setShowMobileNavItems((prev) => !prev)}>
              {showMobileNavItems ? <CloseIcon /> : <HamburgerIcon />}
            </div>
          </MobileContainer>
        </RightSideContainer>
      </Desktop>

      {showMobileNavItems && (
        <Mobile>
          <Dropdown
            inline={mediaBreakPoint !== 1200}
            width={240}
            onAction={handleForEducationDropdownSelection}
            triggerElement={
              <ForEducation>
                <ForEducationText>For education</ForEducationText>
                <ChevronDownIcon />
              </ForEducation>
            }
            placement="bottomLeft"
          >
            {forEducationLinks.map((educationLink) => (
              <Dropdown.Item key={educationLink.url}>
                <Dropdown.ItemText>{educationLink.name}</Dropdown.ItemText>
              </Dropdown.Item>
            ))}
          </Dropdown>

          {navLinks.map((navLink) => (
            <NavLink
              key={navLink.url}
              onClick={(_) => history.push(navLink.url)}
            >
              {navLink.name}
            </NavLink>
          ))}

          {!isLoggedIn && (
            <NavLink onClick={(_) => history.push('/login?returnTo=/homepage')}>
              Login
            </NavLink>
          )}
          {isLoggedIn && (
            <Dropdown
              inline={mediaBreakPoint !== 1200}
              onAction={handleAccountDropdownSelection}
              triggerElement={
                <AccountDropdown>
                  <UserIcon />
                  <AccountText>Account</AccountText>
                </AccountDropdown>
              }
              placement="bottomRight"
            >
              <Dropdown.Item key="email-address">
                <GivenName>{emailAddress}</GivenName>
              </Dropdown.Item>
              <Dropdown.Item key="my-account">
                <Dropdown.ItemText>My Account</Dropdown.ItemText>
              </Dropdown.Item>
              <Dropdown.Item key="my-activity">
                <Dropdown.ItemText>My Activity</Dropdown.ItemText>
              </Dropdown.Item>
              <Dropdown.Item key="notifications">
                <Dropdown.ItemText>Notifications</Dropdown.ItemText>
              </Dropdown.Item>
              <Dropdown.Item key="logout">
                <Dropdown.ItemText>Logout</Dropdown.ItemText>
              </Dropdown.Item>
            </Dropdown>
          )}

          {!extensionInstalled && (
            <Button
              variant="secondary"
              text="Add to Chrome"
              iconPosition="right"
              icon={<ChromeIcon />}
            />
          )}
        </Mobile>
      )}
    </Root>
  )
}

const Root = styled(Content)`
  padding: 30px 60px;
  width: 100%;
`

const Desktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LeftSideContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const NavlinksContainer = styled(LeftSideContainer)`
  @media (max-width: 991px) {
    display: none;
  }
`

const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
`

const DesktopContainer = styled(RightSideContainer)`
  @media (max-width: 991px) {
    display: none;
  }
`

const MobileContainer = styled.div`
  @media (min-width: 991px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;

  .dropdown-container {
    box-shadow: none;
    border: none;
    padding: 0;
    margin-bottom: 32px;

    li {
      justify-content: center;
    }
  }
`

const LogoContainer = styled.div`
  cursor: pointer;
`

const NavLink = styled.a`
  font-family: DM Sans;
  font-style: normal;
  display: block;
  margin-right: 40px;
  color: #232323;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;

  &:hover {
    color: #ac0ae8 !important;
  }

  &:active {
    color: #9b09d1 !important;
  }

  @media (max-width: 991px) {
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.05em;
    margin: 0 0 32px 0;
  }
`

const AccountDropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 991px) {
    margin-top: 48px;
    justify-content: center;
  }
`

const AccountText = styled.span`
  display: block;
  color: #232323;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  margin-left: 8px;

  &:hover {
    color: #ac0ae8;
  }
`

const ForEducation = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;

  &:hover {
    svg {
      color: #ac0ae8;
    }
  }

  @media (max-width: 991px) {
    justify-content: center;
    margin-right: 0;
  }
`
const ForEducationText = styled(AccountText)`
  margin-right: 8px;

  @media (max-width: 991px) {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.05em;
  }
`

const Button = styled(ButtonV2)`
  margin-left: 30px;

  @media (max-width: 992px) {
    margin: 96px 0 0 0;
  }
`

const GivenName = styled.span`
  margin-bottom: 16px;
  color: #232323;
  overflow: hidden;
  text-overflow: ellipsis;
`
