import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { listEmailDomains } from '../../shared/Admin'
import AdminHeader from './AdminHeader'
import Paginator from '../../shared/Paginator'
import { log } from '../../shared/Utils'

const queryString = require('query-string')
class EmailDomains extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      error: '',
      order: 'blended_score',
      pageIndex: 0
    }
  }

  componentDidMount() {
    var pageIndex = 0
    try {
      var tmpPageIndex = parseInt(this.props.match.params.pageIndex)
      if (!Number.isNaN(tmpPageIndex)) {
        pageIndex = tmpPageIndex
      }
    } catch (e) {}
    var querySearch = queryString.parse(window.location.search)
    var order = ''
    if (
      !querySearch.order ||
      !['alpha', 'blended_score', 'email_count'].includes(querySearch.order)
    ) {
      order = 'blended_score'
    } else {
      order = querySearch.order
    }
    this.pageIndex = pageIndex
    this.order = order
    log('pageIndex: ', pageIndex)
    log('order: ', order)
    this._refreshEmailDomains()
  }

  _refreshEmailDomains() {
    this.setState({ loading: true })
    listEmailDomains(
      this.pageIndex,
      this.order,
      (data) => {
        this.setState(data)
        this.setState({ loading: false })
      },
      (err) => this.setState({ loading: false, error: err.toString() })
    )
  }

  render() {
    return (
      <div>
        <AdminHeader />
        <div className="container mote-brand">
          <div>
            <h2>List email domains</h2>

            {this.state.loading && <span>Loading ...</span>}
            {this.state.error && (
              <p style={{ color: 'red' }}>{this.state.error}</p>
            )}
            {!this.state.loading &&
              !this.state.error &&
              this._renderEmailDomains()}
          </div>
        </div>
      </div>
    )
  }

  _renderEmailDomains() {
    return (
      <div className="container mote-brand">
        {(!this.state.currentPage || this.state.currentPage.length === 0) && (
          <p>No domains found</p>
        )}

        {this.state.currentPage && this.state.currentPage.length > 0 && (
          <div>
            {this._renderPaginator()}
            {this._renderSortOrderChanger()}
            <table className="table truncate-school-name">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase" scope="col">
                    Email domain
                  </th>
                  <th className="text-uppercase" scope="col">
                    Institutional customers
                  </th>
                  <th className="text-uppercase" scope="col">
                    Seen users (up to 100)
                  </th>
                  <th className="text-uppercase" scope="col">
                    Large account %
                  </th>
                  <th className="text-uppercase" scope="col">
                    URL
                  </th>
                  <th className="text-uppercase" scope="col">
                    Possible name
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.currentPage.map((ed) =>
                  this._renderEmailDomain(ed)
                )}
              </tbody>
            </table>
            {this._renderPaginator()}
          </div>
        )}
      </div>
    )
  }

  _renderEmailDomain(ed) {
    var customers = ed.mappedInstitutionIdsCount
    var customerLabel = customers
      ? customers > 1
        ? 'Multiple (' + customers + ')'
        : 'Yes'
      : ''
    return (
      <tr key={ed.domain}>
        <td>{ed.domain}</td>
        <td>{customerLabel}</td>
        <td>{ed.first100AdultEmailsCount}</td>
        <td>{ed.schoolDistrictLikelihood}%</td>
        <td>
          {ed.url.length > 0 && (
            <a target={uuidv4()} href={ed.url}>
              {ed.url}
            </a>
          )}
        </td>
        <td>{ed.possibleName}</td>
      </tr>
    )
  }

  _renderPaginator() {
    return (
      <Paginator
        baseURL="/admin/emailDomains/"
        pageIndex={this.pageIndex}
        pageIndexes={this.state.pageIndexes}
      />
    )
  }

  _renderSortOrderChanger() {
    const options = [
      ['blended_score', 'Blended (mix of usage and estimated account size)'],
      ['email_count', 'Observed email addresses (caps at 100)'],
      ['alpha', 'Alphabetical']
    ]
    return (
      <p style={{ textAlign: 'right' }}>
        Sorted order:{' '}
        <select
          onChange={(e) => {
            window.location.href =
              window.location.protocol +
              '//' +
              window.location.host +
              '/admin/emailDomains/0?order=' +
              e.target.value
          }}
        >
          {options.map((pair) => {
            return (
              <option value={pair[0]} selected={this.order === pair[0]}>
                {pair[1]}
              </option>
            )
          })}
        </select>
      </p>
    )
  }
}

export default EmailDomains
