import React from 'react'

export const Error = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none">
      <rect
        width="5"
        height="29.98"
        x="24.735"
        y="21.199"
        fill="#F44336"
        rx="2.5"
        transform="rotate(135 24.735 21.2)"
      ></rect>
      <rect
        width="5"
        height="29.98"
        x="3.536"
        y="24.735"
        fill="#F44336"
        rx="2.5"
        transform="rotate(-135 3.536 24.735)"
      ></rect>
    </svg>
  )
}
