import React from 'react'
import { enforceAdministratorOnlyAccess } from '../shared/Admin'
import { afterMoteAuth, getMoteLogin, getReferrerCodeURL } from '../shared/Auth'
import { BASE_URL } from '../shared/Constants'
import {
  fakeReferral,
  resetReferralState,
  fakePurchase,
  fakeMixedSchoolPurchase,
  fakeSchoolPurchase,
  resetAccount,
  toggleIsMinorState,
  startFreeTrial,
  endFreeTrial,
  quotaLessOne,
  sendActivityNotification
} from '../shared/Commerce'
import { log } from '../shared/Utils'

class DebugSAK extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, loginData: getMoteLogin() }
    log('login data is: ', this.state.loginData)
  }

  componentDidMount(props) {
    enforceAdministratorOnlyAccess()
  }

  render() {
    log('loginData: ', this.state.loginData)
    log('loginData.productStates: ', this.state.loginData.productStates)
    return (
      <table>
        <tr>
          <td style={{ width: '50%', paddingLeft: '10px' }}>
            <h1>Current state</h1>
            {this._renderState()}
          </td>
          <td style={{ verticalAlign: 'top' }}>
            <h1>Change or query state</h1>
            <ul>
              <li>
                <a href="/" onClick={(e) => this._fakeReferral(e)}>
                  Fake referral
                </a>
              </li>
              <li>
                <a href="/" onClick={(e) => this._resetReferralState(e)}>
                  Reset referral state
                </a>
              </li>

              <li>
                Fake purchase:
                <a href="/" onClick={(e) => this._fakePurchase(e, 'essential')}>
                  Essential
                </a>{' '}
                |
                <a href="/" onClick={(e) => this._fakePurchase(e, 'unlimited')}>
                  Unlimited
                </a>{' '}
                |
                <a href="/" onClick={(e) => this._fakeSchoolPurchase(e)}>
                  School with 10 Unlimited seats
                </a>{' '}
                |
                <a href="/" onClick={(e) => this._fakeMixedSchoolPurchase(e)}>
                  School with 5 Essential + 5 Unlimited seats
                </a>{' '}
              </li>

              <li>
                <a href="/" onClick={(e) => this._resetAccount(e)}>
                  Reset account to original state
                </a>
              </li>

              <li>
                <a href="/" onClick={(e) => this._startFreeTrial(e)}>
                  Start free trial
                </a>
              </li>
              <li>
                <a href="/" onClick={(e) => this._endFreeTrial(e)}>
                  End free trial
                </a>
              </li>
              <li>
                <a href="/" onClick={(e) => this._quotaLessOne(e)}>
                  Fake record 19 motes
                </a>
              </li>
              <li>
                <a href="/" onClick={(e) => this._sendActivityNotification(e)}>
                  Send activity notification
                </a>
              </li>

              <li>
                Make:{' '}
                <a href="/" onClick={(e) => this._toggleIsMinorState(e, true)}>
                  under 18 (minor)
                </a>{' '}
                |
                <a href="/" onClick={(e) => this._toggleIsMinorState(e, false)}>
                  18 or over (adult)
                </a>
              </li>

              <li>
                <a target="_new" href={BASE_URL + '/c/geo'}>
                  GeoIP back-end result
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </table>
    )
  }
  _renderState() {
    if (this.state.loading) {
      return <div>Processing...</div>
    } else {
      var isPaid = false
      try {
        isPaid = this.state.loginData.productStates[0].isPaid
      } catch (e) {}
      var isUpgradable = false
      try {
        isUpgradable = this.state.loginData.productStates[0].isUpgradable
      } catch (e) {}
      var product = 'Unknown'
      try {
        product = this.state.loginData.productStates[0].productName
      } catch (e) {}

      return (
        <div>
          <p>
            <b>Email:</b> {this.state.loginData.primaryEmailAddress}{' '}
          </p>
          <p>
            <b>userId:</b> {this.state.loginData.userId}{' '}
          </p>
          <p>
            <b>Referrer code:</b> {this.state.loginData.referrerCode}{' '}
            {getReferrerCodeURL() && (
              <a href={getReferrerCodeURL()}>{getReferrerCodeURL()}</a>
            )}
          </p>

          <p>
            <b>Current product:</b> {product}{' '}
          </p>
          <p>
            <b>Country code</b> {this.state.loginData.countryCode}{' '}
          </p>
          {/* <p>
                        <b>Quota reset</b>{' '}
                        {EpochToPrettyDate(
                            parseInt(this.state.loginData.quotaResetsAt)
                        )}{' '}
                        ({parseInt(this.state.loginData.quotaResetsAt)}){' '}
                    </p> */}
          <p>
            <b>Is paid up?</b> {isPaid ? 'true' : 'false'}{' '}
          </p>
          <p>
            <b>Is upgradable?</b> {isUpgradable ? 'true' : 'false'}{' '}
          </p>
          <p>
            <b>Is minor?</b> {this.state.loginData.isMinor}{' '}
          </p>
          <p>
            <b>productStates:</b>{' '}
            {JSON.stringify(this.state.loginData.productStates, null, 2)}
          </p>

          <p>
            <b>Acquired scopes: </b> {this.state.loginData.acquiredScopes}
          </p>

          {this.state.error && (
            <p>
              <font color="red">
                <b>Error:</b> {this.state.error}{' '}
              </font>
            </p>
          )}
        </div>
      )
    }
  }

  _fakePurchase(e, productEnum) {
    e.preventDefault()
    this.setState({ loading: true })
    fakePurchase(productEnum, ...this.S_F())
  }

  _fakeSchoolPurchase(e) {
    e.preventDefault()
    this.setState({ loading: true })
    fakeSchoolPurchase(...this.S_F())
  }

  _fakeMixedSchoolPurchase(e) {
    e.preventDefault()
    this.setState({ loading: true })
    fakeMixedSchoolPurchase(...this.S_F())
  }

  _fakeReferral(e) {
    e.preventDefault()
    this.setState({ loading: true })
    fakeReferral(...this.S_F())
  }

  _resetReferralState(e) {
    e.preventDefault()
    this.setState({ loading: true })
    resetReferralState(...this.S_F())
  }

  _resetAccount(e) {
    e.preventDefault()
    this.setState({ loading: true })
    resetAccount(...this.S_F())
  }

  _startFreeTrial(e) {
    e.preventDefault()
    this.setState({ loading: true })
    startFreeTrial(...this.S_F())
  }

  _endFreeTrial(e) {
    e.preventDefault()
    this.setState({ loading: true })
    endFreeTrial(...this.S_F())
  }

  _quotaLessOne(e) {
    e.preventDefault()
    this.setState({ loading: true })
    quotaLessOne(...this.S_F())
  }

  _toggleIsMinorState(e, isMinor) {
    e.preventDefault()
    this.setState({ loading: true })
    toggleIsMinorState(isMinor, ...this.S_F())
  }

  _sendActivityNotification(e) {
    e.preventDefault()
    this.setState({ loading: true })
    sendActivityNotification(...this.S_F())
  }

  S_F() {
    return [
      () => {
        this.setState({
          loading: false,
          error: ''
        })
        afterMoteAuth(
          () => this.setState({ loginData: getMoteLogin() }),
          () => {}
        )
      },
      (err) => {
        this.setState({
          loading: false,
          loginData: getMoteLogin(),
          error: err.toString()
        })
      }
    ]
  }
}

export default DebugSAK
