export const apiConfigs = {
  firstClass: {
    timeout: 15000,
    retry: {
      limit: 3,
      methods: ['get', 'post']
    }
  },

  secondClass: {
    timeout: 5000,
    retry: {
      limit: 0
    }
  },
  specific: (route) => {
    const configPerRoute = [
      { path: 'm/addChunk', config: apiConfigs.secondClass }
    ]

    return configPerRoute.find((configPerRoute) =>
      route.includes(configPerRoute.path)
    )
  }
}

export function getApiClientConfig(route) {
  const specificRouteConfig = apiConfigs.specific(route)

  if (specificRouteConfig) {
    return specificRouteConfig.config
  }

  switch (true) {
    case route.startsWith('a/'):
    case route.startsWith('m/'):
    case route.startsWith('i/'):
      return apiConfigs.firstClass
    case route.startsWith('d/'):
    case route.startsWith('s/'):
      return apiConfigs.secondClass
    default:
      throw new Error('Domain/Route is not handled')
  }
}
