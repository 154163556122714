import React from 'react'
import { track } from '../shared/AnalyticsHelpers'

function CustEdLink(props) {
  const href = props.href
  const label = props.label
  const title = props.title
  return (
    <a
      target="_new"
      className="my-1 mx-5 btn btn-primary d-none d-lg-block"
      href={href}
      onClick={() => {
        track('Customer Education Link', {
          label: label,
          href: href,
          title: title
        })
      }}
    >
      {label}
    </a>
  )
}
export default CustEdLink
