import React from 'react'

export const MICROPHONE_ERRORS = Object.freeze({
  notAllowedError: {
    name: 'NotAllowedError',
    message: 'You have denied permission to your microphone!'
  },
  notFoundError: {
    name: 'NotFoundError',
    message: 'Microphone could not be found!'
  },
  somethingWentWrong: {
    name: 'SomethingWentWrong',
    message: 'Something went wrong!'
  }
})

export const useMicrophone = () => {
  const [state, setState] = React.useState({
    mediaStream: null
  })

  async function askForMicrophoneAccess() {
    if (!navigator.mediaDevices) {
      return MICROPHONE_ERRORS.somethingWentWrong
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      })
      setState({ mediaStream: stream })
    } catch (error) {
      cleanup()
      return classifyError(error)
    }
  }

  function cleanup() {
    state?.mediaStream?.getAudioTracks().forEach((track) => track.stop())
  }

  function classifyError(error) {
    let classifiedError

    switch (error.name) {
      case MICROPHONE_ERRORS.notAllowedError.name:
        classifiedError = MICROPHONE_ERRORS.notAllowedError
        break
      case MICROPHONE_ERRORS.notFoundError.name:
        classifiedError = MICROPHONE_ERRORS.notFoundError
        break
      default:
        classifiedError = MICROPHONE_ERRORS.somethingWentWrong
    }

    return classifiedError
  }

  React.useEffect(() => {
    return () => cleanup()
  }, [])

  return {
    ...state,
    askForMicrophoneAccess
  }
}
