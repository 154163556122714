// global chrome //
// global window //

export const error = (m, ...o) => {
  CommonFEError.error(m, ...o)
}

function _innerError(m, ...o) {
  var caller = 'unknown'
  try {
    caller = new Error().stack.split('\n')[2].trim().split(' ')[1]
  } catch (e) {}
  console.error(`${caller}: ` + m)
  if (o) {
    for (var oo of o) {
      console.dir(oo)
    }
  }
}

export const CommonFEError = {
  error: _innerError,
  innerError: _innerError
}
