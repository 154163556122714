import React from 'react'
import { styled } from 'twin.macro'
import { Content } from './content'
import { ButtonV2 } from '@mote/ui'

function getCookie(name) {
  const cookies = document.cookie.split(';').reduce((accumulator, cookie) => {
    const [key, value] = cookie.trim().split('=')

    accumulator[key] = value

    return accumulator
  }, {})

  return cookies[name]
}

export const CookieConsent = () => {
  const [showCookieConsent, setShowCookieConsent] = React.useState(
    !getCookie('cookie-consent')
  )
  function handleNavigation() {
    window.location.replace(
      'https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en'
    )
  }

  function handleConsent() {
    const date = new Date()
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
    document.cookie = `cookie-consent=true; expires=${date.toUTCString()};path=/`
    setShowCookieConsent(false)
  }

  if (!showCookieConsent) return null

  return (
    <Root>
      <ContentStyled>
        <div>
          <Headline>
            We use cookies to ensure we give you the best experience on our
            website.
          </Headline>
          <Subtitle>
            For information about cookie settings, see{' '}
            <NavLink onClick={handleNavigation}>here</NavLink>. If you continue
            without changing your settings, we'll assume you're happy to receive
            cookies on the mote website.
          </Subtitle>
        </div>
        <Button text="I understand" onClick={handleConsent} variant="ghost" />
      </ContentStyled>
    </Root>
  )
}

const Root = styled.div`
  background: #232323;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`

const ContentStyled = styled(Content)`
  display: flex;
  align-items: center;
  padding: 12px 30px;

  @media (max-width: 639px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Headline = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  margin-bottom: 0;
`

const Subtitle = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
`

const NavLink = styled(Subtitle)`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`

const Button = styled(ButtonV2)`
  margin-left: 60px;

  @media (max-width: 639px) {
    margin: 12px 0 0 0;
  }
`
