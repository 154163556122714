import React from 'react'
import {
  enableForcedMinorEssentialDomain,
  enableFallbackUnlimitedDomain,
  listSchools
} from '../../shared/Admin'
import { noLog as log, shortenWithEllipsis } from '../../shared/Utils'

class Schools extends React.Component {
  constructor() {
    super()
    this.state = { mode: 'list', loading: true, institutions: [] }
  }

  componentDidMount() {
    this._refreshSchools()
  }

  render() {
    return (
      <div className="container mote-brand">
        {this.state.mode === 'list' && (
          <div>
            <h2>List schools</h2>

            {this.state.loading && <span>Loading ...</span>}
            {this.state.error && (
              <p style={{ color: 'red' }}>{this.state.error}</p>
            )}
            {!this.state.loading && !this.state.error && this._renderSchools()}
          </div>
        )}
      </div>
    )
  }

  _renderSchools() {
    return (
      <div className="container mote-brand">
        {this.state.institutions.length === 0 && <p>No schools found</p>}
        {this.state.institutions.length > 0 && (
          <table className="table truncate-school-name">
            <thead className="thead-light">
              <tr>
                <th className="text-uppercase" scope="col">
                  Institution id
                </th>
                <th className="text-uppercase school-name" scope="col">
                  Full school name
                </th>
                <th className="text-uppercase school-name" scope="col">
                  Owner name{' '}
                </th>
                <th className="text-uppercase" scope="col">
                  Owner email
                </th>
                <th className="text-uppercase school-name" scope="col">
                  Domains
                </th>
                <th className="text-uppercase school-name" scope="col">
                  Product
                </th>
                <th className="text-uppercase school-name" scope="col">
                  Unused paid licenses?
                </th>
                <th className="text-uppercase school-name" scope="col">
                  Seats used vs paid
                </th>
                <th className="text-uppercase school-name" scope="col">
                  Bonus Essential users
                </th>

                <th className="text-uppercase school-name" scope="col">
                  Subscription valid until
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.institutions.map((s) => this._renderSchool(s))}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  _renderSchool(s) {
    var l = s.currentLicense
    log('rendering ...', s)
    log('with license...', l)
    return (
      <tr key={s.institutionId}>
        <td>
          <a href={'/account?iid=' + s.institutionId}>{s.institutionId}</a>
        </td>
        <td>{shortenWithEllipsis(s.fullName, 40)}</td>
        <td>{s.accountOwnerName}</td>
        <td>{shortenWithEllipsis(s.accountOwnerEmail, 50)}</td>
        <td>{this._renderDomains(s)}</td>
        <td>{l.highestProductEnum}</td>
        <td>{l.canAddHighestProductEnumUsers ? 'Y' : 'N'}</td>
        <td>
          {l.totalPaidSeats - l.unusedPaidSeats}/{l.totalPaidSeats}{' '}
        </td>
        <td> {l.bonusEssentialUsers} </td>
        <td>{s.subscriptionValidUntil}</td>
      </tr>
    )
  }

  _renderDomains(s) {
    var essentialOn = s.enableForcedMinorEssentialDomain
    var essential =
      s.forcedMinorEssentialDomain || (essentialOn ? 'Not set' : 'Disabled')
    var essentialLoading = 'essential' + s.institutionId
    var unlimitedOn = s.enableFallbackUnlimitedDomain
    var unlimited =
      s.fallbackUnlimitedDomain || (unlimitedOn ? 'Not set' : 'Disabled')
    var unlimitedLoading = 'unlimited' + s.institutionId
    return (
      <div>
        <p>
          <b>Essential+force minor:</b> {essential}
          <br />
          <button
            className="mote-button"
            disabled={this.state[essentialLoading]}
            onClick={() => {
              var o = {}
              o[essentialLoading] = true
              this.setState(o)
              enableForcedMinorEssentialDomain(
                s.institutionId,
                !essentialOn,
                () => this._lightRefreshSchools(essentialLoading),
                () => {}
              )
            }}
          >
            {this.state[essentialLoading]
              ? 'Updating ...'
              : essentialOn
              ? 'Disable'
              : 'Enable'}
          </button>
        </p>
        <p>
          <b>Unlimited: </b> {unlimited}
          <br />
          <button
            className="mote-button"
            disabled={this.state[unlimitedLoading]}
            onClick={() => {
              var o = {}
              o[unlimitedLoading] = true
              this.setState(o)
              enableFallbackUnlimitedDomain(
                s.institutionId,
                !unlimitedOn,
                () => this._lightRefreshSchools(unlimitedLoading),
                () => {}
              )
            }}
          >
            {this.state[unlimitedLoading]
              ? 'Updating ...'
              : unlimitedOn
              ? 'Disable'
              : 'Enable'}
          </button>
        </p>
      </div>
    )
  }

  _lightRefreshSchools(loadingKey) {
    listSchools(
      (data) => {
        data[loadingKey] = false
        this.setState(data)
      },
      () => {
        var o = {}
        o[loadingKey] = false
        this.setState(o)
      }
    )
  }

  _refreshSchools() {
    this.setState({ loading: false })
    if (this.state.mode === 'list') {
      listSchools(
        (data) => {
          this.setState(data)
          this.setState({ loading: false })
        },
        (err) => this.setState({ loading: false, error: err.toString() })
      )
    }
  }
}

export default Schools
