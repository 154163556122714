import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'
// import Header from '../shared/Header';
// import Footer from '../shared/Footer';

class StemUpgrade extends React.Component {
  render() {
    return (
      <ReactTypeformEmbed url="https://justmote.typeform.com/to/dANGUIYl" />
    )
  }
}

export default StemUpgrade
