import React from 'react'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'
import GetMoteLink from '../shared/GetMoteLink'

class AlexTheSadEngineer extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <div className="container">
          <h1 className="lg-display-4-mb-4-mt mote-uninstall-title">
            Sorry to see you leave
          </h1>
          <div className="row justify-content-between align-items-center py-4 py-lg-5">
            <div className="col-lg-6 mb-4 text-lg-left">
              <img
                src="images/photos/alex1.png"
                width="100"
                alt="Alex, the sad Engineer"
              />
              <blockquote className="blockquote">
                <p className="lead">
                  Hi, I'm Alex, one of the founders at{' '}
                  <b className="mote-purple">mote</b>. We're a small team,
                  working really hard to make a product that people love.
                  <br></br> <br></br>Would you consider taking a moment to help
                  us understand what went wrong?
                </p>
              </blockquote>
              <p className="lead">
                Or if you're looking to reinstall{' '}
                <b className="mote-purple">mote</b>, click here:<br></br>
                <br></br>
                <GetMoteLink recipe="image" />
              </p>
            </div>
            <div className="col-lg-6 col-xl-6 mb-8 justify-content-center text-lg-left">
              <iframe
                title="Airtable"
                className="airtable-embed mote-brand"
                src="https://airtable.com/embed/shrDBo191YRYNTJkB?backgroundColor=pink"
                frameborder="0"
                onmousewheel=""
                width="100%"
                height="600"
                styleName="background: transparent; border: 1px solid #ccc;"
              ></iframe>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default AlexTheSadEngineer
