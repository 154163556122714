import React from 'react'
import tw, { styled } from 'twin.macro'

const MoteLogoMedium = (props) => (
  <GrowOnHoverSVG
    xmlns="http://www.w3.org/2000/svg"
    width="67"
    height="67"
    fill="none"
    className={props.className}
    viewBox="0 0 67 67"
    style={props.style}
  >
    <path
      className="background"
      fill="#AC0AE8"
      d="M33.5 67C52.002 67 67 52.002 67 33.5 67 14.998 52.002 0 33.5 0 14.998 0 0 14.998 0 33.5 0 52.002 14.998 67 33.5 67z"
    ></path>
    <path
      className="logo-shape"
      fill="#fff"
      d="M23.269 50.082c.02-.919 0-21.793 0-21.793L10.88 40.742s-1.032 1.038-2.408 1.384c-3.097.692-4.474-1.384-4.474-1.384l9.635-9.686v-4.15s0-2.076.345-2.768c.344-.692 1.032-1.384 1.032-1.384l14.797-15.22s3.097-2.075 5.85-.346c2.753 1.73 2.409 4.843 2.409 4.843v20.755L49.422 21.37s2.064-2.422 5.161-1.038c3.097 1.384 3.097 4.497 3.097 4.497v12.107s.345 2.767 1.377 3.805c1.032 1.038 3.441 1.384 3.441 1.384L47.701 57s-1.72 0-3.441-1.384c-1.72-1.383-1.377-3.805-1.377-3.805V41.78S31.68 53.273 30.84 53.887c-.841.613-2.091.863-4.473.346-2.383-.518-3.118-3.233-3.097-4.151z"
    ></path>
  </GrowOnHoverSVG>
)

export const GrowOnHoverSVG = styled.svg(
  {
    cursor: 'pointer'
  },
  (props) => [
    tw`transition-transform duration-100 transform`,
    tw`hover:(scale-105)`
  ]
)

export default MoteLogoMedium
