import React from 'react'
import Header from '../../shared/Header'
import { getMoteLogin } from '../../shared/Auth'
import { enforceAdministratorOnlyAccess } from '../../shared/Admin'

class AdminHeader extends React.Component {
  componentDidMount() {
    enforceAdministratorOnlyAccess()
  }

  render() {
    const loginData = getMoteLogin()
    return (
      <div className="container mote-brand">
        <Header />
        <table width="100%">
          <tbody>
            <tr>
              <td valign="top">
                <a href="/admin?users=1">Users</a> |{' '}
                <a href="/admin?schools=1">Schools</a> |{' '}
                <a href="/admin/emailDomains/0">Email domains</a> |{' '}
                <a href="/admin/debugSAK">DebugSAK</a> (Swiss Army Knife)
              </td>
              <td align="right">
                <p>
                  Logged in as: <b>{loginData.primaryEmailAddress}</b>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default AdminHeader
