import React from 'react'

import { Footer } from '../shared/Footer'
import GetMoteLink from '../shared/GetMoteLink'
import Header from '../shared/Header'

class Release0317 extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <div className="container">
          <main id="main">
            <div className="container">
              <div className="row justify-content-center my-5">
                <div className="col-lg-9">
                  <img
                    src="../images/photos/link-rendered.png"
                    className="img-fluid img-thumbnail"
                    alt="Sharing links with mote"
                  />
                </div>
              </div>
            </div>

            <div className="bg-skew bg-skew-light">
              <div className="container mote-blog">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <article className="pb-2">
                      <h1 className="text-center">
                        Even faster, even friendlier...
                      </h1>
                      <p className="small mb-5 text-center">
                        <span className="text-secondary">updated on </span>
                        <span className="text-uppercase">MAR 23, 2020</span>
                      </p>
                      <p className="lead">
                        We're excited to share the latest raft of new features
                        to improve your document collaboration experience
                      </p>

                      <p>
                        In the midst of some pretty uncharted times, here at
                        globally distributed MoteHQ we're just trying to bring a
                        little joy and peace to the world through faster and
                        friendlier document collaboration.
                      </p>

                      <p>
                        And in times like these, we know that even the smallest
                        of positive actions can create ripples of good karma
                        that slip and slide their way across the universe. A
                        little bit of gratitude, a touch of appreciation, a{' '}
                        <b className="mote-purple">mote</b> of kindness can go a
                        long way.
                      </p>

                      <p>
                        We therefore hope that you and your team mates will love
                        the new <b className="mote-purple">mote</b> features
                        that we're launching today, and that you'll find ways to
                        use them as you adjust to new ways of working.
                        Drumroll...here's the new features:
                      </p>

                      <h3>Transcript editing</h3>
                      <p>
                        The machines continue to pretend that they don't
                        understand everything we're saying, so for now we've
                        added a handy way to edit your{' '}
                        <b className="mote-purple">mote</b> transcripts.
                      </p>
                      <div style={{ marginBottom: '1em' }}>
                        <img
                          src="images/photos/transcript-edit.png"
                          className="img-fluid shadow"
                          alt="Transcript edit"
                        />
                      </div>
                      <a
                        className="link-cta"
                        href="https://support.mote.com/hc/en-us/articles/360041088711"
                      >
                        Read more
                      </a>

                      <h3>Link sharing</h3>

                      <p>
                        Just like Slack, now shared URLs 'unfurl' in the
                        comments box so that your readers can preview your link.
                        Go on - find a way to weave in{' '}
                        <a
                          className="mote-boiler-link"
                          href="https://www.bbc.com/news/av/world-us-canada-51230017/man-uses-warm-coffee-to-rescue-kittens-frozen-to-ground"
                          alt="kitten-story"
                        >
                          this heart-warming kitten story
                        </a>
                        .
                      </p>

                      <a
                        className="link-cta"
                        href="https://support.mote.com/hc/en-us/articles/360041089231"
                      >
                        Read more
                      </a>

                      <h3>Gif sharing</h3>
                      <p>
                        Giphy.com is the gift that just keeps giving...and
                        giving. Bring some unexpected joy to your team-mates by
                        sending them a giphy.com/rick-roll or (personal fave)
                        giphy.com/shaq-shake.
                      </p>

                      <a
                        className="link-cta"
                        href="https://support.mote.com/hc/en-us/articles/360041089951"
                      >
                        Read more
                      </a>

                      <h3>Emojis</h3>

                      <p>
                        Sometimes emojis really are the best way to tell someone
                        how you're feeling. We've now made it really easy to add
                        emojis to your shared documents using mote
                      </p>

                      <a
                        className="link-cta"
                        href="https://support.mote.com/hc/en-us/articles/360041366851"
                      >
                        Read more
                      </a>

                      <h3>Google Slides</h3>
                      <p>
                        If Malcolm in Melbourne now needs to deliver your sales
                        deck because you're on home confinement, mote has got
                        you covered. Simply add voice motes to every slide in
                        your deck to bring your pitch to life, so that big Mal
                        can rehearse, and then deliver the goods in the room.
                      </p>

                      <h3>Google Sheets</h3>
                      <p>
                        Need to collaborate on your household inventory?{' '}
                        <b className="mote-purple">mote</b>'s got you covered:
                      </p>
                      <img
                        src="images/photos/sheets.png"
                        className="img-fluid shadow"
                        alt="Google Sheets"
                      />
                      <div className="media my-5">
                        <img
                          className="avatar avatar-lg mr-3"
                          src="images/photos/Will.jpg"
                          alt="Post author"
                        />
                        <div className="media-body">
                          <h3 className="h5 mt-0">Will Jackson</h3>
                          <p className="mb-0 text-secondary">
                            Will is the CEO and co-founder of{' '}
                            <b className="mote-purple">mote</b>, and is based in
                            the SF Bay Area.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
              <div className="container py-1">
                <div className="text-center">
                  <div>
                    <h2 className="mb-4">Are you ready to start?</h2>
                    <GetMoteLink recipe="atc" />
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default Release0317
