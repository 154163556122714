import React from 'react'

import { noLog as log } from '../../../shared/Utils'
import Congrats from './Congrats'
import InstitutionAdminControls from './InstitutionAdminControls'
import InstitutionContacts from './InstitutionContacts'
import InstitutionIntro from './InstitutionIntro'
import InstitutionProductSummary from './InstitutionProductSummary'
import InvoiceHistory from './InvoiceHistory'
import Minor from './Minor'
import PersonalProductSummary from './PersonalProductSummary'

class AccountInstitution extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.props.data
  }

  render() {
    const data = this.state
    log('data is ', data)
    if (data.isMinor) {
      return <Minor data={data} />
    } else {
      return (
        <div>
          <Congrats />
          <InstitutionIntro data={data} />
          {data.isInstitutionalAdmin && (
            <InstitutionProductSummary data={data} />
          )}
          <PersonalProductSummary data={data} />
          {data.isInstitutionalAdmin && <InvoiceHistory data={data} />}
          {data.isInstitutionalAdmin && (
            <InstitutionAdminControls
              data={data}
              afterUserAdditionCallback={(r) => this.setState(r)}
            />
          )}
          <InstitutionContacts data={data} />
        </div>
      )
    }
  }
}

export default AccountInstitution
