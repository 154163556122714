import React from 'react'

export const useMicrophonePermission = () => {
  const [state, setState] = React.useState({
    permissionResponseReceived: false,
    permissionStatus: null
  })

  function onPermissionStateChange(microphonePermission) {
    if (!microphonePermission) return

    setState({
      permissionResponseReceived: true,
      permissionStatus: microphonePermission.state
    })
  }

  async function checkMicrophonePermission() {
    if (!navigator.permissions) return

    const microphonePermission = await navigator.permissions.query({
      name: 'microphone'
    })

    if (!microphonePermission) return

    onPermissionStateChange(microphonePermission)
    microphonePermission.onchange = onPermissionStateChange
  }

  React.useEffect(() => {
    checkMicrophonePermission()
  }, [state.permissionStatus])

  return state
}
