import React from 'react'

import { error } from '@mote/common'

import { afterMoteAuth, getAccessToken } from '../../../shared/Auth'
import {
  BASE_URL,
  NETWORK_TIMEOUT,
  DEV_FORCE_LOGIN
} from '../../../shared/Constants'
import Error from '../../../shared/Error'
import { Footer } from '../../../shared/Footer'
import Header from '../../../shared/Header'
import Loading from '../../../shared/Loading'
import NotLoggedIn from '../../../shared/NotLoggedIn'
import Paginator from '../../../shared/Paginator'
import UserControls from './UserControls'
import {
  coinFlip,
  fiveTimesOutOfSix,
  getGodModeURLSuffix,
  getGodModeOptions,
  noLog as log,
  reallyTruthy,
  // EpochToAgo,
  EpochToPrettyDate
} from '../../../shared/Utils'
import { DEV_FAKE_VIEW } from './dev_fake_data'

const axios = require('axios').default

var axiosInstance = axios.create({
  baseURL: BASE_URL + 't/',
  timeout: NETWORK_TIMEOUT
})

class InstitutionalUsers extends React.Component {
  constructor(props) {
    super(props)
    var pageIndex = 0
    try {
      var tmpPageIndex = parseInt(this.props.match.params.pageIndex)
      if (!Number.isNaN(tmpPageIndex)) {
        pageIndex = tmpPageIndex
      }
    } catch (e) {}
    log('Found a pageIndex: ' + pageIndex)
    this.pageIndex = pageIndex
    this.godModeOptions = getGodModeOptions()
    this.state = { renderMode: 'Loading' }
  }

  componentDidMount() {
    log('Checking log in status')

    if (!DEV_FORCE_LOGIN) {
      log('checking...')
      const params = Object.assign({}, this.godModeOptions, {
        accessToken: getAccessToken(),
        pageIndex: this.pageIndex
      })
      axiosInstance
        .post('listUsers', params)
        .then((response) => {
          this.setState(response.data)
          this.setState({ renderMode: 'Loaded' })
        })
        .catch((e) => {
          error(e)
          afterMoteAuth(
            () => this.setState({ renderMode: 'OtherError' }),
            (e) => this.setState({ renderMode: 'NotLoggedIn' })
          )
        })
    } else {
      setTimeout(() => {
        if (fiveTimesOutOfSix()) {
          this.setState({ renderMode: 'Loaded' })
          this.setState(DEV_FAKE_VIEW)
        } else if (coinFlip()) {
          this.setState({ renderMode: 'OtherError' })
        } else {
          this.setState({ renderMode: 'NotLoggedIn' })
        }
      }, 2000)
    }
  }

  render() {
    const mode = this.state.renderMode
    return (
      <div className="mote-brand">
        <Header />

        <div className="row justify-content-center">
          <div className="col-lg-10 px-lg-4">
            <h3 className="mote-logged-in-title">
              <a
                className="mote-boiler-link"
                href={'/account' + getGodModeURLSuffix()}
              >
                Back to account home
              </a>
            </h3>
            <h1 className="mote-logged-in-subtitle">User directory</h1>
            <main id="main">
              {mode === 'Loading' && <Loading />}
              {mode === 'NotLoggedIn' && <NotLoggedIn />}
              {mode === 'OtherError' && <Error />}
              {mode === 'Loaded' && this._renderLoaded()}
            </main>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  _renderPaginator() {
    return (
      <Paginator
        baseURL="/account/users/"
        pageIndex={this.pageIndex}
        pageIndexes={this.state.pageIndexes}
      />
    )
  }

  _renderLoaded() {
    return (
      <div>
        {!reallyTruthy(this.state.userCount) && (
          <div>
            We cannot find any users assigned. This shouldn't happen. Contact
            support@mote.com if this persists.
          </div>
        )}

        {reallyTruthy(this.state.userCount) && (
          <div>
            {this._renderPaginator()}

            <table className="table table-hover shadow pricing-table mb-5">
              <thead>
                <tr>
                  <th className="activity-created" scope="col">
                    <p className="activity-table-header">Email</p>
                  </th>
                  <th className="activity-created" scope="col">
                    <p className="activity-table-header">Name</p>
                  </th>
                  <th className="activity-status" scope="col">
                    <p className=" activity-table-header">Under 18?</p>
                  </th>
                  <th className="activity-status" scope="col">
                    <p className=" activity-table-header">Plan</p>
                  </th>
                  <th className="activity-status" scope="col">
                    <p className="activity-table-header">Administrative role</p>
                  </th>

                  {/* <th className="activity-status" scope="col">
                                        <p className="lead activity-table-header">
                                            Admin?
                                        </p>
                                    </th> */}
                  <th className="activity-status" scope="col">
                    <p className=" activity-table-header">Last sign in</p>
                  </th>

                  {/* <th scope="col">
                                        <p className="lead activity-table-header">
                                            Last mote recorded
                                        </p>
                                    </th> */}
                  <th scope="col">
                    <p className=" activity-table-header">Motes recorded</p>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {this.state.currentPage.map((r) => {
                  return (
                    <tr key={r.primaryEmailAddress}>
                      <td> {r.primaryEmailAddress}</td>
                      <td> {r.name}</td>
                      <td style={{ textAlign: 'center' }}>
                        {' '}
                        {r.minorhood === 'Y' ? 'Y' : ''}
                      </td>
                      <td> {r.productName}</td>
                      {/* <td style={{ textAlign: "center" }}>
                                                {" "}
                                                {r.isAdmin ? "Y" : ""}
                                            </td> */}
                      <td>{r.institutionalRole}</td>
                      <td>
                        {r.name && (
                          <p>
                            {/* {EpochToAgo(
                                                            r.lastSignInEpoch
                                                        )}
                                                        <br/> */}
                            <small>
                              {EpochToPrettyDate(r.lastSignInEpoch)}
                            </small>
                          </p>
                        )}
                      </td>

                      {/* <td>
                                                    <p>
                                                        {EpochToAgo(
                                                            r.lastMoteRecordedAt
                                                        )}
                                                        <br/>
                                                        <small>
                                                            {EpochToPrettyDate(
                                                                r.lastMoteRecordedAt)}
                                                        </small>
                                                    </p>

                                            </td> */}

                      <td>{r.totalMotesRecorded}</td>
                      <td>
                        <UserControls
                          userRow={r}
                          godModeOptions={this.godModeOptions}
                          pageIndex={this.pageIndex}
                          afterUpdateCallback={(o) => {
                            log('got network update: ', o)
                            this.setState(o)
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {this._renderPaginator()}
          </div>
        )}
      </div>
    )
  }
}

export default InstitutionalUsers
