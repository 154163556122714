import React from 'react'
import Header from './../shared/Header'
import { Footer } from './../shared/Footer'

class FeedbackLoop extends React.Component {
  render() {
    return (
      <div className="container">
        <Header />
        <div className="container">
          <iframe
            title="Add"
            className="airtable-embed"
            src="https://blogjustmote.wordpress.com/"
            frameborder="0"
            onmousewheel=""
            width="100%"
            height="1000"
            styleName="background: transparent; border: 1px solid #ccc;"
          ></iframe>
        </div>
        <Footer />
      </div>
    )
  }
}

export default FeedbackLoop
