import { error } from '@mote/common'

import { getAccessToken, getMoteLogin } from '../shared/Auth'
import { BASE_URL, ADMIN_ALLOW_LIST } from '../shared/Constants'
import { noLog as log, coinFlip, isDevForceLogin } from '../shared/Utils'

const axios = require('axios').default.create({
  baseURL: BASE_URL + 'x/',
  timeout: 120 * 1000
})

const FAKE_SEARCH_RESULTS = {
  users: [
    {
      userId: 'alice',
      name: 'Alice Alikopoulos',
      primaryEmailAddress: 'alice@foobar.com',
      productName: 'Free',
      totalMotesMade: 2000
    },
    {
      userId: 'bob',
      name: 'Bob Bobjonssen',
      primaryEmailAddress: 'bob@foobar.com',
      productName: 'Free',
      totalMotesMade: 1
    },
    {
      userId: 'Carol',
      name: 'Carol Karlson',
      primaryEmailAddress: 'carlo@foobar.com',
      productName: 'Unlimited',
      totalMotesMade: 21,
      isInstitution: true
    }
  ]
}

function makeVIP(userId, success, failure) {
  _makeUnmakeVIP(userId, true, success, failure)
}

function unmakeVIP(userId, success, failure) {
  _makeUnmakeVIP(userId, false, success, failure)
}

function _makeUnmakeVIP(userId, setTo, success, failure) {
  ;[success, failure] = _assureCallbacks(success, failure)
  axios
    .post('vip', {
      accessToken: getAccessToken(),
      userId: userId,
      setTo: setTo
    })
    .then((r) => {
      log('got a 200: ', r.data)
      success(r.data)
    })
    .catch((e) => {
      error("Couldn't set/unset VIP status: ", e)
      failure(e)
    })
}

function deleteUser(userId, success, failure) {
  ;[success, failure] = _assureCallbacks(success, failure)
  axios
    .post('deleteUser', {
      accessToken: getAccessToken(),
      userId: userId
    })
    .then((r) => {
      success()
    })
    .catch((e) => {
      error("Couldn't delete user: ", e)
      failure(e)
    })
}

function freeTrialExtension(userId, weeks, success, failure) {
  ;[success, failure] = _assureCallbacks(success, failure)
  axios
    .post('trialExtension', {
      accessToken: getAccessToken(),
      userId: userId,
      weeks: weeks
    })
    .then((r) => {
      log('got a 200: ', r.data)
      success(r.data)
    })
    .catch((e) => {
      error("Couldn't search users: ", e)
      failure(e)
    })
}

function searchUsers(userIdsAndEmails, success, failure) {
  ;[success, failure] = _assureCallbacks(success, failure)
  if (isDevForceLogin()) {
    return success(coinFlip() ? FAKE_SEARCH_RESULTS : { users: [] })
  }
  log('Looking up users: ', userIdsAndEmails)
  axios
    .post('searchUsers', {
      accessToken: getAccessToken(),
      userIdsAndEmails: userIdsAndEmails
    })
    .then((r) => {
      log('got a 200: ', r.data)
      success(r.data)
    })
    .catch((e) => {
      error("Couldn't search users: ", e)
      failure(e)
    })
}

function listSchools(success, failure) {
  ;[success, failure] = _assureCallbacks(success, failure)
  axios
    .post('listInstitutions', {
      accessToken: getAccessToken()
    })
    .then((r) => {
      log('got a 200: ', r.data)
      success(r.data)
    })
    .catch((e) => {
      error("Couldn't list schools: ", e)
      failure(e)
    })
}

function listEmailDomains(pageIndex, order, success, failure) {
  ;[success, failure] = _assureCallbacks(success, failure)
  axios
    .post('listEmailDomains', {
      accessToken: getAccessToken(),
      pageIndex: pageIndex,
      order: order
    })
    .then((r) => {
      log('got a 200: ', r.data)
      success(r.data)
    })
    .catch((e) => {
      error("Couldn't list email domains: ", e)
      failure(e)
    })
}

function enableFallbackUnlimitedDomain(
  institutionId,
  toggle,
  success,
  failure
) {
  ;[success, failure] = _assureCallbacks(success, failure)
  axios
    .post('institutionToggleUnlimited', {
      institutionId: institutionId,
      enabled: toggle,
      accessToken: getAccessToken()
    })
    .then((r) => {
      log('got a 200: ', r.data)
      success(r.data)
    })
    .catch((e) => {
      error("Couldn't list schools: ", e)
      failure(e)
    })
}

function enableForcedMinorEssentialDomain(
  institutionId,
  toggle,
  success,
  failure
) {
  ;[success, failure] = _assureCallbacks(success, failure)
  axios
    .post('institutionToggleEssential', {
      institutionId: institutionId,
      enabled: toggle,
      accessToken: getAccessToken()
    })
    .then((r) => {
      log('got a 200: ', r.data)
      success(r.data)
    })
    .catch((e) => {
      error("Couldn't list schools: ", e)
      failure(e)
    })
}

function _assureCallbacks(success, failure) {
  if (!success) {
    success = () => {}
  }
  if (!failure) {
    failure = () => {}
  }
  return [success, failure]
}

function enforceAdministratorOnlyAccess() {
  const loginData = getMoteLogin()
  if (
    !isDevForceLogin() &&
    (loginData === {} ||
      !loginData.primaryEmailAddress ||
      !ADMIN_ALLOW_LIST.includes(loginData.primaryEmailAddress))
  ) {
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.host +
      '/login?returnTo=/admin'
  }
}

export {
  enforceAdministratorOnlyAccess,
  searchUsers,
  makeVIP,
  unmakeVIP,
  freeTrialExtension,
  deleteUser,
  listSchools,
  listEmailDomains,
  enableFallbackUnlimitedDomain,
  enableForcedMinorEssentialDomain
}
