import React from 'react'

import CopyReferrerLink from './CopyReferrerLink'

function ReferrerCTA({ code }) {
  // console.log("code: " + code);
  return (
    <div>
      <p>
        Don't forget, you can still use your invite code (below) to introduce
        friends to mote with an extended free trial.
      </p>

      <CopyReferrerLink code={code} />
    </div>
  )
}

export default ReferrerCTA
