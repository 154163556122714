import React from 'react'

function InstitutionIntro({ data }) {
  return (
    <React.Fragment>
      <h3 className="mote-logged-in-subtitle">Organizational account</h3>
      <p className="lead">Organization: {data.institutionName}</p>
    </React.Fragment>
  )
}

export default InstitutionIntro
