import React from 'react'
import { BASE_URL } from 'web/src/shared/Constants'
import { getAccessToken } from 'web/src/shared/Auth'

const NETWORK_TIMEOUT = 12 * 1000

const INITIAL_STATE = {
  data: null,
  loading: true,
  error: null,
  fetched: false
}

const URL = `${BASE_URL}i/settings`

export const usePreferredLocaleMutation = () => {
  const [state, setState] = React.useState(INITIAL_STATE)

  async function updatePreferredLocale({ preferredLocale }) {
    const controller = new AbortController()
    const id = setTimeout(() => {
      console.log('aborting request')
      controller.abort()
    }, NETWORK_TIMEOUT)

    let error = state.error
    let data = state.data
    try {
      const response = await fetch(URL, {
        headers: {
          'Content-Type': 'application/json'
        },
        signal: controller.signal,
        method: 'post',
        body: JSON.stringify({
          mode: 'set',
          preferredLocale,
          accessToken: getAccessToken()
        })
      })

      data = await response.json()
    } catch (err) {
      error = err
    } finally {
      clearTimeout(id)
      setState({
        loading: false,
        fetched: true,
        error,
        data
      })
    }
  }

  return { ...state, mutate: updatePreferredLocale }
}
