import React from 'react'
import tw, { styled, css } from 'twin.macro'
import { mote_primary } from '@mote/config/defaults/colors'

export const Notice = ({ Icon, title, description, reset }) => {
  return (
    <Container>
      <Icon />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Action onClick={() => reset()}>Try again</Action>
    </Container>
  )
}

const Container = styled.div(() => [
  tw`flex flex-col items-center space-y-4 mt-12`
])

const Title = styled.div(() => [
  tw`font-bold`,
  css`
    font-size: 20px;
  `
])

const Description = styled.div(() => [tw`font-bold text-sm text-center`])

const Action = styled.div(() => [
  tw`font-medium text-base`,
  css`
    color: ${mote_primary};
  `
])
