import React from 'react'

export const Resume = ({ color = '#fff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="none">
      <rect
        width="5"
        height="22"
        x="11"
        y="10.333"
        fill={color}
        rx="2.5"
      ></rect>
      <path
        fill={color}
        d="M19.333 11.814c0-1.125 1.307-1.745 2.178-1.032l11.228 9.186c.652.534.652 1.53 0 2.064L21.51 31.218c-.87.713-2.178.093-2.178-1.032V11.814z"
      ></path>
    </svg>
  )
}
