import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'

class StudentUpgrades extends React.Component {
  render() {
    return (
      <ReactTypeformEmbed url="https://justmote.typeform.com/to/Ph2xO8Wb" />
    )
  }
}

export default StudentUpgrades
