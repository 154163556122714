import React from 'react'

export const TwitterIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M14.1841 1.45055C13.6789 1.67626 13.12 1.8321 12.5074 1.91809C12.8083 1.74612 13.0663 1.52041 13.2812 1.24096C13.507 0.961508 13.6736 0.649813 13.781 0.305874C13.2329 0.649813 12.6202 0.886271 11.9431 1.01525C11.6852 0.725049 11.3681 0.499339 10.9919 0.338118C10.6265 0.176897 10.2395 0.0962864 9.83111 0.0962864C9.30445 0.0962864 8.82079 0.230637 8.38012 0.499339C7.93945 0.757294 7.59013 1.10661 7.33218 1.54728C7.07423 1.98795 6.94525 2.47161 6.94525 2.99827C6.94525 3.25623 6.96675 3.47656 7.00974 3.65928C5.82745 3.59479 4.71502 3.29384 3.67245 2.75644C2.65139 2.22978 1.77542 1.52041 1.04455 0.628317C0.786592 1.09048 0.657615 1.57415 0.657615 2.07931C0.657615 2.57372 0.77047 3.03589 0.99618 3.46581C1.23264 3.88499 1.54971 4.22893 1.94739 4.49763C1.47447 4.48688 1.03917 4.36328 0.641493 4.12682V4.17519C0.641493 4.87381 0.856455 5.48645 1.28638 6.01311C1.72705 6.53977 2.28595 6.87296 2.96308 7.01268C2.72662 7.07717 2.46867 7.10942 2.18922 7.10942C1.99575 7.10942 1.81841 7.09329 1.65719 7.06105C1.83991 7.64145 2.17847 8.11974 2.67288 8.49592C3.16729 8.86136 3.7262 9.04945 4.34958 9.06019C3.85517 9.45787 3.29627 9.76419 2.67288 9.97916C2.06024 10.1941 1.42073 10.3016 0.754348 10.3016C0.614623 10.3016 0.399661 10.2909 0.109462 10.2694H0.061096C1.40461 11.1292 2.88784 11.5591 4.51081 11.5591C5.78983 11.5591 6.96675 11.3119 8.04155 10.8175C9.03038 10.3553 9.89023 9.71045 10.6211 8.88285C11.2982 8.10899 11.8249 7.22765 12.2011 6.23882C12.5558 5.28224 12.7331 4.31491 12.7331 3.33684V2.9499C13.3027 2.54148 13.7864 2.04169 14.1841 1.45055Z"
        fill="currentColor"
      />
    </svg>
  )
}
