import React from 'react'

import { track } from '../shared/AnalyticsHelpers'

function TrackedDownloadLink(props) {
  const href = props.href
  const label = props.label
  const title = props.title
  return (
    <a
      target="_new"
      className="link-cta"
      href={href}
      onClick={() => {
        track('Download Link', {
          label: label,
          href: href,
          title: title
        })
      }}
    >
      {label}
    </a>
  )
}

export default TrackedDownloadLink
