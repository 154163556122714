import React from 'react'
import tw, { styled } from 'twin.macro'
import { MoteReply } from './moteReply/MoteReply'
import { getUserId } from 'web/src/shared/Auth'

export const MoteRepliesList = ({ replies, isOpen }) => {
  const userId = getUserId()

  React.useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'scroll'

    return () => (document.body.style.overflow = 'scroll')
  }, [isOpen])

  if (!isOpen) return null

  if (replies?.length === 0) {
    return (
      <Root>
        <NoRepliesPlaceholder />
        <NoRepliesText>Be the first to reply ...</NoRepliesText>
      </Root>
    )
  }
  return (
    <Root>
      <TotalReplies>
        <span>
          {replies?.length} {replies?.length === 1 ? 'reply' : 'replies'}{' '}
        </span>
      </TotalReplies>

      <RepliesContainer>
        {replies?.map((reply) => (
          <MoteReply key={reply.moteId} reply={reply} userId={userId} />
        ))}
      </RepliesContainer>
    </Root>
  )
}

const Root = styled.div`
  ${tw`flex flex-col gap-4 min-h-0`}
`

const TotalReplies = styled.p`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(213, 212, 218, 0.5);
  line-height: 0.1em;
  margin: 28px 0 22px 0;

  span {
    background: #fff;
    padding: 0 10px;
    font-weight: 500;
    font-size: 16px;
    color: #232323;
  }
`

const RepliesContainer = styled.div`
  ${tw`flex flex-col gap-4 overflow-y-auto min-h-0 overscroll-contain`};
  max-height: calc(100vh - 325px);
  padding-bottom: 100px;
`

const NoRepliesText = styled.span`
  text-align: center;
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #232323;
`

const NoRepliesPlaceholder = () => {
  return (
    <svg
      width="332"
      height="200"
      viewBox="0 0 332 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: 25 }}
    >
      <path
        d="M43.9931 194.223C43.9931 194.223 -35.2025 144.542 18.9212 91.2842C73.0448 38.0264 145.475 -16.4236 194.027 4.6409C242.579 25.7054 238.212 52.3346 291.54 90.8868C344.868 129.439 332.531 160.043 322.979 175.543C313.428 191.043 311.837 195.018 311.837 195.018L43.9931 194.223Z"
        fill="#E9C9F5"
        fillOpacity="0.3"
      />
      <path
        d="M124.688 46.8333C121.77 48.6305 125.305 51.364 126.103 61.418C127.653 63.2811 131.697 64.96 135.478 56.7716C140.203 46.5361 139.708 44.4258 135.365 42.9948C131.022 41.5638 131.454 44.3567 130.834 43.6115C130.214 42.8663 135.563 29.5905 132.644 31.3877C129.726 33.1849 127.606 45.0361 124.688 46.8333Z"
        fill="#F09959"
      />
      <path
        d="M282.249 113.488C287.91 118.604 285.449 123.72 281.264 133.221C277.079 142.722 276.341 177.803 276.341 177.803L279.787 177.316C279.787 177.316 279.295 141.26 283.972 131.516C288.649 121.771 290.618 116.898 294.802 120.066C298.987 123.232 282.249 113.488 282.249 113.488Z"
        fill="#74B570"
      />
      <path
        d="M279.138 106.864C279.826 103.832 283.909 100.552 289.369 97.7691C288.558 102.241 285.955 109.552 288.649 111.052C292.282 113.075 289.003 102.866 290.405 97.2552C295.791 94.6816 301.499 92.8284 307.379 91.7444C307.371 91.7654 307.365 91.7849 307.356 91.8059C304.156 99.8455 299.972 103.013 301.695 104.231C302.991 105.147 307.213 95.8605 312.789 91.1001C316.974 90.8793 320.515 91.441 322.609 93.0821C325.846 95.6185 328.019 97.869 324.763 105.593C322.671 107.033 320.234 107.907 317.695 108.129C310.31 108.859 306.864 109.103 312.033 111.539C314.565 112.732 318.807 111.472 322.247 110.704C321.131 112.739 319.76 115.043 318.093 117.667C317.101 119.238 315.941 120.699 314.633 122.024C312.253 122.476 310.178 122.481 309.079 122.015C305.633 120.553 296.772 115.924 294.556 117.873C292.388 119.781 310.968 120.989 309.68 125.836C300.675 130.972 290.369 128.528 288.548 123.813C286.101 117.48 277.068 115.991 279.138 106.864Z"
        fill="#9CD499"
      />
      <path
        d="M275.11 126.481C284.956 126.481 288.156 141.586 285.448 150.356C282.741 159.126 279.048 175.449 279.048 175.449L276.587 173.012C276.587 173.012 285.941 150.112 283.725 139.88C281.51 129.648 276.095 127.943 275.11 127.943C274.125 127.943 275.11 126.481 275.11 126.481Z"
        fill="#9CD499"
      />
      <path
        d="M272.731 136.713C274.7 132.572 269.531 131.597 269.777 134.764C269.995 137.57 270.019 144.392 267.111 146.249C262.096 145.711 255.272 143.712 250.113 140.663C250.048 140.227 250.124 139.782 250.331 139.393C252.546 135.008 266.823 131.353 262.392 129.892C258.863 128.727 252.68 135.599 247.698 139.06C244.705 136.813 242.7 134.134 242.7 131.144C242.7 127.097 247.669 122.515 254.095 120.325C256.987 120.614 259.673 122.179 263.869 124.532C270.068 128.009 268.634 122.388 262.773 119.052C267.833 119.311 272.877 121.451 276.598 126.562C276.598 126.562 276.246 128.746 278.582 132.126C284.956 141.345 277.6 146.727 268.807 146.378C269.305 143.948 271.37 139.575 272.731 136.713Z"
        fill="#9CD499"
      />
      <path
        d="M291.896 153.937H268.027V179.999C268.027 183.872 268.933 187.587 270.545 190.326C272.157 193.065 274.343 194.603 276.623 194.603H283.3C285.58 194.603 287.766 193.065 289.379 190.326C290.991 187.587 291.896 183.872 291.896 179.999V153.937Z"
        fill="#AC0AE8"
      />
      <rect
        x="153.507"
        y="43.5704"
        width="78.6454"
        height="30.1231"
        rx="6.33"
        fill="white"
        stroke="#BDD0F6"
        strokeWidth="2.532"
      />
      <path
        d="M171.583 57.901C172.146 58.2259 172.146 59.038 171.583 59.3629L163.412 64.0807C162.849 64.4055 162.146 63.9995 162.146 63.3498L162.146 53.9142C162.146 53.2645 162.849 52.8584 163.412 53.1833L171.583 57.901Z"
        fill="#AC0AE8"
      />
      <rect
        x="179.509"
        y="57.2046"
        width="45.668"
        height="3.80567"
        rx="1.688"
        fill="#D5D4DA"
        fillOpacity="0.5"
      />
      <rect
        x="171.543"
        y="83.0248"
        width="78.6454"
        height="30.1231"
        rx="6.33"
        fill="white"
        stroke="#BDD0F6"
        strokeWidth="2.532"
      />
      <path
        d="M189.62 97.3554C190.183 97.6802 190.183 98.4924 189.62 98.8172L181.448 103.535C180.886 103.86 180.182 103.454 180.182 102.804L180.182 93.3685C180.182 92.7188 180.886 92.3127 181.448 92.6376L189.62 97.3554Z"
        fill="#AC0AE8"
      />
      <rect
        x="197.546"
        y="96.6589"
        width="45.668"
        height="3.80567"
        rx="1.688"
        fill="#D5D4DA"
        fillOpacity="0.5"
      />
      <path
        d="M91.5 128.5L115 118.5L129 60.5"
        stroke="#9EC8FF"
        strokeWidth="15.192"
        strokeLinejoin="round"
      />
      <path
        d="M50.6027 104.796C49.554 100.725 52.0038 96.5751 56.0745 95.5264C60.1452 94.4776 64.2953 96.9274 65.344 100.998L72.151 127.42C72.8488 130.128 71.2189 132.889 68.5105 133.587L63.577 134.858C60.8686 135.556 58.1075 133.926 57.4097 131.217L50.6027 104.796Z"
        fill="#F09959"
      />
      <path
        d="M51.6206 145.9C49.1121 137.539 54.1478 128.79 62.6366 126.759L86.353 121.084C95.3827 118.924 104.221 125.297 105.023 134.547L110.245 194.763H66.2817L51.6206 145.9Z"
        fill="#75A1DE"
      />
      <path
        d="M74.9723 95.332C80.519 101.499 83.8601 108.493 79.423 112.508C74.9857 116.522 54.1946 114.113 48.6479 107.945C43.1013 101.778 53.0127 91.1522 57.4497 87.1375C61.8868 83.1229 69.4257 89.1645 74.9723 95.332Z"
        fill="#F09959"
      />
      <path
        d="M66.2696 83.832C71.5464 88.9921 62.4377 96.4094 53.9499 96.3464C45.8529 96.2861 46.516 102.284 44.9011 107.546C42.1972 116.356 32.5946 95.9795 46.0169 84.7771C56.0873 76.3721 62.7569 80.3971 66.2696 83.832Z"
        fill="#2E5B9A"
      />
      <path
        d="M55.3484 121.498C45.6776 116.474 40.1805 107.74 39.9887 105.731C40.047 105.162 40.865 102.876 43.6704 98.2833C47.1771 92.542 58.3209 95.8707 53.955 96.2876L53.955 96.2876C51.2762 96.5434 50.6064 96.6073 52.5045 98.7914C53.6263 99.9233 56.5685 103.134 59.3631 106.922C62.8564 111.657 68.278 111.815 68.8669 107.366C69.3381 103.807 75.7379 100.29 78.8789 98.9764C83.0385 102.904 84.0694 110.871 84.065 114.363C79.8946 118.816 68.9444 119.974 63.9906 119.997C60.7419 115.463 58.6753 111.043 57.068 111.196C55.0589 111.388 56.7651 111.563 58.0574 114.481C59.0913 116.815 60.8171 118.61 59.9724 120.38L55.3484 121.498Z"
        fill="#2E5B9A"
      />
      <path
        d="M56.2935 109.95C58.7452 111.289 60.0135 113.679 59.1266 115.288C58.2396 116.898 55.533 117.117 53.0814 115.777C50.6297 114.438 49.3614 112.048 50.2484 110.439C51.1355 108.83 53.8419 108.61 56.2935 109.95Z"
        fill="#F09959"
      />
      <path
        d="M148.778 183.755C144.972 185.658 139.264 186.246 141.642 188.988L139.264 194.221L143.069 192.318C143.704 193.428 146.399 194.697 147.351 194.221C150.205 192.794 152.108 193.857 154.011 194.221C155.438 194.809 157.721 195.363 161.146 196.124C165.428 197.075 169.233 195.172 170.66 193.27C172.088 191.367 153.535 181.377 148.778 183.755Z"
        fill="#F09959"
      />
      <path
        d="M69.5076 132.582C67.3437 128.988 62.6761 127.829 59.082 129.992C55.488 132.156 54.3286 136.824 56.4924 140.418L69.5076 132.582ZM86.9356 176.256L80.428 180.174L82.1271 182.996L85.3484 183.684L86.9356 176.256ZM56.4924 140.418L80.428 180.174L93.4432 172.338L69.5076 132.582L56.4924 140.418ZM85.3484 183.684L141.006 195.577L144.181 180.72L88.5229 168.828L85.3484 183.684Z"
        fill="#9EC8FF"
      />
      <path
        d="M316.48 199.239H46.561C45.9613 199.239 45.3862 199.003 44.9622 198.584C44.5381 198.164 44.2999 197.595 44.2999 197.001C44.2999 196.408 44.5381 195.839 44.9622 195.419C45.3862 194.999 45.9613 194.763 46.561 194.763H316.48C317.079 194.763 317.654 194.999 318.078 195.419C318.502 195.839 318.741 196.408 318.741 197.001C318.741 197.595 318.502 198.164 318.078 198.584C317.654 199.003 317.079 199.239 316.48 199.239Z"
        fill="#2E5B9A"
      />
    </svg>
  )
}
