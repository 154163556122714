import React from 'react'

import { coinFlip } from '../../../shared/Utils'
import {
  DEV_FORCE_LOGIN,
  BASE_URL,
  NETWORK_TIMEOUT
} from '../../../shared/Constants'
import { getAccessToken } from '../../../shared/Auth'

const axios = require('axios').default

const ROUTES = {
  visible: [
    true,
    'unarchive',
    'Making public',
    'make mote public',
    'Updated',
    false
  ],
  archived: [true, 'archive', 'Hiding ', 'hide mote', 'Updated', false],
  delete: [false, 'delete', 'Deleting', 'delete mote', 'Deleted', true]
}

class Visibility extends React.Component {
  constructor(props) {
    super(props)
    this.a = this.props.activity
    this.onChange = this.props.onChange
    this.onDelete = this.props.onDelete
    this.state = {
      deleted: false,
      error: '',
      loading: false,
      deleteConfirmation: '',
      statusMessage: '',
      selection: this.a.isArchived ? 'archived' : 'visible'
    }
    this.originalSelection = this.state.selection
    this.delet = this.state.selection
  }

  render() {
    return (
      <div>
        {this.state.isDeleted && <p>Deleted</p>}
        {!this.state.isDeleted && (
          <div>
            {!this.state.loading && (
              <p>
                <select
                  value={this.state.selection}
                  onChange={(e) => this._updateSelection(e)}
                >
                  <option value="visible">Unlisted</option>
                  <option value="archived">Hidden</option>
                  <option value="delete">Delete</option>
                </select>
              </p>
            )}

            {!this.state.loading && this.state.selection === 'delete' && (
              <React.Fragment>
                <p>
                  Confirm you would like to delete by typing <b>"DELETE"</b>{' '}
                  below:{' '}
                </p>
                <p>
                  <input
                    autoFocus
                    type="text"
                    value={this.state.deleteConfirmation}
                    onChange={(e) => this.updateDeleteConfirmation(e)}
                  />
                </p>
              </React.Fragment>
            )}
            {this.state.error && (
              <p>
                <font color="red">{this.state.error} </font>{' '}
              </p>
            )}
            {this.state.loading && <p>{this.state.statusMessage} </p>}
          </div>
        )}
      </div>
    )
  }

  _updateSelection(e) {
    const v = e.target.value
    this.setState({
      selection: v,
      error: '',
      deleteConfirmation: '',
      loading: false
    })

    if (ROUTES[v][0]) {
      this._networkRequest(...ROUTES[v])
    }
  }

  updateDeleteConfirmation(e) {
    const v = e.target.value
    this.setState({ deleteConfirmation: v })
    if (['"DELETE"', 'DELETE'].includes(v.trim())) {
      this._networkRequest(...ROUTES.delete)
    }
  }

  _networkRequest(_, route, doing, doingError, done, fadeOut) {
    this.setState({
      loading: true,
      statusMessage: doing + ' ...',
      error: '',
      deleteConfirmation: ''
    })

    const fail = () => {
      this.setState({
        loading: false,
        statusMessage: '',
        selection: this.originalSelection,
        error: `Network error while trying to ${doingError}. Try again later or contact support@mote.com`
      })
      setTimeout(
        () => this.setState({ error: '', selection: this.originalSelection }),
        10000
      )
    }

    const onChangeOrDelete = () => {
      if (route === 'delete') {
        this.onDelete()
      } else {
        this.onChange()
      }
    }

    const succeed = () => {
      this.setState({ loading: false, statusMessage: done })
      if (route === 'delete') {
        this.setState({ isDeleted: true })
      }
      if (fadeOut) {
        setTimeout(() => {
          onChangeOrDelete()
          this.setState({ updateMessage: '' })
        }, 2000)
      } else {
        onChangeOrDelete()
      }
    }

    if (DEV_FORCE_LOGIN) {
      setTimeout(() => {
        coinFlip() ? fail() : succeed()
      }, 2000)
    } else {
      const axiosInstance = axios.create({
        baseURL: BASE_URL + 'm/',
        timeout: NETWORK_TIMEOUT
      })
      axiosInstance
        .post(route + '/' + this.a.moteId, { accessToken: getAccessToken() })
        .then(() => succeed())
        .catch((e) => fail())
    }
  }
}

export default Visibility
