import React from 'react'

export const ChevronDownIcon = ({ color = '#232323' }) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      color={color}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0.5H10L5.38462 5.5L0 0.5Z" fill="currentColor" />
    </svg>
  )
}
