import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'

class Privacy extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <div className="container">
          <main id="main">
            <div className="bg-skew bg-skew-light">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <article className="pb-2">
                      <h1 className="text-center">Privacy Policy</h1>
                      <h4>Introduction</h4>
                      <p>
                        Protecting your private information is our priority.
                        Here's a summary of our commitments to you:
                      </p>
                      <ul>
                        <li>We never sell your data.</li>
                        <li>We never advertise in Mote.</li>
                        <li>We don’t own the content you add to Mote.</li>
                        <li>
                          We use the latest security industry best practices to
                          protect you.
                        </li>
                        <li>
                          We are transparent about our practices and will notify
                          you if things change.
                        </li>
                        <li>
                          We are compliant with FERPA, COPPA, GDPR and the
                          Australian Privacy Act.
                        </li>
                      </ul>
                      <p>
                        With respect to GDPR data processing justification,
                        please be advised that we process user data only to the
                        extent that this is necessary for the performance of a
                        contract.
                      </p>
                      <p>
                        This Statement of Privacy applies to{' '}
                        <a
                          className="mote-boiler-link"
                          href="https://www.justmote.me"
                        >
                          https://www.justmote.me
                        </a>{' '}
                        and the Mote browser extension, and governs data
                        collection and usage. For the purposes of this Privacy
                        Policy, unless otherwise noted, all references to Mote
                        include https://www.justmote.me, the Mote browser
                        extension, and all other applications provided by Mote
                        Technologies, Inc. By using the Mote website, you
                        consent to the data practices described in this
                        statement.
                      </p>
                      <h4>Our Commitment To Protecting Childrens’ Privacy</h4>
                      <p>
                        Everyone at Mote takes the privacy of students and
                        children extremely seriously. We do not intentionally
                        target our services to students or children of any kind.
                        Educators that have the authority to act on parents’
                        behalf may allow students under 13 to use our services
                        and products, if such use is for educational purposes,
                        and if this use ensures that students will not provide
                        any personal information and will not share, or
                        otherwise, distribute recordings containing personal
                        information.
                      </p>
                      <p>
                        In order to provide the utmost level of data privacy and
                        security for users under the age of 18, we require all
                        users to indicate whether they are 18 years of age or
                        older, or under 18 years of age, at the time that they
                        sign-in. For those users under 18, we:
                      </p>
                      <ol>
                        <li>
                          Remove their email addresses and device-level IP
                          addresses from any data shared with third parties.
                        </li>
                        <li>
                          Remove them from any email communications, other than
                          essential email notifications.
                        </li>
                        <li>
                          Reserve the right to refuse to provide them with
                          customer service support.
                        </li>
                        <li>
                          Restrict access to certain features within the Mote
                          product experience.
                        </li>
                        <li>
                          Reserve the right to implement differentiated content
                          retention policies for these users, through such
                          methods that may include, but are not limited to,
                          reducing the length of time that user content is
                          available on the Mote service.
                        </li>
                        <li>
                          Delete all user-generated mote content on the one-year
                          anniversary of its creation.{' '}
                        </li>
                      </ol>
                      <p>
                        At any time, you may correct, update, remove or request
                        access to personal information we may have about you by
                        sending an email request. Parents and educational
                        institutions that have provided minor students with
                        access to Mote may correct, update, remove or request
                        access to the minor’s personal information by sending an
                        email request to support@mote.com.{' '}
                      </p>
                      <p>
                        Please be advised that in an effort to further safeguard
                        against unauthorized access to private information of
                        our users, Mote has implemented verification procedures
                        to ensure that those seeking access to such information
                        are, in fact, legally entitled to do so (i.e., they are
                        the account holder or authorized administrator from
                        his/her/their educational institution). Those parents
                        and educators/administrators with additional inquiries
                        about these privacy policies may contact:
                      </p>
                      <p>
                        William Jackson, CEO <br></br>
                        Mote Technologies, Inc.<br></br>
                        548 Market St<br></br>
                        PMB 52828<br></br>
                        San Francisco, California 94104-5401 USA<br></br>
                        will@justmote.me <br></br>
                        Tel: +1 (205) 880-8969<br></br>
                      </p>
                      <h4>What Data Do We Collect?</h4>
                      <p>
                        In order to provide you with products and services
                        offered on our Site, Mote collects personally
                        identifiable information from users 18 years of age or
                        older, such as your First and Last Name and Email
                        Address. If you purchase Mote's products and services,
                        we collect billing and credit card information as well.{' '}
                        <br></br>
                        Please keep in mind that if you directly disclose
                        personally identifiable information or personally
                        sensitive data through Mote's public message boards,
                        this information may be collected and used by others.{' '}
                        <br></br>
                        We do not collect any personal information about you
                        unless you voluntarily provide it to us.{' '}
                      </p>
                      <h4>How Do We Collect Your Data?</h4>
                      <p>
                        You directly provide Mote with most of the data we
                        collect. We collect and process data when you:
                        <ul>
                          <li>Register for an account on our website.</li>
                          <li>Send us an email message</li>
                          <li>
                            Submit your credit card or other payment information
                            when ordering and purchasing products or services on
                            our website.{' '}
                          </li>
                          <li>Use or view our website.</li>
                          <li>
                            Record your voice when utilizing the Mote browser
                            extension.
                          </li>
                        </ul>
                      </p>
                      <h4>How Will We Use Your Data?</h4>
                      <p>
                        Mote uses your data to:
                        <ul>
                          <li>
                            Operate our website(s) and manage your account with
                            us.
                          </li>
                          <li>
                            Deliver the products and/or services requested by
                            you.
                          </li>
                          <li>
                            Inform you of other products or services available
                            from Mote and its affiliates.
                          </li>
                          <li>
                            Send you additional Mote-related communications,
                            such as user education bulletins, user surveys, and
                            company updates.
                          </li>
                          <li>Send you legally required communications.</li>
                          <li>Process and complete purchase transactions. </li>
                          <li>
                            Provide online access to your audio recordings.{' '}
                          </li>
                        </ul>
                        When Mote processes paid subscription orders, we may
                        send your data to, and also use the resulting
                        information from, credit reference agencies, solely for
                        the purpose of preventing fraudulent purchases.<br></br>
                      </p>
                      <h4>
                        Legal Grounds for Processing EEA and UK User Data:{' '}
                      </h4>
                      <p>
                        The European Union, or “EU,” includes the following
                        countries: Austria, Belgium, Bulgaria, Croatia, Republic
                        of Cyprus, Czech Republic, Denmark, Estonia, Finland,
                        France, Germany, Greece, Hungary, Ireland, Italy,
                        Latvia, Lithuania, Luxembourg, Malta, Netherlands,
                        Poland, Portugal, Romania, Slovakia, Slovenia, Spain and
                        Sweden. The European Free Trade Association, or “EFTA,”
                        is comprised of Iceland, Liechtenstein and Norway
                        (excluding Switzerland). The European Economic Area, or
                        “EEA”, collectively refers to the members of both the EU
                        and EFTA. <br></br>
                        We only collect and process information about EEA users,
                        (and UK users), if we are authorized to do so under
                        applicable EU, (and UK), Laws. The legal grounds for
                        doing so depend on the services you use and how you use
                        them. This means that we only collect and use EEA user
                        information under the following circumstances:
                        <ul>
                          <li>
                            When necessary to provide the user with our services
                          </li>
                          <li>
                            When required by the user to operate / utilize the
                            service provided
                          </li>
                          <li>
                            When needed to provide customer support to the user
                          </li>
                          <li>
                            When required to personalize features, but only when
                            intended to protect the safety and security of
                            services provided to the user;
                          </li>
                          <li>
                            When doing so satisfies a legitimate interest that
                            is not overridden by the user’s data protection
                            interests (i.e, for research and development; to
                            market and promote our services or to protect our
                            legal rights and interests);
                          </li>
                          <li>
                            When the user gives us consent to do so for a
                            specific purpose; or{' '}
                          </li>
                          <li>
                            When the user data is required to comply with a
                            legal obligation.
                          </li>
                        </ul>
                      </p>
                      <h4>With Whom Do We Share Your Information? </h4>
                      <p>
                        Mote does not sell, rent or lease your information. We
                        don’t share your information except with trusted
                        partners to help perform statistical analysis, send you
                        email or postal mail, provide customer support, or
                        arrange for deliveries. All such third parties are
                        prohibited from using your personal information except
                        to provide these services to Mote, and they are required
                        to maintain the confidentiality of your information. We
                        maintain{' '}
                        <a href="https://support.mote.com/hc/en-us/articles/360045255892-Who-do-you-share-my-data-with-">
                          a list of these third party service providers.
                        </a>
                        <br></br>
                        Mote may disclose your personal information, without
                        notice, if required to do so by law or in the good faith
                        belief that such action is necessary to: (a) conform to
                        the edicts of the law or comply with legal process
                        served on Mote or the site; (b) protect and defend the
                        rights or property of Mote; and/or (c) act under exigent
                        circumstances to protect the personal safety of Mote
                        users and/or the public. <br></br>
                        If you are a paying customer or initiate our checkout
                        process, contact and billing information is stored in
                        Stripe’s Level 1 compliant database. For more
                        information about Stripe’s security measures, click{' '}
                        <a href="https://stripe.com/docs/security">here</a>.
                        <br></br>
                      </p>
                      <h4>Tracking User Behavior</h4>
                      <p>
                        Mote may keep track of the websites and pages our users
                        visit within Mote, in order to determine what Mote
                        services are the most popular. This data is used to
                        deliver customized content within Mote to customers
                        whose behavior indicates that they are interested in a
                        particular subject area.<br></br>
                        Mote may also keep track of the shared documents that
                        users have accessed and used to create Mote content, in
                        order to ensure that only authorized users can access
                        this content outside of the source shared document, and
                        to help users to organize and manage the content that
                        they have created.<br></br>
                      </p>
                      <h4>Automatically Collected Information</h4>
                      <p>
                        Information about your computer hardware and software
                        may be automatically collected by Mote. This information
                        can include: your IP address, browser type, domain
                        names, access times and referring website addresses.
                        This information is used for the operation of the
                        service, to maintain quality of the service, and to
                        provide general statistics regarding use of the Mote
                        website. Wherever possible and practicable, we will
                        anonymize IP addresses using such methods as, by way of
                        example only, removing the last three digits.<br></br>
                      </p>
                      <h4>Cookies And Other Personalization Technologies</h4>
                      <p>
                        Mote may use various technologies to collect and store
                        your information, including cookies, pixel tags, local
                        storage application data caches, and server logs, to
                        help you personalize your online experience.
                        (collectively "Cookies"). A Cookie is a text file that
                        is placed on your hard disk by a web page server.
                        Cookies cannot be used to run programs or deliver
                        viruses to your computer. Cookies are uniquely assigned
                        to you, and can only be read by a web server in the
                        domain that issued the cookie to you. <br></br>
                        Most Web browsers automatically accept Cookies, but you
                        can usually modify your browser setting to decline
                        cookies if you prefer. If you choose to decline cookies,
                        you may not be able to fully experience the interactive
                        features of the Mote services or websites you visit.
                      </p>
                      <h4>What Types of Cookies Do We Use?</h4>
                      <p>
                        We use cookies to make our products and services
                        function as needed by our users, and to ensure we can
                        continue to improve our products and services. We do not
                        use cookies for the purposes of advertising or
                        commercially exploiting user information.
                        <ul>
                          <li>
                            “Essential Cookies” or “First Party Cookies” are
                            sometimes referred to as “strictly necessary,”
                            because we cannot provide the functionality that our
                            users need to use our services without them. For
                            example, Essential Cookies may ensure that we know
                            whether you have registered with us, and whether you
                            have confirmed your age with us.{' '}
                          </li>
                          <li>
                            “Analytics Cookies” track information about visits
                            to the Mote website so that we can make improvements
                            and report our performance. For example, these
                            cookies allow us to analyze the number of times
                            users listen to Mote content or read help site
                            articles.
                          </li>
                        </ul>
                        To learn more about cookies, go to{' '}
                        <a href="www.allaboutcookies.org">
                          www.allaboutcookies.org
                        </a>
                        .
                      </p>
                      <h4>Links</h4>
                      <p>
                        Our website and some products/services contain links to
                        other sites. Please be aware that we are not responsible
                        for the content or privacy practices of such other
                        sites. We encourage our users to be aware when they
                        leave our site and to read the privacy statements of any
                        other site that collects personally-identifiable
                        information.
                      </p>
                      <h4>Security of your Personal Information</h4>
                      <p>
                        Mote securely stores your personal information from
                        unauthorized access, use, or disclosure at our Cloud
                        Storage service provider, Amazon Web Services, in
                        Oregon, USA. Mote uses the following methods for this
                        purpose: - SSL Protocol and password protection of all
                        data storage systems. When personal information (such as
                        a credit card number) is transmitted to other websites,
                        it is protected through the use of encryption, such as
                        the Secure Sockets Layer (SSL) protocol.{' '}
                      </p>
                      <p>
                        We delete inactive accounts, including all personal
                        information and content, after one year. Information on
                        content retention for active users can be found{' '}
                        <a
                          href="https://support.mote.com/hc/en-us/articles/360042803211-How-long-will-my-mote-content-be-kept-available-"
                          className="mote-boiler-link"
                          alt="Retention policy"
                        >
                          here
                        </a>
                        .{' '}
                      </p>
                      <p>
                        Mote takes various security measures—physical,
                        electronic, and procedural—to help defend against the
                        unauthorized access, alteration and/or disclosure of
                        your personal information. In addition to the
                        restrictions discussed in this Privacy Policy, our
                        employees are required to comply with information
                        security safeguards, and our systems are protected by
                        technological measures to help prevent unauthorized
                        individuals from gaining access. Mote employees are
                        trained to observe and comply with applicable country,
                        regional and local privacy laws in the handling,
                        processing, and storage of your information.{' '}
                      </p>
                      <p>
                        Unfortunately, no data transmission over the Internet or
                        any wireless network can be guaranteed to be 100%
                        secure. As a result, while we strive to protect your
                        personal information, you acknowledge that: (a) there
                        are security and privacy limitations inherent to the
                        Internet which are beyond our control; and (b) security,
                        integrity, and privacy of any and all information and
                        data exchanged between you and us through this Site
                        cannot be guaranteed.{' '}
                      </p>
                      <p>
                        In the unlikely event that Mote goes out of business or
                        files for bankruptcy, we will protect your personal
                        information and not sell it to any third party.{' '}
                      </p>
                      <h4>Breach Policy</h4>
                      <p>
                        In the unlikely event that Mote comes to suspect a
                        breach of the robust protective measures we have in
                        place with respect to the data we collect, we will carry
                        out an expeditious assessment of whether someone has, in
                        fact, obtained unauthorized access to your protected
                        data. For purposes of this policy, a breach is any
                        unauthorized acquisition of computerized data that
                        compromises the security, confidentiality, or integrity
                        of personal information that we maintain.<br></br>
                        If we determine that a breach has occurred, or even if
                        we conclude that it’s reasonably likely to have taken
                        place, we will immediately send you notice setting forth
                        the following information:
                        <ol>
                          <li>Date of the breach;</li>
                          <li>Types of information subject to the breach;</li>
                          <li>
                            A description, in clear and plain language, of the
                            nature of the breach, what occurred, and all
                            reasonably likely consequences of the breach;
                          </li>
                          <li>
                            Steps we are taking and those proposed to be taken
                            to address the breach (including, where appropriate,
                            any measures to mitigate its possible adverse
                            effects); and
                          </li>
                          <li>
                            The name and contact information of a designated
                            Mote representative that you can contact to discuss
                            the matter further, ask questions, request further
                            information, etc...
                          </li>
                        </ol>
                        Should your use of Mote be part of a formal arrangement
                        with a school district or other educational agency
                        (regardless of whether your relationship with that
                        institution is as a student, teacher, administrator,
                        staff member, or something else), we will also provide
                        the same notice via email and telephone to those
                        person(s) designated by the district/agency for receipt
                        of such information. In such an instance, you may
                        receive notice of the breach described above directly
                        from the district/agency on our behalf.{' '}
                      </p>
                      <p>
                        In either case, and regardless of whether we determine
                        that a breach has actually occurred or not, we will
                        adhere to all national, state and local data breach
                        regulations applicable to the impacted user(s), which
                        may include providing notice to legal authorities, as
                        mandated by the laws of your country and/or
                        regional/local jurisdiction.{' '}
                      </p>
                      <p>
                        For ease of reference, users in Australia, Canada,
                        England and the United States may learn more about the
                        privacy laws, rules and regulations enforced by their
                        respective federal agencies by visiting the
                        corresponding website listed below:
                      </p>

                      <p>
                        Australia: Office of the Australian Information
                        Commissioner
                      </p>
                      <p>
                        Canada: Office of the Privacy Commissioner of Canada
                      </p>
                      <p>United Kingdom: Information Commissioner's Office</p>
                      <p>United States: Federal Trade Commission</p>
                      <h4>Email Communications</h4>
                      <p>
                        From time to time, Mote may contact you via email for
                        the purpose of providing announcements, promotional
                        offers, alerts, confirmations, surveys, and/or other
                        general communication. In order to improve our Services,
                        we may receive a notification when you open an email
                        from Mote or click on a link therein.
                      </p>
                      <p>
                        If you would like to stop receiving marketing or
                        promotional communications via email from Mote, you may
                        opt out of such communications by clicking on the
                        Unsubscribe link.
                      </p>
                      <h4>Changes to this Statement</h4>
                      <p>
                        Mote reserves the right to change this Privacy Policy
                        from time to time. We will notify you about significant
                        changes in the way we treat personal information by
                        sending a notice to the primary email address specified
                        in your account, by placing a prominent notice on our
                        site, and/or by updating any privacy information on this
                        page. Your continued use of the Site and/or Services
                        available through this Site after such modifications
                        will constitute your: (a) acknowledgment of the modified
                        Privacy Policy; and (b) agreement to abide and be bound
                        by that Policy.{' '}
                      </p>
                      <h4>How to contact us</h4>
                      <p>
                        If you have any questions about Mote’s privacy policy,
                        the data we collect, or if you would like to exercise
                        one of your data protection rights, please do not
                        hesitate to contact us by email at support@mote.com.{' '}
                      </p>
                      <h4>What Are Your Data Protection Rights?</h4>
                      <p>
                        Mote would like to make sure you are fully aware of all
                        of your data protection rights. Every user is entitled
                        to the following:
                        <ul>
                          <li>
                            The right to access - You have the right to request
                            copies of your personal data from us. We may charge
                            you a small fee for this service.
                          </li>
                          <li>
                            The right to rectification - You have the right to
                            request that Mote correct any information you
                            believe is inaccurate. You also have the right to
                            request that Mote complete information you believe
                            is incomplete.
                          </li>
                          <li>
                            The right to erasure - You have the right to request
                            that Mote erase your personal data, under certain
                            conditions.
                          </li>
                          <li>
                            The right to restrict processing - You have the
                            right to request that Mote restrict the processing
                            of your personal data, under certain conditions.
                          </li>
                          <li>
                            The right to object to processing - You have the
                            right to object to Mote’s processing of your
                            personal data, under certain conditions.
                          </li>
                          <li>
                            The right to data portability - You have the right
                            to request that Mote transfer the data that we have
                            collected to another organization, or directly to
                            you, under certain conditions.
                          </li>
                        </ul>
                        You may correct, update, remove or request access to
                        your personal information that we collect by sending a
                        request to support@mote.com. We may not be able to
                        completely remove your personal information from our
                        systems in certain circumstances. For example, we may
                        retain your personal information for legitimate business
                        purposes, if it may be necessary to prevent fraud or
                        future abuse, or if required by law.
                      </p>
                      <h4>Contact Information</h4>
                      <p>
                        Mote welcomes your questions or comments regarding this
                        Statement of Privacy and the data we collect. If you
                        believe that Mote has not adhered to this Statement, or
                        if you would like to exercise any of the rights listed
                        above, you may always contact us at support@mote.com.
                        We will respond to all requests as soon as reasonably
                        possible, and in all instances no longer than thirty
                        days after receipt.<br></br>
                        If you are a US customer and have any additional
                        questions, or should you wish to file a complaint
                        related to our privacy policy and practices, or if you
                        feel that Mote has not addressed your concern in a
                        satisfactory manner, you may contact the iKeepSafe Safe
                        Harbor program dedicated consumer complaint division at{' '}
                        <a href="COPPAPrivacy@ikeepsafe.org">
                          COPPAPrivacy@ikeepsafe.org
                        </a>
                        .{' '}
                      </p>

                      <p>Effective as of September 15, 2020</p>
                    </article>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default Privacy
