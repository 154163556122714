import React from 'react'
import { styled } from 'twin.macro'

import { Navbar } from './shared/navbar'
import { Footer } from './shared/footer'
import { Content } from './shared/content'
import { NewHomePageAlert } from './shared/NewHomePageAlert'
import { CookieConsent } from './shared/CookieConsent'

const showNewHomePageAlert =
  window.location.pathname === '/' || window.location.pathname === '/homepage'

export const DefaultLayout = ({ children }) => {
  return (
    <Root>
      {showNewHomePageAlert && <NewHomePageAlert />}
      <Navbar />
      {children}
      <Footer />
      <CookieConsent />
    </Root>
  )
}

const Root = styled.div``
