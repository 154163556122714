import React from 'react'
const queryString = require('query-string')

class Paginator extends React.Component {
  constructor(props) {
    super(props)
    this.baseURL = this.props.baseURL
    this.pageIndex = parseInt(this.props.pageIndex)
    if (Number.isNaN(this.pageIndex)) {
      this.pageIndex = 0
    }
    // log("Page index is: ", this.pageIndex);
    this.pageIndexes = this.props.pageIndexes
    if (!this.pageIndexes) {
      this.pageIndexes = []
    }
    const queryParams = queryString.parse(window.location.search)
    const kvPairs = []
    for (let k of Object.keys(queryParams)) {
      kvPairs.push(k + '=' + encodeURIComponent(queryParams[k]))
    }
    this.qs = kvPairs.length ? '?' + kvPairs.join('&') : ''
  }

  render() {
    if (!this.pageIndexes || this.pageIndexes.length < 2) {
      return <React.Fragment />
    }
    const lastPageIndex =
      parseInt(this.pageIndexes[this.pageIndexes.length - 1]) - 1
    return (
      <div>
        {this.page}
        <p style={{ textAlign: 'center' }}>
          {this.pageIndex > 0 && this._link(this.pageIndex - 1, 'Previous')}
          {this.pageIndexes.map((i) => {
            var pageNumber = parseInt(i)
            var isCurrentOrEllipsis = false
            if (Number.isNaN(pageNumber)) {
              isCurrentOrEllipsis = true
            } else if (pageNumber - 1 === this.pageIndex) {
              isCurrentOrEllipsis = true
            }
            return (
              <React.Fragment key={i}>
                <span>
                  {' '}
                  {isCurrentOrEllipsis && <span>{i}</span>}
                  {!isCurrentOrEllipsis && this._link(pageNumber - 1)}
                </span>
              </React.Fragment>
            )
          })}{' '}
          {this.pageIndex < lastPageIndex &&
            this._link(this.pageIndex + 1, 'Next')}
        </p>
      </div>
    )
  }

  _link(index, label) {
    if (!label) {
      label = index + 1
    }

    return (
      <a className="mote-boiler-link" href={this.baseURL + index + this.qs}>
        {label}
      </a>
    )
  }
}

export default Paginator
