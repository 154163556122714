import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'

class PilotPreSurvey extends React.Component {
  render() {
    return (
      <ReactTypeformEmbed url="https://will838622.typeform.com/to/o8mhE0I5" />
    )
  }
}

export default PilotPreSurvey
