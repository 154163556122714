import tw, { styled, css } from 'twin.macro'
import { mote_primary } from '@mote/config/defaults/colors'

export const Container = styled.div(() => [
  tw`flex items-center justify-center flex-col pt-7`
])

export const Text = styled.div(({ active = true }) => [
  tw`font-bold flex items-center`,
  css`
    font-size: 20px;
    margin-top: ${active ? '38' : '28'}px;
  `
])

export const Controls = styled.div(() => [tw`flex space-x-8 items-center`])

export const MainControlWrapper = styled.div(() => [
  tw`rounded-full flex items-center justify-center cursor-pointer`,
  css`
    width: 90px;
    height: 90px;
    background: ${mote_primary};
  `
])

export const ControlWrapper = styled.div(({ isActive = true }) => [
  tw`cursor-pointer`,
  css`
    opacity: ${isActive ? '1' : '0.2'};
  `
])

export const PulsingDot = styled.div(() => [
  tw`w-2 h-2 rounded-full animate-pulse`,
  css`
    margin-right: 14px;
    background: ${mote_primary};
  `
])
