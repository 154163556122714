import React from 'react'
import { styled } from 'twin.macro'
import Spinner from '@mote/ui/components/spinner'
import { mote_primary } from '@mote/config/defaults/colors'

export const RecorderSpinner = React.memo(() => {
  return (
    <SpinnerHolder>
      <Spinner
        radius={100}
        color={mote_primary}
        duration={1400}
        strokeWidth={3}
      />
    </SpinnerHolder>
  )
})

const SpinnerHolder = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '10px 0',

  '& circle': {
    opacity: '0'
  }
})
