import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AudioPlayerProvider } from 'react-use-audio-player'

import { Trash, Edit, IconClose } from '@mote/assets/svgs/icons'
import {
  Wrapper,
  Container,
  Body,
  Header,
  ControlsContainer,
  ScrubberContainer,
  ScrubberWrapper,
  OptionWrapper,
  CloseIcon
} from './elements'
import Title from './components/Title'
import Options from './components/Options'
import AugmentedControls from './components/Controls/AugmentedControls'
import AugmentedScrubber from './components/Scrubber/AugmentedScrubber'
import Durations from './components/Durations'
import EmojiPicker from './components/Emoji/EmojiPicker'
import Transcription from './components/Transcription/Transcription'

import { MiniCard } from './components/Cards/MiniCard/MiniCard'
import { getL10n } from '@mote/l10n/helpers/l10nWithoutProvider'
import { LocalizationProvider } from '@fluent/react'

export const MoteCard = ({
  moteId,
  audioUrl,
  username,
  onDelete,
  onPlay,
  onPause,
  onEnd,
  onEmoji,
  activeEmojis,
  scrubber,
  transcription,
  transcriptionVisible,
  totalTime,
  scrubberPercentage,
  isPreparing,
  isLoading,
  dynamicWidth,
  isQuiescent,
  dominantLanguage,
  editor,
  isPrivateComments,
  isLandingPage,
  isMiniCard,
  onClose,
  style = {},
  onMouseDown,
  onMouseUp
}) => {
  const [currentTime, setCurrentTime] = useState(0)
  const [forcePause, setForcePause] = useState(false)
  const [showEditor, setShowEditor] = useState(false)

  useEffect(() => {
    const currTime = +totalTime * (+scrubberPercentage / 100)
    setCurrentTime(currTime)
    if (+scrubberPercentage) setForcePause(true)
  }, [scrubberPercentage, totalTime])

  const deleteMote = () => {
    setShowEditor(false)
    onDelete()
  }

  const optionsEnabled = editor || onDelete

  const play = (position) => onPlay(position)
  const pause = (position) => onPause(position)
  const end = (position) => onEnd(position)

  const FullCard = (
    <AudioPlayerProvider>
      <Wrapper
        width={dynamicWidth ? '100%' : undefined}
        className="mote-card"
        data-mote-id={moteId}
        contentEditable="false"
        suppressContentEditableWarning="true"
        style={style?.container}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        {showEditor &&
          editor &&
          editor({
            onClose: () => setShowEditor(false)
          })}

        <Container height={transcriptionVisible ? 'auto' : undefined}>
          <Body>
            <Header>
              <Title
                isPrivateComments={isPrivateComments}
                username={username}
                isPreparing={isPreparing}
                isLoading={isLoading}
              />

              {onClose && (
                <CloseIcon onClick={(_) => onClose()}>
                  <IconClose />
                </CloseIcon>
              )}

              {optionsEnabled && !isPreparing && !isLoading && (
                <Options>
                  {editor && (
                    <OptionWrapper onClick={() => setShowEditor(true)}>
                      <Edit />
                      <span>Edit</span>
                    </OptionWrapper>
                  )}
                  {onDelete && (
                    <OptionWrapper onClick={deleteMote}>
                      <Trash />
                      <span>Delete</span>
                    </OptionWrapper>
                  )}
                </Options>
              )}
            </Header>

            <ControlsContainer isTranscriptVisible={transcriptionVisible}>
              <AugmentedControls
                disabled={isPreparing}
                loading={isLoading}
                audioUrl={audioUrl}
                forcePause={forcePause}
                onPlay={play}
                onPause={pause}
                onEnd={end}
              />
            </ControlsContainer>

            <ScrubberContainer
              className="scrubber-container"
              isTranscriptVisible={transcriptionVisible}
            >
              {scrubber && (
                <ScrubberWrapper>
                  {!isPreparing && (
                    <Durations
                      currentTime={currentTime}
                      totalTime={totalTime}
                    />
                  )}
                  <AugmentedScrubber
                    disabled={isPreparing}
                    forcedProgress={scrubberPercentage}
                    loading={isLoading}
                  />
                </ScrubberWrapper>
              )}
              {transcriptionVisible && !isLoading && (
                <Transcription
                  text={transcription}
                  isQuiescent={isQuiescent}
                  isLandingPage={isLandingPage}
                  isLoading={isLoading}
                />
              )}
            </ScrubberContainer>
          </Body>
        </Container>
        {onEmoji && !isPreparing && !isLoading && (
          <EmojiPicker onEmoji={onEmoji} activeEmojis={activeEmojis} />
        )}
      </Wrapper>
    </AudioPlayerProvider>
  )

  function getCard() {
    return isMiniCard ? (
      <MiniCard
        moteId={moteId}
        audioUrl={audioUrl}
        disabled={isPreparing}
        loading={isLoading}
        onPlay={play}
        onPause={pause}
        onEnd={end}
        isMiniCard
      />
    ) : (
      FullCard
    )
  }

  return window.chrome?.extension ? (
    <LocalizationProvider l10n={getL10n()}>{getCard()}</LocalizationProvider>
  ) : (
    getCard()
  )
}

MoteCard.propTypes = {
  moteId: PropTypes.string.isRequired,
  audioUrl: PropTypes.string.isRequired,
  username: PropTypes.string,
  onDelete: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onEnd: PropTypes.func,
  onEmoji: PropTypes.func,
  activeEmojis: PropTypes.array,
  scrubber: PropTypes.bool,
  transcription: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  transcriptionVisible: PropTypes.bool,
  isPreparing: PropTypes.bool,
  dynamicWidth: PropTypes.bool,
  isQuiescent: PropTypes.bool,
  dominantLanguage: PropTypes.string,
  editor: PropTypes.func,
  isPrivateComments: PropTypes.bool,
  isLandingPage: PropTypes.bool,
  isMiniCard: PropTypes.bool,

  // Used for screenshot generation
  totalTime: PropTypes.string,
  scrubberPercentage: PropTypes.string,
  style: PropTypes.object
}

MoteCard.defaultProps = {
  activeEmojis: [],
  scrubber: true,
  isPreparing: false,
  dynamicWidth: false,
  transcriptionVisible: false,
  isPrivateComments: false,
  isLandingPage: false,
  isMiniCard: false
}
