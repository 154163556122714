import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'

function DataSecurityPolicy() {
  return (
    <div className="container mote-brand">
      <Header />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <article className="pb-2">
              <h1 className="text-center">Data Security Policy</h1>

              <p>
                Safeguarding the data of all Mote users is of foundational
                importance to our business. Our{' '}
                <a className="mote-boiler-link" href="/privacy">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a className="mote-boiler-link" href="/tos">
                  Terms of Service
                </a>
                , developed to meet the most stringent legal and ethical
                requirements of educational users, guide and inform our data
                security policy and practices. Our core commitments to you are:
              </p>

              <ul>
                <li>We never sell your data.</li>
                <li>We never advertise in Mote.</li>
                <li>We don’t own the content you add to Mote.</li>
                <li>
                  We use the latest security industry best practices to protect
                  you.
                </li>
                <li>
                  We are transparent about our practices and will notify you if
                  things change.
                </li>
                <li>
                  We are compliant with FERPA, COPPA, GDPR and the Australian
                  Privacy Act.
                </li>
                <li>
                  We are committed to regularly auditing our privacy and
                  security practices in partnership with respected independent
                  organizations.
                </li>
              </ul>

              <h2>Infrastructure Security</h2>

              <h3>Encryption at Rest and In Transit</h3>

              <p>
                All access into, within Mote services and its contracted 3rd
                party suppliers uses TLS v1.2+ higher. All end-user facing
                services are hosted on AWS and provided through CloudFront and
                Elastic Load Balancer. We also use HTTP Strict Transport
                Security to ensure pages are served over secure connections.
              </p>

              <p>
                All personally identifiable information (PII) which we directly
                manage in AWS DynamoDB, which itself encrypts{' '}
                <a
                  className="mote-boiler-link"
                  href="https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/EncryptionAtRest.html"
                >
                  at rest
                </a>
                . Media (audio MP3s) is stored in AWS S3 and encrypted in
                transit through CloudFront. We contract billing services from
                Stripe to manage credit card data, who provide similarly strong
                encryption in transit and at rest{' '}
                <a
                  className="mote-boiler-link"
                  href="https://stripe.com/docs/security/stripe"
                >
                  warranties
                </a>
                . We use Amplitude for product analytics to understand our
                customers and improve our services, with annual{' '}
                <a
                  className="mote-boiler-link"
                  href="https://amplitude.com/amplitude-security-and-privacy"
                >
                  SOC 2 Type 2 Certification
                </a>{' '}
                and{' '}
                <a
                  className="mote-boiler-link"
                  href="https://amplitude.com/amplitude-security-and-privacy"
                >
                  AES-256 encryption
                </a>
                . Finally, we use Customer.io for email messaging (solely to 18+
                users) who{' '}
                <a
                  className="mote-boiler-link"
                  href="https://customer.io/legal/security/"
                >
                  encrypt in transit
                </a>
                .
              </p>

              <h3>Network Security</h3>

              <p>
                Our services run inside AWS in a dedicated VPC, primarily on EC2
                and DynamoDB, for which AWS assures compliance to the strictest
                security standards including ISO 27001, SOC2, PCI DSS Level 1
                and FISMA with the help of external auditors. More details
                available at{' '}
                <a
                  className="mote-boiler-link"
                  href="https://aws.amazon.com/compliance/programs/"
                >
                  AWS
                </a>
              </p>

              <p>
                Our raw primary data store of PII is DynamoDB. Access to
                DynamoDB is restricted to essential operational staff who have
                undergone background checks. Where PII data is sent to
                downstream platforms, including Amplitude for product analytics
                and Customer.io for email communication, student data is
                anonymised and/or redacted to the greatest extent possible.
              </p>

              <h3>Backups and Availability Control</h3>

              <p>
                User data in DynamoDB is backed up using AWS built-in backup
                tools. Similarly, our media in S3 is backed up to buckets in
                different regions. Our EC2 servers run on redundant
                infrastructure with significant excess capacity for demand
                fluctuations.
              </p>

              <p>
                <a
                  className="mote-boiler-link"
                  href="https://www.datadoghq.com"
                >
                  Datadog
                </a>{' '}
                is contracted for automated monitoring and alerting, and
                identification of service anomalies. We additionally use{' '}
                <a className="mote-boiler-link" href="https://www.sentry.io">
                  Sentry
                </a>{' '}
                to assist in monitoring quality of user experience.
              </p>

              <h2>Physical Security</h2>

              <p>
                All service data resides in our own cloud or contracted 3rd
                party software-as-a-service cloud infrastructure.
              </p>

              <p>
                Access to code, configuration and service data is restricted to
                current staff wht multi-factor authentication (MFA) provided by
                Google Workspace and Github. Access is differentiated by role
                with maximum restrictions being applied to DynamoDB user data
                store.
              </p>

              <p>
                For more information, check out our{' '}
                <a className="mote-boiler-link" href="/privacy">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a className="mote-boiler-link" href="/privacyCenter">
                  Privacy Center
                </a>
                .
              </p>
            </article>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default DataSecurityPolicy
