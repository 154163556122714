import React from 'react'

import { error } from '@mote/common'

import { trackCWSInstallButton } from '../shared/AnalyticsHelpers'
import { CWS_URL } from '../shared/Constants'

class GetMoteLink extends React.Component {
  constructor(props) {
    super(props)
    var recipe = this.props.recipe
    if (!recipe) {
      recipe = 'image'
    }

    if (recipe === 'image') {
      this.withImage = true
      this.classes = ''
    } else {
      this.withImage = false
      this.label = 'Join the beta'
      this.classes = 'btn btn-primary mr-2'
      if (recipe === 'jtb') {
        // pass, inherit above defaults
      } else if (recipe === 'jtb2') {
        this.classes = 'btn btn-primary chrome-fake-button d-none d-lg-block'
      } else if (recipe === 'jtb3') {
        this.classes = 'link-cta d-lg-none'
      } else if (recipe === 'atc') {
        this.label = 'Add to Chrome'
      } else {
        error('Unknown recipe: ' + recipe)
      }
    }
  }

  render() {
    return (
      <a
        className={this.classes}
        onClick={() => trackCWSInstallButton()}
        href={CWS_URL}
      >
        {!this.withImage && this.label}
        {this.withImage && (
          <img
            className="mote-chromestore-button"
            src="/images/badges/ChromeWebStore_BadgeWBorder_Large.png"
            alt="Chrome Store"
          />
        )}
      </a>
    )
  }
}

export default GetMoteLink
