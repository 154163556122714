import React from 'react'
import PropTypes from 'prop-types'
import tw, { styled } from 'twin.macro'

// https://developers.google.com/classroom/guides/sharebutton
export const ClassroomShareButton = ({
  url,
  body,
  courseId,
  itemType,
  locale,
  onShareComplete,
  onShareStart,
  size,
  theme,
  title
}) => {
  React.useEffect(() => {
    window.classroomShareStart = onShareStart
    window.classroomShareComplete = onShareComplete
  }, [])

  return (
    <ButtonContainer
      onClick={(_) => {
        onShareStart()
        window.open(
          `https://classroom.google.com/u/0/share?url=${url}`,
          '_blank',
          'location=yes,height=400,width=650,scrollbars=yes,status=yes'
        )
      }}
    >
      <div
        className="g-sharetoclassroom"
        data-url={url}
        data-body={'Share to Google Classroom'}
        data-courseid={courseId}
        data-itemtype={itemType}
        data-locale={locale}
        data-onsharecomplete="classroomShareComplete"
        data-onsharestart="classroomShareStart"
        data-size={size}
        data-theme={theme}
        data-title={title}
      />
      <ButtonText>Share to Google Classroom</ButtonText>
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div([
  tw`flex flex-row items-center mt-2`,
  `
   font-family: 'Roboto', sans-serif;
    padding: 8px 16px;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    background: #FFFFFF;
    
    &:hover {
      cursor: pointer;
      background: #CACACA;
    }
  `
])

const ButtonText = styled.span`
  margin-left: 16px;
  font-size: 16px;
`

ClassroomShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  body: PropTypes.string,
  courseId: PropTypes.string,
  itemType: PropTypes.oneOf([
    'announcement',
    'assignment',
    'material',
    'question'
  ]),
  locale: PropTypes.string,
  onShareComplete: PropTypes.func,
  onShareStart: PropTypes.func,
  size: PropTypes.number,
  theme: PropTypes.oneOf(['classic', 'dark', 'light']),
  title: PropTypes.string
}

ClassroomShareButton.defaultProps = {
  size: 32,
  theme: 'classic'
}
