import React from 'react'
import { track } from '../../../shared/AnalyticsHelpers'
import { getReferrerCodeURL } from '../../../shared/Auth'
import { CLIPBOARD_ANIMATION_TIMEOUT } from '../../../shared/Constants'
import copy from 'copy-to-clipboard'

class CopyReferrerLink extends React.Component {
  constructor(props) {
    super(props)
    this.code = this.props.code
    this.state = {
      copiedToClipboard: false
    }
  }

  render() {
    const url = getReferrerCodeURL(this.code)
    if (!url) {
      return <div />
    } else {
      return (
        <div>
          <p>
            Share this unique link via socials or email <sup>1</sup> to give
            friends an extended free trial:
          </p>
          <p>
            {url}
            <span
              className="refer-clipboard"
              onClick={() => this._sendURLToClipboard(url)}
            >
              Copy link <span className="material-icons">content_copy</span>
            </span>{' '}
          </p>
          {this.state.copiedToClipboard && (
            <span className="copied-to-clipboard">Copied to clipboard!</span>
          )}
          <p>
            <sup>1</sup> Participants must be 18 or over.
          </p>
        </div>
      )
    }
  }

  _sendURLToClipboard(url) {
    track('Referrer URL Copied To Clipboard')
    copy(url)
    this.setState({ copiedToClipboard: true })
    setTimeout(
      () => this.setState({ copiedToClipboard: false }),
      CLIPBOARD_ANIMATION_TIMEOUT
    )
  }
}

export default CopyReferrerLink
