import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tw, { styled } from 'twin.macro'

import { Dots } from '@mote/assets/svgs/icons/index'
import { useOnClickOutside } from '@mote/helpers/hooks/useOnClickOutside'

Options.propTypes = {
  children: PropTypes.array.isRequired
}

const OptionsActivator = styled.div(() => [
  tw`relative text-right cursor-pointer select-none`
])

const OptionsMenu = styled.div(() => [
  tw`absolute right-0 px-1 py-2 bg-white rounded-md shadow top-6`
])

const OptionMenuItem = styled.div(() => [
  tw`flex items-center py-1 space-x-1 space-y-0.5 text-sm text-gray-500 rounded-md hover:bg-gray-50 tracking-wide`
])

export default function Options({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const optionsMenuRef = React.useRef(null)

  useOnClickOutside(optionsMenuRef, () => setIsOpen(false))

  const renderItems = () => {
    return children
      .filter(Boolean)
      .map((child, index) => (
        <OptionMenuItem key={index}>{child}</OptionMenuItem>
      ))
  }

  return (
    <OptionsActivator>
      <div onClick={() => setIsOpen(true)}>
        <Dots />
      </div>
      {isOpen && (
        <OptionsMenu ref={optionsMenuRef}>{renderItems()}</OptionsMenu>
      )}
    </OptionsActivator>
  )
}
