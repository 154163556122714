import { error } from '@mote/common'

import { getDeviceIdOrAccessTokenHash } from '../shared/Auth'
import { BASE_URL } from '../shared/Constants'
import { noLog as log } from '../shared/Utils'

const axios = require('axios').default

const AXIOS = axios.create({ baseURL: BASE_URL + 'a/', timeout: 3000 })

async function applyReaction(moteId, verb, reaction, callback) {
  if (![`react`, `unreact`].includes(verb)) {
    error(`invalid verb: ${verb}`)
  } else if (!moteId) {
    error(`missing moteId: ${moteId}`)
  } else {
    var o = Object.assign(
      {},
      { reaction: reaction },
      getDeviceIdOrAccessTokenHash()
    )
    log('sending: ', o)

    try {
      const response = await AXIOS.post(`${verb}/${moteId}`, o)
      var d = response.data
      if (d.myReactions) {
        callback(d.myReactions)
        log('successfully applied ', d.myReactions)
      } else {
        error('got surprising response: ', d)
      }
    } catch (e) {
      error('failed with request', e)
    }
  }
}

async function recordListenInActivity(moteId) {
  var o = Object.assign({}, getDeviceIdOrAccessTokenHash())
  log('sending: ', o)

  try {
    const response = await AXIOS.post(`listen/${moteId}`, o)
    log('got back positive response', response?.data)
  } catch (e) {
    error('failed with request', e)
  }
}

export { applyReaction, recordListenInActivity }
