import { styled } from 'twin.macro'

const DropdownItem = styled.li`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 200%;
  display: flex;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;

  & > :not(:last-child) {
    margin-right: 9px;
  }

  &:hover {
    span {
      &:hover {
        color: #9b09d1;
      }

      &:active {
        color: #7807a2;
      }
    }
  }
  &:focus {
    outline: none;
  }
`

const DropdownItemText = styled.span`
  color: #232323;
  overflow: hidden;
  text-overflow: ellipsis;
`

export { DropdownItem, DropdownItemText }
