import React from 'react'
import { Modal } from 'react-bootstrap'

import { error } from '@mote/common'

import { getAccessToken } from '../../../shared/Auth'
import {
  BASE_URL,
  NETWORK_TIMEOUT,
  DEV_FORCE_LOGIN
} from '../../../shared/Constants'
import { coinFlip, noLog as log, reallyTruthy } from '../../../shared/Utils'

const axios = require('axios').default

var axiosInstance = axios.create({
  baseURL: BASE_URL + 't/',
  timeout: NETWORK_TIMEOUT
})

class UserControls extends React.Component {
  constructor(props) {
    super(props)
    this.godModeOptions = this.props.godModeOptions
    this.pageIndex = this.props.pageIndex
    this.afterUpdateCallback = this.props.afterUpdateCallback
    this.state = {
      expanded: false,
      thinking: false,
      userRow: this.props.userRow
    }
    log('got userRow', this.state.userRow)
  }

  render() {
    if (!this.state.userRow.userId) {
      return <React.Fragment />
    } else {
      log('rendering for ... ' + this.state.userRow.primaryEmailAddress)
      return (
        <div>
          <form>
            {!this.state.expanded && (
              <button
                className="pay-button"
                onClick={() =>
                  this.setState({ expanded: !this.state.expanded })
                }
              >
                Change
              </button>
            )}
            <Modal
              size="lg"
              show={this.state.expanded}
              centered
              onHide={() => this._close()}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Update details for {this.state.userRow.primaryEmailAddress}{' '}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this._choice(
                  'canMakeOwner',
                  'makeOwner',
                  'Make organization owner',
                  'There can only be one organisational owner who receives billing communications. Only the owner can reassign ownership.'
                )}
                {this._choice(
                  'canMakeAdmin',
                  'makeAdmin',
                  'Enable user administration rights',
                  'Allow more users to see this view and add/remove, upgrade/downgrade other users.'
                )}
                {this._choice(
                  'canUnmakeAdmin',
                  'unmakeAdmin',
                  'Disable user administration rights'
                )}
                {this._choice(
                  'canUpgradeToUnlimited',
                  'upgradeToUnlimited',
                  'Upgrade to Unlimited plan'
                )}
                {this._choice(
                  'canSwitchToEssential',
                  'switchToEssential',
                  'Move to Essential plan'
                )}
                {this._choice(
                  'canEssentialForceMinor',
                  'makeMinorEssential',
                  'Move to Essential plan (under 18)',
                  'Ensures that users on Essential plan cannot override their under 18 status on login.'
                )}
                {this._choice(
                  'canForceMinor',
                  'makeMinor',
                  'Force under 18 status',
                  'Ensures that users override their under 18 status on login'
                )}
                {this._choice('canUnforceMinor', 'makeAdult', 'Make 18+')}
                {this._choice(
                  'canDelicenseUser',
                  'delicenseUser',
                  'Remove allocated license',
                  'Move use down to Free plan, freeing up one more seat for other users.'
                )}
                {this._choice('canRemoveUser', 'removeUser', 'Remove user')}
              </Modal.Body>
              <Modal.Footer>
                <input
                  type="button"
                  disabled={!this.state.radioChoice}
                  className="pay-button"
                  onClick={(e) => this._applyAction(e)}
                  value={this.state.thinking ? 'Thinking ...' : 'Update'}
                />

                <br />
                <button className="btn" onClick={() => this._close()}>
                  Cancel
                </button>
                {this.state.error && (
                  <p>
                    <font color="red">An error occurred, try again later</font>
                  </p>
                )}
              </Modal.Footer>
            </Modal>
          </form>
        </div>
      )
    }
  }

  _choice(permission, path, label, tip) {
    if (this.state.userRow[permission]) {
      var key = permission + ' ' + path
      return (
        <p>
          <label>
            <input
              onChange={(e) => this._radioChange(e)}
              checked={this.state.radioChoice === key}
              type="radio"
              value={key}
            />
            &nbsp;{label}{' '}
            {tip && (
              <span>
                <br />
                {tip}
              </span>
            )}
          </label>
        </p>
        //   <button
        //     className="pay-button"
        //     disabled={this.state.thinking}
        //     onClick={() => this._applyAction(permission, path)}
        //   >
        //     {label}
        //   </button>

        //   {this._isThinking(permission) && <p>Thinking ...</p>}
        //   {this._hasError(permission) && (
        //     <p>
        //       <font color="red">Error, try again later</font>
        //     </p>
        //   )}
        // </div>
      )
    } else {
      return <React.Fragment />
    }
  }

  _radioChange(e) {
    log('updating to ', e.target.value)
    this.setState({ radioChoice: e.target.value })
  }

  _close() {
    this.setState({
      expanded: false,
      radioChoice: undefined,
      thinking: false,
      error: false
    })
  }

  _isThinking(permission) {
    return reallyTruthy(this.state['thinking.' + permission])
  }

  _applyAction(e) {
    e.preventDefault()
    if (!this.state.radioChoice) {
      return
    }
    var [permission, path] = this.state.radioChoice.split(' ')
    log(`applying ${permission} + ${path}...`)
    this.setState({ thinking: true, error: false })
    if (!DEV_FORCE_LOGIN) {
      const params = Object.assign({}, this.godModeOptions, {
        accessToken: getAccessToken(),
        pageIndex: this.pageIndex,
        operatedUserId: this.state.userRow.userId
      })
      axiosInstance
        .post(path, params)
        .then((response) => {
          log('calling callback')
          this._updateUserRow(response.data)
          this.afterUpdateCallback(response.data)
          this._close()
        })
        .catch((e) => {
          error(e)
          this.setState({ thinking: false, error: true })
        })
    } else {
      this.setState({ thinking: true })
      setTimeout(() => {
        this.setState({ thinking: false })
        if (coinFlip()) {
          this.setState({ error: true })
        } else {
          this._close()
        }
      }, 2000)
    }
  }

  _updateUserRow(data) {
    const userId = this.state.userRow.userId
    log(`looking for ${userId} in `, data)
    for (const row of data.currentPage) {
      if (row.userId === userId) {
        log('found matching row ', row)
        this.setState({ userRow: row })
        return
      }
    }
  }
}

export default UserControls
