import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'

class Blog extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <div className="container">
          <main id="main">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-7">
                  <h1 className="display-4 my-5 my-lg-6 pb-lg-4 text-center font-sans-serif mote-brand">
                    The <b className="mote-purple">mote </b>blog
                  </h1>
                </div>
              </div>
            </div>

            <div className="bg-skew bg-skew-light">
              <div className="container py-4">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card card-hover mb-5 mx-lg-2">
                      <figure>
                        <img
                          className="card-img-top"
                          src="/images/photos/RJ-prologue 16-9.png"
                          alt="Nina Simone"
                          aria-hidden="true"
                        />
                      </figure>
                      <div className="card-body pt-5 px-5 pb-4 text-center">
                        <h2 className="card-title mb-4">
                          <a
                            className="stretched-link mote-link-cta"
                            href="historyannotation"
                          >
                            A history (and future) of annotation
                          </a>
                        </h2>
                        <p className="small mb-4">
                          <span className="text-secondary">published on </span>
                          <span className="text-uppercase">Feb 12, 2020</span>
                        </p>
                        <p className="card-text mb-4">
                          From monks to memes to{' '}
                          <b className="mote-purple">mote</b>, the surprising
                          history of annotation.
                        </p>
                        <a
                          href="historyannotation"
                          className="d-inline-block link-cta mote-link-cta mb-4 text-uppercase"
                        >
                          Continue reading &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-hover mb-5 mx-lg-2">
                      <figure>
                        <img
                          className="card-img-top"
                          style={{ borderWidth: '1' }}
                          src="images/photos/link-share-0317.png"
                          alt="Mick Jagger"
                          aria-hidden="true"
                        />
                      </figure>
                      <div className="card-body pt-5 px-5 pb-4 text-center">
                        <h2 className="card-title mb-4">
                          <a
                            className="stretched-link mote-link-cta "
                            href="/release0317"
                          >
                            Even faster and friendlier comments
                          </a>
                        </h2>
                        <p className="small mb-4">
                          <span className="text-secondary">published on </span>
                          <span className="text-uppercase">Mar 17, 2020</span>
                        </p>
                        <p className="card-text mb-4">
                          Learn about the raft of new features we just launched
                          to keep teams creating together.
                        </p>
                        <a
                          href="/release0317"
                          className="d-inline-block link-cta mote-link-cta mb-4 text-uppercase"
                        >
                          Continue reading &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="card card-hover mb-5 mx-lg-2">
                      <figure>
                        <img
                          className="card-img-top"
                          src="images/photos/nina-simone-please-dont-let-me.jpg"
                          alt="Nina Simone"
                          aria-hidden="true"
                        />
                        <figcaption className="figure-caption text-center">
                          <small>
                            Nina Simone, singing live in September 1968
                          </small>
                        </figcaption>
                      </figure>
                      <div className="card-body pt-5 px-5 pb-4 text-center">
                        <h2 className="card-title mb-4">
                          <a
                            className="stretched-link mote-link-cta "
                            href="beunderstood"
                          >
                            Please don't let me be misunderstood
                          </a>
                        </h2>
                        <p className="small mb-4">
                          <span className="text-secondary">published on </span>
                          <span className="text-uppercase">Jan 22, 2020</span>
                        </p>
                        <p className="card-text mb-4">
                          You're just a soul whose intentions are good, but
                          words on a screen don't always capture what you want
                          to say.{' '}
                        </p>
                        <a
                          href="beunderstood"
                          className="d-inline-block link-cta mote-link-cta mb-4 text-uppercase"
                        >
                          Continue reading &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-hover mb-5 mx-lg-2">
                      <figure>
                        <img
                          className="card-img-top"
                          src="images/photos/Stones1982RotterdamMarcelAntonisse.jpg"
                          alt="Mick Jagger"
                          aria-hidden="true"
                        />
                        <figcaption className="figure-caption text-center">
                          <small>Mick Jagger, singing live in 1982</small>
                        </figcaption>
                      </figure>
                      <div className="card-body pt-5 px-5 pb-4 text-center">
                        <h2 className="card-title mb-4">
                          <a
                            className="stretched-link mote-link-cta "
                            href="/pleased"
                          >
                            Pleased to <br></br>meet you...
                          </a>
                        </h2>
                        <p className="small mb-4">
                          <span className="text-secondary">published on </span>
                          <span className="text-uppercase">Jan 22, 2020</span>
                        </p>
                        <p className="card-text mb-4">
                          But perhaps next time, we can work this through online
                          and save a bunch of time and delay?
                        </p>
                        <a
                          href="/pleased"
                          className="d-inline-block link-cta mote-link-cta mb-4 text-uppercase"
                        >
                          Continue reading &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                        <li className="page-item disabled"><a className="page-link mote-button" href="" tabindex="-1">Previous</a></li>
                        <li className="page-item active"><a className="page-link " href="beunderstood">1</a></li>
                        <li className="page-item"><a className="page-link" href="pleased">2</a></li>
                        <li className="page-item"><a className="page-link" href="historyannotation">3</a></li>
                        <li className="page-item"><a className="page-link mote-button" href="beunderstood">Next</a></li>
                    </ul>
                </nav> */}
                <Footer />
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default Blog
