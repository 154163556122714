import React from 'react'

export const useTranscriptionVisible = () => {
  const [transcriptionVisible, setTranscriptionVisible] = React.useState(
    window.innerWidth < 640
  )

  React.useEffect(() => {
    function handleWindowResize() {
      if (window.innerWidth > 640 && transcriptionVisible) {
        setTranscriptionVisible(false)
      } else if (window.innerWidth < 640 && !transcriptionVisible) {
        setTranscriptionVisible(true)
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [transcriptionVisible])

  return transcriptionVisible
}
