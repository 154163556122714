import React from 'react'

import GetMoteLink from '../shared/GetMoteLink'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class Educators extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>
          <div className="container">
            <div className="row justify-content-between align-items-center py-4 py-lg-5">
              <div className="col-lg-6 col-xl-6 mb-4">
                <h1 className="lg-display-4-mb-4-mt">
                  <b className="mote-purple">mote</b> helps educators connect
                  with students
                </h1>
                <h3>
                  We're proud to help teachers, lecturers and students to stay
                  in touch
                </h3>
                <div className="row ml-0 mr-0">
                  <GetMoteLink recipe="atc" />
                </div>
              </div>

              <div className="d-lg-none mb-2 justify-content-center">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/Educators.jpeg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>
                      Photo credit:{' '}
                      <a href="https://twitter.com/miss_mcelroy/">
                        https://twitter.com/miss_mcelroy/
                      </a>
                    </small>
                  </figcaption>
                </figure>
              </div>

              <div className="d-none d-lg-block col-lg-6 col-xl-5 mb-3 justify-content-center text-lg-left">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/Educators.jpeg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>
                      Photo credit:{' '}
                      <a href="https://twitter.com/miss_mcelroy/">
                        https://twitter.com/miss_mcelroy/
                      </a>
                    </small>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div
            className="bg-cover position-relative"
            style={{
              backgroundImage: 'url(images/logos/mote-background.png',
              backgroundSize: 'auto'
            }}
          >
            <div className="container mote-brand">
              <h3 className="h4 mb-4">
                Kind words we've heard about <b className="mote-purple">mote</b>{' '}
                from teachers
              </h3>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “I teach high school students, and I freaking LOVE this!!!
                      This is a game changer for me in soooo many ways (grading,
                      instructing, etc) Bravo!”
                    </p>
                    <cite className="text-uppercase">RH</cite>
                    <br></br>
                    <br></br>
                    <br></br>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “During this incredibly difficult time in our teaching
                      world this is a game changer. My students can hear my
                      voice and stay connected to me. My crew is only 7-8 years
                      old and they need that desperately. Thank you. I actually
                      started crying when I heard my voice back.“
                    </p>
                    <cite className="text-uppercase">KP</cite>
                  </blockquote>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "Mote is Lovely. It saves me so much time, even the small
                      things like this help to keep that personal connection
                      with my students while we're learning from home.”
                    </p>
                    <cite className="text-uppercase">Sarah Martin</cite>
                    <br></br>
                    <br></br>
                    <br></br>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “THIS IS AMAZING!!!! THANK YOU THANK YOU THANK YOU! It cut
                      my grading time to a fraction of what it has been because
                      I can just speak my corrections! Also my students are
                      loving hearing my voice! I will be sharing with ALL of my
                      fellow teachers!“
                    </p>
                    <cite className="text-uppercase">Cindy Lamphere</cite>
                  </blockquote>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "I was adding the feedback to a student assignment in
                      google docs - I've been trying to find something that
                      would work for DAYS. The unexpected shift to all online
                      teaching and learning has created lots of challenges and
                      frustrations. Being able to give voice feedback cuts down
                      my workload tremendously! And, it allows students to hear
                      my voice, and feel more connected. The included transcript
                      is ideal for diverse learners and those who have English
                      as an additional language. The transcript was surprisingly
                      accurate! Thank you so much for this app!!!”
                    </p>
                    <cite className="text-uppercase">
                      Emily Wickwire-Foster
                    </cite>
                    <br></br>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “I could CRY I am so excited about this extension!!!!!! I
                      have been able to give personal feedback on all 85 of my
                      kids' work today in under 90 minutes. I am THRILLED that
                      they can hear the tone of my voice when I am giving
                      feedback!! I can't wait to hear their reaction when they
                      can actually HEAR me critique their work. I CANNOT wait to
                      share this with my school leadership team and
                      colleagues!!! THANK YOU!!!!!!!!!
                      <span role="img" aria-hidden="true">
                        🙌
                      </span>
                      ”
                    </p>
                    <br></br>
                    <br></br>
                    <cite className="text-uppercase">Karen Ervin</cite>
                  </blockquote>
                </div>
              </div>
              <div className="row" style={{ padding: '2em 0em 1em 1em' }}>
                <h1 className="lg-display-4-mb-2-mt">
                  How can <b className="mote-purple">mote</b> help educators?
                </h1>
              </div>
              <h3>
                The <b className="mote-purple">mote</b> Chrome extension makes
                it easy to add voice note feedback for students within Google
                Docs, Google Classroom, Slides and Sheets.
              </h3>
              <div className="row py-2 mb-lg-3">
                <div className="col-md-4 mb-4">
                  <div className="text-center px-lg-3">
                    <span className="icon icon-primary icon-lg mb-3">
                      <i
                        style={{
                          padding: '12px 0px 0px 0px',
                          fontSize: '32pt'
                        }}
                        className="material-icons mote-purple"
                      >
                        speed
                      </i>
                    </span>
                    <h2 className="h4 text-left">Fast</h2>
                    <p className="mb-md-0 text-left">
                      We type around forty words per minute - but speak up to
                      three times faster.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="text-center px-lg-3">
                    <span className="icon icon-primary icon-lg mb-3">
                      <i
                        style={{
                          padding: '12px 0px 0px 0px',
                          fontSize: '32pt'
                        }}
                        className="material-icons mote-purple"
                      >
                        insert_emoticon
                      </i>
                    </span>
                    <h2 className="h4 text-left">Friendly</h2>
                    <p className="text-left mb-md-0">
                      Stay connected with students, even when you’re no longer
                      able to be together in the classroom.{' '}
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="text-center px-lg-3">
                    <span className="icon icon-primary icon-lg mb-3">
                      <i
                        style={{
                          padding: '12px 0px 0px 0px',
                          fontSize: '32pt'
                        }}
                        className="material-icons mote-purple"
                      >
                        subject
                      </i>
                    </span>
                    <h2 className="h4 text-left">Flexible</h2>
                    <p className="text-left mb-md-0">
                      Opt to transcribe your voice notes, so that students can
                      choose to read, listen or do both.
                    </p>
                  </div>
                </div>
              </div>

              <div className="container py-1">
                <div className="text-center">
                  <h2 className="mb-4">Are you ready to start?</h2>
                  <GetMoteLink recipe="atc" />
                </div>
              </div>

              <div className="container mb-lg-3"></div>

              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Educators
