import { error } from '@mote/common'

import {
  ANALYTICS_PARAMS_BANLIST,
  snakify,
  getUserId,
  getUserIdMaybeAnonymous
} from '../shared/Auth'
import { BASE_URL } from '../shared/Constants'
import { noLog as log } from '../shared/Utils'

const axios = require('axios').default

const AXIOS = axios.create({ baseURL: BASE_URL + '/', timeout: 12000 })

const REFERRER_KEY = 'mote_referrer_user_id'
const CWS_FUNNEL = 'mote_cws_funnel'

function persistReferrer(userId) {
  if (userId) {
    log('Setting referrer to: ', userId)
    window.localStorage.setItem(REFERRER_KEY, userId)
  } else {
    log('Clearing referrer')
    window.localStorage.removeItem(REFERRER_KEY)
  }
}

function getReferrer() {
  var userId = window.localStorage.getItem(REFERRER_KEY)
  if (!userId) {
    return ''
  } else {
    return userId
  }
}

function trackCWSInstallButton() {
  log('Click to CWS!!!!!!!!')
  const referrer = window.localStorage.getItem(REFERRER_KEY)
  var o = {}
  if (referrer) {
    o.referrer_user_id = referrer
  }
  track('Install Extension Clicked', o)
}

function trackCWSFunnelBegin() {
  const referrer = window.localStorage.getItem(REFERRER_KEY)
  const cws_funnel = window.localStorage.getItem(CWS_FUNNEL)
  if (cws_funnel) {
    return
  }
  var o = {}
  if (referrer) {
    o.referrer_user_id = referrer
  }
  track('First Return from CWS', o)
  window.localStorage.setItem(CWS_FUNNEL, 'a')
}

function _trackSchoolPurchaseFunnel(name, failure, o) {
  //   log("0 received request to log " + name, o);
  if (!o) {
    o = {}
  }
  var oo = Object.assign({}, o)
  //   log("0.5 intermeditate: ", oo);
  oo.stepName = name
  log('1 intermeditate: ', oo)
  if (failure) {
    oo.isFailure = true
  } else {
    oo.isFailure = false
  }
  delete oo.sku
  delete oo.currencies
  delete oo.institutionData
  delete oo.currencySymbols
  delete oo.coupons
  delete oo.products
  delete oo.renderMode
  var userId = getUserId()
  if (userId) {
    oo.userId = userId
  }

  log('2 about to log' + name, oo)
  oo.isFailureStep = failure
  track('School Purchase Funnel', oo)
}

function trackSchoolPurchaseFunnelStep(name, o) {
  _trackSchoolPurchaseFunnel(name, false, o)
}

function trackSchoolPurchaseFunnelFailure(name, o) {
  _trackSchoolPurchaseFunnel(name, true, o)
}

function trackCWSFunnelEnd() {
  const referrer = window.localStorage.getItem(REFERRER_KEY)
  const cws_funnel = window.localStorage.getItem(CWS_FUNNEL)
  if (cws_funnel === 'z') {
    return
  }
  var o = {}
  if (referrer) {
    o.referrer_user_id = referrer
  }
  track('First Sign-in after CWS', o)
  window.localStorage.setItem(CWS_FUNNEL, 'z')
}

function postErrorEvent(title, key, text, tags) {
  var default_tags = ['web:1', 'user_visible_error:1', key + ':1']
  if (!tags) {
    tags = []
  }
  var output_tags = default_tags.concat(tags)
  text += '\n\nCurrent URL: ' + window.location.href
  const o = { title: title, key: key, text: text, tags: output_tags }

  log('Going to send: ' + title + ' -> ' + text, o)
  try {
    var url = window.location.href.toLowerCase()
    if (url.includes('http://loc')) {
      log(
        'Oops, sending this error to Segment and Datadog would be too noisy (as we are on loc)'
      )
      return
    }
  } catch (e) {
    error('Errored trying to parse URL: ', e)
  }
  track('Error Seen on Web: ' + title, {
    error_key: key,
    error_text: text
  })
  AXIOS.post('d/error', o)
    .then((r) => log('Successfully sent error event: ', o))
    .catch((e) => error('Could not send error event: ', o, e))
}

export const identify = (userId, o) => {
  var direct = bypassSegment()
  const oo = enrichTrackingData(safeAnalyticsData(o), direct)
  if (direct) {
    const ooo = Object.assign(oo, { userId: userId })
    AXIOS.post('s/identify/everything', ooo).catch((e) =>
      error('Failed to send analytics', e, ooo)
    )
  } else if (window.analytics) {
    window.analytics.identify(o.userId, oo)
  } else {
    error('no way to send analytics data: ', oo)
  }
}

export const track = async (event, o) => {
  if (!o) {
    o = {}
  }
  o['page_context'] = window.location.href

  var direct = bypassSegment()
  const oo = enrichTrackingData(safeAnalyticsData(o), direct)
  const userId = getUserIdMaybeAnonymous()
  oo['userId'] = userId
  if (direct) {
    const ooo = Object.assign({ event: event }, oo)
    log('about to track', ooo)

    try {
      await AXIOS.post('s/track/everything', ooo)
    } catch (e) {
      error('Failed to track analytics', e, ooo)
    }
  } else if (window.analytics) {
    window.analytics.track(event, oo)
  } else {
    error('no way to send analytics data: ', oo)
  }
}

function bypassSegment() {
  return true
  // const host = window.location.host;
  // if(host.indexOf("dev.") > -1 ) {
  //     return true;
  // } else if(host.indexOf("loc") > -1 ) {
  //     return true;
  // } else if (window.location.indexOf("/labs") > -1 ) {
  //     return true
  // } else {
  //     return false;
  // }
}

function enrichTrackingData(o, direct) {
  var oo = Object.assign({}, o)
  oo['tracking_source'] = 'website'
  if (direct) {
    oo['tracking_route'] = 'direct'
  } else {
    oo['tracking_route'] = 'segment'
  }
  return oo
}

function safeAnalyticsData(o) {
  // log("Received: ", o);
  var oo = Object.assign({}, o)
  for (const key of ANALYTICS_PARAMS_BANLIST) {
    if (key in oo) {
      oo[key] = undefined
    }
  }
  if (oo.primaryEmailAddress) {
    const email = oo.primaryEmailAddress
    oo.primaryEmailAddress = undefined
    oo['email'] = email
    const atSymbolIndex = email.indexOf('@')
    if (atSymbolIndex > 0 && email.length > atSymbolIndex + 1) {
      oo['email_domain'] = email
        .slice(atSymbolIndex + 1, email.length)
        .toLowerCase()
    }
  }
  if (
    oo.isMinor === true ||
    oo.isMinor === 'true' ||
    oo.isMinor === 'True' ||
    oo.isMinor === '1' ||
    oo.isMinor === 1
  ) {
    oo.isMinor = '1'
  } else {
    oo.isMinor = '0'
  }
  // log("Sending to snake_case: ", oo);
  return snakify(oo)
}

export {
  getReferrer,
  persistReferrer,
  postErrorEvent,
  trackCWSFunnelBegin,
  trackCWSFunnelEnd,
  trackCWSInstallButton,
  trackSchoolPurchaseFunnelFailure,
  trackSchoolPurchaseFunnelStep
}
