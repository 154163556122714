import React from 'react'
import PropTypes from 'prop-types'
import tw, { styled, css } from 'twin.macro'

import { MoteWord } from '@mote/config/defaults/typography'
import MoteLogoExtraSmall from '@mote/assets/svgs/logo/MoteLogoExtraSmall'
import { Localized } from '@fluent/react'

Title.propTypes = {
  username: PropTypes.string,
  isPreparing: PropTypes.bool,
  isPrivateComments: PropTypes.bool,
  isLoading: PropTypes.bool
}

Title.defaultProps = {
  username: 'Someone',
  isPreparing: false,
  isPrivateComments: false,
  isLoading: false
}

const TitleContainer = styled.div(() => [
  tw`flex w-10/12 p-0 text-sm text-gray-600`,
  css`
    align-items: start;
  `
])
const TitleLogo = styled.div(() => [tw`mr-2`])
const TitleText = styled.div(() => [
  tw`max-w-full space-x-1 whitespace-pre truncate`,
  `
    font-size: 16px;
  `
])

function Title({ username, isPreparing, isPrivateComments, isLoading }) {
  const titleContainerRef = React.useRef(null)

  return (
    <TitleContainer ref={titleContainerRef}>
      <TitleLogo>
        <MoteLogoExtraSmall />
      </TitleLogo>
      {!isPreparing && !isPrivateComments && !isLoading && (
        <Localized id="moter-sent-you-mote" vars={{ moter: username }}>
          <TitleText>{'from {$moter}'}</TitleText>
        </Localized>
      )}
      {!isPrivateComments && isLoading && (
        <TitleText>
          <span>Loading your</span>
          <MoteWord>mote</MoteWord>
          ...
        </TitleText>
      )}
      {isPreparing && (
        <TitleText>
          Preparing your <MoteWord>mote</MoteWord>
          ...
        </TitleText>
      )}
      {!isPreparing && isPrivateComments && (
        <TitleText>
          <MoteWord>mote</MoteWord>
        </TitleText>
      )}
    </TitleContainer>
  )
}

export default Title
