import React from 'react'

function GlobeGray() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8947 4.91759C10.4253 2.02851 9.318 0 8.0294 0C6.74081 0 5.63353 2.02851 5.16407 4.91759H10.8947ZM4.92125 8.02344C4.92125 8.74166 4.9601 9.43077 5.02809 10.094H11.0275C11.0955 9.43077 11.1343 8.74166 11.1343 8.02344C11.1343 7.30521 11.0955 6.6161 11.0275 5.95287H5.02809C4.9601 6.6161 4.92125 7.30521 4.92125 8.02344ZM15.4339 4.91759C14.508 2.72085 12.6334 1.02234 10.3184 0.336467C11.1084 1.42998 11.6524 3.07673 11.9373 4.91759H15.4339ZM5.73714 0.336467C3.42545 1.02234 1.5476 2.72085 0.624869 4.91759H4.12155C4.40322 3.07673 4.94715 1.42998 5.73714 0.336467ZM15.7804 5.95287H12.0668C12.1348 6.63228 12.1736 7.32786 12.1736 8.02344C12.1736 8.71902 12.1348 9.4146 12.0668 10.094H15.7771C15.9552 9.43077 16.0556 8.74166 16.0556 8.02344C16.0556 7.30521 15.9552 6.6161 15.7804 5.95287ZM3.8852 8.02344C3.8852 7.32786 3.92405 6.63228 3.99204 5.95287H0.278439C0.103605 6.6161 0 7.30521 0 8.02344C0 8.74166 0.103605 9.43077 0.278439 10.094H3.9888C3.92405 9.4146 3.8852 8.71902 3.8852 8.02344ZM5.16407 11.1293C5.63353 14.0184 6.74081 16.0469 8.0294 16.0469C9.318 16.0469 10.4253 14.0184 10.8947 11.1293H5.16407ZM10.3217 15.7104C12.6334 15.0245 14.5112 13.326 15.4372 11.1293H11.9405C11.6556 12.9701 11.1117 14.6169 10.3217 15.7104ZM0.624869 11.1293C1.55084 13.326 3.42545 15.0245 5.74038 15.7104C4.95039 14.6169 4.40646 12.9701 4.12155 11.1293H0.624869Z"
        fill="#676E78"
      />
    </svg>
  )
}

export default GlobeGray
