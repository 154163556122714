import React from 'react'
import { styled, css } from 'twin.macro'

export const ButtonV2 = ({
  variant = 'primary',
  text,
  icon,
  htmlType = 'button',
  className = '',
  onClick,
  disabled = false,
  iconPosition = 'left'
}) => {
  return (
    <Root
      disabled={disabled}
      variant={variant}
      type={htmlType}
      className={className}
      onClick={onClick}
    >
      {icon && iconPosition === 'left' && (
        <IconContainer iconPosition={iconPosition}>{icon}</IconContainer>
      )}
      <Text variant={variant}>{text}</Text>
      {icon && iconPosition === 'right' && (
        <IconContainer iconPosition={iconPosition}>{icon}</IconContainer>
      )}
    </Root>
  )
}

const Root = styled.button`
  font-family: DM Sans;
  display: inline-flex;
  flex: 0 0 auto;
  outline: none;
  cursor: pointer;
  user-select: none;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  min-width: 180px;
  height: 48px;
  border-radius: 4px;
  border: none;

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      background: #ac0ae8;

      &:hover {
        background: #9b09d1;
      }

      &:active {
        background: #7807a2;
      }
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background: rgba(172, 10, 232, 0.1);
      color: rgba(172, 10, 232, 1);

      &:hover {
        background: #9b09d1;

        span {
          color: white;
        }
      }

      &:active {
        background: #7807a2;

        span {
          color: white;
        }
      }
    `}

  ${({ variant }) =>
    variant === 'ghost' &&
    css`
      background: rgba(255, 255, 255, 0.1);

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &:active {
        background: rgba(255, 255, 255, 0.4);
      }
    `}

  ${({ variant }) =>
    variant === 'link' &&
    css`
      padding: 0;
      color: white;
      min-width: unset;
      background: unset;
      height: unset;
      justify-content: flex-start;

      &:hover {
        span {
          color: rgba(255, 255, 255, 0.6);
        }
      }

      &:active {
        span {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    `}
  
    &[disabled] {
    cursor: not-allowed;
    background-color: #eaeaed;

    span {
      color: #969696;
      cursor: not-allowed;
    }
  }
`

const Text = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  text-align: center;

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      color: #ac0ae8;
    `}

  ${({ variant }) =>
    variant === 'ghost' &&
    css`
      color: white;
    `}
`

const IconContainer = styled.span`
  ${({ iconPosition }) =>
    iconPosition === 'left' &&
    `
  margin-right: 14px;
  
  `}

  ${({ iconPosition }) =>
    iconPosition === 'right' &&
    `
  margin-left: 14px;
  
  `}
`
