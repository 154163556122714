import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'
// import GetMoteLink from '../shared/GetMoteLink';
import TrackedDownloadLink from '../shared/TrackedDownloadLink'

class PrivacyCenter extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <div className="container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <article className="pb-2">
                  <h1 className="text-center">Privacy Center</h1>
                  <p className="text-center lead">
                    Protecting your right to privacy is our priority.
                  </p>

                  <h2 className="h3 mt-4">A message from our team.</h2>
                  <p>
                    One of the best things about starting a new company is being
                    able to design - both in your products and your company
                    culture - the things you care about, and the values you
                    hold.
                  </p>
                  <p>
                    When we started Mote, we knew we wanted to build a product
                    that we would love to use ourselves, and that we hoped
                    others would find valuable. We also wanted a business model
                    that supported the kinds of business practices that we
                    believe in, and that wouldn't distract us from focusing on
                    the most important people: you, our users.
                  </p>
                  <p>
                    For us, making privacy a core part of our product and
                    company culture was an easy decision: it's what we would
                    want from any product, any company we work with. Mote has
                    been developed by our small team working in the United
                    States and the United Kingdom. Mote is a US company, but we
                    are trying hard to think globally and embrace users from all
                    over the world.
                  </p>

                  <p>
                    We don’t own your content, and we'll never show you an ad or
                    hand over your information to anyone who intends on doing
                    so. Our business model is simple: we make money from selling
                    subscriptions. That’s it.
                  </p>

                  <p>
                    Should you have any questions or concerns, please feel free
                    to email us at support@mote.com.
                  </p>

                  <p>Happy moting,</p>

                  <p className="lead">The team at Mote</p>

                  <div className="IKS-seals">
                    <img
                      className="IKS-seal"
                      src="images/badges/iKS_COPPA.png"
                      alt="iKeepSafe COPPA seal"
                    />
                    <img
                      className="IKS-seal"
                      src="images/badges/iKS_FERPA.png"
                      alt="iKeepSafe FERPA seal"
                    />
                    <img
                      className="IKS-seal"
                      src="images/badges/iKS_CSPC.png"
                      alt="iKeepSafe CSPC seal"
                    />
                  </div>

                  <div className="container justify-content-center">
                    <p>
                      <a
                        className="mote-boiler-link"
                        href="https://ikeepsafe.org/"
                      >
                        iKeepSafe
                      </a>{' '}
                      has certified Mote as fully compliant with COPPA, FERPA
                      and CSPC. This is the most secure level of certification
                      for educational technology products.
                    </p>
                  </div>
                  <p>
                    For more information, check out our{' '}
                    <a className="mote-boiler-link" href="/privacy">
                      Privacy Policy
                    </a>
                    ,{' '}
                    <a
                      className="mote-boiler-link"
                      href="/privacy/dataSecurity"
                    >
                      Data Security Policy
                    </a>
                    , and{' '}
                    <a className="mote-boiler-link" href="/ToS">
                      Terms of Service
                    </a>
                    .
                  </p>

                  <h3 className="text-left">GDPR in the UK</h3>
                  <p>
                    For schools in the United Kingdom, we have a GDPR-compliant
                    data processing agreement (DPA) template. If you would like
                    to put a DPA in place, please complete the template linked
                    below, and email to{' '}
                    <a href="mailto:schools@justmote.me">schools@justmote.me</a>{' '}
                    for counter-signature.
                  </p>
                  <TrackedDownloadLink
                    href="/docs/UK-GDPR.pdf"
                    label="Get DPA template"
                    title="DPA for UK schools"
                  />
                </article>
              </div>
            </div>

            {/* <div className="container py-1">
                                <div className="text-center">
                                    <div className="d-none d-md-block">
                                        <h2 className="mb-4">Are you ready to start?</h2>
                                        <GetMoteLink recipe="atc" />
                                    </div>
                                </div>
                            </div> */}
            <div className="container py-1">
              <div className="text-center">
                <div className="d-block d-md-none">
                  <h2 className="mb-4">
                    Try <b className="mote-purple">mote</b>
                  </h2>
                  <a href="/chrome" className="btn btn-primary mr-2">
                    Send me a link
                  </a>
                </div>
              </div>
            </div>
            <div className="container mb-lg-3"></div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyCenter
