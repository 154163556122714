import React from 'react'

const MoteLogoSmall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 29 29"
    className={props.className}
    style={props.style}
  >
    <path
      className="background"
      fill="#AC0AE8"
      d="M14.5 29C22.508 29 29 22.508 29 14.5S22.508 0 14.5 0 0 6.492 0 14.5 6.492 29 14.5 29z"
    />
    <path
      className="logo-shape"
      fill="#fff"
      d="M10.072 21.677c.01-.397 0-9.433 0-9.433l-5.362 5.39s-.447.45-1.042.6c-1.34.299-1.937-.6-1.937-.6l4.17-4.192v-1.796s0-.899.15-1.198c.149-.3.447-.6.447-.6l6.404-6.587s1.34-.898 2.532-.15c1.192.749 1.043 2.096 1.043 2.096v8.984l4.915-4.941s.894-1.048 2.234-.45c1.341.6 1.341 1.947 1.341 1.947v5.24s.149 1.198.596 1.647c.447.45 1.49.6 1.49.6l-6.405 6.438s-.745 0-1.49-.6c-.745-.598-.596-1.646-.596-1.646v-4.342s-4.849 4.975-5.213 5.24c-.364.265-.905.374-1.936.15s-1.35-1.4-1.34-1.797z"
    />
  </svg>
)

export default MoteLogoSmall
