import React from 'react'
import { useGenericRequestHandler } from '../../handlers/useGenericRequestHandler'

export const useMotesCountQuery = () => {
  return useGenericRequestHandler({
    route: 's/stats',
    method: 'GET',
    clientType: 'public'
  })
}
