import { v4 as uuidv4 } from 'uuid'

import { error } from '@mote/common'

import { getReferrer, identify, track } from '../shared/AnalyticsHelpers'
import {
  BASE_URL,
  GOOGLE_CLIENT_ID,
  REDIRECT_URI,
  NETWORK_TIMEOUT
} from '../shared/Constants'
import {
  log as _log,
  reallyTruthy,
  getTZOffset,
  getGoodHostname,
  isDevForceLogin,
  coinFlip
} from '../shared/Utils'

function log(m, ...o) {
  const prefix = 'Auth/' + m
  _log(prefix, ...o)
}

const axios = require('axios').default
var AXIOS = axios.create({
  baseURL: BASE_URL + 'i/',
  timeout: NETWORK_TIMEOUT
})

const MAX_LOGIN_RETRIES = 3
// error('Initing with google client id: ' + GOOGLE_CLIENT_ID);

const STORAGE_TIMESTAMP = 'mote-lastAuth'
const STORAGE_PREFIX_KEYS = 'mote-auth-'
const PERSONAL_SCOPE_STORAGE_PREFIX = 'mote-personal-scopes-'
// const GENERAL_SCOPE_STORAGE_KEY= "mote-general-scopes";
const ANONYMOUS_PREFIX = 'anonymous:from_website:'

const MANDATORY_PARAMS = [
  'accessToken',
  'userId',
  'primaryEmailAddress',
  'createdAt'
]
const PARAMS = MANDATORY_PARAMS.concat([
  'name',
  'givenName',
  'familyName',
  'imageURL',
  'legals',
  'isMinor',
  'countryCode',
  'inMarketBy',
  'productStates',
  'quotaResetsAt',
  'acquiredScopes',
  'TZOffset',
  'anonymousUserId',
  'anonymousCreatedAt',
  'referrerCode',
  'lastTranslationLanguage',
  'canUseBook',
  'hitBookLimit',
  'needsSurvey',
  'institutionNameHints',
  'preferredLocale'
])
export const ANALYTICS_PARAMS_BANLIST = [
  'accessToken',
  'userId',
  'imageURL',
  'quotaResetsAt',
  'productStates',
  'acquiredScopes',
  'anonymousUserId',
  'anonymousCreatedAt'
]
const DEFAULT_SCOPES = ['profile', 'email']
const VIRTUAL_SCOPES = {
  'pseudo:google_slides_audio_insert': [
    'https://www.googleapis.com/auth/drive.file',
    'https://www.googleapis.com/auth/presentations.readonly'
  ]
}
const SUPPORTED_SCOPES = DEFAULT_SCOPES.concat(Object.keys(VIRTUAL_SCOPES))

const REFERRER_KEY = 'referrerUserId'

// Extension ID whitelist (web app only communicates with whitelisted extensions)
const CANDIDATE_EXTENSIONS = [
  'ajphlblkfpppdpkgokiejbjfohfohhmk', // Production
  'kbeknjjgcngcoeelocjmjefgmkkmemgh', // Development
  'pjdhnjalalmadopdnagiacmmpdgfkggi' // Debug
]

var EXTENSION_FOUND_CALLBACKS = []

var AUTH_LISTENERS = { success: [], failure: [] }

export const getUserIdMaybeAnonymous = () => {
  const o = readAllDataInStorage()
  if (o.userId) {
    return o.userId
  }
  if (!o.anonymousUserId) {
    makeAnonymousUserId(o)
  }
  return o.anonymousUserId
}

function makeAnonymousUserId(o) {
  o.anonymousUserId = ANONYMOUS_PREFIX + uuidv4()
  o.anonymousCreatedAt = Math.floor(Date.now() / 1000)
  window.localStorage.setItem(
    STORAGE_PREFIX_KEYS + 'anonymousUserId',
    o.anonymousUserId
  )
  window.localStorage.setItem(
    STORAGE_PREFIX_KEYS + 'anonymousCreatedAt',
    o.anonymousCreatedAt
  )
  return o
}

export const getDeviceIdOrAccessTokenHash = () => {
  const o = readAllDataInStorage()
  if (o.accessToken) {
    return { accessToken: getAccessToken() }
  } else {
    if (!o.anonymousUserId) {
      makeAnonymousUserId(o)
    }
    return { deviceId: o.anonymousUserId }
  }
}

export const addExtensionFoundListener = (f) => {
  EXTENSION_FOUND_CALLBACKS.push(f)
}

export const afterMoteAuth = (success, failure, options) => {
  if (!options) {
    options = {}
  }
  if (options.addToListeners) {
    _addToAuthListeners(success, failure)
  }
  if (!_isAllMandatoryDataInStorage()) {
    // error('Oh dear, missing some params in storage');
    _notifyAuthListeners(failure, false)
  } else {
    _reAuth(success, failure, options)
  }
}

export const identifyMaybe = () => {
  const o = readAllDataInStorage()
  if (o.userId && o.accessToken && o.primaryEmailAddress && o.name) {
    identify(o.userId, o)
  }
  return o
}

function _broadcastLoginWithDecoration(o) {
  if (o) {
    var oo = Object.assign({}, o)
    if (oo.acquiredScopes) {
      oo.acquiredScopes = _decorateWithVirtual(oo.acquiredScopes)
    }
    _broadcastToExtensions('login', oo)
  }
}

function _decorateWithVirtual(scope) {
  if (!scope) {
    return DEFAULT_SCOPES.join(' ')
  } else {
    var newScopes = scope.split(' ')
    const vScopeKeys = Object.keys(VIRTUAL_SCOPES)
    log('_decorate: vScopekeys:' + vScopeKeys)
    for (let i = 0; i < vScopeKeys.length; i++) {
      const vScope = vScopeKeys[i]
      const vScopeList = VIRTUAL_SCOPES[vScope]
      log('_decorate: checking :' + vScope + ' which has: ' + vScopeList)
      var matches = {}
      for (let j = 0; j < vScopeList.length; j++) {
        for (let k = 0; k < newScopes.length; k++) {
          log('_decorate: comparing:' + vScopeList[j] + ' to ' + newScopes[k])
          if (vScopeList[j] === newScopes[k]) {
            log('_decorate: we have a hit!')
            matches[newScopes[k]] = 1
          }
        }
      }
      log('_decorate: matches are !' + Object.keys(matches))
      if (Object.keys(matches).length === vScopeList.length) {
        log("_decorate: yay, we're virtual, adding: " + vScope)
        newScopes.push(vScope)
      }
    }
    return newScopes.join(' ')
  }
}

export const identifyMaybeAndBroadcast = () => {
  const o = identifyMaybe()
  _broadcastLoginWithDecoration(o)
}

export const snakify = (src) => {
  // log('Input object', o);
  var o = Object.assign({}, src)
  for (var key of Object.keys(o)) {
    var value = o[key]
    // log(`stashing away ${key} - ${value}`);
    var currentKey = key
    // log('checking: ' + currentKey + ' with ' + value);
    while (true) {
      const found = currentKey.match(/^([a-z_]+)([A-Z][a-z]+)([A-Z][a-z]+)?/)
      if (!found) {
        if (currentKey === 'imageURL') {
          o[currentKey] = undefined
          o['image_URL'] = value
        } else if (currentKey === 'TZOffset') {
          o[currentKey] = undefined
          o['TZ_offset'] = value
        }
        break
      } else {
        // log('processing: ' + currentKey + ' with ' + value);
        o[currentKey] = undefined
        var camel = found[2]
        if (camel !== 'URL') {
          camel = camel.toLowerCase()
        }
        var suffix = found[3]
        if (!suffix) {
          suffix = ''
        }
        currentKey = found[1] + '_' + camel + suffix
        // log(`Reassigning ${currentKey} to ${value}`);
        o[currentKey] = value
      }
    }
  }
  // log("snake_case results", o);
  return o
}

function _isAllMandatoryDataInStorage() {
  for (var key of MANDATORY_PARAMS) {
    var value = window.localStorage.getItem(STORAGE_PREFIX_KEYS + key)
    if (!value) {
      // log("Missing value from storage: " + key);
      return false
    }
  }
  return true
}

export const readAllDataInStorage = () => {
  var o = {}
  for (var key of PARAMS) {
    o[key] = window.localStorage.getItem(STORAGE_PREFIX_KEYS + key)
    if (['productStates', 'institutionNameHints'].includes(key)) {
      try {
        const value = JSON.parse(o[key])
        o[key] = value
      } catch (e) {
        error('could not parse:', key, o[key])
      }
    } else if (o[key] === 'true') {
      o[key] = true
    } else if (o[key] === 'false') {
      o[key] = false
    }
  }
  o[REFERRER_KEY] = getReferrer()
  // log("read all data in storage: ", o);
  return o
}

export const getMaximalScopes = (scope) => {
  log('getMaximalScopes: Setting maximal scopes for: ' + scope)

  var scopesStr = getCurrentScope() || DEFAULT_SCOPES.join(' ')
  log('getMaximalScopes: found this existing scopesStr: ', scopesStr)
  log('getMaximalScopes: its type is: ', typeof scopesStr)
  if (!scopesStr) {
    log('getMaximal: empty scopeStr - setting to empty str')
    scopesStr = ''
  }
  var scopes = []

  // add if valid scope
  log('getMaximalScopes: passed scope was: ' + scope)
  if (scope && SUPPORTED_SCOPES.includes(scope)) {
    scopes = scopesStr.split(' ')
    if (!scopes.includes(scope)) {
      scopes.push(scope)
      scopesStr = scopes.join(' ')
    }
  }
  // ensure scopes are valid
  if (scopesStr) {
    scopes = scopesStr.split(' ')
    var cleanScopes = []
    scopes.forEach((s) => {
      if (SUPPORTED_SCOPES.includes(s)) {
        cleanScopes.push(s)
      }
    })
    scopesStr = cleanScopes.join(' ')
  }

  // ensure we have at least default scopes
  if (!scopesStr) {
    log('getMaximalScopes: empty scopes - setting to ' + DEFAULT_SCOPES)
    scopesStr = DEFAULT_SCOPES.join(' ')
  }
  return _googliseScopes(scopesStr)
}

function _googliseScopes(scopesStr) {
  log('_googliseScopes: rendering scopes for google from: ' + scopesStr)
  const scopes = scopesStr.split(' ')
  const googleScopes = []
  scopes.forEach((scope) => {
    if (DEFAULT_SCOPES.includes(scope)) {
      log('_googliseScopes: I am default scope: ' + scope)
      googleScopes.push(scope)
    } else if (VIRTUAL_SCOPES[scope]) {
      log(
        '_googliseScopes: I am virtual scope: ' + scope + ': ',
        VIRTUAL_SCOPES[scope]
      )
      VIRTUAL_SCOPES[scope].forEach((virtual) => {
        log(
          '_googliseScopes: after split, adding this virtual scope: ' + virtual
        )
        googleScopes.push(virtual)
      })
    }
  })
  log('_googliseScopes: sending to google scopes: ' + googleScopes)
  return googleScopes.join(' ')
}

export const persistAcquiredScopes = (o) => {
  const email = getEmail()
  const scope = getCurrentScope()
  if (email && scope) {
    log('_persistAcquiredScopes: we can set a personal scope: ' + scope)
    window.localStorage.setItem(PERSONAL_SCOPE_STORAGE_PREFIX + email, scope)
  }
}

function _persistDataToStorage(o) {
  for (var key of PARAMS) {
    if (!['anonymousUserId', 'anonymousCreatedAt'].includes(key)) {
      var value = o[key]
      if (['productStates', 'institutionNameHints'].includes(key)) {
        value = JSON.stringify(value)
      }
      window.localStorage.setItem(STORAGE_PREFIX_KEYS + key, value)
    }
  }
  window.localStorage.setItem(STORAGE_TIMESTAMP, Date.now() / 1000)
}

function _deleteAllDataInStorage() {
  for (var key of PARAMS) {
    if (
      !['anonymousUserId', 'anonymousCreatedAt', 'acquiredScopes'].includes(key)
    ) {
      window.localStorage.removeItem(STORAGE_PREFIX_KEYS + key)
    }
  }
}

export function _persistBroadcastIdentify(o, skipIdentify = false) {
  log('_persistBroadcastIdentify: sending this data to storage', o)
  _persistDataToStorage(o)
  persistAcquiredScopes(o)
  _broadcastLoginWithDecoration(o)

  if (skipIdentify) return

  identify(o.userId, o)
}

function _reAuth(success, failure, options) {
  var storage = readAllDataInStorage()

  if (options.applyDefaultState) {
    if (storage.accessToken) {
      _notifyAuthListeners(success, true)
    } else {
      _notifyAuthListeners(failure, false)
    }
  }

  var storageWithTZOffsetAndHostname = Object.assign(
    {
      TZOffset: getTZOffset(),
      lastLoginHostname: window.location.hostname
    },
    storage
  )
  log('_reAuth: Attempt to signIn/AccessToken')
  AXIOS.post('signIn/AccessToken', storageWithTZOffsetAndHostname)
    .then((response) => {
      var o = response.data
      if (_isValidResponse(o)) {
        // log("We got a valid response: " + JSON.stringify(o));
        _persistBroadcastIdentify(o)
        _notifyAuthListeners(success, true)
        broadcastLastLoginHostname()
      } else {
        error('Could not use mote access token: ' + JSON.stringify(o))
        _notifyAuthListeners(failure, false)
      }
    })
    .catch((e) => {
      error(
        'Invalid response on signing in with accessToken: ',
        storageWithTZOffsetAndHostname,
        e
      )
      _notifyAuthListeners(failure, false)
    })
}

export const postSurvey = (survey, success, failure) => {
  if (isDevForceLogin()) {
    setTimeout(() => {
      if (coinFlip()) {
        // log("yay, success!");
        success()
      } else {
        // log("boo, failure");
        failure()
      }
    }, 2000)
  } else {
    var toSend = Object.assign(
      {},
      {
        accessToken: getAccessToken(),
        survey: survey
      }
    )

    // log("Writing to survey");
    AXIOS.post('survey', toSend)
      .then((response) => {
        var received = response.data
        // log("got survey response: ", received);
        if (_isValidResponse(received)) {
          _persistBroadcastIdentify(received)
          success()
        } else {
          error('weird, invalid response')
          failure()
        }
      })
      .catch((e) => {
        error('Weird, invalid response on survey', e)
        failure()
      })
  }
}

function _isValidResponse(o) {
  for (var key of MANDATORY_PARAMS) {
    if (!o[key]) {
      // log(`Missing [${key}] from `, o);
      return false
    }
  }
  return true
}

function _addToAuthListeners(success, failure) {
  AUTH_LISTENERS.success.push(success)
  AUTH_LISTENERS.failure.push(failure)
}

function _notifyAuthListeners(callback, isSuccess) {
  callback()
  var listeners
  if (isSuccess) {
    listeners = AUTH_LISTENERS.success
  } else {
    listeners = AUTH_LISTENERS.failure
  }
  // log(`we found these listeners on ${isSuccess}: ${listeners}`);
  for (const f of listeners) {
    try {
      f()
    } catch (e) {
      // error(`failure notifying ${isSuccess} to a listener`);
    }
  }
}

export const loginWith = (o, success, failure, count) => {
  if (!count) {
    count = 0
  }
  var oo = Object.assign({}, o, {
    authCode: o.code,
    identityProvider: 'google'
  })
  oo[REFERRER_KEY] = getReferrer()
  oo.isMinor =
    o.isMinor === 1 ||
    o.isMinor === '1' ||
    o.isMinor === true ||
    o.isMinor === 'true'
      ? true
      : false
  oo['TZOffset'] = getTZOffset()
  oo['lastLoginHostname'] = window.location.hostname
  if (oo.newScope) {
    oo.scope = oo.newScope
  }
  log('loginWinth: About to send to signIn/IDPAuthCode: ', oo)
  AXIOS.post('signIn/IDPAuthCode', oo)
    .then((response) => {
      var obj = response.data
      if (_isValidResponse(obj)) {
        log('loginWith: We got a valid response!', obj)
        _persistBroadcastIdentify(obj)
        _notifyAuthListeners(success, true)
        broadcastLastLoginHostname()
      } else {
        error('Invalid response from server: ', obj)
        _notifyAuthListeners(failure, false)
      }
    })
    .catch((e) => {
      if (count < MAX_LOGIN_RETRIES) {
        error(`Failed with authCode (attempts=${count}), going to retry: `, e)
        loginWith(o, success, failure, count + 1)
      } else {
        error('Invalid response on signing with accessToken: ', e)
        _notifyAuthListeners(failure, false)
      }
    })
}

export const probe = () => {
  _broadcastToExtensions('probe', {})
}

export const broadcastPreferredLocale = (selectedLanguage) => {
  _broadcastToExtensions('preferred_locale', {
    preferredLocale: selectedLanguage
  })
}

export const broadcastLastLoginHostname = () => {
  _broadcastToExtensions('last_login_hostname', {
    lastLoginHostname: window.location.hostname
  })
}

export const broadcastExternalWebsiteRedirection = (redirectionInfo) => {
  _broadcastToExtensions('redirect_to', redirectionInfo)
}

export const checkWildCardOriginsPermissions = () => {
  _broadcastToExtensions('check_wildcard_origins_permissions')
}

export const requestWildCardOriginsPermissions = () => {
  _broadcastToExtensions('request_wildcard_origins_permissions')
}

function _broadcastToExtensions(event, o) {
  if (!window.chrome) return

  for (var extensionId of CANDIDATE_EXTENSIONS) {
    try {
      var sendData = Object.assign({}, o, { extensionId: extensionId })
      // log(
      //   `_broadcastToExtensions: About to dispatch [${event}] to ${extensionId}: `,
      //   sendData
      // )
      window.chrome.runtime?.sendMessage(
        extensionId,
        { type: event, data: sendData },
        (response) => {
          if (response && response.extensionId) {
            console.log(
              `_broadcastToExtensions: Got valid response from background.js (${response.extensionId}): `,
              response
            )
            for (var f of EXTENSION_FOUND_CALLBACKS) {
              try {
                f(response)
              } catch (e) {}
            }
          }
        }
      )
    } catch (e) {}
  }
}

export const logout = async () => {
  try {
    track('Authentication: Log Out')
    _broadcastToExtensions('logout', {})
    var o = readAllDataInStorage()
    // log('Sending to signout: ',o);
    AXIOS.post('signOut', o)
      .then(() => {
        // log('Logout request succeeded');
      })
      .catch((e) => {
        error('Logout request failed', e)
      })
  } catch (error) {
    error('Some error signing out: ', error)
  }
  _notifyAuthListeners(() => {}, false)
  _deleteAllDataInStorage()
  if (window.analytics) {
    window.analytics.reset()
  } else {
    error('No analytics.js to reset()')
  }
}

export const getMoteLogin = () => {
  if (!isDevForceLogin()) {
    return readAllDataInStorage()
  } else {
    return {
      primaryEmailAddress: 'jane.doe@foobar.com',
      givenName: 'Jane',
      needsSurvey: coinFlip(),
      institutionNameHints: coinFlip() ? [] : ['Boise Elementary', 'Boise High']
    }
  }
}

export const setAccessToken = (newAccessToken) => {
  return window.localStorage.setItem(
    `${STORAGE_PREFIX_KEYS}accessToken`,
    newAccessToken
  )
}

export const getAccessToken = () => {
  var o = readAllDataInStorage()
  if (o.accessToken) {
    return o.accessToken
  } else {
    return ''
  }
}

export const getUserId = () => {
  var o = readAllDataInStorage()
  if (o.userId) {
    return o.userId
  } else {
    return ''
  }
}

export const getEmail = () => {
  var o = readAllDataInStorage()
  if (o.primaryEmailAddress) {
    return o.primaryEmailAddress
  } else {
    return ''
  }
}

export const getProductEnum = () => {
  var o = readAllDataInStorage()
  try {
    if (o.productStates && o.productStates[0].productEnum) {
      return o.productStates[0].productEnum
    }
  } catch (e) {}
  return ''
}

export const isPaid = () => {
  var o = readAllDataInStorage()
  try {
    if (o.productStates && o.productStates[0].isPaid) {
      return o.productStates[0].isPaid
    }
  } catch (e) {}
  return false
}

export const getUsersName = () => {
  var o = readAllDataInStorage()
  if (o.name) {
    return o.name
  } else {
    return ''
  }
}

export const getUsersEmail = () => {
  var o = readAllDataInStorage()
  if (o.primaryEmailAddress) {
    return o.primaryEmailAddress
  } else {
    return ''
  }
}

export const isMinor = () => {
  var o = readAllDataInStorage()
  return reallyTruthy(o.isMinor)
}

export const isValidScope = (scope) => {
  log('isValidScope: checking scope validity of: ' + scope)
  const result = scope && SUPPORTED_SCOPES.includes(scope)
  log('isValidScope? ' + result)
  return result
}

export const getLastPersonalScope = () => {
  const email = getEmail()
  if (email) {
    const scopesStr = window.localStorage.getItem(
      PERSONAL_SCOPE_STORAGE_PREFIX + email
    )
    if (scopesStr) {
      log(`getLastPersonalScope: we have a scope for ${email}: ${scopesStr}`)
      return scopesStr
    }
  }
  log(`getLastPersonalScope: no scope for email [${email}]`)
  return ''
}

export const getCurrentScope = () => {
  var o = readAllDataInStorage()
  if (o.userId && o.primaryEmailAddress && o.acquiredScopes) {
    return o.acquiredScopes
  } else {
    return ''
  }
}

export const isVanillaScopeSet = (scopesStr) => {
  if (!scopesStr) {
    scopesStr = getLastPersonalScope()
  }
  log('isVanillaScopeSet: got scopesStr: ' + scopesStr)

  const decorated = _decorateWithVirtual(scopesStr)
  if (decorated !== DEFAULT_SCOPES.join(' ') && decorated !== scopesStr) {
    log(
      'isVanillaScopeSet: decorated is different, returning false because: ' +
        decorated
    )
    return false
  }
  log('isVanillaScopeSet: yes, we are vanilla because of: ' + decorated)
  return true
}

export const hasScope = (scope) => {
  var scopesStr = getCurrentScope()
  log(`hasScope: checking , '${scope}' in ${scopesStr}`)
  if (scopesStr) {
    const scopes = scopesStr.split(' ')
    if (scopes.includes(scope)) {
      return true
    }
    const vss = VIRTUAL_SCOPES[scope]
    if (vss) {
      for (let i = 0; i < vss.length; i++) {
        if (!scopes.includes(vss[i])) {
          return false
        }
      }
      log(`hasScope: yay, '${scope}' is in ${scopesStr}`)
      return true
    }
  }
  log(`hasScope: falling through to false`)
  return false
}

export const getOAuthURL = (additionalScope, state) => {
  var url = 'https://accounts.google.com/o/oauth2/auth?'
  var scope = getMaximalScopes(additionalScope)
  const o = {
    client_id: GOOGLE_CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    response_type: 'code',
    prompt: 'consent',
    scope: scope,
    access_type: 'offline'
  }
  // if (additionalScope) {
  //  o["include_granted_scopes"] = true;
  // }
  state.newScope = scope
  const stateParams = new URLSearchParams(state)
  o.state = stateParams.toString()
  // log("Building params from : ", o);
  var params = new URLSearchParams(o)
  url += params.toString()
  log('Generating OAuth URL: ' + url)
  return url
}

export const getReferrerCodeURL = (alternativeReferrerCode) => {
  var o = readAllDataInStorage()
  const rc = o.referrerCode || alternativeReferrerCode
  if (rc && rc.length > 0 && rc.length < 10) {
    return window.location.protocol + '//' + getGoodHostname() + '/r/' + rc
  } else {
    error('no referrer code: ', alternativeReferrerCode)
    return ''
  }
}

export const getGiftCodeURL = (gc) => {
  if (gc && gc.length > 0 && gc.length < 10) {
    return window.location.protocol + '//' + getGoodHostname() + '/g/' + gc
  } else {
    error('no gift code: ', gc)
    return ''
  }
}
