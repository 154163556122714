import React, { useState, useEffect, useCallback } from 'react'
import { useAudioPosition } from 'react-use-audio-player'

import Scrubber from './Scrubber'

export default function AugmentedScrubber({
  forcedProgress,
  disabled,
  loading
}) {
  const { percentComplete, duration, seek } = useAudioPosition({
    highRefreshRate: true
  })

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setProgress(percentComplete)
  }, [percentComplete])

  const goTo = useCallback(
    (percent) => {
      seek(percent * duration)
    },
    [duration, seek]
  )

  return (
    <Scrubber
      disabled={disabled}
      loading={loading}
      leftPosition={parseInt(forcedProgress) || progress}
      clickPosition={goTo}
    />
  )
}
