import React from 'react'

function NotLoggedIn() {
  return (
    <div>
      <p className="lead">Please sign in</p>
      <p className="title">
        You're not logged in, please sign in to mote in order to proceed to this
        page.
      </p>
      <br></br>
      <button className="pay-button">
        <a
          href={
            '/login?returnTo=' +
            encodeURIComponent(
              window.location.pathname + window.location.search
            )
          }
        >
          Let's do it
        </a>
      </button>
    </div>
  )
}

export default NotLoggedIn
