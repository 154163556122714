import { useGenericRequestHandler } from '../../../handlers/useGenericRequestHandler'

/**
 * mutate expect as a minimum payload
 * { summary: { chunks: chunkCount, bytes: byteCount } }
 */
export const useMoteFinalizeMutation = (moteId) => {
  return useGenericRequestHandler({
    route: `m/finalise/${moteId}`,
    method: 'POST'
  })
}
