import React from 'react'
import { styled } from 'twin.macro'

import { ButtonV2 } from '@mote/ui'
import { ChromeIcon } from '@mote/icons'

import { MastHead } from './MastHead'
import { ReactComponent as ReviewsSVG } from './assets/Reviews.svg'
import { Content } from '../../../../layouts/shared/content'
import { trackCWSInstallButton } from 'web/src/shared/AnalyticsHelpers'
import { CWS_URL } from 'web/src/shared/Constants'

export const HeroSection = () => {
  function handleAddToChromeClick() {
    trackCWSInstallButton()
    window.open(CWS_URL, '_new')
  }

  return (
    <Root>
      <Content>
        <Container>
          <Main>
            <Title>Fast, friendly voice messaging</Title>
            <SubTitle>
              Mote is voice messaging for the web, integrated into the products
              you already know and love.
            </SubTitle>
            <Button
              text="Add to Chrome"
              iconPosition="left"
              icon={<ChromeIcon />}
              onClick={handleAddToChromeClick}
            />
            <Reviews>
              <ReviewsSVG />
            </Reviews>
          </Main>
          <MastHead />
        </Container>
      </Content>
    </Root>
  )
}

const Root = styled.div`
  padding: 128px 0px;

  @media (max-width: 991px) {
    padding: 0;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`

const Main = styled.section`
  flex: 0 0 430px;
  margin-right: 41px;

  @media (max-width: 991px) {
    order: 2;
    padding-bottom: 48px;
  }
  @media (max-width: 639px) {
    padding-bottom: 40px;
  }
`
const Title = styled.h1`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  line-height: 110%;
  letter-spacing: -0.05em;
  color: #232323;
  margin-bottom: 16px;
`
const SubTitle = styled.h4`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #232323;
  margin-bottom: 32px;
`
const Button = styled(ButtonV2)``
const Reviews = styled.div`
  margin-top: 12px;
`
