import React from 'react'

import { error } from '@mote/common'

import { afterMoteAuth, identifyMaybeAndBroadcast } from '../../../shared/Auth'
import { DEV_FORCE_LOGIN } from '../../../shared/Constants'
import { Footer } from '../../../shared/Footer'
import Header from '../../../shared/Header'
import { getGodModeOptions, log } from '../../../shared/Utils'
import { DEV_FAKE_ACCOUNT_DATA } from './dev_fake_data'
import { queryAccount } from '../../../shared/Commerce'

import AccountInstitution from './AccountInstitution'
import AccountConsumer from './AccountConsumer'
import Error from '../../../shared/Error'
import Loading from '../../../shared/Loading'
import NotLoggedIn from '../../../shared/NotLoggedIn'

const queryString = require('query-string')

const MAX_POLLS = 20
const POLL_SLEEP = 1000

class AccountIndex extends React.Component {
  constructor(props) {
    super(props)
    var defaultAccount = DEV_FORCE_LOGIN ? DEV_FAKE_ACCOUNT_DATA : {}
    var querySearch = queryString.parse(window.location.search)
    log('querySearch', querySearch)
    this.state = Object.assign({}, defaultAccount, {
      loggedIn: false,
      renderMode: 'Loading',
      institutionAdminMode: undefined,
      syncWithChargebee:
        querySearch['from-portal'] && querySearch['state'] === 'succeeded',
      emailAddresses: '',
      essentialError: '',
      essentialConfirmation: '',
      essentialNeedsGoogleOverride: false,
      chargebeePolls: 0,
      essentialEdit: false,
      unlimitedError: '',
      unlimitedConfirmation: '',
      unlimitedNeedsGoogleOverride: false,
      unlimitedEdit: false,
      copiedToClipboard: false
    })
  }

  componentDidMount() {
    log('Checking log in status')

    if (!DEV_FORCE_LOGIN) {
      this.pollAccountStatus()
    } else {
      this.setState({ renderMode: 'Account' })
    }
  }

  pollAccountStatus() {
    const godModeOptions = getGodModeOptions()
    log('God mod options are: ', godModeOptions)
    log('current state is: ', this.state)
    log('synching chargebee?: ', this.state.syncWithChargebee)
    log('pollcount ', this.state.chargebeePolls)
    queryAccount(
      godModeOptions,
      (results) => {
        log('fetched results: ', results)
        if (
          this.state.syncWithChargebee &&
          !(
            results.isPaid ||
            (results.isInstitutionalAdmin &&
              results.currentLicense &&
              results.currentLicense.totalPaidSeats)
          )
        ) {
          if (this.state.chargebeePolls < MAX_POLLS) {
            log('trying again on chargebee')
            this.setState({
              renderMode: 'ChargebeeSyncing',
              chargebeePolls: this.state.chargebeePolls + 1
            })
            setTimeout(() => this.pollAccountStatus(), POLL_SLEEP)
          } else {
            this.setState({ renderMode: 'ChargebeeSyncError' })
          }
        } else {
          this.setState(results)
          identifyMaybeAndBroadcast()
          this.setState({ renderMode: 'Account' })
          log('logged in with: ', results)
        }
      },
      (e) => {
        error('error querying account: ', e)
        afterMoteAuth(
          () => this.setState({ renderMode: 'OtherError' }),
          (e) => this.setState({ renderMode: 'NotLoggedIn' })
        )
      }
    )
  }

  render() {
    var mode = this.state.renderMode
    return (
      <div className="container mote-brand">
        <Header />

        <div className="row justify-content-center">
          <div className="col-lg-10 px-lg-4">
            <h1 className="mote-logged-in-title">My account</h1>
            <main id="main">
              {mode === 'Loading' && <Loading />}
              {mode === 'ChargebeeSyncing' && (
                <div>
                  Waiting to sync your account status with the payment gateway
                  ...
                </div>
              )}
              {mode === 'ChargebeeSyncError' && (
                <div>
                  We were not able to sync your payment information. Refresh the
                  page later and contact support@mote.com if your payment is
                  not yet reflected.
                </div>
              )}
              {mode === 'OtherError' && <Error />}
              {mode === 'NotLoggedIn' && <NotLoggedIn />}
              {mode === 'Account' && (
                <React.Fragment>
                  {this.state.isInstitution && (
                    <AccountInstitution data={this.state} />
                  )}
                  {!this.state.isInstitution && (
                    <AccountConsumer data={this.state} />
                  )}
                </React.Fragment>
              )}
            </main>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default AccountIndex
