import React from 'react'

import GetMoteLink from '../shared/GetMoteLink'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class Music extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>

          <div className="container">
            <div className="row justify-content-between align-items-center py-4">
              <div className="col-lg-6 col-xl-6 mb-4">
                <h3 className="lg-display-4-mb-4-mt">
                  Remote learning &amp; feedback for Music &amp; Visual Arts
                  students
                </h3>
                <p className="lead">
                  Learn how <b className="mote-purple">mote</b> can help ease
                  your workload, and help your students stay connected.
                </p>
              </div>

              <div className="d-lg-none mb-2 justify-content-center">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/music.jpg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>Photo credit: Clark Young, via Unsplash</small>
                  </figcaption>
                </figure>
              </div>

              <div className="d-none d-lg-block col-lg-6 col-xl-5 mb-3 justify-content-center text-lg-left">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/music.jpg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>Photo credit: Clark Young, via Unsplash</small>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="teacher-page-intro">
              <p>
                Voice can be especially powerful for feedback delivered on
                students' creative and artistic performances and expressions,
                which is why we're seeing more and more Music, Art and Design
                teachers integrate <b className="mote-purple">mote</b> into
                their toolkit.
              </p>
            </div>
          </div>

          <div
            className="bg-cover position-relative"
            style={{
              backgroundImage: 'url(images/logos/mote-background.png',
              backgroundSize: 'auto'
            }}
          >
            <div className="container mote-brand">
              <h3 className="h4 mb-4">
                Here's what we've heard about{' '}
                <b className="mote-purple">mote</b> from Music &amp; Visual Arts
                teachers
              </h3>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "As a music teacher, it is SO handy to be able to play
                      examples for students. I used to use a different method,
                      but it required leaving Google Classroom to grade playing
                      tests. Now, I can do all of it directly in Google
                      Classroom!"
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “It has made my workflow much faster and more effective.
                      As a music teacher I can play or sing back rhythms to the
                      students as part of their feedback.”
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      I'm using <b className="mote-purple">mote</b> to give
                      feedback to my students. I'm a music teacher, and I was
                      giving them written feedback. However, this allows me to
                      sing or play instruments to show my students what I am
                      talking about. It's sometimes hard to convey music in
                      words. This allows me to better connect with my students
                      and be a better teacher.“
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "I'm an orchestra teacher (music) and it makes it
                      INCREDIBLY easier to give feedback to kids on their
                      submitted video performances in Google Classroom. I can
                      click on comments, use the{' '}
                      <b className="mote-purple">mote</b> extension and sing or
                      play for them whatever it is that I want them to fix. For
                      a musician: A sound byte is worth a thousand words! You
                      can quote me on that! It saves me time and makes my
                      feedback to students much more effective. Finally,
                      especially in these times of quarantine and online
                      learning, it's great to have a fast and easy way for
                      students to hear my friendly voice and make that
                      personable connection with them."
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "Used <b className="mote-purple">mote</b> today to record
                      a formative feedback session with a DP Visual Arts student
                      about her process portfolio. Meant we could concentrate on
                      the content rather than scribbling notes!"
                    </p>
                    <cite className="text-uppercase">
                      Claire Rees, via Twitter
                    </cite>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "After spending two hours{' '}
                      <b className="mote-purple">mote</b>-ing last night, giving
                      kids feedback about their digital art portfolios, it was
                      WONDERFUL to see that some had listened to my comments. I
                      have written comments for years only to find them left on
                      the classroom floor or unopened in a digital format. So
                      this is amazing."
                    </p>
                    <cite className="text-uppercase">Kate Humphrey</cite>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
              </div>

              <div className="container py-1">
                <div className="text-center">
                  <h2 className="mb-4">Start your free trial</h2>
                  <GetMoteLink recipe="atc" />
                </div>
              </div>

              <div className="container mb-lg-3"></div>

              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Music
