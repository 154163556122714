import React from 'react'
import { useHover } from '@mote/business'

export const Hover = ({ prop, children }) => {
  const { hoverRef, isHovered } = useHover()

  return (
    <div ref={hoverRef}>{React.cloneElement(children, prop(isHovered))}</div>
  )
}
