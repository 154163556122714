import { getPreferredLocaleFromStorage, isChromeExtension } from '@mote/common'
import { generateTranslationBundles } from './generateTranslationBundles'
import { ReactLocalization } from '@fluent/react'

const APP = isChromeExtension() ? 'extension' : 'web'

let l10nRef
;(async () => {
  const localeBundles = await generateTranslationBundles(
    APP,
    await getPreferredLocaleFromStorage()
  )

  console.log({ APP, locale: await getPreferredLocaleFromStorage() })

  l10nRef = new ReactLocalization(await Promise.all(localeBundles))
})()

export const L10n = (id, fallback = '') => {
  if (!l10nRef) {
    return console.error('Localization is not initialized yet')
  }
  const localizedString = l10nRef.getString(id)
  return localizedString === id ? fallback : localizedString
}

export const getL10n = () => l10nRef
