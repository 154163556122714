import React from 'react'
import PropTypes from 'prop-types'
import tw, { styled } from 'twin.macro'

export const Toggle = ({ value, onChange, disabled, text }) => {
  const disabledClasses = disabled ? 'tw-opacity-50 tw-cursor-not-allowed' : ''

  return (
    <div
      className={`tw-flex tw-items-center tw-cursor-pointer ${disabledClasses}`}
      onClick={() => !disabled && onChange && onChange(!value)}
    >
      <div className="tw-pr-2" style={{ marginRight: '2px' }}>
        <ToggleBG value={value}>
          <ToggleHandler value={value} />
        </ToggleBG>
      </div>
      <div>{text}</div>
    </div>
  )
}

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.object.isRequired,
  disabled: PropTypes.bool
}

const ToggleBG = styled.span(({ value }) => [
  tw`bg-gray-200 relative inline-flex flex-shrink-0 h-4 transition-colors duration-200 ease-in-out border-2 border-opacity-0 rounded-full cursor-pointer w-8 focus:outline-none`,
  value && tw`bg-secondary border-opacity-100`
])

const ToggleHandler = styled.span(({ value }) => [
  tw`translate-x-0 inline-block w-3 h-3 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow`,
  value && tw`translate-x-4`
])
