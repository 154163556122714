import React from 'react'
import { styled } from 'twin.macro'

export const ChevronRightIcon = ({
  className = '',
  onClick = () => {},
  tooltipText = ''
}) => {
  return (
    <SVG
      onClick={onClick}
      className={className}
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-tip={tooltipText}
    >
      <rect
        width="2"
        height="11.8295"
        rx="1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 1.41455 0)"
        fill="#232323"
      />
      <rect
        width="2"
        height="11.8293"
        rx="1"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.000488281 15.3137)"
        fill="#232323"
      />
    </SVG>
  )
}

const SVG = styled.svg`
  cursor: pointer;
`
