import { reallyTruthy, log } from '../shared/Utils'

const CANARY_PREFIX = 'mote-canary-'

export const canIUseCanary = (feature) => {
  var result = window.localStorage.getItem(CANARY_PREFIX + feature)
  log('found result: ' + result)
  return reallyTruthy(result)
}

export const canIUseCanaryChargebee = () => {
  return true
}
