import React from 'react'
import { GlobalStyles } from 'twin.macro'
import {
  Container,
  HeroContentContainer,
  Hero,
  Logo,
  LogoLink,
  MainContent,
  Root,
  Heading
} from './layout'
import { Footer } from '../../shared/Footer'
import {
  RecordReplySidebar,
  ToggleRecordReplySidebarButton
} from '@mote/business'

export const MoteLandingLayout = ({ hero, children }) => {
  return (
    <>
      <GlobalStyles />

      <Root>
        <MainContent>
          <Hero>
            <Heading>
              <LogoLink href="/">
                <Logo
                  src="/images/logos/mote-lockup-header.png"
                  alt="mote logo"
                />
              </LogoLink>
              <ToggleRecordReplySidebarButton />
            </Heading>
            <HeroContentContainer>{hero}</HeroContentContainer>
          </Hero>

          <Container>{children}</Container>
          <Footer />
        </MainContent>
        <RecordReplySidebar />
      </Root>
    </>
  )
}
