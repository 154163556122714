import React from 'react'
import { styled } from 'twin.macro'
import { useHistory } from 'react-router-dom'

import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  Logo,
  TwitterIcon
} from '@mote/icons'
import { ButtonIcon, ButtonV2 } from '@mote/ui'
import { LanguagePicker } from 'web/src/shared/languagePicker/LanguagePicker'
import { Content } from './content'

const currentYear = new Date().getFullYear()

export const Footer = () => {
  const history = useHistory()

  return (
    <Root>
      <Content>
        <Logo color="white" />

        <MainSection>
          <Mission>
            <Heading>Mote's mission</Heading>
            <MissionStatement>
              We're making voice messaging faster and more useful for busy
              people everywhere.
            </MissionStatement>
          </Mission>

          <LinksContainer>
            <Column>
              <Heading>Help</Heading>
              <Button
                variant="link"
                text="Community"
                onClick={(_) => history.push('/community')}
              />
              <Button
                variant="link"
                text="Privacy"
                onClick={(_) => history.push('/PrivacyCenter')}
              />
              <Button
                variant="link"
                text="Contact us"
                onClick={(_) => history.push('/contact')}
              />
            </Column>

            <Column>
              <Heading>Company</Heading>
              <Button
                variant="link"
                text="Careers"
                onClick={(_) => history.push('/Careers')}
              />
              <Button
                variant="link"
                text="Press"
                onClick={(_) =>
                  window.location.replace(
                    'https://www.notion.so/Mote-Press-1b8d3ace9bb44ccd97cec2c4d0e5d432'
                  )
                }
              />
              <Button
                variant="link"
                text="Blog"
                onClick={(_) =>
                  window.location.replace('https://learn.mote.com/')
                }
              />
            </Column>

            <Column>
              <Heading>Support</Heading>
              <LanguagePicker />
              <Button
                variant="link"
                text="Support"
                onClick={(_) =>
                  window.location.replace('https://support.mote.com/hc/en-us')
                }
              />
              <Button
                variant="link"
                text="Requests"
                onClick={(_) =>
                  window.location.replace(
                    'https://mote.canny.io/feature-requests'
                  )
                }
              />
            </Column>
          </LinksContainer>
        </MainSection>

        <Socials>
          <ButtonIcon
            icon={<TwitterIcon />}
            inverted
            onClick={(_) =>
              window.location.replace('https://twitter.com/justmoteHQ')
            }
          />
          <ButtonIcon
            icon={<LinkedInIcon />}
            inverted
            onClick={(_) =>
              window.location.replace(
                'https://www.linkedin.com/company/motehq/'
              )
            }
          />
          <ButtonIcon
            icon={<FacebookIcon />}
            inverted
            onClick={(_) =>
              window.location.replace('https://www.facebook.com/moteHQ/')
            }
          />
          <ButtonIcon
            icon={<InstagramIcon />}
            inverted
            onClick={(_) =>
              window.location.replace('https://www.instagram.com/justmotehq/')
            }
          />
        </Socials>

        <Copyright>
          &copy; Mote Technologies, Inc. {currentYear}. Brought to you with{' '}
          <i
            style={{
              color: '#FF4033',
              fontSize: '14pt',
              verticalAlign: 'bottom'
            }}
            className="material-icons"
          >
            favorite
          </i>{' '}
          from our global team.
        </Copyright>
      </Content>
    </Root>
  )
}

const Root = styled.footer`
  background: #232323;
  width: 100%;
  padding: 96px 0 24px 0;

  @media (max-width: 1199px) {
    padding: 80px 0 24px 0;
  }

  @media (max-width: 991px) {
    padding: 60px 0 24px 0;
  }
  @media (max-width: 639px) {
    padding: 40px 0 24px 0;
  }
`

const MainSection = styled.main`
  margin: 40px 0 96px 0;
  color: white;
  display: flex;

  @media (max-width: 1199px) {
    margin: 40px 0 80px 0;
  }

  @media (max-width: 991px) {
    margin: 40px 0 60px 0;
  }
  @media (max-width: 639px) {
    margin: 40px 0;
    flex-direction: column;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 41px;

  button {
    margin-bottom: 8px;
  }

  @media (max-width: 991px) {
    margin: 0 0 32px 0;
    width: 150px;
  }
`

const Heading = styled.h3`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin-bottom: 16px;
`

const Mission = styled.div`
  width: 239px;
  margin-right: 41px;

  @media (max-width: 639px) {
    margin: 0 0 32px 0;
    flex-direction: column;
  }
`

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 639px) {
    align-items: flex-start;
  }
`

const MissionStatement = styled.span`
  font-family: DM Sans;
  font-size: 16px;

  color: #ffffff;
`

const Button = styled(ButtonV2)`
  font-weight: normal;
`

const Socials = styled.div`
  margin-bottom: 16px;

  button {
    margin-right: 7px;
  }
`

const Copyright = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: white;
`
