import React from 'react'
import tw, { styled, css } from 'twin.macro'
import { AudioPlayerProvider } from 'react-use-audio-player'
import { mote_primary } from '@mote/config/defaults/colors'
import { Trash } from './TrashIcon'
import { ReplyPlayer } from './ReplyPlayer'
import { useMoteDeleteMutation } from '@mote/business'

export const Playback = ({ moteId, audioUrl, onReset, onDelete }) => {
  const { mutate: deleteMutation } = useMoteDeleteMutation(moteId)
  return (
    <AudioPlayerProvider>
      <Text>Your reply:</Text>
      <ReplyPlayer replyIsMine={false} audioUrl={audioUrl} />

      <Actions>
        <ActionIcon
          onClick={async () => {
            await deleteMutation()
            onDelete()
          }}
        >
          <Trash />
        </ActionIcon>

        <ActionButton onClick={() => onReset()}>Done</ActionButton>
      </Actions>
    </AudioPlayerProvider>
  )
}

const Text = styled.div(() => [tw`text-sm my-4`])

const Actions = styled.div(() => [tw`flex justify-between items-center mt-4`])

const ActionIcon = styled.div(() => [
  tw`p-3 bg-white rounded-full cursor-pointer`,
  css`
    border: 1px solid rgba(213, 212, 218, 0.5);
  `
])

const ActionButton = styled.div(() => [
  tw`rounded-lg px-8 flex items-center justify-center text-white text-base cursor-pointer`,
  css`
    height: 40px;
    background: ${mote_primary};
  `
])
