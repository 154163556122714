import React from 'react'

export const ArrowIcon = ({ color = '#AC0AE8' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="14"
      color={color}
      fill="none"
    >
      <rect
        width="18"
        height="1.5"
        y="6.038"
        fill="currentColor"
        rx="0.75"
      ></rect>
      <rect
        width="9"
        height="1.5"
        x="12.56"
        y="0.538"
        fill="currentColor"
        rx="0.75"
        transform="rotate(45 12.56 .538)"
      ></rect>
      <rect
        width="9"
        height="1.5"
        x="11.5"
        y="12.402"
        fill="currentColor"
        rx="0.75"
        transform="rotate(-45 11.5 12.402)"
      ></rect>
    </svg>
  )
}
