import React from 'react'

import GetMoteLink from '../shared/GetMoteLink'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class STEM extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>

          <div className="container">
            <div className="row justify-content-between align-items-center py-4">
              <div className="col-lg-6 col-xl-6 mb-4">
                <h3 className="lg-display-4-mb-4-mt">
                  Remote learning &amp; feedback for Science &amp; Mathematics
                  students
                </h3>
                <p className="lead">
                  Learn how <b className="mote-purple">mote</b> can help ease
                  your workload, and help your students stay connected.
                </p>
              </div>

              <div className="d-lg-none mb-2 justify-content-center">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/stem.jpg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>Photo credit: Antoine Dautry, via Unsplash</small>
                  </figcaption>
                </figure>
              </div>

              <div className="d-none d-lg-block col-lg-6 col-xl-5 mb-3 justify-content-center text-lg-left">
                <figure className="figure">
                  <img
                    height="400"
                    src="images/photos/stem.jpg"
                    className="img-fluid img-thumbnail"
                    title="A teacher uses mote"
                    alt="mote demo"
                  ></img>
                  <figcaption className="figure-caption text-center">
                    <small>Photo credit: Antoine Dautry, via Unsplash</small>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="teacher-page-intro">
              <p>
                Are you using Google Classroom to manage hand-written student
                exercise sheets, submitted electronically? Use{' '}
                <b className="mote-purple">mote</b> to provide Private Comment
                feedback about methodology or to explain theory, in ways that
                would be hard or time-consuming to type.
              </p>
              <p>
                Some STEM teachers have praised{' '}
                <b className="mote-purple">mote</b>’s transcription feature,
                because it allows students to quickly understand teacher
                feedback by listening to it, and then to refer back to the
                details of the feedback by reading the transcript.
              </p>
            </div>
          </div>

          <div
            className="bg-cover position-relative"
            style={{
              backgroundImage: 'url(images/logos/mote-background.png',
              backgroundSize: 'auto'
            }}
          >
            <div className="container mote-brand">
              <h3 className="h4 mb-4">
                Here's what we've heard about{' '}
                <b className="mote-purple">mote</b> from Science and Mathematics
                teachers
              </h3>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "This is amazing. Less typing! Also, I teach math. A
                      student has already told me that he understands his
                      mistakes more when he hears me explain them rather than a
                      text response."
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “I teach math and have to type multiple comments on any
                      given assignment. Mote allows me to give more in depth
                      feedback verbally than I could ever type.”
                    </p>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      “I am teaching GCSE science from home in lockdown and I’m
                      finding it the quickest and best way to give feedback and
                      attach verbal explanations to slides without making big
                      screencastify videos that some kids cannot load at home
                      because of their wifi.”
                    </p>
                    <cite className="text-uppercase">Nicola E.</cite>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
                <div className="col-md">
                  <blockquote className="bg-white rounded shadow mb-3 p-4">
                    <p className="text-secondary">
                      "I chose <b className="mote-purple">mote</b> because I am
                      a math teacher and I needed a more efficient method of
                      giving feedback on EVERY assignment for 100 kids. It was
                      taking way too long to type feedback in the comments
                      section of google classroom assignments."
                    </p>
                    <cite className="text-uppercase">Tina P.</cite>
                    <div className="quote-heart-div">
                      <span className="material-icons quote-heart">
                        favorite
                      </span>
                    </div>
                  </blockquote>
                </div>
              </div>

              <div className="container py-1">
                <div className="text-center">
                  <h2 className="mb-4">Start your free trial</h2>
                  <GetMoteLink recipe="atc" />
                </div>
              </div>

              <div className="container mb-lg-3"></div>

              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default STEM
