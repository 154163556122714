import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'

class WebinarFeedback extends React.Component {
  render() {
    return (
      <ReactTypeformEmbed url="https://justmote.typeform.com/to/jQK4CVnj" />
    )
  }
}

export default WebinarFeedback
