import React from 'react'

import Tooltip from '@material-ui/core/Tooltip'

import { getApplicationName } from '@mote/common'
import { ClassroomShareButton } from '@mote/business'

import { afterMoteAuth, getAccessToken } from '../../../shared/Auth'
import {
  BASE_URL,
  SHORT_LINK_DOMAIN,
  NETWORK_TIMEOUT
} from '../../../shared/Constants'
import Header from '../../../shared/Header'
import Loading from '../../../shared/Loading'
import { Footer } from '../../../shared/Footer'
import Paginator from '../../../shared/Paginator'
import {
  noLog as log,
  // error,
  reallyTruthy,
  EpochToPrettyDate,
  getGodModeOptions,
  EpochToAgo
} from '../../../shared/Utils'
import Visibility from './Visibility'
import FAKE_ACTIVITY from './dev_fake_data'
import { track } from '../../../shared/AnalyticsHelpers'

const axios = require('axios').default
const queryString = require('query-string')
const psl = require('psl')

const PLAYER_ID = 'audio-player'
const CLASSROOM_PARAMS = {
  c: { param: 'className', name: 'Class name' },
  s: { param: 'studentName', name: 'Student name' },
  a: { param: 'assignmentName', name: 'Assignment name' }
}
const DEV_FORCE_LOGIN =
  reallyTruthy(process.env.REACT_APP_DEV_FORCE_LOGIN) || false

class Activity extends React.Component {
  constructor(props) {
    super(props)
    var pageIndex = 0
    try {
      var tmpPageIndex = parseInt(this.props.match.params.pageIndex)
      if (!Number.isNaN(tmpPageIndex)) {
        pageIndex = tmpPageIndex
      }
    } catch (e) {}
    // log("Found a pageIndex: " + pageIndex);
    this.pageIndex = pageIndex
    this.godModeOptions = getGodModeOptions()
    this.queryParams = queryString.parse(window.location.search)
    this.hasFacetOrFilter = false
    for (let key of ['e', 'c', 's', 'a']) {
      if (this.queryParams[key] !== undefined) {
        this.hasFacetOrFilter = true
        break
      }
    }

    this.state = {
      loggedIn: false,
      renderMode: 'Loading',
      activity: []
    }
  }

  ///////////////////////////// rendering methods //////////////////////////

  render() {
    var methodName = '_render' + this.state.renderMode
    var method = this[methodName].bind(this)
    return (
      <div className="container mote-brand">
        <Header />
        <main id="main">{method()}</main>
        <Footer />
      </div>
    )
  }
  _renderLoading() {
    return <Loading />
  }

  _renderError() {
    return (
      <div>
        Sorry, we couldn't load your activity data. Please try again later.
      </div>
    )
  }

  _renderActivity() {
    return (
      <div>
        <h1 className="mote-logged-in-title">My activity</h1>
        {this.state.activity.length > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">{this._renderPaginator()}</div>
              <div className="col-3">{this._renderEngagementFilter()}</div>
            </div>
            <div>
              {this._renderFacetOrFilter()}
              {this._renderActivityTable()}
              <div className="row">
                <div className="col-3"></div>
                <div className="col-6">{this._renderPaginator()}</div>
                <div className="col-3"></div>
              </div>
            </div>
          </div>
        )}

        {this.state.activity.length === 0 && (
          <span>
            No activity yet. When you record motes you can see them here.
          </span>
        )}
      </div>
    )
  }

  _renderPaginator() {
    return (
      <Tooltip title="Browse your motes reverse chronologically" arrow>
        <Paginator
          baseURL="/account/activity/"
          pageIndex={this.pageIndex}
          pageIndexes={this.state.pagination.pageIndexes}
        />
      </Tooltip>
    )
  }

  _renderFacetOrFilter() {
    if (!this.hasFacetOrFilter) {
      return <React.Fragment />
    }
    const q = this.queryParams
    return (
      <p>
        <b>Filtered by:</b>
        <br />

        {q.c !== undefined && this._renderFacetOrFilterLink('c', 'Class', q.c)}
        {q.a !== undefined &&
          this._renderFacetOrFilterLink('a', 'Assignment', q.a)}
        {q.s !== undefined &&
          this._renderFacetOrFilterLink('s', 'Student', q.s)}
        {q.e !== undefined &&
          this._renderFacetOrFilterLink(
            'e',
            'moticed',
            reallyTruthy(q.e) ? 'yes' : 'no'
          )}
      </p>
    )
  }

  _renderFacetOrFilterLink(param, dimension, value) {
    return (
      <span>
        {dimension} = {value}{' '}
        <Tooltip title="Clear filter" arrow>
          <a
            className="activity-clear"
            href={this._makeURLWithParams(param, undefined)}
          >
            <span className="material-icons">clear</span>
          </a>
        </Tooltip>{' '}
        <br />{' '}
      </span>
    )
  }

  _renderEngagementFilter() {
    return (
      <Tooltip
        title="Filter by mote engagement - has it been 'moticed' yet?"
        arrow
      >
        <span>
          {this._renderEngagementLink('All', undefined)} |{' '}
          {this._renderEngagementLink('moticed', '1')} |{' '}
          {this._renderEngagementLink('unmoticed', '0')}
        </span>
      </Tooltip>
    )
  }

  _renderEngagementLink(label, value) {
    if (this.queryParams.e === value) {
      return <React.Fragment>{label}</React.Fragment>
    } else {
      return (
        <a className="activity-link" href={this._makeURLWithParams('e', value)}>
          {label}
        </a>
      )
    }
  }

  _renderActivityTable() {
    return (
      <div className="container">
        <table className="table mb-5 shadow table-hover pricing-table">
          <thead>
            <tr>
              <th className="activity-asset" scope="col">
                <p className="lead activity-table-header">Voice note</p>
              </th>
              <th className="activity-created" scope="col">
                <p className="lead activity-table-header">Created</p>
              </th>
              {/* <th scope='col'><h4>Availability</h4></th> */}
              <th className="activity-status" scope="col">
                <p className="lead activity-table-header">Availability</p>
              </th>
              <th scope="col">
                <p className="lead activity-table-header">Listen or download</p>
              </th>
            </tr>
          </thead>

          <tbody>
            {this.state.activity.map((a) => {
              var peopleRows = this._crunchInteractionsIntoPeopleRows(a)
              // log("procesing node:", a);
              var studentish = this._isReallyForStudent(a)
              return (
                <tr key={a.moteId}>
                  <th className="activity-row" scope="row">
                    {this._niceTitle(a)}
                    {peopleRows.map((pr) => (
                      <div key={Math.random()} className="row activity-user">
                        <Tooltip title="Audience name" placement="right" arrow>
                          <span className="activity-audience col-6">
                            {pr.name}
                          </span>
                        </Tooltip>
                        <span className="activity-engagement col">
                          {pr.view && (
                            <Tooltip title="Opened or viewed comment" arrow>
                              <span className="material-icons engagement-icons">
                                visibility
                              </span>
                            </Tooltip>
                          )}
                          {pr.listen && (
                            <Tooltip title="Listened to comment" arrow>
                              <span className="material-icons engagement-icons">
                                headset
                              </span>
                            </Tooltip>
                          )}

                          {pr.react.includes('thinking') && (
                            <Tooltip title="May need help to action" arrow>
                              <span
                                role="img"
                                className="engagement-emoji"
                                aria-label="Feedback Thinking"
                              >
                                🤔
                              </span>
                            </Tooltip>
                          )}
                          {pr.react.includes('gem') && (
                            <Tooltip title="Understood and actioned" arrow>
                              <span
                                role="img"
                                className="engagement-emoji"
                                aria-label="Feedback Gem"
                              >
                                💎
                              </span>
                            </Tooltip>
                          )}
                        </span>
                      </div>
                    ))}
                    <p className="activity-transcript small">
                      {a.transcriptDisplay}
                    </p>
                    {a.className && (
                      <span className="small">
                        {/* In class:{" "} */}
                        <Tooltip title="Filter by class" arrow>
                          <a
                            className="activity-link"
                            href={this._makeURLWithParams(
                              'c',
                              a.className,
                              true
                            )}
                          >
                            {a.className}
                          </a>
                        </Tooltip>{' '}
                        <br />
                      </span>
                    )}
                    {a.assignmentName && (
                      <span className="small">
                        {/* For assignment:{" "} */}
                        <Tooltip title="Filter by assignment" arrow>
                          <a
                            className="activity-link"
                            href={this._makeURLWithParams(
                              'a',
                              a.assignmentName,
                              true
                            )}
                          >
                            {a.assignmentName}
                          </a>
                        </Tooltip>{' '}
                        <br />
                      </span>
                    )}
                    {studentish && (
                      <span className="small">
                        {/* To student:{" "} */}
                        <Tooltip title="Filter by student" arrow>
                          <a
                            className="activity-link"
                            href={this._makeURLWithParams(
                              's',
                              a.studentName,
                              true
                            )}
                          >
                            {a.studentName}
                          </a>
                        </Tooltip>{' '}
                        <br />
                      </span>
                    )}
                    {(a.canRedirect || a.canLink) && (
                      <>
                        <a
                          className="activity-link small"
                          href={
                            a.canRedirect ? '/m/' + a.moteId : a.containingURL
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View original context
                        </a>{' '}
                        |{' '}
                      </>
                    )}
                    <a
                      className="activity-link small"
                      href={'/m/' + a.moteId}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View landing page
                    </a>
                    {a.canRedirect && (
                      <ClassroomShareButton
                        url={`${SHORT_LINK_DOMAIN}${a.moteId}`}
                        onShareStart={() => {
                          this._trackClassroomSharing('started', a.moteId)
                        }}
                        onShareComplete={() => {
                          this._trackClassroomSharing('completed', a.moteId)
                        }}
                      />
                    )}
                  </th>
                  <td className="activity-row-ago">
                    <p>
                      {EpochToAgo(a.created)}
                      <br></br>
                      <small>{EpochToPrettyDate(a.created)}</small>
                    </p>
                  </td>
                  {/* <td><p>Published</p></td> */}
                  <td className="activity-row">
                    <Visibility
                      activity={a}
                      onChange={() => {}}
                      onDelete={() => {
                        this.setState({ renderMode: 'Loading' })
                        this._fetchActivity()
                      }}
                    />
                  </td>
                  <td className="activity-row">
                    {' '}
                    <audio
                      id={PLAYER_ID}
                      controls
                      style={{ outline: 'none' }}
                      autoPlay={false}
                      src={a.playbackLocation}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  _isReallyForStudent(a) {
    if (a.studentName && getApplicationName(a.containingURL)) {
      if (
        a.containingURL.indexOf('/student/') > -1 ||
        a.containingURL.indexOf('/tg/') > -1
      ) {
        return true
      }
    }
    return false
  }

  _niceTitle(a) {
    var mode = ''
    var domain = ''
    if (this._isReallyForStudent(a)) {
      mode = 'student'
    } else if (a.title) {
      mode = 'motebook'
    } else if (getApplicationName(a.containingURL)) {
      mode = 'applicationName'
    } else {
      function extractHostname(url) {
        var hostname
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf('//') > -1) {
          hostname = url.split('/')[2]
        } else {
          hostname = url.split('/')[0]
        }

        //find & remove port number
        hostname = hostname.split(':')[0]
        //find & remove "?"
        hostname = hostname.split('?')[0]

        return hostname
      }

      try {
        domain = psl.get(extractHostname(a.containingURL))
        if (domain) {
          mode = 'domain'
        }
      } catch (e) {}
    }
    return (
      <p>
        {mode === 'student' && (
          <b>
            For {a.studentName}, in {getApplicationName(a.containingURL)}
          </b>
        )}
        {mode === 'motebook' && (
          <span>
            <b>{a.title}</b> (from your motebook)
          </span>
        )}
        {mode === 'applicationName' && (
          <b>In {getApplicationName(a.containingURL)}</b>
        )}
        {mode === 'domain' && <b>From {domain} </b>}

        {mode === '' && <b>Mote ID: {a.moteId}</b>}
      </p>
    )
  }

  //////////////////////////// non-rendering methods //////////////////////////

  componentDidMount() {
    document.title = 'Activity'
    this._fetchActivity()
  }

  _renderClassroomShareButtons() {
    if (!window.gapi) return
    window.gapi.sharetoclassroom.go()
  }

  _fetchActivity(hasAuthenticated, count) {
    if (DEV_FORCE_LOGIN) {
      this.setState({
        renderMode: 'Activity'
      })
      this.setState(FAKE_ACTIVITY)
      setTimeout(this._renderClassroomShareButtons, 1000)

      return
    }
    if (!count) {
      count = 0
    }
    var axiosInstance = axios.create({
      baseURL: BASE_URL + 'a/',
      timeout: NETWORK_TIMEOUT
    })
    const accessToken = getAccessToken()
    const filtersAndFacets = {}
    if (this.queryParams.e !== undefined) {
      filtersAndFacets['wasEngagedWith'] = reallyTruthy(this.queryParams.e)
    }

    for (let p of Object.keys(CLASSROOM_PARAMS)) {
      if (this.queryParams[p] !== undefined) {
        filtersAndFacets['facetName'] = CLASSROOM_PARAMS[p].param
        filtersAndFacets['facetValue'] = this.queryParams[p]
        break
      }
    }
    log('found filters and facets: ', filtersAndFacets)
    const params = Object.assign({}, this.godModeOptions, {
      accessToken: accessToken,
      pageIndex: this.pageIndex,
      ...filtersAndFacets
    })
    if (reallyTruthy(accessToken) || hasAuthenticated) {
      axiosInstance
        .post('activity', params)
        .then((response) => {
          this.setState({
            activity: response.data.activity,
            pagination: response.data.pagination,
            renderMode: 'Activity'
          })

          this._renderClassroomShareButtons()
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 403 &&
            !hasAuthenticated
          ) {
            this._reAuthThenFetchActivity(count + 1)
          } else {
            this.setState({ renderMode: 'Error' })
          }
        })
    } else {
      this._reAuthThenFetchActivity(count + 1)
    }
  }

  _crunchInteractionsIntoPeopleRows(activityRow) {
    const interactions = activityRow.interactions
    if (!interactions || interactions === {}) {
      return []
    }
    var persons = {}
    for (const key of ['view', 'listen', 'react']) {
      try {
        for (const row of interactions[key]) {
          var id, name, timestamp
          var o = {}
          if (row.userId) {
            id = row.userId
            name = row.userDisplayName
          } else {
            id = row.deviceId
            name = 'Someone'
          }
          timestamp = Date.parse(row.created)
          if (persons[id]) {
            o = persons[id]
            if (timestamp > o.timestamp) {
              o.timestamp = timestamp
            }
          } else {
            o = {
              id: id,
              name: name,
              timestamp: timestamp,
              view: false,
              listen: false,
              react: []
            }
          }
          if (key === 'react') {
            o.react.push(row.reaction)
            o.view = true
          } else if (key === 'listen') {
            o.listen = true
            o.view = true
          } else if (key === 'view') {
            o.view = true
          }
          persons[id] = o
        }
      } catch (e) {
        // error("error building rows for ", e, key, interactions);
      }
    }
    var personRows = []
    for (const key of Object.keys(persons)) {
      personRows.push(persons[key])
    }
    personRows.sort((a, b) => {
      return b.timestamp - a.timestamp
    })
    return personRows
  }

  _reAuthThenFetchActivity(count) {
    if (count > 1) {
      this.setState({ renderMode: 'Error' })
    } else {
      afterMoteAuth(
        () => this._fetchActivity(true, count),
        () => this._redirectToLogin()
      )
    }
  }

  _makeURLWithParams(key, value, scrubClassroom) {
    const targetParams = Object.assign({}, this.queryParams)
    if (scrubClassroom) {
      delete targetParams.a
      delete targetParams.c
      delete targetParams.s
    }
    if (value === undefined) {
      delete targetParams[key]
    } else {
      targetParams[key] = value
    }
    const kvPairs = []
    for (let k of Object.keys(targetParams)) {
      kvPairs.push(k + '=' + encodeURIComponent(targetParams[k]))
    }
    var result =
      '/account/activity/0' + (kvPairs.length ? '?' + kvPairs.join('&') : '')
    log(`calculated URL for ${key} and ${value}: ${result}`)
    return result
  }

  _redirectToLogin() {
    window.location.href =
      window.location.protocol + '//' + window.location.host + '/login'
  }

  _trackClassroomSharing(status, moteId) {
    track(`Share to Classroom ${status}`, {
      content_asset_id: moteId
    })
  }
}

export default Activity
