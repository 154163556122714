import React, { useEffect, useState } from 'react'
import { css, styled } from 'twin.macro'
import { mote_primary, mote_primary_shades } from '@mote/config/defaults/colors'
import { beginAnimation, endAnimation } from './animationHelper'
import { limitAmplitude } from './AudioPlaybackUtils'
import MoteLogoMedium from './MoteLogoMedium'
import MoteLogoMini from './MoteLogoMini'
import { Pause } from '../controls/'

export const PauseReactive = (props) => {
  const [amplitude, setAmplitude] = useState(0)

  useEffect(() => {
    if (beginAnimation && endAnimation && !process.env.STORYBOOK) {
      beginAnimation(props.audioStream, props.audioContext, setAmplitude)

      return function cleanup() {
        if (process.env.STORYBOOK) return
        endAnimation()
      }
    }
  }, [props.audioStream, props.audioContext])

  return (
    <div style={{ position: 'relative' }}>
      <Pause
        style={{ position: 'relative', zIndex: '1' }}
        recordingMode={props.recordingMode}
      />
      <PulsingDiv>
        <ReactiveBGCirclePause
          style={{ transform: `scale(${limitAmplitude(amplitude)})` }}
        />
      </PulsingDiv>
    </div>
  )
}

export const MoteLogoMediumPulsing = (props) => (
  <PulsingDiv>
    <MoteLogoMedium />
  </PulsingDiv>
)

export const MoteLogoMediumReactive = (props) => {
  const [amplitude, setAmplitude] = useState(0)
  const MoteLogo = props.isMini ? MoteLogoMini : MoteLogoMedium
  const MoteLogoReactive = props.isMini
    ? ReactiveBGCircleMini
    : ReactiveBGCircle

  useEffect(() => {
    if (beginAnimation && endAnimation && !process.env.STORYBOOK) {
      beginAnimation(props.audioStream, props.audioContext, setAmplitude)

      return function cleanup() {
        if (process.env.STORYBOOK) return
        endAnimation()
      }
    }
  }, [props.audioStream, props.audioContext])

  return (
    <div style={{ position: 'relative' }}>
      <MoteLogo
        style={{ position: 'relative', zIndex: '1' }}
        recordingMode={props.recordingMode}
      />
      <PulsingDiv>
        <MoteLogoReactive
          style={{ transform: `scale(${limitAmplitude(amplitude)})` }}
        />
      </PulsingDiv>
    </div>
  )
}

export const PulsingDiv = styled.div(() => [
  css`
    animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  `
])

const ReactiveBGCircle = styled.div({
  width: '67px',
  height: '67px',
  opacity: '0.2',
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: '0',
  background: mote_primary,
  borderRadius: '33.5px'
})

const ReactiveBGCircleMini = styled.div({
  width: '29px',
  height: '29px',
  opacity: '0.2',
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: '0',
  background: mote_primary,
  borderRadius: '33.5px'
})

const ReactiveBGCirclePause = styled.div({
  width: '36px',
  height: '36px',
  opacity: '0.2',
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: '0',
  background: mote_primary,
  borderRadius: '33.5px'
})
