// Not DRY - already exists in reactplugin (should be part of commonfe)

export function secondsToString(t) {
  if (t < 0) {
    return '0:00'
  } else {
    return (
      Math.floor(t / 60).toString() +
      ':' +
      Math.floor(t % 60)
        .toString()
        .padStart(2, '0')
    )
  }
}
