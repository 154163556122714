import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tw, { styled, css } from 'twin.macro'
import { useSpring, animated } from 'react-spring'
import ClickAwayListener from 'react-click-away-listener'

import { Volume as VolumeIcon, Mute } from '@mote/assets/svgs/controls/index'

import { Slider } from './Slider'

const ACTIVATOR_WIDTH = '25px'
const ACTIVATOR_MAX_WIDTH = '100px'
const ACTIVATOR_HEIGHT = '25px'
const SLIDER_MIN_WIDTH = '0px'
const SLIDER_MAX_WIDTH = '60px'
const SLIDER_MIN_OPACITY = 0
const SLIDER_MAX_OPACITY = 1

const Control = styled.div(() => [tw`cursor-pointer`])

const VolumeContainer = styled.div(({ disabled }) => [
  css`
    position: relative;
    width: ${ACTIVATOR_WIDTH};
    height: ${ACTIVATOR_HEIGHT};
  `,
  disabled &&
    `
    pointer-events: none;
    cursor: not-allowed;
  
    svg {
      path {
        fill: #c4c4c4;
      }
    }
  `
])

const VolumeActivator = animated(
  styled.div(() => [
    css`
      height: ${ACTIVATOR_HEIGHT};
      border: 1px solid rgb(233, 233, 233);
      border-radius: 20px;
      box-sizing: border-box;
      position: absolute;
    `
  ])
)

const IconContainer = styled.div(() => [
  css`
    position: absolute;
    top: 4px;
    left: 4px;
  `
])

const SliderContainer = animated(
  styled.div(() => [
    css`
      margin-left: 28px;
      height: 100%;
      display: flex;
      align-items: center;
      background: white;
      z-index: 1;
    `
  ])
)

Volume.propTypes = {
  muted: PropTypes.bool.isRequired,
  toggleMute: PropTypes.func.isRequired,
  volume: PropTypes.number.isRequired,
  changeVolume: PropTypes.func.isRequired
}

function Volume({ muted, toggleMute, volume, changeVolume, disabled = false }) {
  const [{ width, sliderWidth, opacity }, set] = useSpring(() => ({
    width: ACTIVATOR_WIDTH,
    sliderWidth: SLIDER_MIN_WIDTH,
    opacity: SLIDER_MIN_OPACITY
  }))

  const [volumeOpen, setVolumeOpen] = useState(false)

  const toggleAnimation = (open) => {
    set({
      width: open ? ACTIVATOR_MAX_WIDTH : ACTIVATOR_WIDTH,
      sliderWidth: open ? SLIDER_MAX_WIDTH : SLIDER_MIN_WIDTH,
      opacity: open ? SLIDER_MAX_OPACITY : SLIDER_MIN_OPACITY
    })

    setVolumeOpen(open)
  }

  return (
    <VolumeContainer disabled={disabled}>
      <ClickAwayListener onClickAway={() => toggleAnimation(false)}>
        <VolumeActivator
          style={{ width }}
          onMouseEnter={() => toggleAnimation(true)}
          onMouseLeave={() => toggleAnimation(false)}
        >
          <IconContainer
            onClick={(e) => {
              e.stopPropagation()
              toggleMute()
            }}
            onTouchEnd={(e) => {
              e.preventDefault()
              volumeOpen ? toggleMute() : toggleAnimation(true)
            }}
          >
            <Control>{muted ? <Mute /> : <VolumeIcon />}</Control>
          </IconContainer>
          <SliderContainer style={{ width: sliderWidth, opacity }}>
            <Slider currentVolume={volume} updateVolume={changeVolume} />
          </SliderContainer>
        </VolumeActivator>
      </ClickAwayListener>
    </VolumeContainer>
  )
}

export default Volume
