import React from 'react'
import { styled } from 'twin.macro'
import ReactTooltip from 'react-tooltip'

export const Tooltip = () => {
  return (
    <StyledTooltip
      place="bottom"
      type="dark"
      effect="solid"
      backgroundColor="#5F5F5F"
    />
  )
}

const StyledTooltip = styled(ReactTooltip)`
  padding: 3px 5px !important;
  font-size: 11px !important;
`
