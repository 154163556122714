import React from 'react'
import { InlineWidget } from 'react-calendly'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class BookPD extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <div className="container">
          <h1 className="lg-display-4-mb-4-mt mote-uninstall-title">
            Book your <b className="mote-purple">mote</b> PD
          </h1>
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 mb-4 text-lg-left">
              <img
                src="images/photos/alex1.png"
                width="100"
                alt="Alex, the sad Engineer"
              />
              <blockquote className="blockquote">
                <p className="lead">
                  If your school, district or Academy Trust would like to
                  arrange some <b className="mote-purple">mote</b> professional
                  development, we'd love to help. Please book some time for an
                  initial discussion, or go ahead and schedule a slot for your
                  group!
                </p>
              </blockquote>
            </div>
            <div className="col-lg-6 col-xl-6 mb-8 justify-content-center text-lg-left">
              <InlineWidget url="https://calendly.com/will-mote/" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default BookPD
