import React from 'react'

import { DefaultLayout } from '../../layouts'
import { HeroSection } from './sections/hero/hero.section'
import { CertificatesSection } from './sections/certificates/certificates.section'
import { BenefitsSection } from './sections/benefits/benefits.section'
import { DemoSection } from './sections/demo/demo.section'
import { TestimonialsSection } from './sections/testimonials/testimonials.section'
import { FeaturesSection } from './sections/features/features.section'
import { SocialSection } from './sections/social/social.section'

export const HomepageScreen = () => {
  return (
    <DefaultLayout>
      <HeroSection />
      <CertificatesSection />
      <BenefitsSection />
      <DemoSection />
      <FeaturesSection />
      <TestimonialsSection />
      <SocialSection />
    </DefaultLayout>
  )
}
