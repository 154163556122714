import React from 'react'
import { styled, css } from 'twin.macro'

import { DropdownItem, DropdownItemText } from './dropdown.components'
import {
  KeyboardKey,
  useKeyPress,
  useOnClickOutside,
  useOnScrollOutside
} from '@mote/helpers'

export const Dropdown = ({
  onAction,
  placement,
  disabled = false,
  triggerElement,
  closeElement,
  children,
  height,
  inline = false,
  className = '',
  width = 215
}) => {
  const [showDropdown, setShowDropdown] = React.useState(false)
  const wrapperRef = React.useRef(null)
  const triggerRef = React.useRef(null)
  const closeElementRef = React.useRef(null)

  const triggerElementClickHandler = React.useCallback(() => {
    if (disabled) return

    setShowDropdown((prev) => !prev)
  }, [disabled, setShowDropdown])

  console.log({ inline })
  const outSideEventHandler = (e) => {
    if (inline) {
      return
    } else if (closeElement && closeElementRef.current?.contains(e.target)) {
      return
    } else if (!closeElement && triggerRef.current?.contains(e.target)) {
      return
    }

    setShowDropdown(false)
  }

  useOnClickOutside(wrapperRef, outSideEventHandler)
  useOnScrollOutside(wrapperRef, outSideEventHandler)
  useKeyPress(KeyboardKey.Escape, () => {
    if (!showDropdown || inline) return

    setShowDropdown(!showDropdown)
  })

  return (
    <Root>
      <TriggerElementWrapper
        ref={triggerRef}
        onClick={triggerElementClickHandler}
        disabled={disabled}
      >
        {triggerElement}
      </TriggerElementWrapper>
      <StyledDropdown
        inline={inline}
        className={`${className} dropdown-container`}
        width={width}
        height={height}
        ref={wrapperRef}
        disabled={disabled}
        placement={placement}
        showDropdown={showDropdown}
      >
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            console.error('Children has invalid elements')
            return child
          }

          if (!child.key) {
            console.error('All children must have a unique key')
            return child
          }

          return React.cloneElement(child, {
            onClick: () => {
              onAction(child.key)
              setShowDropdown(false)
            }
          })
        })}
      </StyledDropdown>
    </Root>
  )
}

Dropdown.Item = DropdownItem
Dropdown.ItemText = DropdownItemText

const Root = styled.div`
  display: inline-block;
  position: relative;
  max-width: 100%;
`

const StyledDropdown = styled.ul`
  position: ${({ inline }) => (inline ? 'relative' : 'absolute')};
  display: ${({ showDropdown }) => (showDropdown ? 'block' : 'none')};
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: ${({ width }) => width}px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 13px 18px;
  background: white;
  z-index: 10;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  overflow-y: auto;
  margin: 0;

  ${({ placement, inline }) => {
    if (inline) return ''

    switch (placement) {
      case 'bottomLeft':
        return `
          left: 0px;
        `
      case 'bottomRight':
        return `
          right: 0px;
        `
      case 'topLeft':
        return `
          bottom: 100%;
          left: 0px;
        `
      case 'topRight':
        return `
        bottom: 100%;
        right: 0px;
        `
    }
  }}

  ${({ inline }) =>
    inline &&
    css`
      height: unset;
      display: block;
      max-height: 0;
      transition: all 250ms ease-in-out;
    `}
  
  ${({ showDropdown, height }) =>
    showDropdown &&
    css`
      max-height: ${showDropdown ? (height ? `${height}px` : '1000px') : 0};
    `}
`

const TriggerElementWrapper = styled.div`
  display: block;
  opacity: ${({ disabled }) => disabled && '.5'};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: not-allowed;
  `};
`
