import React from 'react'

function InstitutionContacts({ data }) {
  const hasPureAdmins =
    data.accountPureAdminEmails && data.accountPureAdminEmails.length > 0

  return (
    <React.Fragment>
      <p>
        Your organization's plan owner is <b>{data.accountOwnerName}</b> (
        <a
          className="mote-boiler-link"
          href={'mailto:' + data.accountOwnerEmail}
        >
          {data.accountOwnerEmail}
        </a>
        )
      </p>

      {hasPureAdmins && (
        <React.Fragment>
          <p>These people are also organizational administrators:</p>
          <p>{data.accountPureAdminEmails.join(', ')}</p>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default InstitutionContacts
