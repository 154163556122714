import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'
// import Header from '../shared/Header';
// import Footer from '../shared/Footer';

class DemoVideos extends React.Component {
  render() {
    return (
      <ReactTypeformEmbed url="https://will838622.typeform.com/to/Kl3xUt" />
    )
  }
}

export default DemoVideos
