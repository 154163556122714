import React from 'react'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'
import { Emoji } from '@mote/ui/components/emoji'
import Carousel from 'react-bootstrap/Carousel'

class Amplifier extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand container">
          <Header />
          <div className="row">
            <h1 className="lg-display-4-mb-2-mt pt-4">
              Learn and Share: The Mote Amplifier Package
            </h1>
          </div>
          <div className="row justify-content-center my-5">
            <div className="col-lg-9">
              <Carousel fade>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="images/photos/amplifier-1.png"
                    alt="Amplifier Package"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="images/photos/amplifier-2.png"
                    alt="Amplifier Package"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="images/photos/amplifier-3.png"
                    alt="Amplifier Package"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="images/photos/amplifier-4.png"
                    alt="Amplifier Package"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="images/photos/amplifier-5.png"
                    alt="Amplifier Package"
                  />
                </Carousel.Item>
              </Carousel>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1 className="pt-4 text-center">
                  Do you want to help others to discover the joy of Mote?
                </h1>
                <h3 className="pt-3 text-center">If so, you are amazing! </h3>
                <h3 className="py-3 text-center">
                  <em>And</em> you're in the right place - welcome to the Mote
                  Amplifier Package! <Emoji>📢</Emoji>
                </h3>
              </div>

              <div className="col-lg-8">
                <h3 className="py-3">What is Mote Amplifier?</h3>
                <ul>
                  <li className="lead">
                    Mote Amplifier is our program for supporting anyone looking
                    to spread the word about Mote
                  </li>
                  <li className="lead">
                    Once you've signed up, we'll send you a link to an exclusive
                    PD deck developed with the wonderful{' '}
                    <a
                      className="mote-boiler-link"
                      href="https://slidesmania.com/"
                    >
                      SlidesMania
                    </a>
                  </li>
                  <li className="lead">
                    We're standing by to help you deliver a great learning
                    experience for other educators. We may also be able send you
                    some cool swag...<Emoji>😉</Emoji>
                  </li>
                </ul>

                <h3 className="py-3">Getting started with Amplifier</h3>

                <ul>
                  <li className="lead">
                    Read about the different session formats, then click to 'Get
                    Started'!
                  </li>
                  <li className="lead">
                    Tell us a bit about yourself (we already know you’re
                    awesome!)
                  </li>
                  <li className="lead">
                    Tell us a bit more about the session you're planning
                  </li>
                  <li className="lead">
                    You'll receive a link to the Mote Amplifier Package -
                    created in collaboration with{' '}
                    <a
                      className="mote-boiler-link"
                      href="https://slidesmania.com/"
                    >
                      SlidesMania
                    </a>
                    !
                  </li>
                </ul>
              </div>

              <div className="row justify-content-center">
                <h3 className="pt-4 text-center">Amplifier session formats</h3>

                <div className="row py-3">
                  <div className="col-lg-4">
                    <div className="card card-hover card-featured card-price shadow-lg mb-4">
                      <div className="card-header">
                        <h3 className="h4 font-weight-normal">
                          EdCamp/Conference
                        </h3>
                      </div>
                      <div className="card-body">
                        <p>
                          These are so much fun - even more so with Mote 🚀{' '}
                        </p>
                        <p>
                          The Mote Amplifier Package gives you a great
                          headstart!{' '}
                        </p>
                        <p>
                          {' '}
                          We’ll do our best to support you with super swag and
                          prizes! 🏆
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="card card-hover card-featured card-price shadow-lg mb-4">
                      <div className="card-header">
                        <h3 className="h4 font-weight-normal">PD Sessions</h3>
                      </div>
                      <div className="card-body">
                        <p>
                          The most powerful voice is that of a trusted
                          coworker.💜{' '}
                        </p>
                        <p>
                          Ready to become an advocate of Mote in your community?{' '}
                        </p>
                        <p>
                          Use the Amplifier Package template to share Mote -
                          step by simple step
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card card-hover card-featured card-price shadow-lg mb-4">
                      <div className="card-header">
                        <h3 className="h4 font-weight-normal">
                          Self-Paced Course
                        </h3>
                      </div>
                      <div className="card-body">
                        <p>
                          Asynchronous learning is a thing - that’s for sure! ✅{' '}
                        </p>
                        <p>
                          The Amplifier Package is easily shared with your team
                        </p>
                        <p>
                          Your colleagues can learn about all of Mote's features
                          at their own pace
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container py-1">
                  <div className="text-center">
                    <h2 className="mb-4">Ready to Amplify?</h2>
                    <a
                      className="btn btn-primary mb-3"
                      href="https://share.hsforms.com/1-iO89ukaSvWtSkvALVrQ4Q4w7sl"
                    >
                      Sign up
                    </a>
                    <p className="small">
                      <a
                        className="mote-boiler-link"
                        href="https://docs.google.com/presentation/d/1PLp_pFwXIc85TR-9RS1ek66kAOFVmiSlTj26cH2pBDQ/template/preview"
                      >
                        Descargar Amplifier en español
                      </a>
                    </p>
                  </div>
                </div>

                <div className="container mb-lg-3"></div>

                <Footer />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Amplifier
