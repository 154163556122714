import React from 'react'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class Press extends React.Component {
  render() {
    return (
      <div className="mote-brand">
        <div className="container">
          <Header />
          <embed src="docs/082420.pdf" width="800px" height="2100px" />

          <Footer />
        </div>
      </div>
    )
  }
}

export default Press
