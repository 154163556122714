import React from 'react'
import Creatable from 'react-select/creatable'
import { getMoteLogin, postSurvey } from '../../shared/Auth'
import { noLog as log } from '../../shared/Utils'

class Survey extends React.Component {
  constructor(props) {
    super(props)
    this.onSuccess = this.props.onSuccess
    const loginData = getMoteLogin()
    var hints = []
    if (
      loginData.institutionNameHints &&
      Array.isArray(loginData.institutionNameHints)
    ) {
      hints = loginData.institutionNameHints
    }
    log('loginData: ', loginData)
    log('hints', hints)
    this.state = {
      q: 'A1',
      survey: { institutionName: '' },
      institutionNameHints: hints
    }
  }

  render() {
    return (
      <div>
        {/* <h3>You're almost there...</h3> */}
        <h3>
          Help us tailor your <span className="mote-purple">mote</span>{' '}
          experience...
        </h3>
        <p>...by answering a few quick questions!</p>

        {this.state.q === 'A1' && this._questionA1()}
        {this.state.q === 'T2' && this._questionT2()}
        {this.state.q === 'O2' && this._questionO2()}
        {this.state.q === 'T3' && this._questionT3()}
        {this.state.q === 'O3' && this._questionO3()}
        {this.state.q === 'A4' && this._questionA4()}
        {this.state.q === 'Posting' && this._posting()}
        {this.state.q === 'Failed' && this._failed()}
      </div>
    )
  }

  _questionA1() {
    const key = 'is_for_education'
    const question = 'How are you planning to use mote?'
    return (
      <div>
        <p className="lead">Q1 (of 4): {question} </p>
        {this._buttonAnswer('T2', key, question, 'For education', true)}{' '}
        <br></br>
        {this._buttonAnswer('O2', key, question, 'For something else', false)}
      </div>
    )
  }

  _questionT2() {
    const question = 'What subjects do you teach?'
    const key = 'teaches_subject'
    return (
      <div>
        <p className="lead">:Q2 (of 4): {question} </p>
        <div className="survey-container">
          {this._mcqAnswer(key, question, 'Mathematics', 'math')}
          {this._mcqAnswer(key, question, 'Science', 'science')}
          {this._mcqAnswer(key, question, 'Computer Science', 'compsci')}
          {this._mcqAnswer(key, question, 'Special Education', 'spec_ed')}
          {this._mcqAnswer(key, question, 'Social Studies', 'social')}
          {this._mcqAnswer(key, question, 'Creative Arts', 'arts')}
          {this._mcqAnswer(key, question, 'English / Language Arts', 'ela')}
          {this._mcqAnswer(key, question, 'Foreign Languages', 'languages')}
          {this._mcqAnswer(key, question, 'P.E. / Health', 'pe_health')}
          {this._mcqAnswer(key, question, 'Other', 'other')}
        </div>
        <button
          className="btn-answer btn-large"
          onClick={() => {
            this.setState({ q: 'A1' })
            this._clearKey(key)
          }}
        >
          Back
        </button>
        <button
          className="btn-answer btn-large"
          disabled={!this._hasKey(key)}
          onClick={() => this.setState({ q: 'T3' })}
        >
          Next
        </button>
      </div>
    )
  }

  _questionO2() {
    const question = 'What industry do you work in?'
    const key = 'industry'
    return (
      <React.Fragment>
        <p className="lead">Q2 (of 4): {question}</p>
        <div className="survey-container">
          {this._buttonAnswer('O3', key, question, 'Media', 'media')}
          {this._buttonAnswer('O3', key, question, 'Technology', 'technology')}
          {this._buttonAnswer('O3', key, question, 'Finance', 'finance')}
          {this._buttonAnswer('O3', key, question, 'Law', 'law')}
          {this._buttonAnswer('O3', key, question, 'Other', 'other')}
        </div>
        <button
          className="btn-answer btn-large"
          onClick={() => {
            this.setState({ q: 'A1' })
            this._clearKey(key)
          }}
        >
          Back
        </button>
      </React.Fragment>
    )
  }

  _questionT3() {
    const question = 'What grades do you teach?'
    const key = 'teaches_grade'

    return (
      <div>
        <p className="lead">Q3 (of 4): {question}</p>
        <div className="survey-container">
          {this._mcqAnswer(key, question, 'Pre-K/K', 'kindergarten')}
          {this._mcqAnswer(key, question, 'Grades 1 to 3', 'grade_1_to_3')}
          {this._mcqAnswer(key, question, 'Grades 4 to 5', '4_to_5')}
          {this._mcqAnswer(key, question, 'Grades 6 to 8', '6_to_8')}
          {this._mcqAnswer(key, question, 'Grades 9 to 12', '9_to_12')}
          {this._mcqAnswer(key, question, 'Higher Education', 'higher_ed')}
          {this._mcqAnswer(key, question, 'Other', 'other')}
          {this._mcqAnswer(key, question, "None - I don't teach", 'none')}
        </div>
        <button
          className="btn-answer btn-large"
          onClick={() => {
            this.setState({ q: 'T2' })
            this._clearKey(key)
          }}
        >
          Back
        </button>
        <button
          className="btn-answer btn-large"
          disabled={!this._hasKey(key)}
          onClick={() => this.setState({ q: 'A4' })}
        >
          Next
        </button>
      </div>
    )
  }

  _questionO3() {
    const key = 'role'
    const question = 'What best describes your role?'
    return (
      <div>
        <p className="lead">Q3 (of 4): {question}</p>
        <div className="survey-container">
          {this._buttonAnswer('A4', key, question, 'Executive', 'executive')}
          {this._buttonAnswer('A4', key, question, 'Manager', 'manager')}
          {this._buttonAnswer(
            'A4',
            key,
            question,
            'Product manager',
            'product_manager'
          )}
          {this._buttonAnswer(
            'A4',
            key,
            question,
            'Software engineer',
            'software_engineer'
          )}
          {this._buttonAnswer('A4', key, question, 'Designer', 'designer')}
          {this._buttonAnswer('A4', key, question, 'Consultant', 'consultant')}
          {this._buttonAnswer('A4', key, question, 'Other', 'other')}
        </div>
        <button
          className="btn-answer btn-large"
          onClick={() => {
            this.setState({ q: 'O2' })
            this._clearKey(key)
          }}
        >
          Back
        </button>
      </div>
    )
  }

  _questionA4() {
    const hints = this.state.institutionNameHints
    const hasHints = hints.length > 0

    const options = []
    if (hasHints) {
      hints.forEach((hint) => options.push({ value: hint, label: hint }))
    }
    var _updater = (v) => {
      log('received value: ', v)
      //   var vv = v;
      //   if(typeof vv !== "string") {
      // vv = vv.value;
      //   }
      //   log("actually using value: " , vv);
      const survey = this.state.survey
      survey.institutionName = v
      this.setState({ survey: survey })
    }

    return (
      <div>
        <p className="lead">
          Q4 (of 4): Enter or select the name of your{' '}
          {this.state.survey.is_for_education[0].value
            ? 'school'
            : 'organization'}
          {''}
        </p>
        {hasHints && (
          <Creatable
            isClearable
            options={options}
            value={this.state.survey.institutionName}
            onChange={_updater}
            onInputChange={(v, action) => {
              if (action === 'set-value') {
                _updater(v)
              }
            }}
            formatCreateLabel={() => {
              return 'Adding ...'
            }}
          />
        )}
        {!hasHints && (
          <span>
            {' '}
            <input
              type="text"
              onChange={(e) => _updater(e.target.value)}
              value={this.state.survey.institutionName}
            ></input>
          </span>
        )}
        <div>
          <button
            className="btn-answer btn-large"
            onClick={() =>
              this.setState({ q: this.state.survey.is_teacher ? 'T3' : 'O3' })
            }
          >
            Back
          </button>

          <button
            className="btn-answer btn-large"
            onClick={() => {
              this.setState({ q: 'Posting' })
              var survey = this.state.survey
              if (typeof survey.institutionName == 'object') {
                survey.institutionName = survey.institutionName.value
              }
              log('about to send survey', survey)
              postSurvey(
                survey,
                () => {
                  log('got success !!!')
                  this.onSuccess()
                },
                () => {
                  log('got a failure!')
                  this.setState({ q: 'Failed' })
                }
              )
            }}
          >
            Done!
          </button>
        </div>
      </div>
    )
  }

  _posting() {
    return <div>Posting your survey response ...</div>
  }

  _failed() {
    return (
      <div>
        Hmmm, something funky happened and we couldn't process your response. It
        could be a network problem your end or a server problem our end. Try
        again later. Sorry :-(
      </div>
    )
  }

  _buttonAnswer(advanceTo, key, question, label, value) {
    return (
      <button
        className="btn-answer"
        onClick={() => {
          const survey = this.state.survey
          survey[key] = [{ question: question, label: label, value: value }]
          this.setState({ q: advanceTo, survey: survey })
          log('survey state is now: ', survey)
        }}
      >
        {label}
      </button>
    )
  }

  _mcqAnswer(key, question, label, value) {
    var isChosen = false
    var foundIndex = -1
    if (this.state.survey[key]) {
      const o = this.state.survey[key]
      for (let i = 0; i < o.length; i++) {
        if (o[i].value === value) {
          isChosen = true
          foundIndex = i
        }
      }
    }
    return (
      <button
        className={isChosen ? 'btn-answer' : 'btn-option'}
        onClick={() => {
          const survey = this.state.survey
          if (isChosen) {
            survey[key].splice(foundIndex, 1)
            if (survey[key].length === 0) {
              delete survey[key]
            }
          } else {
            const response = { question: question, label: label, value: value }
            if (!survey[key]) {
              survey[key] = [response]
            } else {
              survey[key].push(response)
            }
          }
          this.setState({ survey: survey })
          log('survey state is now: ', survey)
        }}
      >
        {label}
      </button>
    )
  }

  _hasKey(key) {
    return this.state.survey[key] && this.state.survey[key].length > 0
  }

  _clearKey(key) {
    var survey = this.state.survey
    if (survey[key]) {
      delete survey[key]
      this.setState({ survey: survey })
    }
  }
}

export default Survey
