import React from 'react'
import ReactModal from 'react-modal'
import {
  searchUsers,
  deleteUser,
  makeVIP,
  unmakeVIP,
  freeTrialExtension
} from '../../shared/Admin'
import { yesno, EpochToPrettyDate } from '../../shared/Utils'
import { Footer } from '../../shared/Footer'

const deleteModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

class Users extends React.Component {
  constructor(props) {
    super(props)
    if (this.props.updateUserList) {
      this.updateUserList = this.props.updateUserList
    } else {
      this.updateUserList = () => {}
    }
    this.loadingUpdater = (b, err) => {
      if (err) {
        this.setState({ error: err.toString() })
      } else {
        this.setState({ error: undefined })
      }
    }
    var searchBox = ''
    if (this.props.userList && this.props.userList.length > 0) {
      searchBox = this.props.userList.join(' ')
    }
    this.state = { searchBox: searchBox }
  }

  render() {
    const searchedUsers = this._getSearchItems()
    const disabled = searchedUsers.length === 0
    return (
      <div className="mote-brand">
        <form method="post">
          {this.props.users && (
            <p>
              Enter some user ids or email addresses below (one per line, space
              or comma-delimited) to look them up
            </p>
          )}
          {this.props.schools && (
            <p>Copy and paste the email addresses you received from the CSV.</p>
          )}

          <textarea
            rows="3"
            name="searchTerms"
            cols="80"
            value={this.state.searchBox}
            onChange={(e) => this._handleSearchChange(e)}
          ></textarea>
          <br />
          <input
            disabled={disabled}
            type="submit"
            value="Go!"
            onClick={(e) => this._handleUserSearch(e)}
          />
        </form>
        {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
        {this.state.confirmationMessage && (
          <p style={{ color: 'blue' }}>{this.state.confirmationMessage}</p>
        )}

        {this.state.results &&
          this.state.results.users &&
          this.state.results.users.length === 0 && (
            <div>
              <p>No matching users found in our database</p>
              {this.props.schools && (
                <div style={{ color: 'red' }}>
                  <p>
                    WARNING: You can proceeed, but it would seem highly unlikely
                    that not a single user in the CSV is on our system.
                  </p>
                  <button onClick={() => this.adminUserPicker('', '')}>
                    Yes, I know what I'm doing. Keep going
                  </button>
                </div>
              )}
            </div>
          )}
        {this.state.results &&
          this.state.results.users &&
          this.state.results.users.length > 0 &&
          this._renderUserResults()}
        <Footer />
      </div>
    )
  }

  _renderUserResults() {
    const users = this.state.results.users
    const searchedUsers = this._getSearchItems()
    const delta = searchedUsers.length - users.length

    return (
      <div>
        {this.props.schools && (
          <div>
            <h3>Results</h3>
            <p>
              Matched {users.length} existing user
              {users.length > 1 && 's'}
            </p>
            {delta && (
              <p>
                Didn't match {delta} user{delta > 1 && 's'}, who must be new,
                and to be provisioned.
              </p>
            )}
          </div>
        )}
        <h3>
          Found {users.length} user{users.length > 1 && 's'}:
        </h3>

        <table className="table">
          <thead className="thead-light">
            <tr>
              <th className="text-uppercase" scope="col">
                User id
              </th>
              <th className="text-uppercase" scope="col">
                Name
              </th>
              <th className="text-uppercase" scope="col">
                Email
              </th>
              <th className="text-uppercase" scope="col">
                Product
              </th>
              <th className="text-uppercase" scope="col">
                Total motes
              </th>
              <th className="text-uppercase" scope="col">
                Minor?
              </th>
              <th className="text-uppercase" scope="col">
                Paid?
              </th>
              <th className="text-uppercase" scope="col">
                VIP?
              </th>
              <th className="text-uppercase" scope="col">
                On referral Unlimited?
              </th>
              <th className="text-uppercase" scope="col">
                In free trial?
              </th>
              <th className="text-uppercase" scope="col">
                Institutional?
              </th>
              <th className="text-uppercase" scope="col">
                Admin actions
              </th>
            </tr>
          </thead>
          <tbody>{users.map((u) => this._renderUser(u))}</tbody>
        </table>
      </div>
    )
  }

  _renderUser(u) {
    var canMutateUser = this.props.users && !u.isInstitution
    var showFTE = !(u.isPaid || u.isVIP || u.isMinor) && canMutateUser
    return (
      <tr key={u.userId}>
        <td>
          <a href={'/account?uid=' + u.userId}>{u.userId}</a>
        </td>
        <td>{u.name}</td>
        <td>{u.primaryEmailAddress}</td>
        <td> {u.productName}</td>
        <td>
          <a href={'/account/activity?uid=' + u.userId}>{u.totalMotesMade}</a>
        </td>
        <td>{yesno(u.isMinor)}</td>
        <td>{yesno(u.isPaid)}</td>
        <td>{yesno(u.isVIP)}</td>
        <td>{yesno(u.isOnReferralUnlimited)}</td>
        {u.isInFreeTrial && (
          <td>
            Ends at {EpochToPrettyDate(u.freeTrialExpiryEpoch)}
            {showFTE && this._showFreeTrialExtension(u)}
          </td>
        )}
        {!u.isInFreeTrial && (
          <td>no {showFTE && this._showFreeTrialExtension(u)} </td>
        )}

        <td>{yesno(u.isInstitution)}</td>
        <td>
          {canMutateUser && !u.isVIP && (
            <React.Fragment>
              <button
                className="pay-button"
                onClick={() => this._makeVIP(u.userId)}
              >
                Make VIP
              </button>
              <br />
            </React.Fragment>
          )}
          {canMutateUser && u.isVIP && (
            <React.Fragment>
              <button
                className="pay-button"
                onClick={() => this._unmakeVIP(u.userId)}
              >
                Unmake VIP
              </button>
              <br />
            </React.Fragment>
          )}
          {canMutateUser && !u.isInstitutionalOwner && (
            <React.Fragment>
              <button
                className="pay-button"
                onClick={() =>
                  this.setState({
                    deleteConfirmationFor: u.userId
                  })
                }
              >
                Delete user
              </button>
              <br />
            </React.Fragment>
          )}
          <ReactModal
            isOpen={this.state.deleteConfirmationFor === u.userId}
            style={deleteModalStyle}
          >
            <div>
              <h1>Achtung! Beware! ΠΡΟΣΟΧΗ!</h1>
              <p>
                This user will lose all their motes if you proceed. This process
                is{' '}
                <b>
                  <font color="red">not reversible</font>
                </b>
                . Type the word <b>delete</b> in the box below to confirm
                deletion.
              </p>

              <p>
                This user has made {u.totalMotesMade} mote
                {u.totalMotesMade !== 1 && 's'}.
              </p>
              <p>
                <input
                  value={this.state.deleteConfirmation}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      deleteConfirmation: e.target.value
                    })
                  }
                />
              </p>
              <button
                disabled={this.state.deleteConfirmation !== 'delete'}
                onClick={() => {
                  this.setState({
                    deleteConfirmation: '',
                    deleteConfirmationFor: undefined
                  })
                  this._deleteUser(u.userId)
                }}
              >
                Confirm
              </button>
              <button
                onClick={() =>
                  this.setState({
                    deleteConfirmationFor: undefined
                  })
                }
              >
                Cancel
              </button>
            </div>
          </ReactModal>
        </td>
      </tr>
    )
  }

  _showFreeTrialExtension(u) {
    var userId = u.userId
    return (
      <div>
        Extend by weeks:
        {this._extensionButton(userId, 2)}
        {this._extensionButton(userId, 4)}
        {this._extensionButton(userId, 6)}
        {this._extensionButton(userId, 8)}
      </div>
    )
  }

  _extensionButton(u, n) {
    return (
      <button
        className="extend-trial-button"
        onClick={(e) => this._freeTrialExtension(u, n)}
      >
        {n}
      </button>
    )
  }

  _getSearchItems(setState, changedValue) {
    var searchBox = changedValue || this.state.searchBox
    if (!searchBox) {
      return []
    }
    var candidate = searchBox.trim().replace(/\s+/g, ',')
    candidate = candidate.replace(/,,/g, ',')
    const uidsAndEmails = candidate.split(',')
    if (
      uidsAndEmails.length === 0 ||
      (uidsAndEmails.length === 1 && !uidsAndEmails[0])
    ) {
      // log("none found");
      return []
    }
    if (this.state.schools) {
      for (const candidate of uidsAndEmails) {
        const atLocation = candidate.indexOf('@')
        if (atLocation === -1 || atLocation > candidate.length - 3) {
          if (setState) {
            this.setState({
              error: `Not an email address: ${candidate}`
            })
          }
          return []
        }
      }
    }
    // log("found some uids and emails: ", uidsAndEmails);
    if (setState) {
      this.setState({ error: undefined })
    }
    return uidsAndEmails
  }

  _handleSearchChange(e) {
    this.setState({ searchBox: e.target.value, confirmationMessage: '' })
    if (this.state.schools) {
      this.setState({ error: undefined })
      this._getSearchItems(this.state.schools, e.target.value)
    }
  }

  _handleUserSearch(e) {
    if (e) {
      e.preventDefault()
    }
    const uidsAndEmails = this._getSearchItems()
    this.updateUserList(uidsAndEmails)
    // log("sending these items: ", uidsAndEmails);
    this.loadingUpdater(true)
    searchUsers(
      uidsAndEmails,
      (results) => {
        this.loadingUpdater(false)
        this.setState({ results: results })
      },
      (err) => this.loadingUpdater(false, err)
    )
  }

  _makeVIP(userId) {
    this.loadingUpdater(true)
    makeVIP(
      userId,
      () => {
        this.setState({
          confirmationMessage: `The user [${userId}] is now a VIP`
        })
        this._handleUserSearch()
      },
      (err) => this.loadingUpdater(false, err)
    )
  }

  _unmakeVIP(userId) {
    this.loadingUpdater(true)
    unmakeVIP(
      userId,
      () => {
        this.setState({
          confirmationMessage: `The user [${userId}] is no longer a VIP`
        })
        this._handleUserSearch()
      },
      (err) => this.loadingUpdater(false, err)
    )
  }

  _deleteUser(userId) {
    this.loadingUpdater(true)
    deleteUser(
      userId,
      () =>
        this.setState({
          confirmationMessage: `This user [${userId}] will be deleted within a few minutes. You will get a confirmation email.`
        }),
      (err) => this.loadingUpdater(false, err)
    )
  }

  _freeTrialExtension(userId, weeks) {
    this.loadingUpdater(true)
    freeTrialExtension(
      userId,
      weeks,
      () => {
        this.setState({
          confirmationMessage: `This user [${userId}] has had their trial period extended by ${weeks} weeks`
        })
        this._handleUserSearch()
      },
      (err) => this.loadingUpdater(false, err)
    )
  }
}

export default Users
