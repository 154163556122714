import React from 'react'

export const useAudioStream = () => {
  const [sourceStream, setSourceStream] = React.useState(null)
  const [audioContext, setAudioContext] = React.useState(null)
  const [audioStream, setAudioStream] = React.useState(null)

  React.useEffect(() => {
    if (!sourceStream || !audioContext) return

    const audioStream = audioContext.createMediaStreamSource(sourceStream)
    setAudioStream(audioStream)
  }, [sourceStream, audioContext])

  const start = () => {
    setupSourceStream()
    setupAudioContext()
  }

  const stop = () => {
    if (!sourceStream) return

    sourceStream.getTracks().forEach((track) => {
      try {
        track.stop()
      } catch (e) {
        console.log(e)
      }
    })

    setSourceStream(null)
    setAudioStream(null)
    setAudioContext(null)
  }

  const setupSourceStream = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    })
    setSourceStream(stream)
  }

  const setupAudioContext = () => {
    const context = new (window.AudioContext || window.webkitAudioContext)()
    setAudioContext(context)
  }

  return [start, stop, audioContext, audioStream]
}
