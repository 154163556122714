import React from 'react'

export const useCountdownTimer = (time = 30) => {
  const [seconds, setSeconds] = React.useState(time)
  const [isActive, setIsActive] = React.useState(false)

  function toggle() {
    setIsActive(!isActive)
  }

  function reset() {
    setSeconds(time)
    setIsActive(false)
  }

  React.useEffect(() => {
    let interval = null

    interval = setInterval(() => {
      if (seconds <= 0) {
        clearInterval(interval)
      } else if (isActive) {
        setSeconds((seconds) => seconds - 1)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [seconds, isActive])

  return {
    seconds,
    isActive,
    toggle,
    reset
  }
}
