import React from 'react'
import GoogleButton from 'react-google-button'
import { UserAgent } from '@quentin-sommer/react-useragent'

import { error } from '@mote/common'

import {
  trackCWSFunnelBegin,
  trackCWSFunnelEnd,
  postErrorEvent,
  track
} from '../../shared/AnalyticsHelpers'
import {
  addExtensionFoundListener,
  afterMoteAuth,
  getMoteLogin,
  getOAuthURL,
  hasScope,
  identifyMaybe,
  isValidScope,
  isVanillaScopeSet,
  loginWith,
  logout
} from '../../shared/Auth'
import { LEGALS_VERSION } from '../../shared/Constants'
import GetMoteLink from '../../shared/GetMoteLink'
import { Footer } from '../../shared/Footer'
import Header from '../../shared/Header'
import Loading from '../../shared/Loading'
import MoteCarousel from '../../shared/MoteCarousel'
import Survey from './Survey'
import { log as _log, isDevForceLogin } from '../../shared/Utils'
import { FAKE_DATA } from './dev_fake_data'

function log(m, ...o) {
  const prefix = 'Login/' + m
  _log(prefix, ...o)
}

const queryString = require('query-string')

const LOGIN_TIMEOUT_SECONDS = 15

class Login extends React.Component {
  /////////////////// public React methods /////////////////

  constructor(props) {
    super(props)
    try {
      this.referrerCode = this.props.match.params.referrerCode
      // log("Found a referral code: " + this.referrerCode);
    } catch (e) {
      this.referrerCode = undefined
      // log("No referral code found");
    }

    this.state = {
      userDetails: {},
      isMinor: false,
      needsConsent: true,
      renderMode: 'Init',
      extensionFound: false,
      justCompletedSurvey: false
    }
    this.queryParams = {}
    addExtensionFoundListener(() => {
      this.setState({ extensionFound: true })
    })
    this.scopeToAdd = ''
    trackCWSFunnelBegin()

    log('constructor isVanillaScope() ' + isVanillaScopeSet())
  }

  render() {
    log('render: mode is ' + this.state.renderMode)
    var methodName = '_render' + this.state.renderMode
    var method = this[methodName].bind(this)
    return (
      <div className="container mote-brand">
        <Header />
        <div>
          {method()}
          <div height="600"></div>
          <Footer />
        </div>
      </div>
      // </div>
    )
  }

  componentDidMount() {
    document.title = 'login'
    if (!isDevForceLogin()) {
      this._initLogin()
      setTimeout(() => {
        if (this.state.renderMode === 'Init') {
          // log("Timing out afer " + LOGIN_TIMEOUT_SECONDS + " seconds");
          track('Authentication: Login Error')
          this.setState({
            renderMode: 'LoginError',
            error: `We could not log you in after ${LOGIN_TIMEOUT_SECONDS} seconds.`
          })
        }
      }, LOGIN_TIMEOUT_SECONDS * 1000)
    } else {
      this._initQueryParams()
      this.setState(FAKE_DATA)
    }
  }

  ////////////// rendering methods for various states //////////

  _renderInit() {
    return <Loading />
  }

  _renderAgeGate() {
    return (
      <div className="container mote-brand">
        <div className="text-center justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-6 px-lg-5">
              <h1 className="mote-login-title">
                Welcome to <b className="mote-purple">mote</b>!
              </h1>

              <p className="lead">
                There's a version of <b className="mote-purple">mote</b> for
                everyone, but please first tell us: <br />
                {/* To get started, please tell us: */}
              </p>
              {/* <h3>What is your age?</h3> */}

              <div>
                <img
                  width="200"
                  src="/images/illustrations/over18.png"
                  alt="over18"
                ></img>
              </div>
              <div>
                <button
                  className="btn btn-outline-primary m-2"
                  width="80px"
                  onClick={() => this._selectMinor(false)}
                >
                  Yes
                </button>
                <button
                  className="btn btn-outline-primary m-2"
                  width="80px"
                  onClick={() => this._selectMinor(true)}
                >
                  No
                </button>
              </div>
              <a
                className="mote-boiler-link small"
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.mote.com/hc/en-us/articles/360058027072"
              >
                What's the difference?
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderNeedsGoogleSignIn() {
    return (
      <div className="container mote-brand">
        <div className="text-center justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 px-lg-5">
              <UserAgent returnFullParser>
                {(parser) =>
                  parser.getBrowser().name === 'Chrome' &&
                  parser.getBrowser().version < '80' && (
                    <div>
                      <div className="alert alert-danger" role="alert">
                        <i className="fas fa-exclamation-circle mr-1"></i>
                        It looks like you're using an old, unsupported version
                        of Chrome, and you will not be able to record voice
                        notes. Please try updating Chrome, or speak to your IT
                        department.
                      </div>
                    </div>
                  )
                }
              </UserAgent>
              <UserAgent returnFullParser>
                {(parser) =>
                  parser.getBrowser().name === 'Edge' &&
                  parser.getBrowser().version > '80' && (
                    <div>
                      <div className="alert alert-primary" role="alert">
                        <i className="fas fa-exclamation-circle mr-1"></i>
                        It looks like you're using the new Edge Chromium. Mote
                        should work well, but please let us know if you
                        experience issues.
                      </div>
                    </div>
                  )
                }
              </UserAgent>

              {!this.state.loggedInButNeedsScope && (
                <div>
                  <h1 className="text-center mote-login-title">
                    Welcome to <b className="mote-purple">mote</b>!
                  </h1>
                  <p className="lead">
                    To create your own <b className="mote-purple">mote</b> voice
                    notes:
                  </p>
                  <h3>Please sign in</h3>{' '}
                </div>
              )}
            </div>
          </div>
        </div>

        {!this.state.isMinor && !this.state.loggedInButNeedsScope && (
          <div className="row justify-content-center">
            <label className="login-checkbox">
              I acknowledge that I have read and agree to the Mote{' '}
              <a className="mote-boiler-link" href="/ToS" target="new">
                Terms of Service
              </a>{' '}
              and{' '}
              <a className="mote-boiler-link" href="/privacy" target="new2">
                Privacy Policy
              </a>
              <input
                type="checkbox"
                onClick={() => {
                  // log("T&C consent ticked");
                  this.setState({ needsConsent: !this.state.needsConsent })
                }}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        )}
        {this.state.loggedInButNeedsScope && (
          <div>
            <h1 className="mote-logged-in-title">Welcome back!</h1>

            <p>
              You are logged in, but you tried to record a{' '}
              <b className="mote-purple">mote</b> inside Google Slides.{' '}
            </p>

            <p>
              We can only do that if you to request Google to allow us to write
              into your Google Drive and Google Slides.{' '}
            </p>

            <p>
              Please sign in again below to start recording voice notes with{' '}
              <b className="mote-purple">mote</b> directly inside Google Slides!
            </p>

            <p />
          </div>
        )}

        <div className="row justify-content-center">
          <GoogleButton
            type="dark" // can be light or dark
            disabled={this.state.needsConsent}
            onClick={() => this._startOAuth()}
          />
        </div>
        {!this.state.loggedInButNeedsScope && (
          <React.Fragment>
            {!this._getScopeToAdd() && (
              <React.Fragment>
                {isVanillaScopeSet() && (
                  <div className="row justify-content-center mt-4">
                    <p>
                      {' '}
                      Google will share your name, email address and profile
                      picture with Mote.
                    </p>
                  </div>
                )}

                {!isVanillaScopeSet() && (
                  <div className="row justify-content-center mt-4">
                    <p>
                      Google will share your name, email address, profile
                      picture and grant again permission insert audio voice
                      notes into Google Drive for Google Slides.
                    </p>
                  </div>
                )}
              </React.Fragment>
            )}

            {this._getScopeToAdd() && (
              <div className="row">
                <br />
                <p>
                  You want to record a <b className="mote-purple">mote</b>{' '}
                  inside Google Slides.{' '}
                </p>

                <p>
                  We can only do that if you to tell Google to allow us to write
                  into your Google Drive and read Google Slides. We will also
                  need your name, email address and profile picture.{' '}
                </p>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }

  _renderLoginError() {
    return (
      <div>
        <div className="text-center justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h3 className="text-center mote-logged-in-title">
                Aw snap. We haven't been able to sign you in.
              </h3>

              <p className="mote-login-install-cta">
                Please try clicking below to refresh your browser. <br></br>If
                that doesn't work, please click 'Logout' above, and then try
                signing in with another Google account (if you have one).
                <br></br>
                If you've tried that, and still see this message, the issue may
                be caused by a problem with the Google permissions that are
                stored on your machine. The easiest way to fix this is to simply
                select 'Clear browsing data' from the Chrome menu in your
                browser, then ensure 'Cookies and other site data' is selected,
                and then ensure that you're clearing at least the last 24 hours.
                {/* the bottom of the problem if you copy and paste the contents of
                Chrome &gt; View &gt; Developer &gt; JavaScript Console to
                <a href="mailto:diagnostics@justmote.me">
                  diagnostics@justmote.me
                </a> */}
                If you can't then login, please email support@mote.com and we'll
                do our best to get you up and running.
              </p>
              <br></br>
              <button
                className="btn btn-primary"
                onClick={() => {
                  window.location.href =
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    '/login'
                }}
              >
                Click to refresh
              </button>
            </div>
            <div>{this.state.error}</div>
          </div>
        </div>
      </div>
    )
  }

  _renderJustCompletedSurvey() {
    if (this.state.justCompletedSurvey) {
      return (
        <div>
          <h3>You rock - thanks for completing the survey!</h3>
        </div>
      )
    } else {
      return <React.Fragment />
    }
  }

  _renderLoggedIn() {
    const loginData = getMoteLogin()
    // log("loginData is: ", loginData);
    var mode
    if (
      !this.state.justCompletedSurvey &&
      loginData.needsSurvey
      // &&
      // ("jane.doe@foobar.com" === loginData.primaryEmailAddress ||
      // loginData.primaryEmailAddress.indexOf("@justmote.me") > -1)
    ) {
      mode = 'survey'
    } else if (!this.state.extensionFound) {
      mode = 'installExtension'
    } else {
      mode = 'allSet'
    }
    return (
      <div>
        <p className="text-right mote-logout" onClick={() => this._logout()}>
          Logout
        </p>
        <div className="justify-content-center">
          <div>
            <h1 className="mote-logged-in-title">Welcome!</h1>
          </div>
          <div style={{ textAlign: 'left' }}>
            <p>You're signed in as {loginData.primaryEmailAddress} </p>
          </div>

          {mode === 'survey' && (
            <Survey
              onSuccess={() => {
                this.setState({ justCompletedSurvey: true })
              }}
            />
          )}

          {mode === 'installExtension' && (
            <div>
              {this._renderJustCompletedSurvey()}

              <p className="mote-login-install-cta">
                To use <b className="mote-purple">mote</b>, just click below to
                add our Chrome extension:
              </p>
              <GetMoteLink />
            </div>
          )}

          {mode === 'allSet' && (
            <div>
              {this._renderJustCompletedSurvey()}

              {this._wasScopeAdded() && (
                <div>
                  <h3>Great! You're all set!</h3>

                  <p>
                    Go back to your Google Slides tab to start recording audio
                    notes with <b className="mote-purple">mote</b>.
                  </p>

                  {/* <p>
                    And of course, you can also use{' '}
                    <b className="mote-purple">mote</b> inside Google Docs,
                    Sheets and Classroom.
                  </p> */}
                </div>
              )}
              {!this._wasScopeAdded() && (
                <div>
                  {/* <p className="lead">
                    <a
                      href="https://docs.google.com/document/"
                      className="mote-google-docs-link"
                    >
                      Open Google Docs, Sheets, Slides
                    </a>{' '}
                    or{' '}
                    <a
                      href="https://classroom.google.com/"
                      className="mote-google-docs-link"
                    >
                      Classroom
                    </a>{' '}
                    and hit 'refresh' to start using{' '}
                    <b className="mote-purple">mote</b>
                  </p> */}
                  <MoteCarousel />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  ///////////////////// state management //////////////////////

  _onGoogleError(e) {
    if (!e) {
      return
    }
    error('Google auth error: ', e)
    if (e && e.error && e.error === 'popup_closed_by_user') {
      // log('Pop up closed, don't need to log this');
      return
    }
    var tags = []
    var errorText =
      'User-facing error message: ' +
      this.state.error +
      '\n\nInternal error:' +
      e
    if (e && e.details) {
      errorText += '\n\nError details: ' + e.details
    }
    if (e && e.error) {
      errorText += '\n\nError details: ' + e.error
    }
    log('_onGoogleError: Login error details: ' + errorText)
    try {
      log('_onGoogleError: Raw error: ' + e.toString())
    } catch (e) {}
    postErrorEvent('Login Error', 'loginError', errorText, tags)
    this.setState({
      renderMode: 'LoginError',
      error: 'Cannot currently login to Google, please try again later.'
    })
  }

  _logout() {
    try {
      logout()
    } catch (e) {
      postErrorEvent('Logout Error', 'logoutError', e)
    }
    this.setState({ renderMode: 'AgeGate' })
  }

  _fixURL() {
    try {
      log('Cleaning up URL: ' + window.location.href)
      window.history.replaceState(
        { page: 1 },
        'Mote Login',
        window.location.protocol + '//' + window.location.host + '/login'
      )
    } catch (e) {}
  }

  _initQueryParams() {
    log('_initQueryParams: initial raw qs: ', window.location.search)
    var querySearch = queryString.parse(window.location.search)
    log('_initQueryParams: initial parse of qs: ', querySearch)
    var queryHash = queryString.parse(window.location.hash)
    Object.assign(this.queryParams, querySearch, queryHash)
    if (this.referrerCode) {
      this.queryParams.referrerCode = this.referrerCode
    }
    if (this.queryParams.state) {
      var state = queryString.parse(this.queryParams.state)
      for (const key of Object.keys(state)) {
        this.queryParams[key] = state[key]
      }
      if ('isMinor' in state) {
        this.queryParams.isMinor =
          state.isMinor === '1' ||
          state.isMinor === 'true' ||
          state.isMinor === true
            ? true
            : false
        // log('setting isMinor to: ' + this.queryParams.isMinor);
      }
    }
    log(
      '_initQueryParams: Query params are: ' + JSON.stringify(this.queryParams)
    )
    if (this.queryParams.addScope && isValidScope(this.queryParams.addScope)) {
      this.scopeToAdd = this.queryParams.addScope
      delete this.queryParams.addScope
    }
    if (this.queryParams.state) {
      var withScopes = this.scopeToAdd ? 1 : 0
      track('Authentication: Returning from Google OAuth', {
        withScopes: withScopes
      })
    }
    log('_initQueryParams: Scope to add is: ' + this._getScopeToAdd())
    log('_initQueryParams: Have we added this scope? ' + this._wasScopeAdded())
    log(
      '_initQueryParams: Query params after processing: ' +
        JSON.stringify(this.queryParams)
    )
  }

  _safeQueryParams() {
    var safe = {}
    for (const key of Object.keys(this.queryParams)) {
      if (['state', 'code', 'error', 'force'].indexOf(key) === -1) {
        safe[key] = this.queryParams[key]
      }
    }
    return safe
  }

  _serialiseSafeQueryParams() {
    var safe = this._safeQueryParams()
    // log('about to serialise: ', safe);
    var safeParams = new URLSearchParams(safe)
    return safeParams.toString()
  }

  _startOAuth() {
    var safe = this._safeQueryParams()
    safe.isMinor = this.state.isMinor
    safe.legals = LEGALS_VERSION
    var additionalScope = this._getScopeToAdd()
    var url = getOAuthURL(additionalScope, safe)
    log('_startOAuth: Sending to: ' + url)
    window.location.href = url
  }

  _initLogin() {
    this._initQueryParams()
    this._fixURL()
    if ('force' in this.queryParams || this.referrerCode) {
      this._logout()
      return
    }

    var setScopeSensitivePostLoginState = () => {
      log('checking whether we need to go to re google login ')
      if (this._getScopeToAdd()) {
        log('we need scope')
        track('Authentication: Start Login', { withScopes: 1 })
        this.setState({
          loggedInButNeedsScope: true,
          needsConsent: false,
          renderMode: 'NeedsGoogleSignIn'
        })
      } else {
        log('no, we can just drop into vanilla LoggedIn state')
        identifyMaybe()
        track('Authentication: Logged In')
        this.setState({ renderMode: 'LoggedIn' })
      }
    }

    var googleLogin = () => {
      if (this.queryParams.code || this.queryParams.error) {
        this._fixURL()
        if (this.queryParams.code) {
          loginWith(
            this.queryParams,
            () => {
              // log("Comparing returnTo: " + this.queryParams.returnTo);
              log(
                'googleLogin: Query params are: ' +
                  JSON.stringify(this.queryParams)
              )
              console.log('queryParams', this.queryParams)
              if (
                this.queryParams.returnTo &&
                _matchesAnyOf(this.queryParams.returnTo, [
                  'homepage',
                  'checkout',
                  'support',
                  'account',
                  'admin',
                  'gift',
                  'g/',
                  'm/'
                ])
              ) {
                console.log('REDIRECTING')
                // log("We are redirecting to returnTo");
                const loc = window.location
                window.location.href =
                  loc.protocol +
                  '//' +
                  loc.host +
                  this.queryParams.returnTo +
                  '?' +
                  this._serialiseSafeQueryParams()
              } else {
                setScopeSensitivePostLoginState()
              }
            },
            (e) => {
              this._onGoogleError(e)
            }
          )
        } else {
          this._onGoogleError(this.queryParams)
        }
      } else {
        // log("No auth token");
        this.setState({ renderMode: 'AgeGate' })
      }
    }

    if (this.queryParams.newScope) {
      googleLogin()
    } else {
      afterMoteAuth(
        () => {
          // log("Successfully authenticated");
          this._fixURL()
          trackCWSFunnelEnd()
          setScopeSensitivePostLoginState()
        },
        googleLogin,
        {}
      )
    }
  }

  _selectMinor(isMinor) {
    track('Authentication: Start Login', { withScopes: 0 })
    this.setState({ isMinor: isMinor, renderMode: 'NeedsGoogleSignIn' })
    if (isMinor) {
      this.setState({ needsConsent: false })
    }
  }

  _wasScopeAdded() {
    const scope = this.queryParams.newScope
    return scope && hasScope()
  }

  _getScopeToAdd() {
    log('_getScopeToAdd: checking scope to add: ' + this.scopeToAdd)
    if (this.scopeToAdd) {
      log('we got scopeToAdd: ' + this.scopeToAdd)
      const isValid = isValidScope(this.scopeToAdd)

      log('is it valid? ' + isValid)
      if (isValid) {
        return this.scopeToAdd
      }
    }
    log('no scope to add')
    return ''
  }
}

function _matchesAnyOf(x, l) {
  if (!x) {
    return false
  }
  for (const item of l) {
    // log(`looking for /${item} in ${x}`);
    if (x.includes('/' + item)) {
      // log("We have a match!!!!");
      return true
    }
  }
  return false
}

export default Login
