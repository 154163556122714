import React from 'react'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'
// import IntercomWrapper from "../shared/IntercomWrapper";
import Loading from '../shared/Loading'
import {
  listProducts,
  getSymbol,
  getSKU,
  prettyPriceAnnual,
  generateURLParamsFromSKU
} from '../shared/Commerce'
import { noLog as log } from '../shared/Utils'
import Tooltip from '@material-ui/core/Tooltip'

class Pricing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      failed: false,
      recommendedCurrency: 'usd',
      recommendedCurrencyLongName: 'US Dollars'
    }
  }

  componentDidMount() {
    document.title = 'Pricing'
    listProducts(
      (results) => {
        log('yay, got: ', results)
        this.setState(results)
        this.setState({ loading: false })
      },
      (e) => this.setState({ failed: true })
    )
  }

  _purchaseLink(label, product, plan) {
    const sku = getSKU(product, plan, this.state.recommendedCurrency)

    return (
      <div>
        {!sku && ' '}
        {sku && <a href={this._generateURL(sku)}>{label}</a>}
      </div>
    )
  }

  _purchasePrice(product, plan, discountPct) {
    const sku = getSKU(product, plan, this.state.recommendedCurrency)

    return (
      <span>
        {!sku && ' '}
        {sku && prettyPriceAnnual(sku, discountPct)}
      </span>
    )
  }

  _generateURL(sku) {
    return '/checkout?' + generateURLParamsFromSKU(sku)
  }

  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <main id="main">
          {this.state.failed && this._renderFailure()}
          {!this.state.failed && this.state.loading && <Loading />}
          {!this.state.failed && !this.state.loading && this._renderSuccess()}
          {/* <IntercomWrapper needsLogin={true} /> */}
          <Footer />
        </main>
      </div>
    )
  }

  _renderFailure() {
    return (
      <div>
        <div className="container">
          <div className="row justify-content-center py-4 py-md-3 py-xl-4">
            <div className="col-lg-10 col-xl-8 text-center mb-xl-2">
              <span className="alert-warning">
                It looks like we're unable to load price information right now.
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderSuccess() {
    var currency = getSymbol(this.state.recommendedCurrency)
    return (
      <div>
        <div className="container">
          <div className="row align-items-right">
            <div className="col-xl-10"></div>
            <a
              href="/checkoutschools"
              className="d-none d-md-block btn btn-pill btn-primary"
            >
              Get a quote
            </a>
          </div>
          <div className="justify-content-center">
            <h1 className="mote-login-title">
              There's a <b className="mote-purple">mote</b> for everyone
            </h1>
          </div>
        </div>
        <div className="container">
          <table className="table shadow pricing-table mb-5">
            <thead>
              <tr>
                <th scope="col" className="mote-pricing-table">
                  <div className="d-block h4 mb-1 text-muted">
                    <span>
                      Prices are in
                      <br />
                      {this.state.recommendedCurrencyLongName}
                    </span>
                  </div>
                  {/* <small className="d-block text-muted">And may be subject to sales tax / VAT</small> */}
                </th>
                <th scope="col">
                  <span className="d-block h4 mb-1 display-5 font-weight-normal pricing-column">
                    Free
                  </span>
                  <small className="d-block mote-purple">
                    Short &amp; sweet
                  </small>
                </th>
                <th scope="col">
                  <span className="d-block h4 mb-1 display-5 font-weight-normal pricing-column">
                    Essential
                  </span>
                  <small className="d-block mote-purple">
                    Share your voice
                  </small>
                </th>
                <th scope="col">
                  <span className="d-block h4 mb-1 display-5 font-weight-normal pricing-column">
                    Unlimited
                  </span>
                  <small className="d-block mote-purple">Mote to the max</small>
                </th>
                {/* <th scope="col">
                                    <span className="d-block h4 mb-1 display-5 font-weight-normal pricing-column">
                                        Schools
                                    </span>
                                    <small className="d-block mote-purple">
                                        Connect at scale
                                    </small>
                                </th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="col">For individuals</th>
                <th scope="col">
                  <div className="d-flex">
                    <span className="h4 font-weight-normal mt-1">
                      {currency}{' '}
                    </span>
                    <span className="display-4 font-weight-normal">0</span>
                  </div>
                  <small className="d-block text-white">
                    /year
                    <div className="pricing-cta-row">
                      <div className="buy-now-link-outline">
                        <a href="/login">Get started</a>
                      </div>
                    </div>
                  </small>
                </th>
                <th scope="col">
                  <div className="d-flex">
                    <span className="h4 font-weight-normal mt-1">
                      {currency}{' '}
                    </span>
                    <span className="display-4 font-weight-normal">
                      {this._purchasePrice('essential', 'year')}
                    </span>
                  </div>

                  <small className="d-block text-muted">
                    /year
                    <div className="pricing-cta-row">
                      <Tooltip
                        arrow
                        title="Buy a single subscription for yourself"
                        placement="bottom"
                        maxwidth="200"
                        backgroundcolor="theme.palette.common.black"
                      >
                        <div className="buy-now-link">
                          {this._purchaseLink('Buy now', 'essential', 'year')}
                        </div>
                      </Tooltip>
                    </div>
                  </small>
                </th>
                <th scope="col">
                  <div className="d-flex">
                    <span className="h4 font-weight-normal mt-1">
                      {currency}{' '}
                    </span>
                    <span className="display-4 font-weight-normal">
                      {this._purchasePrice('unlimited', 'year')}
                    </span>
                  </div>
                  <small className="d-block text-muted">
                    /year
                    {/* <span className="d-block special-offer">
                                            Jan special: save 20% with code <b>motemyday</b>
                                        </span> */}
                    <div className="pricing-cta-row">
                      <Tooltip
                        arrow
                        title="Buy a single subscription for yourself"
                        placement="bottom"
                        maxwidth="200"
                        backgroundcolor="theme.palette.common.black"
                      >
                        <div className="buy-now-link">
                          {this._purchaseLink('Buy now', 'unlimited', 'year')}
                        </div>
                      </Tooltip>
                    </div>
                  </small>
                </th>
              </tr>
              <tr>
                <th scope="col">For schools and groups</th>
                <th scope="col">
                  <div className="d-flex">
                    <span className="h4 font-weight-normal mt-1">
                      {currency}{' '}
                    </span>
                    <span className="display-4 font-weight-normal">0</span>
                  </div>
                  <small className="d-block text-white">
                    /year
                    <div className="pricing-cta-row">
                      <div className="buy-now-link-outline">
                        <a href="/login">Get started</a>
                      </div>{' '}
                    </div>
                  </small>
                </th>
                <th scope="col">
                  <div className="d-flex">
                    <span className="h4 font-weight-normal mt-1">
                      {currency}{' '}
                    </span>
                    <span className="display-4 font-weight-normal">
                      {this._purchasePrice('essential', 'year')}
                    </span>
                  </div>

                  <small className="d-block text-muted">
                    /user per year
                    <div className="pricing-cta-row">
                      <Tooltip
                        arrow
                        title="See pricing, buy now or get an emailed quote"
                        placement="bottom"
                        maxwidth="200"
                        backgroundcolor="theme.palette.common.black"
                      >
                        <div className="buy-now-link">
                          <a href="/checkoutschools">Buy or get quote</a>
                        </div>
                      </Tooltip>
                    </div>
                  </small>
                </th>
                <th scope="col">
                  <div className="d-flex">
                    <span className="h4 font-weight-normal mt-1">
                      {currency}{' '}
                    </span>
                    <span className="display-4 font-weight-normal">
                      {this._purchasePrice('unlimited', 'year')}
                    </span>
                    <span>
                      <br></br>or less
                    </span>
                  </div>
                  <small className="d-block text-muted">
                    /user per year
                    <div className="pricing-cta-row">
                      <Tooltip
                        arrow
                        title="See pricing, buy now or get an emailed quote"
                        placement="bottom"
                        maxwidth="200"
                        backgroundcolor="theme.palette.common.black"
                      >
                        <div className="buy-now-link">
                          <a href="/checkoutschools">Buy or get quote</a>
                        </div>
                      </Tooltip>
                    </div>
                  </small>
                </th>
              </tr>
              <tr>
                <th scope="row">
                  Create unlimited{' '}
                  <a
                    href="https://www.youtube.com/watch?v=MWoA-gqsG5Q"
                    className="activity-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    mote voice notes and feedback
                  </a>
                </th>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                {/* <td>
                                <span className="material-icons pricing-icons" aria-hidden="true">
                                        done
                                        </span>
                                    <span className="sr-only">Yes</span>
                                </td> */}
              </tr>
              <tr>
                <th scope="row">
                  Use <span className="mote-purple">mote</span> to easily add
                  voice to your{' '}
                  <a
                    href="https://www.youtube.com/watch?v=TCLJHEQGRMk"
                    className="activity-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Slides
                  </a>{' '}
                  presentations
                </th>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  Listen-to &amp; read unlimited{' '}
                  <span className="mote-purple">mote</span> voice notes
                </th>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  Easily{' '}
                  <a
                    href="https://www.youtube.com/watch?v=MlICOr2ZU8w"
                    className="activity-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    create emojis
                  </a>{' '}
                  in Docs comments
                </th>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
              </tr>

              <tr>
                <th scope="row">
                  Monitor your{' '}
                  <a
                    href="https://www.youtube.com/watch?v=WhG-SjiMjd8"
                    className="activity-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    voice note engagement
                  </a>
                </th>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  Receive{' '}
                  <a
                    href="https://www.youtube.com/watch?v=Mf-3bc7QrJA"
                    className="activity-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    feedback reactions
                  </a>
                </th>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
              </tr>
              <tr>
                <th scope="row">Voice note recording time</th>
                <td>30s</td>
                <td>90s</td>
                <td>90s</td>
                {/* <td>90s</td> */}
              </tr>
              <tr>
                <th scope="row">
                  Voice-to-text{' '}
                  <a
                    href="https://www.youtube.com/watch?v=szwe2rswnIY"
                    className="activity-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    transcribe your mote voice notes
                  </a>{' '}
                </th>
                <td>
                  <span className="sr-only">No</span>
                </td>
                <td>
                  <span className="sr-only">No</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <a
                    href="https://www.youtube.com/watch?v=r8nqbol3qEE"
                    className="activity-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Translate
                  </a>{' '}
                  your <span className="mote-purple">mote</span> transcripts
                </th>
                <td>
                  <span className="sr-only">No</span>
                </td>
                <td>
                  <span className="sr-only">No</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  Save &amp; retrieve <span className="mote-purple">motes</span>{' '}
                  from your{' '}
                  <a
                    href="https://www.youtube.com/watch?v=EtQ2MJJZcaw"
                    className="activity-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    motebook
                  </a>
                </th>
                <td>
                  <span className="sr-only">No</span>
                </td>
                <td>
                  <span className="sr-only">No</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  Transcribe <b className="mote-purple">motes</b> with Math
                  &amp; Scientific notation using{' '}
                  <a
                    href="https://www.youtube.com/watch?v=B1mUfb8nlcs"
                    className="activity-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    STEM mode
                  </a>{' '}
                  (
                  <a
                    className="mote-boiler-link"
                    href="https://support.mote.com/hc/en-us/articles/360051688971-Using-mote-STEM-mode"
                  >
                    ?
                  </a>
                  )
                </th>
                <td>
                  <span className="sr-only">No</span>
                </td>
                <td>
                  <span className="sr-only">No</span>
                </td>
                <td>
                  <span
                    className="material-icons pricing-icons"
                    aria-hidden="true"
                  >
                    done
                  </span>
                  <span className="sr-only">Yes</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default Pricing
