import React from 'react'
import { styled } from 'twin.macro'
import { Content } from '../../../../layouts/shared/content'
import ActivityPNG from './assets/activity.png'
import MotePNG from './assets/mote.png'
import ReactPNG from './assets/react.png'
import StemPNG from './assets/stem.png'
import TranscribePNG from './assets/transcribe.png'
import TranslatePNG from './assets/translate.png'

const features = [
  {
    title: 'Record your voice',
    description:
      'It is fast and effortless, and your recordings live in the cloud.',
    image: MotePNG
  },
  {
    title: 'Transcribe your mote',
    description:
      'Instant transcriptions make your voice notes even more accessible.',
    image: TranscribePNG
  },
  {
    title: 'Translate your transcript',
    description:
      'Integrated translation is perfect for connecting with language learners.',
    image: TranslatePNG
  },
  {
    title: 'React with emojis',
    description:
      'Emoji reactions make it easy for recipients to acknowledge your comments.',
    image: ReactPNG
  },
  {
    title: 'Talk STEM',
    description:
      'STEM mode recognizes and renders scientific and mathematical language',
    image: StemPNG
  },
  {
    title: 'Track engagement',
    description:
      'See when your motes have been seen and heard, allowing you to follow up.',
    image: ActivityPNG
  }
]

export const FeaturesSection = () => {
  function renderFeatures() {
    return features.map((feature) => (
      <Feature key={feature.title}>
        <Details>
          <Image src={feature.image} />
          <Title>{feature.title}</Title>
          <Description>{feature.description}</Description>
        </Details>
      </Feature>
    ))
  }

  return (
    <Content>
      <Header>Key product features</Header>

      <FeaturesContainer>{renderFeatures()}</FeaturesContainer>
    </Content>
  )
}

const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Feature = styled.div`
  font-family: DM Sans;
  flex-basis: 33.333333%;
  padding: 10px;
  margin-bottom: 32px;
`

const Details = styled.div`
  width: 333px;
`

const Image = styled.img`
  height: 130px;
  margin-bottom: 32px;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 16px;
`

const Description = styled.div`
  font-size: 18px;
  line-height: 150%;
`

const Header = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 80px;
  margin-top: 48px;

  @media (max-width: 1199px) {
    font-size: 28px;
    margin-bottom: 60px;
    margin-top: 40px;
  }

  @media (max-width: 991px) {
    font-size: 24px;
    margin-bottom: 48px;
    margin-top: 32px;
  }

  @media (max-width: 639px) {
    font-size: 21px;
    margin-bottom: 32px;
    margin-top: 32px;
  }
`
