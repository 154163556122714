import React from 'react'

function Emoji() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      fill="none"
      viewBox="0 0 18 17"
    >
      <path
        fill="#5F5F5F"
        fillRule="evenodd"
        d="M0 9.034c0-3.766 2.973-6.892 6.723-7.09v1.641a5.456 5.456 0 00-5.084 5.45A5.434 5.434 0 007.1 14.495a5.46 5.46 0 005.187-3.74h1.702c-.775 3.092-3.586 5.379-6.889 5.379a7.095 7.095 0 01-7.1-7.1zm9.183 1.23c-1.024 1.228-3.175 1.228-4.199 0a.815.815 0 00-1.16-.07c-.342.274-.376.786-.103 1.127a4.438 4.438 0 003.38 1.537 4.367 4.367 0 003.345-1.537c.273-.341.239-.853-.102-1.126a.816.816 0 00-1.161.068zM4.916 8.487a1.1 1.1 0 001.092-1.092c0-.58-.512-1.093-1.092-1.093a1.1 1.1 0 00-1.093 1.093c0 .614.478 1.092 1.093 1.092zm4.37 0a1.1 1.1 0 001.092-1.092c0-.58-.512-1.093-1.093-1.093a1.1 1.1 0 00-1.092 1.093c0 .614.478 1.092 1.092 1.092z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#5F5F5F"
        d="M14.053 3.004h2.458c.273 0 .546.273.546.546v.546a.561.561 0 01-.546.547h-2.458V7.1a.561.561 0 01-.546.547h-.546a.54.54 0 01-.547-.547V4.643H9.957a.54.54 0 01-.547-.547V3.55c0-.273.24-.546.547-.546h2.457V.546c0-.273.24-.546.547-.546h.546c.273 0 .546.273.546.546v2.458z"
      ></path>
    </svg>
  )
}

export default Emoji
