import React from 'react'

import Header from '../shared/Header'
import { Footer } from '../shared/Footer'
import { BASE_URL, NETWORK_TIMEOUT } from '../shared/Constants'
import { getAccessToken } from '../shared/Auth'
const axios = require('axios').default

class SlideProbe extends React.Component {
  constructor() {
    super()
    this.state = {
      loggedIn: getAccessToken() ? true : false,
      loading: false,
      containingURL: ''
    }
  }

  componentDidMount() {
    this.cleanState()
  }

  cleanState() {
    this.setState({
      canInsert: '',
      error: '',
      errorCode: '',
      errorPhase: '',
      exception: ''
    })
  }

  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <h3>
          Let's test your <b className="mote-purple">mote</b> for Google Slides
          setup
        </h3>

        {this.state.loggedIn && this.renderLoggedIn()}
        {!this.state.loggedIn && this.renderLoginButton()}
        <Footer />
      </div>
    )
  }

  renderLoginButton() {
    return (
      <div>
        <p>You need to be logged in to use this feature</p>

        <button className="pay-button">
          <a href="/login?addScope=pseudo:google_slides_audio_insert&returnTo=/support/testSlides">
            Let's do it
          </a>
        </button>
      </div>
    )
  }

  renderLoggedIn() {
    return (
      <div>
        <p>
          <b>Please copy and paste your Google Slides URL below:</b>
        </p>
        <p>
          <input
            type="text"
            size="80"
            value={this.state.containingURL}
            onChange={(e) =>
              this.setState({ containingURL: e.target.value.trim() })
            }
          />
        </p>

        <p>
          <button
            onClick={() => this.runProbe()}
            className="pay-button"
            disabled={this.state.loading}
          >
            {this.state.loading && 'Testing...'}
            {!this.state.loading && 'Test'}
          </button>
        </p>

        {this.state.error && (
          <div>
            <p>
              We got this error which is probably unrelated to your Slides
              problem:{' '}
            </p>

            <p>
              <font color="red">{this.state.error}</font>
            </p>

            <p>Try again later or contact support@mote.com</p>
          </div>
        )}

        <p>
          {!this.state.loading && (
            <React.Fragment>
              {this.state.canInsert === true && (
                <div>
                  {' '}
                  <b>All good!</b> No errors returned. You should be able to
                  successfully insert your <b className="mote-purple">mote</b>{' '}
                  voice note into Google Slides.
                </div>
              )}

              {this.state.canInsert === false && (
                <div>
                  {' '}
                  <font color="red">
                    <h4>PROBLEMS</h4>
                  </font>
                  <p>
                    <b>Error code: </b> {this.state.errorCode}{' '}
                  </p>
                  <p>
                    <b>Error phase: </b> {this.state.errorPhase}{' '}
                  </p>
                  <p>
                    {this.state.exception && (
                      <span>
                        <b>Exception:</b> {this.state.exception}
                      </span>
                    )}
                  </p>
                  <p className="lead">
                    Please copy/paste all the messages returned above into your
                    existing mote support email thread, or create a{' '}
                    <a
                      href="mailto:support@mote.com"
                      className="mote-boiler-link"
                    >
                      new one
                    </a>
                    .
                  </p>
                </div>
              )}
            </React.Fragment>
          )}
        </p>
      </div>
    )
  }

  runProbe() {
    this.setState({ loading: true })
    this.cleanState()
    var axiosInstance = axios.create({
      baseURL: BASE_URL + 'm/',
      timeout: NETWORK_TIMEOUT
    })

    axiosInstance
      .post('canInsertAudioIntoSlides', {
        accessToken: getAccessToken(),
        containingURL: this.state.containingURL,
        lastLoginHostname: window.location.hostname
      })
      .then((response) => {
        this.setState({ loading: false })
        this.setState(response.data)
      })
      .catch((err) => {
        this.setState({ loading: false })
        console.log(err)
        this.setState({ error: err.toString() })
      })
  }
}

export default SlideProbe
