import { APPLICATION_NAMES } from '../constants'
import { getApplicationKey } from './getApplicationKey'

export const getApplicationName = (url) => {
  const key = getApplicationKey(url)
  if (key) {
    return APPLICATION_NAMES[key]
  } else {
    return ''
  }
}
