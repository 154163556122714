import React from 'react'

import { reallyTruthy, getGodModeURLSuffix } from '../../../shared/Utils'

function InstitutionProductSummary({ data }) {
  const l = data.currentLicense
  const showPaymentDetails =
    data.paymentInfoAvailableFromProvider || data.prettyAmountPaid
  return (
    <div>
      <p className="lead">
        {!data.hasPaymentExpired
          ? 'Organizational'
          : 'Most recent organizational'}{' '}
        product tier(s): {data.institutionProductSummary}
        {data.hasPaymentExpired && <span>&nbsp;[EXPIRED]</span>}
      </p>

      {showPaymentDetails &&
        !data.hasPaymentExpired &&
        l &&
        reallyTruthy(l.totalPaidSeats) && (
          <div>
            <p className="lead">
              Licenses assigned/paid: {l.totalPaidSeats - l.unusedPaidSeats}/
              {l.totalPaidSeats}
            </p>
            {l.highestProductEnum === 'unlimited' && (
              <p className="lead">
                Bonus Essential student users: {l.bonusEssentialUsers}
              </p>
            )}
            {/* {l.totalUsers > 0 && ( */}
            <p className="lead">
              <a
                className="mote-boiler-link"
                href={
                  '/account/users/0' + getGodModeURLSuffix(data.institutionId)
                }
              >
                Browse or manage all registered users
              </a>
            </p>
            {/* )} */}
          </div>
        )}
    </div>
  )
}

export default InstitutionProductSummary
