import React from 'react'

import { UserAgent } from '@quentin-sommer/react-useragent'
import TrackedDownloadLink from '../shared/TrackedDownloadLink'
import { track } from '../shared/AnalyticsHelpers'

class Labs extends React.Component {
  render() {
    return (
      <div>
        <h1>Tracked Download Link</h1>

        <TrackedDownloadLink
          href="https://arxiv.org/pdf/2010.04019.pdf"
          label="Something random from Arxiv"
        />

        <h1>Testing Tracking</h1>
        <button onClick={() => track('Test Labs Event')}>
          Click me to generate a "Test Labs Event"
        </button>
        <h1>User agent fun</h1>
        <p>I render everywhere</p>

        <UserAgent mobile>
          <p>You can only see me on mobile</p>
        </UserAgent>

        <UserAgent chrome>
          <p>You can only see me on chrome etc</p>
        </UserAgent>

        <UserAgent chrome>
          {(uaIsChrome) => <div>{!uaIsChrome && <p>Chrome sucks</p>}</div>}
        </UserAgent>

        <UserAgent safari>
          <p>You can only see me on Safari</p>
        </UserAgent>

        <UserAgent returnFullParser>
          {(parser) => (
            <div>
              <p>I see you... </p>
              <p>OS: {parser.getOS().name}</p>
              <p>Browser: {parser.getBrowser().name}</p>
              <p>Browser version: {parser.getBrowser().version}</p>
              <p>CPU: {parser.getCPU().architecture}</p>
              <p>Full UA string: {parser.getUA()}</p>
            </div>
          )}
        </UserAgent>

        <UserAgent returnFullParser>
          {(parser) =>
            parser.getBrowser().name === 'Chrome' &&
            parser.getBrowser().version > '79' && (
              <div>You're bang up to date!</div>
            )
          }
        </UserAgent>

        <UserAgent returnFullParser>
          {(parser) =>
            parser.getBrowser().name === 'Chrome' &&
            parser.getBrowser().version < '80' && (
              <div>
                Sorry dude, we only support recent versions of Chrome etc.
              </div>
            )
          }
        </UserAgent>

        <UserAgent returnFullParser>
          {(parser) =>
            parser.getBrowser().name === 'Edge' &&
            parser.getBrowser().version > '79' && (
              <div>You're using new Edge, slick.</div>
            )
          }
        </UserAgent>
      </div>
    )
  }
}

export default Labs
