import React from 'react'

function Minor({ data }) {
  const state = data.productStates[0]
  return (
    <div>
      <p className="lead">
        Account type: Under 18 <br></br>
        Product tier: {data.productName}
      </p>
      <p className="lead"> {state.description[0]} </p>
    </div>
  )
}

export default Minor
