import { useGenericRequestHandler } from '../../../handlers/useGenericRequestHandler'

export const useMoteRegisterMutation = (parentMoteId = undefined) => {
  return useGenericRequestHandler({
    route: 'm/register',
    method: 'POST',
    options: {
      json: {
        parentMoteId,
        containingURL: window.location.href
      }
    }
  })
}
