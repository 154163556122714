import ky from 'ky'
import { API_BASE_URL } from '@mote/config/constants'
import {
  _persistBroadcastIdentify,
  getAccessToken,
  setAccessToken
} from 'web/src/shared/Auth'

export const publicApiClient = ky.extend({
  prefixUrl: API_BASE_URL
})

export const afterResponseHandler = async (request, options, response) => {
  try {
    if (response.status === 403) {
      return (window.location.href = `/login?returnTo=${window.location.pathname}`)
    }
    if (response.status >= 400) return

    const res = await response.json()

    if (res.accessToken && res.accessToken !== getAccessToken()) {
      setAccessToken(res.accessToken)
    }

    if (res.identity) {
      console.log('res', res)
      _persistBroadcastIdentify(res.identity, true)
    }
  } catch (err) {
    console.error(err)
  }

  return null
}

export const privateApiClient = publicApiClient.extend({
  hooks: {
    afterResponse: [afterResponseHandler]
  }
})
