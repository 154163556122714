import React from 'react'
import { Footer } from '../shared/Footer'
import Header from '../shared/Header'

class Community extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="mote-brand">
          <div className="container">
            <Header />
          </div>

          <div className="container">
            <div className="row justify-content-center my-5">
              <div className="col-lg-9">
                <figure>
                  <img
                    src="images/logos/mote-ambassadors.png"
                    width="300"
                    className="img-fluid img-thumbnail"
                    alt="mote community"
                  />
                </figure>
              </div>
            </div>
            <div className="bg-skew bg-skew-light">
              <div className="container">
                <div className="row justify-content-center py-2 py-lg-2"></div>
                <div className="col-lg-8">
                  <article className="pb-2">
                    <h1 className="text-center">
                      The <b className="mote-purple">mote</b> community
                    </h1>

                    <p>
                      Here at <b className="mote-purple">mote</b>, we’re on a
                      mission to make feedback faster, friendlier and more
                      effective.
                    </p>

                    <p>
                      If you’re passionate about great feedback, if you want to
                      learn new ways to raise your feedback game, and if you’d
                      like to get more involved with{' '}
                      <b className="mote-purple">mote</b>, then we would love
                      you to become a member of our community.
                    </p>
                    <h3>Join the conversation</h3>
                    <p>
                      We have a lively <b className="mote-purple">mote</b>{' '}
                      <a
                        href="https://www.facebook.com/groups/736374953859823/"
                        className="mote-boiler-link"
                      >
                        Facebook group
                      </a>{' '}
                      filled with a diverse group of global educators.
                    </p>

                    <p>
                      Members are free to discuss any and all aspects of the
                      feedback experience, as well as engaging in discussions
                      about our products, marketing and strategy.
                    </p>

                    <h3>
                      Become a Mote Certified Educator{' '}
                      <b className="mote-purple">#MOTECE</b>
                    </h3>
                    <p>
                      Demonstrate your <b className="mote-purple">mote</b>{' '}
                      knowledge and earn an exclusive certificate by becoming a
                      mote certified educator.
                    </p>
                    <a
                      className="link-cta"
                      href="https://docs.google.com/forms/d/e/1FAIpQLScofp80GLsF_UyPddoxtTqDIr9Mkz77UhS4Ttfaops24DbIWg/viewform?usp=sf_link"
                    >
                      Apply now
                    </a>

                    <h3 className="mt-4">Become a Mote Ambassador</h3>
                    <p>
                      Think you've got what it takes to reach the pinnacle of{' '}
                      <b className="mote-purple">mote</b> expertise? If you're
                      already a <b className="mote-purple">#MOTECE</b> then
                      you're ready to begin your journey to becoming a Mote
                      Ambassador.
                    </p>
                    <em>
                      Spring semester applications for the Mote Ambassador
                      program are now closed, but we'll be re-opening
                      applications for the Fall cohort in August 2021.
                    </em>
                  </article>
                  {/* <a className="link-cta" href="https://docs.google.com/forms/d/e/1FAIpQLSdhNM9Q6-6URVxYN2QQ45W8nOp1PSVVsJG60Y50VCuEvtFWvA/viewform?usp=sf_link">Apply now</a> */}

                  {/* <p>If you’d like to contribute to conversations about <b className="mote-purple">mote</b>’s product roadmap, or discuss the future of feedback with a diverse group of educators, then we’d love you to join the  <a href="https://www.facebook.com/groups/736374953859823/" className="mote-boiler-link">group</a>.
</p>
                            <h3>Become a <b className="mote-purple">mote</b> Ambassador</h3>

                            <p>Our Ambassador program recognizes educators who have made special contributions to the community, and who want to help in representing and promoting the needs of diverse user groups at the highest level.</p>

                            <p>The current requirements to become an ambassador are as follows:</p>

                            <ol>
                                <li>Join our <a href="https://www.facebook.com/groups/736374953859823/" className="mote-boiler-link">Facebook group</a> and contribute to discussions</li>
                                <li>Attend a live webinar training / Q&amp;A session (held weekly)</li>
                                <li>Create or contribute to content that helps introduce mote to a new audience. Examples include creating and posting a demo video or talking about mote for a livestreamed training session.</li>
                                <li>Be willing and able to take on a position of leadership, e.g. Country, State or Regional lead, facebook group moderator.
</li>

                            </ol>

                            <p className="lead">The benefits of becoming a mote Ambassador include…
</p>
                            <ul>
                                <li>Free lifetime access to mote's top tier product</li>
                                <li>Access to exclusive group discussions and events</li>
                                <li>Exclusive badge to brighten up your email signature</li>
                                <li>Swag! <b className="mote-purple">mote</b> T-shirt, <b className="mote-purple">mote</b> stickers…</li>
                                <li>A 90-day free trial link to share with your colleagues and followers</li>
                                <li>Eternal gratitude from moteHQ</li>
                            </ul>
                            <p className="lead"> Interested? Start by joining our <a href="https://www.facebook.com/groups/736374953859823/" className="mote-boiler-link">Facebook group</a>.</p> */}
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Community
