const { darken, lighten } = require('polished')

const mulberry = '#ac0ae8' // primary brand color
const indigo = '#65008A' // dark primary
const lavender = '#E9C9F5' // light primary
const blueberry = '#19006D' // brand dark blue
const custom_black = '#232323' // custom black
const moonlight = '#616569' // grey: Moonlight
const charcoal = '#6D7278' // grey: Charcoal
const stone = '#B3B5B8' // grey: Stone
const light_silver = '#D5D4DA' // grey: Light silver
const palest_grey = '#f7f7f7' // grey: palest grey
const mote_green = '#57C96A'
const mote_red = '#F44336'

module.exports = {
  mote_primary: mulberry,
  indigo: indigo,
  lavender: lavender,
  blueberry: blueberry,
  black: custom_black,
  moonlight: moonlight,
  charcoal: charcoal,
  stone: stone,
  light_silver: light_silver,
  palest_grey: palest_grey,
  mote_green: mote_green,
  mote_red: mote_red,
  mote_primary_shades: {
    50: lighten(0.5, mulberry),
    100: lavender,
    200: lighten(0.4, mulberry),
    300: lighten(0.3, mulberry),
    400: lighten(0.2, mulberry),
    500: mulberry, // mulberry
    600: darken(0.05, mulberry),
    700: darken(0.1, mulberry),
    800: darken(0.15, mulberry),
    900: indigo
  }
}
