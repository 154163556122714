import React from 'react'

const defaultState = {
  isOpen: false,
  highlightId: null,
  mote: null,
  refetchReplies: false
}

export const RecordReplySidebarContext = React.createContext(null)

export const useRecordReplySidebarState = () => {
  const [state, updateState] = React.useState(defaultState)

  function toggle() {
    updateState((prev) => ({
      ...prev,
      isOpen: !prev.isOpen
    }))
  }

  function loadMote(mote, options = { isOpen: false, highlightId: null }) {
    updateState((prev) => ({
      ...prev,
      ...options,
      mote
    }))
  }

  function removeHighlight() {
    updateState((prev) => ({
      ...prev,
      highlightId: null
    }))
  }

  function addNewReply(newReply) {
    updateState((prev) => {
      const replies = [...prev.mote.replyMotes]

      replies.push(newReply)

      return {
        ...prev,
        mote: {
          ...prev.mote,
          replyMotes: replies
        }
      }
    })
  }

  function removeLastReply() {
    updateState((prev) => {
      const replies = [...prev.mote.replyMotes]

      replies.pop()

      return {
        ...prev,
        mote: {
          ...prev.mote,
          replyMotes: replies
        }
      }
    })
  }

  return {
    state,
    toggle,
    loadMote,
    removeHighlight,
    addNewReply,
    removeLastReply
  }
}

export const useRecordReplySidebar = () => {
  return React.useContext(RecordReplySidebarContext)
}
