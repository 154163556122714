import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'
import {
  listProducts,
  getSymbol,
  getSKU,
  prettyPrice,
  generateURLParamsFromSKU
} from '../shared/Commerce'
import { noLog as log } from '../shared/Utils'

class JulyPricing extends React.Component {
  constructor(props) {
    super(props)
    this.state = { recommendedCurrency: 'usd' }
  }

  componentDidMount() {
    document.title = 'Pricing'
    listProducts((results) => {
      log('yay, got: ', results)
      this.setState(results)
    })
  }

  _purchaseLink(label, product, plan) {
    const sku = getSKU(product, plan, this.state.recommendedCurrency)

    return (
      <div>
        {!sku && ' '}
        {sku && <a href={this._generateURL(sku)}>{label}</a>}
      </div>
    )
  }

  _purchasePrice(product, plan, discountPct) {
    const sku = getSKU(product, plan, this.state.recommendedCurrency)

    return (
      <span>
        {!sku && ' '}
        {sku && prettyPrice(sku, discountPct)}
      </span>
    )
  }

  _generateURL(sku) {
    return '/checkout?' + generateURLParamsFromSKU(sku)
  }

  _renderCurrencyPicker() {
    if (!this.state.products) {
      return <span>US $</span>
    }
    var items = []
    for (const row of this.state.currencies) {
      items.push(
        <option key={row.currencyCode} value={row.currencyCode}>
          {row.name}
        </option>
      )
    }

    return (
      <form>
        <select
          className="form-control"
          value={this.state.recommendedCurrency}
          onChange={(e) => {
            log('got event: ', e)
            log('target: ', e.target)
            this.setState({ recommendedCurrency: e.target.value })
            e.preventDefault()
          }}
        >
          {items}
        </select>
      </form>
    )
  }

  render() {
    var currency = getSymbol(this.state.recommendedCurrency)
    return (
      <div className="container mote-brand">
        <Header />
        <main id="main">
          <div className="container">
            <div className="row justify-content-center py-4 py-md-3 py-xl-4">
              <div className="col-lg-10 col-xl-8 text-center mb-xl-2">
                <h1 className="display-4 mb-2">
                  There's a <b className="mote-purple">mote</b> for everyone
                </h1>
                <h2>Pricing</h2>
              </div>
            </div>
          </div>

          <div className="container">
            {/* <!-- Pricing table --> */}
            <table className="table shadow pricing-table table-responsive-md mb-5">
              <thead>
                <tr>
                  <th scope="col" className="mote-pricing-table">
                    <div className="d-block h4 mb-1 text-muted">
                      <span>Prices are in </span>
                      {this._renderCurrencyPicker()}
                    </div>
                    {/* <small className="d-block text-muted">And may be subject to sales tax / VAT</small> */}
                  </th>
                  <th scope="col">
                    <span className="d-block h4 mb-1 display-5 font-weight-normal">
                      Student
                    </span>
                    <small className="d-block mote-purple">
                      React &amp; respond
                    </small>
                  </th>
                  <th scope="col">
                    <span className="d-block h4 mb-1 display-5 font-weight-normal">
                      Essential
                    </span>
                    <small className="d-block mote-purple">
                      Share your voice
                    </small>
                  </th>
                  <th scope="col">
                    <span className="d-block h4 mb-1 display-5 font-weight-normal">
                      Unlimited
                    </span>
                    <small className="d-block mote-purple">
                      Mote to the max
                    </small>
                  </th>
                  <th scope="col">
                    <span className="d-block h4 mb-1 display-5 font-weight-normal">
                      School
                    </span>
                    <small className="d-block mote-purple">Buy in bulk</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="col">Yearly price</th>
                  <th scope="col">
                    <div className="d-flex">
                      <span className="h4 font-weight-normal mt-1">
                        {currency}{' '}
                      </span>
                      <span className="display-4 font-weight-normal">0</span>
                    </div>
                    <small className="d-block text-muted">
                      /year
                      <span className="buy-now-link">
                        <br></br>
                        <a href="/login">Get started</a>
                      </span>
                    </small>
                  </th>
                  <th scope="col">
                    <div className="d-flex">
                      <span className="h4 font-weight-normal mt-1">
                        {currency}{' '}
                      </span>
                      <span className="display-4 font-weight-normal">19</span>
                    </div>
                    <small className="d-block text-muted">
                      /year
                      <span className="buy-now-link">
                        <br></br>
                        <a href="/login">Get started</a>
                      </span>
                    </small>
                  </th>
                  <th scope="col">
                    <div className="d-flex">
                      <span className="h4 font-weight-normal mt-1">
                        {currency}{' '}
                      </span>
                      <span className="display-4 font-weight-normal">49</span>
                    </div>
                    <small className="d-block text-muted">
                      /year
                      <span className="buy-now-link">
                        <br></br>
                        <a href="/login">Get started</a>
                      </span>
                    </small>
                  </th>
                  <th scope="col">
                    <div className="d-flex">
                      <span className="h4 font-weight-normal mt-1">
                        {currency}{' '}
                      </span>
                      <span className="display-4 font-weight-normal">39</span>
                    </div>
                    <small className="d-block text-muted">
                      /account/year
                      <span className="buy-now-link">
                        <br></br>
                        <a href="/login">Get started</a>
                      </span>
                    </small>
                  </th>
                </tr>
                <tr>
                  <th scope="row">
                    Unlimited <b className="mote-purple">mote</b> voice notes
                  </th>
                  <td>
                    N/A <br></br>
                    <small>(limit 20 per month)</small>
                    <span className="sr-only">No</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    ></i>
                    <span className="sr-only">Yes</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    ></i>
                    <span className="sr-only">Yes</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    ></i>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr>
                  <th scope="row">
                    Transcribe <b className="mote-purple">mote</b> voice notes
                  </th>
                  <td>
                    N/A
                    <span className="sr-only">No</span>
                  </td>
                  <td>
                    N/A
                    <span className="sr-only">No</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    ></i>
                    <span className="sr-only">Yes</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    ></i>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    See <b className="mote-purple">mote</b> feedback reactions
                  </th>
                  <td>
                    N/A
                    <span className="sr-only">No</span>
                  </td>
                  <td>
                    N/A
                    <span className="sr-only">Yes</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    ></i>
                    <span className="sr-only">Yes</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    ></i>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    Translate <b className="mote-purple">mote</b> transcripts
                  </th>
                  <td>
                    N/A
                    <span className="sr-only">No</span>
                  </td>
                  <td>
                    N/A
                    <span className="sr-only">No</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    >
                      <span></span>
                    </i>
                    <span> Soon!</span>
                    <span className="sr-only">Yes</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    >
                      <span></span>
                    </i>
                    <span> Soon!</span>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>

                <tr>
                  <th scope="row">
                    Save &amp; retrieve <b className="mote-purple">motes</b>{' '}
                    from <b className="mote-purple">mote</b>book
                  </th>
                  <td>
                    N/A
                    <span className="sr-only">No</span>
                  </td>
                  <td>
                    N/A
                    <span className="sr-only">No</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    ></i>
                    <span> Soon!</span>
                    <span className="sr-only">Yes</span>
                  </td>
                  <td>
                    <i
                      className="far fa-check-circle fa-lg text-success"
                      aria-hidden="true"
                    ></i>
                    <span> Soon!</span>
                    <span className="sr-only">Yes</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
        <Footer />
      </div>
    )
  }
}

export default JulyPricing
