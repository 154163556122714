import React from 'react'

function Congrats() {
  const congrats =
    window.location.search.indexOf('congrats') > -1 ||
    window.location.search.indexOf('succeeded') > -1
  return (
    <div>
      {congrats && (
        <p className="lead">
          <b className="mote-purple">Congratulations!</b>
        </p>
      )}
    </div>
  )
}

export default Congrats
