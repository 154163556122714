import React from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { afterMoteAuth, getMoteLogin, logout } from '../shared/Auth'
import { noLog as log, reallyTruthy, showLogin } from '../shared/Utils'

const DEV_FORCE_LOGIN =
  reallyTruthy(process.env.REACT_APP_DEV_FORCE_LOGIN) || false
const DEV_FAKE_LOGIN_DATA = {
  primaryEmailAddress: 'willmjackson@gmail.com',
  name: 'Will Jackson'
}

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isLoggedIn: false }
    this._isMounted = false
  }

  componentDidMount() {
    log('calling afterMoteAuth: ', DEV_FORCE_LOGIN)
    this._isMounted = true
    if (!DEV_FORCE_LOGIN) {
      afterMoteAuth(
        () => {
          if (this._isMounted) {
            this.setState({ isLoggedIn: true })
          }
        },
        () => {
          if (this._isMounted) {
            this.setState({ isLoggedIn: false })
          }
        },
        { addToListeners: true, applyDefaultState: true }
      )
    } else {
      this.setState({ isLoggedIn: true })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <div className="container">
        <Navbar expand="lg" bg="white" variant="light" className="new-header">
          <Navbar.Brand href="/" className="brand">
            <img
              src="/images/logos/mote-lockup-header.png"
              height="50"
              className="d-inline-block align-top"
              alt="mote logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto items">
              <NavDropdown title="for education" className="mote-nav-link">
                <NavDropdown.Item className="logout-link" href="/schools">
                  Information for schools
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="logout-link"
                  href="https://learn.mote.com/the-hub/"
                >
                  Learning Hub
                </NavDropdown.Item>
                <NavDropdown.Item className="logout-link" href="/amplifier">
                  Share: Mote Amplifier
                </NavDropdown.Item>
                <NavDropdown.Item className="logout-link" href="/elementary">
                  Elementary
                </NavDropdown.Item>
                <NavDropdown.Item className="logout-link" href="/ela">
                  English &amp; Humanities
                </NavDropdown.Item>
                <NavDropdown.Item className="logout-link" href="/languages">
                  ESL &amp; Modern Languages
                </NavDropdown.Item>
                <NavDropdown.Item className="logout-link" href="/stem">
                  Science &amp; Math
                </NavDropdown.Item>
                <NavDropdown.Item className="logout-link" href="/music">
                  Music &amp; Visual Arts
                </NavDropdown.Item>
                <NavDropdown.Item className="logout-link" href="/community">
                  Join our Community
                </NavDropdown.Item>
              </NavDropdown>
              <Navbar.Text>
                <Nav.Link href="/teams" className="mote-nav-link">
                  for business
                </Nav.Link>
              </Navbar.Text>
              {/* <Navbar.Text>
                <Nav.Link href="/schools" className="mote-nav-link">
                  for schools
                </Nav.Link>
              </Navbar.Text> */}
              <Navbar.Text>
                <Nav.Link href="/pricing" className="mote-nav-link">
                  pricing
                </Nav.Link>
              </Navbar.Text>
              <Navbar.Text>
                <Nav.Link href="/quote" className="mote-nav-link">
                  get a quote
                </Nav.Link>
              </Navbar.Text>
              {showLogin() && this._renderLoginDropdown()}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }

  _renderLoginDropdown() {
    return (
      <div className="navbar-text">
        {this.state.isLoggedIn && this._renderLoggedIn()}
        {!this.state.isLoggedIn && this._renderLoggedOut()}
      </div>
    )
  }

  _renderLoggedIn() {
    const personImg = (
      <img alt="YOU" height="60" width="60" src="/images/icons/profile.png" />
    )
    const loginData = !DEV_FORCE_LOGIN ? getMoteLogin() : DEV_FAKE_LOGIN_DATA
    return (
      <NavDropdown
        className="person-img"
        title={personImg}
        id="login-pull-down"
      >
        <div className="email-link" style={{ textTransform: 'lowercase' }}>
          {loginData.primaryEmailAddress}
        </div>
        <NavDropdown.Divider />
        <NavDropdown.Item className="account-link" href="/account">
          My Account
        </NavDropdown.Item>
        <NavDropdown.Item className="logout-link" href="/account/activity">
          My Activity
        </NavDropdown.Item>
        <NavDropdown.Item className="logout-link" href="/account/notifications">
          Notifications
        </NavDropdown.Item>
        <NavDropdown.Item
          className="logout-link"
          onClick={(e) => this._logout(e)}
          href="/"
        >
          Logout
        </NavDropdown.Item>
      </NavDropdown>
    )
  }

  _renderLoggedOut() {
    return (
      <Navbar.Text>
        <Nav.Link href="/login" className="mote-nav-link">
          login
        </Nav.Link>
      </Navbar.Text>
    )
  }

  _logout(e) {
    e.preventDefault()
    log('attempting logout')
    try {
      logout()
    } catch (e) {}
    this.setState({ isLoggedIn: false })
    const newURL =
      window.location.protocol + '//' + window.location.host + '/login'
    log('redirecting to: ', newURL)
    window.location = newURL
  }
}

export default Header
