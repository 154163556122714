import React from 'react'

import { EpochToPrettyDate } from '../../../shared/Utils'

function PersonalProductSummary({ data }) {
  const mode = data.isGifted
    ? 'gift'
    : data.isPaid
    ? 'paid'
    : data.isVIP
    ? 'VIP'
    : data.isOnReferralUnlimited
    ? 'RU'
    : ''
  const showPlan = mode === 'paid' && !data.isInstitution
  return (
    <div>
      <p className="lead">
        {data.isInstitution ? 'Your personal product tier' : 'Product tier'}:{' '}
        <b>{data.productName}</b>
        {data.isTemporaryUnlimited && mode !== 'VIP' && (
          <span>
            {' '}
            until {EpochToPrettyDate(data.temporaryUnlimitedEpoch)} then{' '}
            <b>{data.postTemporaryProductName}</b>
          </span>
        )}
        <br />
        {mode === 'gift' && (
          <React.Fragment>
            Gift subscription expiry date:{' '}
            {EpochToPrettyDate(data.giftExpiryEpoch)}
          </React.Fragment>
        )}
        {mode === 'RU' && (
          <React.Fragment>
            Invite reward expiry date:{' '}
            {EpochToPrettyDate(data.referralUnlimitedExpiryEpoch)}
          </React.Fragment>
        )}
        {showPlan && (
          <React.Fragment>
            Payment plan: <b>{data.planName}</b> (runs until{' '}
            <b>
              {EpochToPrettyDate(data.planExpiryEpoch || data.nextRenewalEpoch)}
            </b>
            )
          </React.Fragment>
        )}
      </p>
    </div>
  )
}

export default PersonalProductSummary
