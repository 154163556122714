import React from 'react'
import PropTypes from 'prop-types'
import tw, { styled, css } from 'twin.macro'

import {
  RewindPassive,
  RewindActive,
  Play
} from '@mote/assets/svgs/controls/index'
import Volume from '../Volume/Volume'
import { PauseReactive } from '@mote/assets/svgs/logo'
import { Tooltip } from '../../../../../../mote-ui'

Controls.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  onRewind: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  muted: PropTypes.bool.isRequired,
  toggleMute: PropTypes.func.isRequired,
  volume: PropTypes.number.isRequired,
  changeVolume: PropTypes.func.isRequired,
  audioStream: PropTypes.object,
  audioContext: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

const ControlsContainer = styled.div(() => [
  tw`relative flex items-center justify-center space-x-2`
])

const Control = styled.div(({ width, height, disabled }) => [
  css`
    cursor: pointer;
    width: ${width}px;
    height: ${height}px;
  `,
  disabled &&
    `
  cursor: not-allowed;
  
    svg {
      path {
        fill: #c4c4c4;
      }
    }
  `
])

export default function Controls({
  isPlaying,
  onRewind,
  onPlay,
  onPause,
  muted,
  toggleMute,
  volume,
  changeVolume,
  audioStream,
  audioContext,
  disabled = false,
  loading = false
}) {
  const disableControls = disabled || loading

  return (
    <ControlsContainer>
      <Control width={25} height={25} disabled={disableControls}>
        {isPlaying ? (
          <div
            onClick={(e) => {
              e.stopPropagation()
              onRewind()
            }}
            data-testid="rewind-button"
          >
            <RewindActive />
          </div>
        ) : (
          <div>
            <RewindPassive />
          </div>
        )}
      </Control>

      <Control width={36} height={36} disabled={disableControls}>
        {isPlaying ? (
          <div
            onClick={(e) => {
              e.stopPropagation()
              onPause()
            }}
            data-testid="pause-button"
          >
            <PauseReactive
              audioStream={audioStream}
              audioContext={audioContext}
            />
          </div>
        ) : (
          <div
            data-tip={loading ? 'Loading mote ...' : null}
            onClick={(e) => {
              e.stopPropagation()
              onPlay()
            }}
            data-testid="play-button"
          >
            <Play />
          </div>
        )}
      </Control>

      <Volume
        disabled={disableControls}
        muted={muted}
        toggleMute={toggleMute}
        volume={volume}
        changeVolume={changeVolume}
      />

      <Tooltip />
    </ControlsContainer>
  )
}
