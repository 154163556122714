import React from 'react'
import { addExtensionFoundListener, probe } from 'web/src/shared/Auth'

export const useExtensionInstalled = () => {
  const [extensionInstalled, setExtensionInstalled] = React.useState(null)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (extensionInstalled === null) {
        setExtensionInstalled(false)
      }
    }, 200)

    addExtensionFoundListener(() => {
      clearTimeout(timeout)
      setExtensionInstalled(true)
    })

    probe()

    return () => clearTimeout(timeout)
  }, [])

  return extensionInstalled
}
