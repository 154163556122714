import { coinFlip, fiveTimesOutOfSix } from '../../../shared/Utils'

export const DEV_FAKE_VIEW = {
  renderMode: 'Loaded',
  userCount: fiveTimesOutOfSix() ? 10 : 0,
  currentPage: [
    {
      userId: 'foo',
      primaryEmailAddress: 'bill@foo.com',
      name: 'Bill Gates',
      minorhood: 'N',
      institutionalRole: coinFlip() ? '' : 'Owner',
      isAdmin: coinFlip(),
      lastSignInEpoch: coinFlip() ? 0 : 1304093541,
      canMakeOwner: coinFlip(),
      canUnmakeAdmin: coinFlip(),
      canDelicenseUser: coinFlip(),
      canRemoveUser: coinFlip(),
      canUpgradeToUnlimited: coinFlip(),
      canSwitchToEssential: coinFlip(),
      canEssentialForceMinor: coinFlip(),
      canForceMinor: coinFlip(),
      canUnforceMinor: coinFlip(),
      canMakeAdmin: coinFlip(),
      lastMoteRecordedAt: coinFlip() ? 0 : 1604093541,
      totalMotesRecorded: coinFlip() ? 0 : 10
    },
    {
      userId: 'bar',
      primaryEmailAddress: 'melinda@foo.com',
      name: 'Melinda Gates',
      minorhood: 'Y',
      isAdmin: coinFlip(),
      canMakeOwner: coinFlip(),
      canUnmakeAdmin: coinFlip(),
      canMakeAdmin: coinFlip(),
      canDelicenseUser: coinFlip(),
      canRemoveUser: coinFlip(),
      institutionalRole: coinFlip() ? '' : 'Admin',
      lastSignInEpoch: coinFlip() ? 0 : 1504093541,
      lastMoteRecordedAt: coinFlip() ? 0 : 1604093541,
      totalMotesRecorded: coinFlip() ? 0 : 10
    }
  ],
  pageIndexes: [1, 2, 3, 4, 5, 6, 7, 8, '...', 23]
}
