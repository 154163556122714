import { DEFAULT_LOCALE } from '@mote/common'
import { FluentBundle, FluentResource } from '@fluent/bundle'

async function generateBundles(app = null, preferredLocale = DEFAULT_LOCALE) {
  if (process.env.NODE_ENV === 'test') return []

  if (!app) {
    throw new Error('No app specified')
  }

  const MAP_PATH = `/locales/locales-${app}.map.json`
  const LOCALE_FILES_MAP = await (
    await fetch(
      window.chrome?.extension
        ? window.chrome.extension.getURL(MAP_PATH)
        : MAP_PATH
    )
  ).json()

  async function readFTLFile(url) {
    const response = await fetch(
      window.chrome?.extension ? window.chrome.extension.getURL(url) : url
    )
    return await response.text()
  }

  async function assembleLocale(url, locale) {
    const files = LOCALE_FILES_MAP[locale]

    if (!files?.length) {
      return ''
    }

    let translations = ''

    for (let i = 0; i < files.length; i++) {
      const fetchedText = await readFTLFile(`${url}/${files[i]}`)
      translations += '\n' + fetchedText
    }

    return translations
  }

  async function getMessages(locale) {
    const url = `/locales/${locale}`
    return await assembleLocale(url, locale)
  }

  const languagesToProcess = [preferredLocale]

  if (preferredLocale !== DEFAULT_LOCALE) {
    languagesToProcess.push(DEFAULT_LOCALE)
  }

  return languagesToProcess.map(async (language) => {
    let translations = await getMessages(language)
    if (!translations) {
      translations = await getMessages(DEFAULT_LOCALE)
    }

    const bundle = new FluentBundle(language)
    const resource = new FluentResource(translations)
    bundle.addResource(resource)

    return bundle
  })
}

export const generateTranslationBundles = generateBundles
