import React from 'react'
import { styled } from 'twin.macro'

export const NewHomePageAlert = () => {
  return <Root>Welcome to our new homepage. We now live at mote.com!</Root>
}

const Root = styled.div`
  padding: 15px 0;
  background-color: #ac0ae8;
  text-align: center;
  color: white;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.05em;
`
