import React from 'react'
import {
  useMoteRegisterMutation,
  useMoteAddChunkMutation,
  useMoteFinalizeMutation
} from '@mote/business'

export const useMediaUploader = (parentMoteId) => {
  const [isRegistered, setIsRegistered] = React.useState(false)
  const [chunksToSend, setChunksToSend] = React.useState([])
  const [error, setError] = React.useState(null)
  const [chunkErrorCount, setChunkErrorCount] = React.useState(0)

  const {
    data,
    error: registerError,
    mutate: registerMote
  } = useMoteRegisterMutation(parentMoteId)
  const { error: chunkError, mutate: addChunk } = useMoteAddChunkMutation(
    data?.moteId,
    0
  )
  const {
    error: finalizeError,
    mutate: finalizeMote
  } = useMoteFinalizeMutation(data?.moteId)

  async function register() {
    await registerMote()

    if (registerError) {
      setError(registerError)
      return
    }

    setIsRegistered(true)
  }

  function sendChunk(chunk) {
    setChunksToSend((prevChunks) => [
      ...prevChunks,
      {
        data: chunk,
        id: prevChunks.length
      }
    ])
  }

  function send(chunk) {
    addChunk({
      body: chunk.data,
      abortable: false,
      routeOverride: `m/addChunk/${data.moteId}/${chunk.id}`
    })

    if (chunkError) {
      setChunkErrorCount((count) => count + 1)
      if (chunkErrorCount > 5) setError('Too many chunk errors, aborting!')
    }

    markSentChunk(chunk)
  }

  function markSentChunk(chunk) {
    setChunksToSend((prevChunks) =>
      prevChunks.map((item) =>
        item.id === chunk.id ? { ...item, sent: true } : item
      )
    )
  }

  async function finalize() {
    const bytes = chunksToSend.reduce((total, chunk) => {
      return total + chunk.data.size
    }, 0)

    await finalizeMote({
      variables: { summary: { chunks: chunksToSend.length, bytes } }
    })
    if (finalizeError) setError(finalizeError)

    cleanup()
  }

  function cleanup() {
    setIsRegistered(false)
    setChunksToSend([])
    setError(null)
    setChunkErrorCount(0)
  }

  React.useEffect(() => {
    if (!isRegistered) return

    chunksToSend.forEach((chunk) => {
      if (chunk.sent) return
      send(chunk)
    })
  }, [isRegistered, chunksToSend])

  return {
    register,
    sendChunk,
    finalize,
    cleanup,
    error
  }
}
