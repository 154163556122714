import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from 'twin.macro'

import { GlobeIcon, ChevronDownIcon } from '@mote/icons'
import { isEnglishSpeakingLocale, TRANSLATION_LOCALES } from '@mote/common'
import { useLocale } from '@mote/l10n/provider/LocaleProvider'

import { InfoModal } from './InfoModal'
import { Dropdown } from '@mote/ui'

export const LanguagePicker = () => {
  const [showInfoModal, setShowInfoModal] = useState(false)
  const { locale, updateLocale } = useLocale()

  const selectLanguage = async (locale) => {
    await updateLocale(locale)
    if (!isEnglishSpeakingLocale(locale)) {
      setShowInfoModal(true)
    }
  }

  const closeInfoModal = () => {
    setShowInfoModal(false)
  }

  return (
    <Root>
      <Dropdown
        width={150}
        height={200}
        onAction={selectLanguage}
        triggerElement={
          <StyledButton>
            <GlobeIcon />
            <ButtonText>{locale.name}</ButtonText>
            <ChevronDownIcon color="white" />
          </StyledButton>
        }
        placement="topRight"
      >
        {TRANSLATION_LOCALES.map((language) => (
          <Dropdown.Item key={language.code}>
            <Dropdown.ItemText>{language.name}</Dropdown.ItemText>
          </Dropdown.Item>
        ))}
      </Dropdown>

      <InfoModal onClose={closeInfoModal} isVisible={showInfoModal} />
    </Root>
  )
}

const Root = styled.div`
  position: relative;
`

const StyledButton = styled.button`
  outline: none !important;
  background: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 0;
  width: 100%;
  max-width: 220px;
  padding: 0;
`

const ButtonText = styled.span`
  margin: 0 6px;
  color: white;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
`

LanguagePicker.propTypes = {
  activeLanguage: PropTypes.string,
  onTranslate: PropTypes.func
}
