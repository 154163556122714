import React from 'react'
import {
  persistReferrer,
  track,
  trackCWSInstallButton
} from '../../shared/AnalyticsHelpers'
import {
  applyReaction,
  recordListenInActivity
} from '../../shared/ReactionNetworking'
import { noLog as log } from '../../shared/Utils'
import { UserAgent } from '@quentin-sommer/react-useragent'
import { Localized } from '@fluent/react'

import {
  AudioIssuePrompt,
  CardContainer,
  TitleContainer,
  Transcription,
  TranscriptionWrapper,
  Notice,
  PreviewImage,
  PreviewContainer,
  PreviewLink,
  PreviewBody,
  PreviewTitle,
  PreviewDescription,
  ExtensionBox,
  ButtonContainer,
  MoteCardContainer
} from './layout'
import { MoteCard } from '@mote/business'

import { Button, Emoji } from '@mote/ui'
import { P } from '@mote/config/defaults/typography'
import { CWS_URL } from '../../shared/Constants'

import { MoteLandingLayout } from './MoteLandingLayout'
import { ToggleRecordReplySidebarButton } from '@mote/business'
import { useTranscriptionVisible } from './useTranscriptionVisible'

const PLAYER_ID = 'audio-player'

export const LandingPageMote = ({
  mote,
  hasRedirectionPermissions,
  extensionInstalled,
  onRequestRedirectionPermissions
}) => {
  const isTranscriptionVisible = useTranscriptionVisible()

  const [myReactions, setMyReactions] = React.useState(mote.myReactions)

  const [hasAudioListeners, setHasAudioListeners] = React.useState(false)
  const [audioIssues, setAudioIssues] = React.useState(false)

  const everPlayedRef = React.useRef(false)

  const playTracking = React.useCallback(async () => {
    await track('Mote Landing Page Audio Started or Restarted', {
      content_asset_id: mote.moteId,
      content_rich: mote.canRedirect
    })

    if (!everPlayedRef.current) {
      await recordListenInActivity(mote.moteId)
      // needs to be a ref to avoid re-rendering
      // as it causes audio play issues on initial play
      everPlayedRef.current = true
    }
  }, [everPlayedRef, mote])

  const pauseTracking = React.useCallback(async () => {
    await track('Mote Landing Page Audio Paused or Ended', {
      content_asset_id: mote.moteId,
      content_rich: mote.canRedirect
    })
  }, [mote])

  const onEnd = React.useCallback(() => {
    track('Mote Play Audio Ended', {
      content_asset_id: mote?.moteId
    })
  }, [mote])

  const renderDynamicMathText = React.useCallback(() => {
    if (!mote) return null
    const MathText = React.lazy(() => import('../MathText'))

    return (
      <React.Suspense fallback={mote.transcriptDisplay}>
        <MathText text={mote.transcriptDisplayStem} />
      </React.Suspense>
    )
  }, [mote])

  const shouldEnableReactions = React.useCallback(() => {
    if (!mote) return false

    return (
      mote.availableReactions?.length > 0 ||
      mote.availableUnreactions?.length > 0
    )
  }, [mote])

  const renderTranscriptText = React.useCallback(
    (isCardTranscript) => {
      const plainTranscript = mote.transcriptDisplay
      const parseUA = (parser) => {
        try {
          const { name, version } = parser.getBrowser()

          const isOldSafari = name.includes('Safari') && version < 8
          return isOldSafari || isCardTranscript
            ? plainTranscript
            : renderDynamicMathText()
        } catch (error) {
          log(
            'Failed to determine transcript based on Browser UA, defaulting to plain transcript!'
          )
          return plainTranscript
        }
      }

      return (
        <UserAgent returnFullParser>{(parser) => parseUA(parser)}</UserAgent>
      )
    },
    [renderDynamicMathText, mote]
  )

  const networkCall = React.useCallback(
    (verb, reaction) => {
      applyReaction(mote.moteId, verb, reaction, (result) =>
        setMyReactions(result)
      )
    },
    [mote]
  )

  const toggleEmoji = React.useCallback(
    (emoji) => {
      if (myReactions.includes(emoji)) {
        setMyReactions(myReactions.filter((item) => item !== emoji))
        networkCall('unreact', emoji)
      } else {
        setMyReactions([...myReactions, emoji])
        networkCall('react', emoji)
      }
    },
    [myReactions, networkCall]
  )

  const onEmojiHandler = React.useCallback(() => {
    if (shouldEnableReactions()) return toggleEmoji()

    return null
  }, [shouldEnableReactions, toggleEmoji])

  const renderCard = React.useMemo(() => {
    return (
      <CardContainer>
        <MoteCard
          moteId={mote.moteId}
          username={mote.createdByName}
          audioUrl={mote.playbackLocation}
          onEmoji={onEmojiHandler}
          activeEmojis={myReactions}
          transcription={renderTranscriptText()}
          transcriptionVisible={isTranscriptionVisible}
          onPlay={playTracking}
          onPause={pauseTracking}
          isLandingPage
          onEnd={onEnd}
        />

        <AudioIssuePrompt onClick={() => setAudioIssues(true)}>
          <Localized id="cant-hear-any-audio-click">
            Can't hear any audio? Click here!
          </Localized>
        </AudioIssuePrompt>

        {audioIssues && (
          <audio
            id={PLAYER_ID}
            controls
            style={{
              outline: 'none',
              marginTop: '12px',
              width: '100%',
              maxWidth: '400px'
            }}
            autoPlay={true}
            src={mote.playbackLocation}
          />
        )}
      </CardContainer>
    )
  }, [
    isTranscriptionVisible,
    playTracking,
    pauseTracking,
    audioIssues,
    mote,
    myReactions,
    renderTranscriptText,
    onEnd,
    onEmojiHandler
  ])

  const renderTranscript = React.useMemo(() => {
    return (
      mote.transcriptDisplay && (
        <TranscriptionWrapper>
          <Transcription className="mote-transcript">
            {mote.isQuiescent
              ? renderTranscriptText()
              : 'Awaiting transcription ...'}
          </Transcription>
        </TranscriptionWrapper>
      )
    )
  }, [mote, renderTranscriptText])

  React.useEffect(() => {
    if (!mote) return
    if (!mote.playbackLocation) return

    const player = document.getElementById(PLAYER_ID)
    if (!player) {
      log('We could not find an audio player')
    } else if (!hasAudioListeners) {
      setHasAudioListeners(true)
      log('yay, we have mapped listeners to the audio player!!!')
      player.addEventListener('play', playTracking)
      player.addEventListener('pause', pauseTracking)
    } else {
      log('we have already assigned something')
    }

    if (mote.createdByUserId) {
      persistReferrer(mote.createdByUserId)
    }
  }, [mote, playTracking, pauseTracking, hasAudioListeners])

  return (
    <MoteLandingLayout
      hero={
        <>
          <TitleContainer>
            <Localized
              id="moter-left-voice-note-for"
              vars={{ moter: mote.createdByName }}
            >
              <h1>{'{$moter} left a voice note for you'}</h1>
            </Localized>
          </TitleContainer>
          <MoteCardContainer>
            {renderCard}
            {renderTranscript}
          </MoteCardContainer>
          <ToggleRecordReplySidebarButton className="mt-4" />
        </>
      }
    >
      {mote.canRedirect && (
        <>
          {mote.canLink && (
            <>
              <Notice>
                <P>
                  {mote.createdByName} left you this voice note on a webpage
                  <Emoji label="point">👇</Emoji> go check it out!
                </P>
              </Notice>

              <PreviewContainer>
                {mote.containingURLHeroImage && (
                  <PreviewImage src={mote.containingURLHeroImage} />
                )}
                <PreviewBody>
                  <PreviewLink href={mote.containingURL}>
                    {mote.containingURL}
                  </PreviewLink>
                  <PreviewTitle>
                    {mote.containingURLTitle ||
                      'No linked content title found!'}
                  </PreviewTitle>
                  <PreviewDescription>
                    {mote.containingURLDescription ||
                      'No linked content description found!'}
                  </PreviewDescription>
                </PreviewBody>
              </PreviewContainer>
            </>
          )}
        </>
      )}

      {!extensionInstalled && (
        <ExtensionBox>
          <P isLight className="text-white">
            <Localized
              id={
                mote.canRedirect
                  ? 'add-mote-chrome-extension-any-webpage'
                  : 'add-the-mote-chrome-extension'
              }
            >
              Add the mote Chrome extension to create your own voice notes.
            </Localized>
          </P>
          <ButtonContainer>
            <Button
              isSecondary
              className="py-2 bg-white"
              label={<Localized id="add-to-chrome">Add to Chrome</Localized>}
              onClick={() => {
                trackCWSInstallButton()
                window.open(CWS_URL, '_new')
              }}
            />
          </ButtonContainer>
        </ExtensionBox>
      )}

      {extensionInstalled && !hasRedirectionPermissions && (
        <ExtensionBox>
          <P isLight className="text-white">
            <Emoji label="wave">👋</Emoji> - to use Mote in apps like Gmail, and
            around the web, you’ll need to grant additional permissions.
          </P>
          <Button
            isSecondary
            className="py-2 bg-white"
            label={'Grant Permissions'}
            onClick={onRequestRedirectionPermissions}
          />
        </ExtensionBox>
      )}
    </MoteLandingLayout>
  )
}
