import React from 'react'

export const UserIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#AC0AE8" />
      <circle cx="8" cy="5.5" r="2.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.52246 12C3.67753 10.1958 5.69943 9 8.00064 9C10.3019 9 12.3238 10.1958 13.4788 12C12.3238 13.8042 10.3019 15 8.00064 15C5.69943 15 3.67753 13.8042 2.52246 12Z"
        fill="white"
      />
    </svg>
  )
}
