import React from 'react'

export const LinkedInIcon = (color = 'currentColor') => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M13.5141 0.774271H1.56756C1.27736 0.774271 1.03015 0.876378 0.825939 1.08059C0.621726 1.27406 0.519619 1.51052 0.519619 1.78997V13.8816C0.519619 14.161 0.621726 14.3975 0.825939 14.5909C1.03015 14.7952 1.27736 14.8973 1.56756 14.8973H13.5141C13.7935 14.8973 14.0353 14.7952 14.2396 14.5909C14.4438 14.3975 14.5459 14.161 14.5459 13.8816V1.78997C14.5459 1.51052 14.4438 1.27406 14.2396 1.08059C14.0353 0.876378 13.7935 0.774271 13.5141 0.774271ZM4.77586 12.5918H2.66386V6.22355H4.77586V12.5918ZM3.7118 5.35296H3.69568C3.35174 5.35296 3.07229 5.25085 2.85733 5.04664C2.64237 4.83168 2.53489 4.56835 2.53489 4.25665C2.53489 3.93421 2.64237 3.67088 2.85733 3.46667C3.08304 3.25171 3.37324 3.14423 3.72792 3.14423C4.08261 3.14423 4.36744 3.25171 4.5824 3.46667C4.79736 3.67088 4.90484 3.93421 4.90484 4.25665C4.90484 4.56835 4.79736 4.83168 4.5824 5.04664C4.36744 5.25085 4.07724 5.35296 3.7118 5.35296ZM12.4016 12.5918H10.2896V9.1739C10.2896 8.72248 10.209 8.37855 10.0478 8.14209C9.86508 7.87339 9.59101 7.73903 9.22557 7.73903C8.95687 7.73903 8.72041 7.81965 8.5162 7.98087C8.35498 8.12059 8.226 8.29794 8.12927 8.5129C8.08628 8.64187 8.06478 8.81384 8.06478 9.02881V12.5918H5.95278V9.62532C5.96353 7.60468 5.96353 6.47076 5.95278 6.22355H8.06478V7.12639C8.50545 6.42777 9.13959 6.07845 9.96719 6.07845C10.6981 6.07845 11.2785 6.30954 11.7084 6.77171C12.1706 7.27687 12.4016 7.99699 12.4016 8.93207V12.5918Z"
        fill="currentColor"
      />
    </svg>
  )
}
