import React from 'react'

const IconClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    fill="none"
    viewBox="0 0 14 15"
    className={props.className}
  >
    <path
      fill="#AC0AE8"
      fillOpacity="0.01"
      d="M6.781.969A6.78 6.78 0 000 7.75a6.78 6.78 0 006.781 6.781 6.78 6.78 0 006.782-6.781A6.78 6.78 0 006.78.969zm3.309 8.586c.137.11.137.328 0 .465l-1.067 1.066c-.136.137-.355.137-.464 0L6.78 9.28l-1.804 1.805c-.11.137-.329.137-.465 0L3.445 9.992c-.136-.11-.136-.328 0-.465L5.25 7.75 3.445 5.973c-.136-.11-.136-.328 0-.465L4.54 4.44c.11-.136.328-.136.465 0L6.78 6.22 8.56 4.44c.109-.136.328-.136.464 0l1.067 1.067c.137.137.137.355 0 .465L8.313 7.75l1.777 1.805z"
    ></path>
    <path
      fill="#B3B5B8"
      d="M8.618 7.75l2.734-2.734a.812.812 0 000-1.204l-.601-.601a.812.812 0 00-1.203 0L6.813 5.945 4.052 3.211a.812.812 0 00-1.203 0l-.602.602a.812.812 0 000 1.203L4.981 7.75l-2.734 2.762a.812.812 0 000 1.203l.602.601a.812.812 0 001.203 0l2.761-2.734 2.735 2.734a.812.812 0 001.203 0l.601-.601a.812.812 0 000-1.203L8.618 7.75z"
    ></path>
  </svg>
)

export default IconClose
