import React from 'react'
import { styled } from 'twin.macro'

import FastPNG from './assets/fast.png'
import FreePNG from './assets/free.png'
import FriendlyPNG from './assets/friendly.png'
import { Content } from '../../../../layouts/shared/content'
import { useMotesCountQuery } from '../../../../api/requests/tracking'

function getMotesCountFromStorageOrDefault() {
  const motesCount = window.localStorage.getItem('mote-motes-count')

  if (motesCount) {
    return parseInt(motesCount)
  }
  return 27782015
}

const numberFormat = new Intl.NumberFormat()

export const BenefitsSection = () => {
  const { data } = useMotesCountQuery()

  const count = React.useMemo(() => {
    if (!data) {
      const motesCount = getMotesCountFromStorageOrDefault()

      return numberFormat.format(motesCount)
    }

    window.localStorage.setItem('mote-motes-count', data?.motesCount)

    return numberFormat.format(data?.motesCount)
  }, [data])

  return (
    <Root>
      <Content>
        <StatisticsContainer>
          <NumberOfMotesCreated>
            🚀 {count} motes created to date!
          </NumberOfMotesCreated>
          <NumberOfUsers>
            Join over 1 million users already on the Mote boat ⛵
          </NumberOfUsers>
        </StatisticsContainer>

        <Benefits>
          <Benefit>
            <ImageContainer>
              <img src={FastPNG} alt="fast" />
            </ImageContainer>
            <Title>Mote is fast</Title>
            <Subtitle>
              Voice notes are 3x faster than typing. Don't believe us? Try for
              yourself!
            </Subtitle>
          </Benefit>
          <Benefit>
            <ImageContainer>
              <img src={FriendlyPNG} alt="friendly" />
            </ImageContainer>
            <Title>Mote is friendly</Title>
            <Subtitle>
              With voice, your intentions and empathy shine through clearly
            </Subtitle>
          </Benefit>
          <Benefit>
            <ImageContainer>
              <img src={FreePNG} alt="free" />
            </ImageContainer>
            <Title>Mote is free</Title>
            <Subtitle>
              With a free trial period and free tier access forever...what are
              you waiting for?
            </Subtitle>
          </Benefit>
        </Benefits>
      </Content>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 96px 0;

  @media (max-width: 1199px) {
    padding: 80px 0;
  }

  @media (max-width: 991px) {
    padding: 60px 0;
  }
  @media (max-width: 639px) {
    padding: 40px 0;
  }
`

const StatisticsContainer = styled.div``
const NumberOfMotesCreated = styled.h5`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.05em;
  color: #232323;
  margin-bottom: 16px;
`
const NumberOfUsers = styled.h3`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.05em;
  color: #232323;
  margin-bottom: 80px;

  @media (max-width: 1199px) {
    margin-bottom: 60px;
  }

  @media (max-width: 991px) {
    margin-bottom: 48px;
  }

  @media (max-width: 639px) {
    margin-bottom: 32px;
  }
`
const Benefits = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 639px) {
    flex-direction: column;
    align-items: center;
  }
`
const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 333px;
`
const ImageContainer = styled.div`
  width: 130px;
  height: 130px;
  background: #f9f9fb;
  display: grid;
  place-items: center;
  border-radius: 50%;
  margin-bottom: 32px;

  @media (max-width: 1199px) {
    width: 110px;
    height: 110px;
  }

  @media (max-width: 639px) {
    width: 90px;
    height: 90px;
    margin: 40px 0 32px 0;
  }
`
const Title = styled.p`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.05em;
  color: #232323;
`
const Subtitle = styled.span`
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #232323;
`
