import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'

class Contact extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <div className="container">
          <h1 className="lg-display-4-mb-4-mt mote-uninstall-title">
            Getting in touch
          </h1>
          <div className="col-9">
            <h3>Sales enquiries:</h3>
            <p>
              The best option is to check out our pricing page and complete our{' '}
              <a href="/quote" className="mote-boiler-link">
                quote form
              </a>
              .<br></br>
              We follow up within 1 working day.<br></br>
              Sales questions? Email us at{' '}
              <a href="mailto:success@justmote.me" className="mote-boiler-link">
                success@justmote.me
              </a>{' '}
            </p>
            <h3>Help &amp; support:</h3>
            <p>
              Our{' '}
              <a
                href="https://support.mote.com/hc/"
                className="mote-boiler-link"
              >
                support site
              </a>{' '}
              is the fastest way to address 99% of questions.<br></br>
              Still need help? Please reach out at{' '}
              <a href="mailto:support@mote.com" className="mote-boiler-link">
                support@mote.com
              </a>
              .
            </p>
            <p>
              <b>Write to us:</b>
              <br></br>
              Mote Technologies, Inc.<br></br>
              548 Market St<br></br>
              PMB 52828<br></br>
              San Francisco, California 94104-5401 <br></br>
              USA<br></br>
            </p>
            <p>
              Call us*: +1 (205) 880-8969<br></br>
              <br></br>* Leave a message if no-one's home - we're usually out,
              helping make feedback awesome.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Contact
