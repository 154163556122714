import { useGenericRequestHandler } from '../../../handlers/useGenericRequestHandler'

export const useMoteAddChunkMutation = (moteId, chunkId) => {
  return useGenericRequestHandler({
    route: `m/addChunk/${moteId}/${chunkId}`,
    method: 'POST',
    clientType: 'private',
    abortable: false,
    options: {
      headers: {
        'Content-Type': 'audio/*'
      }
    }
  })
}
