import React from 'react'
import { getAccessToken } from '../shared/Auth'
import { BASE_URL, NETWORK_TIMEOUT } from '../shared/Constants'
import Header from '../shared/Header'
import Loading from '../shared/Loading'
import { Footer } from '../shared/Footer'
import {
  noLog as log,
  reallyTruthy,
  coinFlip,
  randomChoice,
  doubleDice
} from '../shared/Utils'

const axios = require('axios').default

const DEV_FORCE_LOGIN =
  reallyTruthy(process.env.REACT_APP_DEV_FORCE_LOGIN) || false

const FAKE_MESSAGES = [
  'We have no record of that gift code',
  'This gift is already redeemed by you. You are good to go!',
  'This gift is already redeemed by someone else',
  'You are already paid up'
]

const FAKE_DEV_STATE = {
  loggedIn: coinFlip(),
  redemptionSuccess: coinFlip(),
  renderMode: randomChoice([
    'Loading',
    'GiftCode404',
    'SystemError',
    'UserFacingError',
    'NeedToLogin'
  ]),
  redemptionError: {
    code: Math.floor(Math.random() * 70) + 10,
    message: randomChoice(FAKE_MESSAGES),
    userFacingOK: coinFlip(),
    systemError: coinFlip()
  }
}

class Gift extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: false,
      renderMode: 'Loading',
      couponId: null
    }
  }

  componentDidMount() {
    document.title = 'Gift Code'
    try {
      this.giftCode = this.props.match.params.giftCode
    } catch (e) {}

    if (!this.giftCode) {
      this.setState({ renderMode: 'GiftCode404' })
    } else {
      if (!DEV_FORCE_LOGIN) {
        this._tryRedeem()
      } else {
        if (doubleDice()) {
          this._redirectToAccount()
        } else {
          this.setState(FAKE_DEV_STATE)
        }
      }
    }
  }

  render() {
    var methodName = '_render' + this.state.renderMode
    log('methodname is: ' + methodName)
    var method = this[methodName].bind(this)
    return (
      <div className="container mote-brand">
        <Header />
        <main id="main">{method()}</main>
        <Footer />
      </div>
    )
  }

  _renderLoading() {
    return <Loading />
  }

  _renderGiftCode404() {
    return (
      <span>
        We couldn't find a gift code. Are you sure this is the correct URL?
        Please email support@mote.com if the problem persists.
      </span>
    )
  }

  _renderSystemError() {
    return (
      <span>
        Oh dear, something weird happened on our side. Sorry about that. If the
        problem persists after reload, reach out to us on support@mote.com
      </span>
    )
  }

  _renderUserFacingError() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Gift redemption error</h1>
          <p className="lead">
            This gift code can't be redeemed because:{' '}
            {this.state.redemptionError.message}{' '}
          </p>
          <p>
            If this doesn't sound right, please email us: support@mote.com
          </p>
        </div>
      </div>
    )
  }

  _renderNeedToLogin() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-8 px-lg-4">
          <h1 className="mote-logged-in-title">Please sign in</h1>
          <p className="title">
            You're not logged in, please sign in to mote in order to use your
            gift code.
          </p>
          <br></br>
          <button className="pay-button">
            <a href={'/login?returnTo=/g/' + this.giftCode}>Let's do it</a>
          </button>
        </div>
      </div>
    )
  }

  _redirectToAccount() {
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.host +
      '/account?congrats'
  }

  _tryRedeem() {
    var axiosInstance = axios.create({
      baseURL: BASE_URL + 'c/',
      timeout: NETWORK_TIMEOUT
    })
    const accessToken = getAccessToken()
    if (!reallyTruthy(accessToken)) {
      this.setState({ renderMode: 'NeedToLogin' })
      return
    }
    axiosInstance
      .post('redeemGiftCode', {
        accessToken: accessToken,
        giftId: this.giftCode
      })
      .then((response) => {
        this.setState(response.data)
        if (response.data.redemptionSuccess) {
          this._redirectToAccount()
        } else {
          if (response.data.redemptionError.userFacingOK) {
            this.setState({ renderMode: 'UserFacingError' })
          } else {
            this.setState({ renderMode: 'SystemError' })
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          this.setState({ renderMode: 'NeedToLogin' })
        } else {
          this.setState({ renderMode: 'SystemError' })
        }
      })
  }
}

export default Gift
