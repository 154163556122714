import React from 'react'

import { EpochToPrettyDate } from '../../../shared/Utils'
import Congrats from './Congrats'
import CopyReferrerLink from './CopyReferrerLink'
import Gifts from './Gifts'
import InvoiceHistory from './InvoiceHistory'
import Minor from './Minor'
import PersonalProductSummary from './PersonalProductSummary'
import ReferrerCTA from './ReferrerCTA'

class AccountConsumer extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.props.data
  }

  render() {
    const data = this.state
    if (data.isMinor) {
      return <Minor data={data} />
    } else if (data.isGifted) {
      return (
        <div>
          <Congrats />
          <PersonalProductSummary data={data} />

          <p>
            Your account was a gift from {data.giftorName} (
            <a href={'mailto:' + data.giftorEmail}>{data.giftorEmail}</a>)
          </p>
          <ReferrerCTA code={this.state.referrerCode} />
        </div>
      )
    } else if (data.isPaid) {
      return (
        <div>
          <Congrats />

          <PersonalProductSummary data={data} />
          <InvoiceHistory data={data} />
          <ReferrerCTA code={data.referrerCode} />
          <Gifts data={data} />
        </div>
      )
    } else if (data.isVIP) {
      return (
        <div>
          <PersonalProductSummary data={data} />

          <p>
            Thanks to your general awesomeness and support for{' '}
            <b className="mote-purple">mote</b> you can enjoy Unlimited access{' '}
            <b>indefinitely</b>.
          </p>
          <p>
            Keep spreading the word using the unique link below (right now this
            is a regular upgrade, but we're working on a better Ambassador/VIP
            invite offer.<sup>1</sup>
          </p>
          <CopyReferrerLink code={this.state.referrerCode} />
          <Gifts data={data} />
        </div>
      )
    } else if (data.isOnReferralUnlimited) {
      return (
        <div>
          <PersonalProductSummary data={data} />

          <p>
            Thanks to friends activating your invitations, you're flying high
            with free Unlimited access - nice work!
          </p>
          <p>
            Keep inviting friends using the unique link below, and redeem up to
            ten 30-day trial extensions.<sup>1</sup>
          </p>
          <CopyReferrerLink code={this.state.referrerCode} />
          <Gifts data={data} />
        </div>
      )
    } else if (data.isInFreeTrial) {
      return (
        <div>
          <p className="lead">
            You are on our Unlimited tier free trial until{' '}
            <b>{EpochToPrettyDate(data.freeTrialExpiryEpoch)}</b>.
          </p>

          <h3>Want to stay Unlimited for free?</h3>
          {/* <p className="lead">Invite your friends to try <b className="mote-purple">mote</b></p> */}

          <p>
            Invite your friends to try <b className="mote-purple">mote</b>.
          </p>

          {this._renderReferralBenefits()}

          <CopyReferrerLink code={this.state.referrerCode} />
          <Gifts data={data} />
        </div>
      )
    } else {
      return (
        <div>
          <p className="lead">
            Your free trial has expired - check out prices and deals{' '}
            <a href="/pricing" className="mote-boiler-link">
              here
            </a>
            .
          </p>
          <h3>Want to reactivate Unlimited for free?</h3>
          <p>
            Invite your friends to try <b className="mote-purple">mote</b> and
            you can unlock 30 more days of free Unlimited access.
          </p>
          <p>
            For every invited friend who joins{' '}
            <b className="mote-purple">mote</b>, we'll add 60 days.
          </p>
          {this._renderReferralBenefitsForFriends()}
          <CopyReferrerLink code={this.state.referrerCode} />{' '}
          <Gifts data={data} />
        </div>
      )
    }
  }

  _renderReferralBenefits() {
    return (
      <div>
        <p>
          For every friend who joins <b className="mote-purple">mote</b>, we'll
          extend your free trial by 30 days. Keep inviting friends and you can
          redeem up to ten 30-day trial extensions.
        </p>
        {this._renderReferralBenefitsForFriends()}
      </div>
    )
  }

  _renderReferralBenefitsForFriends() {
    return (
      <p>
        And your friends will get a 60-day Unlimited tier{' '}
        <b className="mote-purple">mote</b> free trial, instead of the regular
        30-day trial.
      </p>
    )
  }
}

export default AccountConsumer
