import React from 'react'
import PropTypes from 'prop-types'
import tw, { styled, css } from 'twin.macro'
import { useAudioPosition } from 'react-use-audio-player'

import { P } from '@mote/config/defaults/typography'
import { secondsToString } from '../../moteCard/utilities'

export const DurationIndicator = React.memo(({ replyIsMine }) => {
  const { position, duration } = useAudioPosition()

  return (
    <DurationsContainer replyIsMine={replyIsMine}>
      <StyledP replyIsMine={replyIsMine} isSmall data-testid="current-duration">
        {secondsToString(position)}
      </StyledP>
      <span>/</span>
      <StyledP replyIsMine={replyIsMine} isSmall data-testid="total-duration">
        {secondsToString(duration)}
      </StyledP>
    </DurationsContainer>
  )
})

const DurationsContainer = styled.div(({ replyIsMine }) => [
  tw`flex flex-row text-xs items-center text-gray-800 gap-1`,
  `
  color: #232323;
  line-height: 16px;
  margin-top: 6px;
  `,
  replyIsMine &&
    `
      color: white;
  `
])

const StyledP = styled(P)`
  ${({ replyIsMine }) =>
    replyIsMine &&
    css`
      color: white;
    `}
`

DurationIndicator.propTypes = {
  currentTime: PropTypes.number,
  totalTime: PropTypes.string
}
