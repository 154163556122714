import { error } from '@mote/common'

import { getAccessToken, afterMoteAuth } from '../shared/Auth'
import { canIUseCanaryChargebee } from '../shared/Canary'
import { NETWORK_TIMEOUT } from '../shared/Constants'
import { BASE_URL } from '../shared/Constants'
import { noLog as log, getTZOffset } from '../shared/Utils'

const queryString = require('query-string')
const axios = require('axios').default.create({
  baseURL: BASE_URL + 'c/',
  timeout: NETWORK_TIMEOUT
})

var PRODUCT_LIST = {}

export const PRODUCT_UNLIMITED_VOLUME = 'unlimited_volume'
export const PRODUCT_ESSENTIAL_VOLUME = 'essential_volume'

function queryAccount(godModeOptions, success, failure) {
  if (!godModeOptions) {
    godModeOptions = {}
  }
  if (!success) {
    success = () => {}
  }
  if (!failure) {
    failure = () => {}
  }
  var params = Object.assign({}, godModeOptions, {
    accessToken: getAccessToken(),
    TZOffset: getTZOffset()
  })
  log('godModeOptions: ', godModeOptions)
  log('full params before request: ', params)
  axios
    .post('account', params)
    .then((r) => {
      success(r.data)
    })
    .catch((e) => {
      error("Couldn't query account: ", e)
      failure(e)
    })
}

function fakePurchase(productEnum, success, failure) {
  _accountSAK('fake_purchase', success, failure, {
    productEnum: productEnum
  })
}

function fakeSchoolPurchase(success, failure) {
  _accountSAK('fake_school_purchase', success, failure, {})
}

function fakeMixedSchoolPurchase(success, failure) {
  _accountSAK('fake_mixed_school_purchase', success, failure, {})
}

function fakeReferral(success, failure) {
  _accountSAK('fake_referral', success, failure)
}

function resetReferralState(success, failure) {
  _accountSAK('reset_referral_state', success, failure)
}

function quotaLessOne(success, failure) {
  _accountSAK('quota_less_1', success, failure)
}

function resetAccount(success, failure) {
  _accountSAK('reset', success, failure)
}

function toggleIsMinorState(isMinor, success, failure) {
  const mode = isMinor ? 'minor' : 'adult'
  _accountSAK(mode, success, failure)
}

function startFreeTrial(success, failure) {
  log('success: ', success)
  log('failure: ', failure)
  _accountSAK('free_trial_start', success, failure)
}

function endFreeTrial(success, failure) {
  _accountSAK('free_trial_end', success, failure)
}

function sendActivityNotification(success, failure) {
  _accountSAK('send_activity_notification', success, failure)
}

function _accountSAK(mode, success, failure, options) {
  if (!options) {
    options = {}
  }
  var postObj = Object.assign(
    { mode: mode, accessToken: getAccessToken(), TZOffset: getTZOffset() },
    options
  )
  axios
    .post('accountSAK', postObj)
    .then((r) =>
      afterMoteAuth(
        () => success(),
        (e) => failure(e)
      )
    )
    .catch((e) => {
      error(`Couldn't apply ${mode} to account: `, e)
      failure(e)
    })
}

function listProductsForSchools(success, failure) {
  listProducts(success, failure, true)
}

function listProducts(success, failure, forSchools) {
  // to test error state, uncomment this line
  // failure();
  if (!success) {
    success = () => {}
  }
  if (!failure) {
    failure = () => {}
  }
  const provider = canIUseCanaryChargebee() ? 'chargebee' : 'stripe'
  axios
    .get('products?provider=' + provider)
    .then((r) => {
      PRODUCT_LIST = _decorateWithCurrencySymbols(r.data)
      // console.log("Got products: ", PRODUCT_LIST);
      if (forSchools) {
        if (!_schoolProductsAreValid(r.data)) {
          failure(new Error('Invalid school products'))
        }
      }
      success(PRODUCT_LIST)
    })
    .catch((e) => {
      error("Couldn't fetch products: ", e)
      failure(e)
    })
}

function _schoolProductsAreValid(o) {
  if ('products' in o) {
    const p = o.products
    if (PRODUCT_ESSENTIAL_VOLUME in p && PRODUCT_UNLIMITED_VOLUME in p) {
      return true
    }
  }
  return false
}

function _decorateWithCurrencySymbols(o) {
  var symbolMap = {}
  for (const row of o.currencies) {
    symbolMap[row.currencyCode] = row.symbol
  }
  o.currencySymbols = symbolMap
  return o
}

function getSymbol(currency) {
  try {
    return PRODUCT_LIST.currencySymbols[currency]
  } catch (e) {
    return '$'
  }
}

function hasApplicableCoupons(sku) {
  log('do we have coupons for ', sku)
  log('found coupons are: ', PRODUCT_LIST.coupons)
  try {
    return (
      Object.keys(PRODUCT_LIST.coupons).length > 0 &&
      sku.planEnum === 'year' &&
      sku.productEnum !== 'essential'
    )
  } catch (e) {
    return false
  }
}

function getCouponDiscount(couponId) {
  log('looking up: ' + couponId)
  try {
    const nCouponId = normalisedCouponId(couponId)
    log('looking up .. ' + nCouponId + ' in ', PRODUCT_LIST)
    var result = parseFloat(PRODUCT_LIST.coupons[nCouponId])
    if (!Number.isNaN(result)) {
      log('Found it!!!')
      return result / 100
    }
  } catch (e) {
    error('oh dear', e)
  }
  return 0
}

function normalisedCouponId(couponId) {
  try {
    return couponId.replace(/[# ]+/g, '').toLowerCase()
  } catch (e) {
    return ''
  }
}

function getSKU(product, plan, currency) {
  const p = PRODUCT_LIST.products
  if (!p) {
    return null
  }
  try {
    return p[product][plan][currency]
  } catch (e) {
    error(`no matching price for ${product} - ${plan} - ${currency}`)
    return null
  }
}

function getSchoolSaving(sku, quantity, asPct, isUnit) {
  if (!quantity) {
    return
  }
  const quotedTotal = formalPrice(sku, null, quantity, true)
  const baseUnit = formalPrice(sku, null, 1, true)
  const baseUnitTotal = baseUnit * quantity
  if (baseUnitTotal === quotedTotal) {
    return null
  }
  var difference = baseUnitTotal - quotedTotal
  if (isUnit) {
    difference /= quantity
  }
  if (asPct) {
    const pct = (100 * difference) / baseUnitTotal
    return Math.round(pct) + '%'
  } else {
    const symbol = getSymbol(sku.currency)
    var price = difference
    return symbol + price.toFixed(2)
  }
}

function formalSchoolPrice(sku, quantity, isUnit, times) {
  return formalPrice(sku, null, quantity, false, isUnit, times)
}

function formalPrice(sku, couponId, quantity, raw, isUnit, times) {
  if (!quantity) {
    quantity = 1
  }
  if (!times) {
    times = 1
  }
  var discount = getCouponDiscount(couponId)
  var price
  const realQuantity = isUnit ? 1 : quantity
  if (sku.tiers) {
    var found = false
    for (var i = 0; !found && i < sku.tiers.length; i++) {
      const tier = sku.tiers[i]
      if (tier.to && tier.to < quantity) {
        continue
      }
      found = true
      price = Math.round(
        times * realQuantity * parseFloat(tier.rawYearlyPrice.toString())
      )
    }
  } else {
    price = Math.round(
      realQuantity * parseFloat(sku.rawPrice.toString()) * (1 - discount)
    )
  }
  const symbol = getSymbol(sku.currency)
  if (sku.hasCentiles) {
    price /= 100
    if (raw) {
      return parseFloat(price.toFixed(2))
    } else {
      return symbol + price.toFixed(2)
    }
  } else {
    if (raw) {
      return price
    } else {
      return symbol + price
    }
  }
}

function prettyPrice(sku, discountPct) {
  var price = parseInt(sku.rawMonthlyPrice.toString())
  log('Price is: ' + price)
  if (discountPct) {
    price *= 100 / discountPct
    price = Math.floor(price)
  }
  log('Price2 is: ' + price)
  if (price === 0) {
    return '0'
  } else if (!sku.hasCentiles) {
    return price
  } else {
    var m = price.toString().match(/^(\d+)(\d{2})$/)
    if (m) {
      var units = m[1]
      var decimals = m[2]
      if (decimals === '00') {
        return units
      } else {
        return units + '.' + decimals
      }
    } else {
      m = price.toString().match(/^(\d{2})$/)
      if (m) {
        return '0.' + m[1]
      } else {
        return '0'
      }
    }
  }
}

function prettyPriceAnnual(sku, discountPct) {
  var price = parseInt(sku.rawYearlyPrice.toString())
  log('Price is: ' + price)
  if (discountPct) {
    price *= 100 / discountPct
    price = Math.floor(price)
  }
  log('Price2 is: ' + price)
  if (price === 0) {
    return '0'
  } else if (!sku.hasCentiles) {
    return price
  } else {
    var m = price.toString().match(/^(\d+)(\d{2})$/)
    if (m) {
      var units = m[1]
      var decimals = m[2]
      if (decimals === '00') {
        return units
      } else {
        return units + '.' + decimals
      }
    } else {
      m = price.toString().match(/^(\d{2})$/)
      if (m) {
        return '0.' + m[1]
      } else {
        return '0'
      }
    }
  }
}

function getSKUFromParams(product, plan, currency) {
  const p = PRODUCT_LIST.products
  try {
    if (p && product && plan && currency && p[product][plan][currency]) {
      log('Products are: ' + JSON.stringify(PRODUCT_LIST))
      log(`Using ${product} / ${plan} / ${currency}`)
      log('Found: ' + JSON.stringify(p[product][plan][currency]))
      return p[product][plan][currency]
    }
  } catch (e) {}
  return null
}

function getSKUFromURL() {
  log('query params: ', window.location.search)
  const o = queryString.parse(window.location.search)
  return getSKUFromParams(o.product, o.plan, o.currency)
}

function generateURLParamsFromSKU(sku) {
  return (
    'plan=' +
    sku.planEnum +
    '&product=' +
    sku.productEnum +
    '&currency=' +
    sku.currency
  )
}

function createNewChargebeeCheckout(
  priceId,
  quantity,
  institutionMode,
  institutionData,
  onSuccess,
  onFailure
) {
  axios
    .post('newCheckout', {
      accessToken: getAccessToken(),
      priceId: priceId,
      quantity: quantity,
      institutionMode: institutionMode,
      institutionName: institutionData.institutionName,
      institutionRegion: institutionData.institutionRegion,
      institutionCountryCode: institutionData.institutionCountryCode
    })
    .then((response) => {
      log('Got something back: ', response.data)
      if (response.data.URL) {
        return onSuccess(response.data.URL)
      } else {
        return onFailure(response.error.message)
      }
    })
    .catch((e) => {
      return onFailure('Something bad happened on our end, try again later')
    })
}

function createSubscription(
  stripe,
  paymentMethodId,
  priceId,
  couponId,
  quantity,
  institutionMode,
  institutionData,
  success,
  onFailure
) {
  if (!institutionMode) {
    institutionMode = false
  }
  if (!institutionData) {
    institutionData = {}
  }
  if (!success) {
    success = () => {}
  }
  if (!onFailure) {
    onFailure = () => {}
  }
  log('paymentMethodId: ' + paymentMethodId)
  log('priceId: ' + priceId)
  axios
    .post('createSubscription', {
      accessToken: getAccessToken(),
      TZOffset: getTZOffset(),
      paymentMethodId: paymentMethodId,
      priceId: priceId,
      quantity: quantity,
      couponId: couponId,
      institutionMode: institutionMode,
      institutionName: institutionData.institutionName,
      institutionRegion: institutionData.institutionRegion,
      institutionCountryCode: institutionData.institutionCountryCode
    })
    .then((response) => {
      log('Got something back: ', response.data)
      return response.data
    })
    .then((result) => {
      log('Got back result: ', result)
      if (result.error) {
        if (result.error.message) {
          throw new Error(result.error.message)
        } else {
          log('Oh dear, got non-specific error (1): ', result.error)
          throw new Error('Some error processing card (1)')
        }
      } else {
        return result
      }
    })
    .then((result) => {
      return {
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        subscription: result,
        onFailure: onFailure
      }
    })
    .then((result) => {
      log('2nd interaction needed? ', stripe, result)
      return handlePaymentThatRequiresCustomerAction(stripe, result)
    })
    .then((result) => {
      log('requires payment method? ', stripe, result)
      return handleRequiresPaymentMethod(stripe, result)
    })
    .then((result) => {
      log('About to return success', result)
      success(result.subscription)
    })
    .catch((e) => {
      error("Couldn't create purchase: ", e)
      onFailure(e)
    })
}

function handlePaymentThatRequiresCustomerAction(
  stripe,
  { subscription, invoice, priceId, paymentMethodId, isRetry, onFailure }
) {
  log('Checking what we need to do on sub: ', subscription)
  log('and invoice: need to do on: ', invoice)
  if (subscription && subscription.status === 'active') {
    // Subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId }
  }

  // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
  // If it's a retry, the payment intent will be on the invoice itself.
  let paymentIntent = invoice
    ? invoice.payment_intent
    : subscription.latest_invoice.payment_intent

  if (
    paymentIntent.status === 'requires_action' ||
    (isRetry === true && paymentIntent.status === 'requires_payment_method')
  ) {
    log('need to do something: ', paymentIntent)
    return stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId
      })
      .then((result) => {
        if (result.error) {
          if (result.error.message) {
            log('Aborting with error message!')
            throw new Error(result.error.message)
          } else {
            log('Oh dear, got non-specific error: ', result.error)
            throw new Error('Some error processing card (2)')
          }
        } else {
          log('got result', result)
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer.
            // There's a risk of the customer closing the window before the callback.
            // We recommend setting up webhook endpoints later in this guide.
            return {
              priceId: priceId,
              subscription: subscription,
              invoice: invoice,
              paymentMethodId: paymentMethodId
            }
          } else {
            log('got a non-success result', result)
          }
        }
      })
  } else {
    log('mothing to do: ', subscription)
    // No customer action needed.
    return { subscription, priceId, paymentMethodId }
  }
}

function handleRequiresPaymentMethod(
  stripe,
  { subscription, paymentMethodId, priceId }
) {
  if (subscription.status === 'active') {
    // subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId }
  } else if (
    subscription.latest_invoice.payment_intent.status ===
    'requires_payment_method'
  ) {
    // Using localStorage to manage the state of the retry here,
    // feel free to replace with what you prefer.
    // Store the latest invoice ID and status.
    localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
    localStorage.setItem(
      'latestInvoicePaymentIntentStatus',
      subscription.latest_invoice.payment_intent.status
    )
    throw new Error('Your card was declined.')
  } else {
    return { subscription, priceId, paymentMethodId }
  }
}

// function retrySubscription() {

// }

// function cancelSubscription() {

// }

export {
  fakeReferral,
  resetReferralState,
  fakePurchase,
  fakeSchoolPurchase,
  fakeMixedSchoolPurchase,
  queryAccount,
  resetAccount,
  quotaLessOne,
  toggleIsMinorState,
  endFreeTrial,
  startFreeTrial,
  listProducts,
  listProductsForSchools,
  createNewChargebeeCheckout,
  createSubscription,
  getSymbol,
  getSKU,
  getSKUFromURL,
  getSKUFromParams,
  prettyPrice,
  prettyPriceAnnual,
  formalPrice,
  formalSchoolPrice,
  getSchoolSaving,
  generateURLParamsFromSKU,
  hasApplicableCoupons,
  getCouponDiscount,
  normalisedCouponId,
  sendActivityNotification
}
