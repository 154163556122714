import React from 'react'
import { styled } from 'twin.macro'

import CoppaPNG from './assets/coppa.png'
import FerpaPNG from './assets/ferpa.png'
import GoogleEducationPartnerJPEG from './assets/google-education-partner.jpeg'
import { Content } from '../../../../layouts/shared/content'

export const CertificatesSection = () => {
  return (
    <Root>
      <Content>
        <Headline>The certified companion to Google Workspace.</Headline>

        <Certificates>
          <img src={CoppaPNG} alt="Coppa" />
          <img
            src={GoogleEducationPartnerJPEG}
            alt="Google Education Partner"
          />
          <img src={FerpaPNG} alt="Ferpa" />
        </Certificates>
      </Content>
    </Root>
  )
}

const Root = styled.div`
  padding: 60px 0;
  background: #f9f9fb;

  @media (max-width: 991px) {
    padding: 48px 0;
  }
  @media (max-width: 639px) {
    padding: 40px 0;
  }
`

const Headline = styled.h1`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.05em;
`

const Certificates = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img:not(:last-of-type) {
    margin-right: 25px;
  }
`
