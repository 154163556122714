import React from 'react'
import { styled, css } from 'twin.macro'
import { AudioPlayerProvider } from 'react-use-audio-player'
import copy from 'copy-to-clipboard'
import dayjs from 'dayjs'

import { ReplyPlayer } from './ReplyPlayer'
import { SHORT_LINK_DOMAIN } from 'plugin/src/shared/Constants'
import { useRecordReplySidebar } from '../RecordReplySidebar.context'

const TWO_MINUTES = 2 * 60 * 1000
function checkIfNewReply(date) {
  return new Date() - new Date(date) < TWO_MINUTES
}

export const MoteReply = ({ reply, userId }) => {
  const { state } = useRecordReplySidebar()
  const [showSuccessNotification, setShowSuccessNotification] = React.useState(
    false
  )
  const [isNewReply, setIsNewReply] = React.useState(
    checkIfNewReply(reply.createdAt)
  )
  const [showTranscriptionBox, setShowTranscriptionBox] = React.useState(false)

  const replyIsMine = React.useMemo(() => {
    if (!userId) return false

    return (
      userId === reply.createdByUserId // && state.highlightId !== reply.moteId
    )
  }, [userId])
  const moteReplyRef = React.useRef(null)

  function toggleTranscriptionBox() {
    setShowTranscriptionBox((prev) => !prev)
  }

  function handleCopyToClipboard() {
    const url = `${SHORT_LINK_DOMAIN}${reply.moteId}`
    copy(url)
    setShowSuccessNotification(true)

    setTimeout(() => {
      setShowSuccessNotification(false)
    }, 3000)
  }

  React.useEffect(() => {
    if (!moteReplyRef.current || !state.highlightId === reply.moteId) return

    moteReplyRef.current.scrollIntoView()
  }, [moteReplyRef])

  React.useEffect(() => {
    if (!isNewReply) return

    const timeout = setTimeout(() => setIsNewReply(false), TWO_MINUTES)

    return () => clearTimeout(timeout)
  }, [isNewReply])

  return (
    <AudioPlayerProvider>
      <Root ref={moteReplyRef} replyIsMine={replyIsMine}>
        <CreatedAt>
          {isNewReply && (
            <>
              <NewReply>New!</NewReply> Just now
            </>
          )}{' '}
          {!isNewReply && dayjs(reply.createdAt).format('HH:mma DD MMMM')}
        </CreatedAt>
        <Card
          highlight={state.highlightId === reply.moteId}
          replyIsMine={replyIsMine}
        >
          <FullName replyIsMine={replyIsMine}>
            {replyIsMine ? 'You replied 🌟' : reply.createdByName}
          </FullName>
          <ReplyPlayer
            replyIsMine={replyIsMine}
            audioUrl={reply.playbackLocation}
            onCopyToClipboard={handleCopyToClipboard}
          />
          {/*{showTranscriptionBox && (*/}
          {/*  <TranscriptionBox>i am the transcription</TranscriptionBox>*/}
          {/*)}*/}
          {/*<ShowHideTranscriptionButton onClick={toggleTranscriptionBox}>*/}
          {/*  Show Transcript*/}
          {/*</ShowHideTranscriptionButton>*/}
          {showSuccessNotification && (
            <NotificationContainer>
              <Notification>
                <SuccessNotificationBadge />
                <NotificationText>Copied to the clipboard!</NotificationText>
              </Notification>
            </NotificationContainer>
          )}
        </Card>
      </Root>
    </AudioPlayerProvider>
  )
}

const Root = styled.div`
  max-width: 305px;
  width: 100%;

  ${({ replyIsMine }) =>
    replyIsMine &&
    css`
      margin-left: 18px;
    `}
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px;
  background: #f9f9fa;
  border-radius: 10px;
  position: relative;

  ${({ highlight }) =>
    highlight &&
    css`
      border: 4px solid #feef62;
    `}

  ${({ replyIsMine }) =>
    replyIsMine &&
    css`
      background: #ac0ae8;
    `}
`

const CreatedAt = styled.span`
  display: block;
  text-align: center;
  color: rgba(35, 35, 35, 0.4);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`

const FullName = styled.span`
  color: #232323;
  margin-bottom: 10px;
  font-weight: 500;

  ${({ replyIsMine }) =>
    replyIsMine &&
    css`
      color: #ffffff;
    `}
`

const NotificationContainer = styled.div`
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Notification = styled.span`
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  padding: 16px;
`

const NotificationText = styled.span`
  font-size: 14px;
  margin-left: 13px;
  color: #232323;
`

const NewReply = styled.span`
  color: #ac0ae8;
  font-weight: bold;
`

const TranscriptionBox = styled.div``
const ShowHideTranscriptionButton = styled.span`
  cursor: pointer;
`

const SuccessNotificationBadge = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#57C96A" />
      <path
        d="M6 9.8L9.13043 13L15 7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}
