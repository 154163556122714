import React from 'react'
import PropTypes from 'prop-types'
import tw, { styled, css } from 'twin.macro'

const isTouch = 'ontouchend' in document.documentElement

// Strict function separation of mouse/touch events otherwise things go haywire on mobile
// `preventDefault` on touchdown does not help so this is a working but more verbose alternative
export const Slider = ({ currentVolume, updateVolume }) => {
  const volumeBarEl = React.useRef(null)

  function barPosition(e) {
    const clickPageX = e.touches ? e.touches[0].pageX : e.pageX
    const barStart =
      volumeBarEl.current.getBoundingClientRect().left + window.scrollX
    const clickPos = clickPageX - barStart
    const barWidth = volumeBarEl.current.clientWidth
    const percentage = clickPos / parseInt(barWidth)
    const percentageInRange = Math.min(Math.max(percentage, 0), 1)

    updateVolume(percentageInRange * 100)
  }

  function mouseUp(e) {
    e.preventDefault()
    volumeBarEl.current.removeEventListener('mousemove', barPosition)
  }

  function touchUp(e) {
    e.preventDefault()
    volumeBarEl.current.removeEventListener('touchmove', barPosition)
  }

  function eventSetup(e) {
    e.preventDefault()

    barPosition(e)

    if (isTouch) {
      volumeBarEl.current.addEventListener('touchmove', barPosition)
      volumeBarEl.current.addEventListener('touchend', touchUp, { once: true })
    } else {
      volumeBarEl.current.addEventListener('mousemove', barPosition)
      volumeBarEl.current.addEventListener('mouseup', mouseUp, { once: true })
    }
  }

  return (
    <div
      onTouchStart={isTouch ? eventSetup : () => {}}
      onMouseDown={!isTouch ? eventSetup : () => {}}
      ref={volumeBarEl}
      style={{
        position: 'relative',
        touchAction: 'none',
        width: '100%',
        height: 8
      }}
    >
      <ScrubberContainer>
        <BgBar />
        <Bar left={currentVolume} />
      </ScrubberContainer>
      <DotContainer>
        <DotWrapper left={currentVolume}>
          <Dot />
        </DotWrapper>
      </DotContainer>
    </div>
  )
}

Slider.propTypes = {
  currentVolume: PropTypes.number.isRequired,
  updateVolume: PropTypes.func.isRequired
}

const Bar = styled.div(({ left }) => [
  tw`w-full bg-violet-500`,
  css`
    height: 6px;
    transform: translateX(${-100 + left}%);
  `
])

const BgBar = styled.div(() => [
  tw`absolute left-0 w-full bg-gray-200`,
  css`
    height: 6px;
  `
])

const Dot = styled.div(() => [
  tw`absolute w-3 h-3 rounded-full bg-violet-500`,
  css`
    top: -3px;
  `
])

const DotWrapper = styled.div(({ left }) => [
  tw`w-full`,
  css`
    transform: translateX(${left < 10 ? left - 8 : left - 10}%);
  `
])

const DotContainer = styled.div(() => [
  tw`absolute top-0 w-full mb-2 cursor-pointer`
])

const ScrubberContainer = styled.div(() => [
  tw`relative w-full mb-2 overflow-hidden cursor-pointer rounded-2xl`
])
