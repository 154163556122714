import { AU_LAUNCH_TIME, K_SHOW_LOGIN_NAV, K_IN_MARKET } from './Constants'
const queryString = require('query-string')
const ago = require('s-ago')

var LOG_START = Date.now()
const LOG_WITH_TIME = true

export const EpochToAgo = (e) => {
  if (!e) {
    return ''
  }
  return ago(new Date(1000 * e))
}

export const EpochToPrettyDate = (e) => {
  if (!e) {
    return ''
  }
  const d = new Date(e * 1000)
  const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  return d.getDate() + ' ' + MONTHS[d.getMonth()] + ' ' + d.getFullYear()
}

export const EpochToPrettyDateTime = (e) => {
  const d = new Date(e * 1000)
  const date = EpochToPrettyDate(e)
  return (
    date +
    ' ' +
    d.getHours().toString().padStart(2, '0') +
    ':' +
    d.getMinutes().toString().padStart(2, '0')
  )
}

export const EpochToPrettyDateWithUrgency = (e) => {
  const baseDate = EpochToPrettyDate(e)
  const diff = (new Date(e * 1000) - Date.now()) / 1000
  const HOUR = 3600
  const DAY = 24 * HOUR
  var delta = ''
  if (diff < 2 * HOUR) {
    delta = 'soon'
  } else if (diff < 2 * DAY) {
    delta = 'in ' + Math.floor(diff / HOUR) + ' hours'
  } else {
    delta = 'in ' + Math.floor(diff / DAY + 0.5) + ' days'
  }
  return baseDate + ' (ends ' + delta + ')'
}

export const SecondsToString = (t) => {
  return (
    Math.floor(t / 60).toString() + ':' + (t % 60).toString().padStart(2, '0')
  )
}

export const log = (m, ...o) => {
  // var caller = "unknown";
  // try {
  // caller = new Error().stack.split("\n")[2].trim().split(" ")[1];
  // } catch (e) {}

  var timePrefix = ''
  if (LOG_WITH_TIME) {
    timePrefix =
      '[' + (Date.now() - LOG_START).toString().padStart(6, '0') + ']'
  }

  // console.log(`${timePrefix} [${caller}]: ` + m);
  console.log(`${timePrefix} ` + m)
  if (o) {
    for (var oo of o) {
      console.dir(oo)
    }
  }
}

export const noLog = () => {} /// use "import {noLog as log}" to suppress logging noise

export const reallyTruthy = (bool) => {
  if (!bool || bool === 'false' || bool === '0' || bool === [] || bool === {}) {
    return false
  } else {
    return true
  }
}

export const showLogin = () => {
  if (
    reallyTruthy(
      Date.now() / 1000 > AU_LAUNCH_TIME ||
        window.localStorage.getItem(K_SHOW_LOGIN_NAV) ||
        window.localStorage.getItem(K_IN_MARKET)
    )
  ) {
    return true
  } else {
    return false
  }
}

export const getTZOffset = () => {
  return new Date().getTimezoneOffset()
}

export const coinFlip = () => {
  return Math.random() >= 0.5
}

export const fiveTimesOutOfSix = () => {
  return 6 * Math.random() < 5
}

export const randomChoice = (array) => {
  // log("got array: ", array);
  return array[Math.floor(Math.random() * array.length)]
}

export const singleDice = (array) => {
  return Math.random() * 6 < 1
}

export const doubleDice = (array) => {
  return Math.random() * 36 < 1
}

export const validEmail = (e) => {
  const pattern = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  return pattern.test(e)
}

export const yesno = (b) => {
  return reallyTruthy(b) ? 'yes' : 'no'
}

export const shortenWithEllipsis = (s, n) => {
  if (s.length > n) {
    return s.substring(0, n) + ' ... '
  } else {
    return s
  }
}

export const getGoodHostname = () => {
  var hn = window.location.host.toLowerCase()
  if (hn === 'justmote.me') {
    return 'www.justmote.me'
  } else if (hn === 'mote.com') {
    return 'www.mote.com'
  } else {
    return hn
  }
}

export const needsWWWRedirect = () => {
  var hn = window.location.host.toLowerCase()
  return hn === 'justmote.me' || hn === 'mote.com'
}

export const maybeWWWRedirect = () => {
  if (needsWWWRedirect()) {
    window.location.href =
      window.location.protocol +
      '//' +
      getGoodHostname() +
      window.location.pathname +
      window.location.search +
      window.location.hash
  }
}

export const getGodModeOptions = () => {
  // log("search location: " + window.location.search);
  var querySearch = queryString.parse(window.location.search)
  // log("querySearch : ", querySearch);
  const adminOptions = {}
  if (querySearch.iid) {
    adminOptions.institutionIdByAdmin = querySearch.iid
  } else if (querySearch.uid) {
    adminOptions.userIdByAdmin = querySearch.uid
  }
  // log("returning : ", adminOptions);
  return adminOptions
}

export const getGodModeOptionsForState = () => {
  const options = getGodModeOptions()
  return {
    inGodMode: Object.keys(options).length > 0 ? true : false,
    godModeOptions: options
  }
}

export const getGodModeURLSuffix = (institutionId) => {
  // log("computing URL ...");
  const options = getGodModeOptionsForState()
  // log("options are ", options);
  if (options.inGodMode) {
    var o = options.godModeOptions
    // log("we are in god mode with ", o);
    if (o.institutionIdByAdmin) {
      return '?iid=' + o.institutionIdByAdmin
    } else if (institutionId) {
      return '?iid=' + institutionId
    } else if (o.userIdByAdmin) {
      return '?uid=' + o.userIdByAdmin
    }
  }
  return ''
}

export const getTZAbbreviation = () => {
  var result = ''
  try {
    // Chrome, Firefox
    result = /.*\s(.+)/.exec(
      new Date().toLocaleDateString(navigator.language, {
        timeZoneName: 'short'
      })
    )[1]
  } catch (e) {
    // IE, some loss in accuracy due to guessing at the abbreviation
    // Note: This regex adds a grouping around the open paren as a
    //       workaround for an IE regex parser bug
    result = new Date()
      .toTimeString()
      .match(new RegExp('[A-Z](?!.*[(])', 'g'))
      .join('')
  }
  return result
}

export const isDevForceLogin = () => {
  return reallyTruthy(process.env.REACT_APP_DEV_FORCE_LOGIN) || false
}
