import React from 'react'
import Header from '../shared/Header'
import { Footer } from '../shared/Footer'
import MoteCarousel from '../shared/MoteCarousel'

class GetStarted extends React.Component {
  render() {
    return (
      <div className="container mote-brand">
        <Header />
        <div className="container">
          <h1 className="lg-display-4-mb-4-mt mote-uninstall-title">
            Get Started
          </h1>
          <MoteCarousel />
        </div>
        <Footer />
      </div>
    )
  }
}

export default GetStarted
