import { normaliseURL } from '../private/normaliseURL'
import {
  GDOCS,
  GSLIDES,
  GSHEETS,
  GCLASSROOM,
  GMAIL,
  GFORMS
} from '../constants'

export const getApplicationKey = (url) => {
  url = normaliseURL(url)
  if (!url.includes('google.com/')) {
    return ''
  }
  if (
    url.includes('/static/') ||
    url.includes('/iframeapi') ||
    url.includes('/bscframe')
  ) {
    return ''
  }
  if (url.includes('https://docs.google.com/document/')) {
    return GDOCS
  } else if (url.includes('https://docs.google.com/presentation/')) {
    return GSLIDES
  } else if (url.includes('https://docs.google.com/spreadsheets/')) {
    return GSHEETS
  } else if (url.includes('https://docs.google.com/forms/')) {
    return GFORMS
  } else if (url.includes('https://classroom.google.com/')) {
    return GCLASSROOM
  } else if (url.includes('https://mail.google.com/')) {
    return GMAIL
  } else {
    // error("Unknown application type");
    return ''
  }
}
