import React from 'react'
import { styled, css } from 'twin.macro'
import { mote_primary } from '@mote/config/defaults/colors'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import AnywherePNG from './assets/anywhere.png'
import BrowserOutlinePNG from './assets/browser-outline.png'
import ClassroomPNG from './assets/classroom.png'
import DocsPNG from './assets/docs.png'
import FormsPNG from './assets/forms.png'
import GmailPNG from './assets/gmail.png'
import SheetsPNG from './assets/sheets.png'
import SlidesPNG from './assets/slides.png'

import MoteLogoPNG from './assets/mote-logo.png'
import ClassroomLogoPNG from './assets/classroom-logo.png'
import DocsLogoPNG from './assets/docs-logo.png'
import FormsLogoPNG from './assets/forms-logo.png'
import GmailLogoPNG from './assets/gmail-logo.png'
import SheetsLogoPNG from './assets/sheets-logo.png'
import SlidesLogoPNG from './assets/slides-logo.png'

const allImages = [
  AnywherePNG,
  ClassroomPNG,
  SlidesPNG,
  DocsPNG,
  FormsPNG,
  SheetsPNG,
  GmailPNG
]
const allIcons = [
  MoteLogoPNG,
  ClassroomLogoPNG,
  SlidesLogoPNG,
  DocsLogoPNG,
  FormsLogoPNG,
  SheetsLogoPNG,
  GmailLogoPNG
]

const content = [
  {
    title: 'On any webpage!',
    description:
      'Don’t just share a weblink - use Mote to leave a voice note on any website and bring your link to life.'
  },
  {
    title: 'Google Classroom',
    description:
      'Mote integrates with Private Comments, Class Comments and Announcements.'
  },
  {
    title: 'Google Slides',
    description:
      'Record and insert audio directly into a Google Slides at the click of a button.'
  },
  {
    title: 'Google Docs',
    description:
      'Share fast, friendly voice note feedback and comments in Google Docs.'
  },
  {
    title: 'Google Forms',
    description:
      'Share fast, friendly voice note feedback and comments in Google Docs.'
  },
  {
    title: 'Google Sheets',
    description:
      'Play Mote voice notes from within Google Sheets - a perfect match for our Google Forms integration.'
  },
  {
    title: 'Gmail',
    description:
      'Tired of typing? Spice up your emails with Mote voice messaging, integrated directly into Gmail.'
  }
]

export const DemoSection = () => {
  const slider = React.useRef(null)
  const [activeSlide, setActiveSlide] = React.useState(0)
  const [stopAutoplay, setStopAutoplay] = React.useState(false)

  const settings = {
    arrows: false,
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: !stopAutoplay,
    autoplaySpeed: 6000,
    beforeChange: (current, next) => setActiveSlide(next)
  }

  function carouselImages() {
    return allImages.map((image) => <img key={image} src={image} />)
  }

  function carouselIcons() {
    return allIcons.map((image, index) => (
      <Icon
        key={image}
        active={activeSlide === index}
        onClick={() => {
          slider.current.slickGoTo(index)
          setStopAutoplay(true)
        }}
        src={image}
      />
    ))
  }

  function contentCopy() {
    const { title, description } = content[activeSlide]
    return (
      <>
        <Header>Mote works where you do</Header>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </>
    )
  }

  return (
    <DemoContainer>
      <DemoBackground />

      <CarouselBase>
        <SliderContainer>
          <Slider ref={slider} {...settings}>
            {carouselImages()}
          </Slider>
        </SliderContainer>
      </CarouselBase>

      <Content>
        {contentCopy()}

        <IconsContainer>{carouselIcons()}</IconsContainer>
      </Content>
    </DemoContainer>
  )
}

// TODO: Move out breakpoint values to settings

const DemoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 96px 0 48px 0;

  @media (max-width: 1199px) {
    padding: 80px 0 40px 0;
  }

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    padding: 60px 0 32px 0;
    display: flex;
    align-items: center;
  }

  @media (max-width: 639px) {
    flex-direction: column-reverse;
    padding: 40px 0 32px 0;
  }
`

const DemoBackground = styled.div`
  top: 0;
  position: absolute;
  background: ${mote_primary};
  height: 263px;
  width: 100vw;

  @media (max-width: 1199px) {
    height: 239px;
  }

  @media (max-width: 991px) {
    height: 215px;
  }

  @media (max-width: 639px) {
    height: 175px;
  }
`

const Content = styled.div`
  z-index: 1;
  font-family: DM Sans;
  color: white;
`

const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 16px;
  line-height: 120%;

  @media (max-width: 1199px) {
    font-size: 16px;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 16px;
  line-height: 120%;

  @media (max-width: 1199px) {
    font-size: 28px;
  }

  @media (max-width: 991px) {
    font-size: 24px;
  }

  @media (max-width: 639px) {
    font-size: 21px;
  }
`

const Description = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  width: 520px;
  margin-bottom: 48px;

  @media (max-width: 639px) {
    margin-bottom: 32px;
  }
`

const CarouselBase = styled.div`
  background-image: url(${BrowserOutlinePNG});
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 520px;
  height: 378px;
  position: relative;
  filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.05));
  margin-right: 40px;

  @media (max-width: 1199px) {
    width: 431px;
    height: 313px;
    margin-right: 30px;
  }

  @media (max-width: 991px) {
    width: 574px;
    height: 417px;
    margin-right: 0;
  }

  @media (max-width: 639px) {
    background-size: cover;
    width: 100%;
    height: auto;
    margin-right: 0;
  }
`

const SliderContainer = styled.div`
  width: 520px;
  height: 340px;
  margin-top: 38px;

  @media (max-width: 1199px) {
    width: 431px;
    height: 313px;
  }

  @media (max-width: 991px) {
    width: 574px;
    height: 417px;
  }

  @media (max-width: 639px) {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`

const IconsContainer = styled.div`
  display: flex;
  height: 52px;
  margin-top: 32px;

  @media (max-width: 1199px) {
    height: 42px;
  }

  @media (max-width: 991px) {
    margin-bottom: 32px;
  }
`

const Icon = styled.img`
  margin-right: 16px;
  opacity: 0.2;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`
