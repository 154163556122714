export const FAKE_ACTIVITY = {
  pagination: { pageIndexes: [1, 2, 3, 4, 5, 6, 7, 8, '...', 23] },
  activity: [
    {
      moteId: 'foobar123',
      url: 'https://mote.fyi/sh5jm22',
      studentName: 'Jane Doe',
      assignmentName: 'Covalence',
      className: 'Chem 101',
      playbackLocation: 'https://inter.web/someaudio.mp3',
      title: 'foo',
      transcriptDisplay:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      containingURL:
        'https://docs.google.com/document/d/14DwY7-XDy0SGSl7nwgOGpF8s-w4DgI1WQE6r6QkGHjI/edit',
      created: 1599037921,
      interactions: {
        listen: [
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:a6e9673a-8601-42c4-803c-c8a059970a40'
          }
        ],
        react: [
          {
            created: '2020-07-12T13:11:06.103831+0000',
            reaction: 'gem',
            userDisplayName: 'Alex Nunes',
            userId: 'wRMR2zSZr6XpUV64LpgdZhWvvpGpKmtU'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:a6e9673a-8601-42c4-803c-c8a059970a40',
            reaction: 'gem'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:a6e9673a-8601-42c4-803c-c8a059970a40',
            reaction: 'thinking'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            reaction: 'thinking',
            userDisplayName: 'Alex Nunes',
            userId: 'wRMR2zSZr6XpUV64LpgdZhWvvpGpKmtU'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:b2efc610-f1b0-4c3d-8c31-31746c02b983',
            reaction: 'thinking'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:b2efc610-f1b0-4c3d-8c31-31746c02b983',
            reaction: 'gem'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:fbf01e80-96be-4a35-8527-bca12aaf4685',
            reaction: 'thinking'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:fbf01e80-96be-4a35-8527-bca12aaf4685',
            reaction: 'gem'
          }
        ],
        view: [
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_website:2c1c64fa-7256-48d0-8f9c-ba4da0003d4a'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:1ce27954-4c5d-4c80-9d3f-940249f87a98'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:da5da605-54e2-4f8e-9349-fe78355bedac'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:a6e9673a-8601-42c4-803c-c8a059970a40'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:86d2fdc0-d9b2-4d01-bbe8-bfb989649413'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            userDisplayName: 'Alex Nunes',
            userId: 'wRMR2zSZr6XpUV64LpgdZhWvvpGpKmtU'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:3fba1844-6d9e-4512-93d1-d2372e717cdf'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:b2efc610-f1b0-4c3d-8c31-31746c02b983'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:fbf01e80-96be-4a35-8527-bca12aaf4685'
          },
          {
            created: '2020-07-12T13:11:06.103831+0000',
            deviceId:
              'anonymous:from_extension:dc0322ca-2983-418c-ab52-ba3580d8ecb1'
          }
        ]
      }
    },
    {
      moteId: 'foobar124',
      url: 'https://mote.fyi/sh5jm22',
      playbackLocation: 'https://inter.web/someaudio.mp3',
      transcriptDisplay: '',
      containingURL: 'https://mail.google.com/d/12321',
      created: 1593801540
    },
    {
      assignmentName: '',
      canLink: true,
      canRedirect: true,
      className: '',
      containingURL: 'https://www.bbc.com/',
      created: 1620830266,
      duration: 3.3,
      interactionSummary: {
        listen: 0,
        react: [
          ['gem', 0],
          ['thinking', 0]
        ],
        view: 1
      },
      interactions: {
        view: [
          {
            created: '2021-05-12T14:37:52.597464+0000',
            deviceId:
              'anonymous:from_website:444b0e87-a5c4-4277-899c-4be4bd73ee3b'
          }
        ]
      },
      isArchived: false,
      moteId: '47jv6a9',
      playbackLocation:
        'https://media.justmote.me/dev/audio_files3/4/7/j/v/6/a/9/47jv6a9.mp3',
      studentName: '',
      title: '',
      transcriptDisplay: '',
      wasEngagedWith: true
    }
  ]
}

export default FAKE_ACTIVITY
