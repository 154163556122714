import React from 'react'
import { getGiftCodeURL } from '../../../shared/Auth'
import { CLIPBOARD_ANIMATION_TIMEOUT } from '../../../shared/Constants'
import { EpochToPrettyDate } from '../../../shared/Utils'
import copy from 'copy-to-clipboard'

class Gifts extends React.Component {
  constructor(props) {
    super(props)
    this.state = props.data
  }

  render() {
    return (
      <React.Fragment>{this._hasGifts() && this._renderGifts()}</React.Fragment>
    )
  }

  _hasGifts() {
    const data = this.state
    return data.giftPayments && data.giftPayments.length > 0
  }

  _renderGifts() {
    const payments = this.state.giftPayments
    var paymentRows = []
    for (let index = 0; index < payments.length; index++) {
      const row = payments[index]
      paymentRows.push(
        <p key={index}>
          You paid {row.prettyAmountPaid} for {row.quantity} gift license
          {row.quantity > 1 ? 's' : ''} on{' '}
          {EpochToPrettyDate(row.lastPaymentEpoch)}.
        </p>
      )
    }
    const giftees = this.state.giftees
    var giftRows = []
    for (let index = 0; index < giftees.length; index++) {
      const row = giftees[index]
      // var f = () => this._tryTranslate(TRANSLATION_LOCALES[index][0]);
      const url = getGiftCodeURL(row.giftId)
      giftRows.push(
        <tr key={row.giftId}>
          <td>{url}</td>
          <td>
            {!row.gifteeEmail && (
              <div>
                <span
                  className="refer-clipboard"
                  onClick={() => this.sendGiftURLToClipboard(url)}
                >
                  Copy link <span className="material-icons">content_copy</span>
                </span>
                <br></br>
                {this.state.giftURLCopiedToClipboard === url && (
                  <span className="copied-to-clipboard small">
                    Copied to clipboard!
                  </span>
                )}
              </div>
            )}
            {row.gifteeEmail && <span className="text-right">N/A</span>}
          </td>
          <td>
            {row.gifteeEmail && (
              <span>
                {row.gifteeName} <br></br>
                <a
                  className="mote-boiler-link"
                  href={'mailto:' + row.gifteeEmail}
                >
                  <small>{row.gifteeEmail}</small>
                </a>
              </span>
            )}
            {!row.gifteeEmail && <span>Unclaimed</span>}
          </td>
        </tr>
      )
    }

    return (
      <div>
        <br />
        <h2>Your gift codes</h2>

        {paymentRows}
        <p>
          We sent {paymentRows.length > 1 ? '' : 'an'} invoice
          {paymentRows.length > 1 ? 's' : ''} to{' '}
          <b>{this.state.primaryEmailAddress}</b>
        </p>
        <table className="table shadow pricing-table table-responsive-md mb-5">
          <thead>
            <tr>
              <th scope="col" className="mote-pricing-table">
                <div className="d-block h4 mb-1">
                  <span>Gift code</span>
                </div>
              </th>
              <th scope="col">
                <span className="d-block h4 mb-1 display-5 font-weight-normal pricing-column">
                  Share link
                </span>
              </th>
              <th scope="col">
                <span className="d-block h4 mb-1 display-5 font-weight-normal pricing-column">
                  Claimed by
                </span>
              </th>
            </tr>
          </thead>
          <tbody>{giftRows}</tbody>
        </table>
      </div>
    )
  }

  sendGiftURLToClipboard(url) {
    copy(url)
    this.setState({ giftURLCopiedToClipboard: url })
    setTimeout(
      () => this.setState({ giftURLCopiedToClipboard: undefined }),
      CLIPBOARD_ANIMATION_TIMEOUT
    )
  }
}

export default Gifts
