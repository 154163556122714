import tw, { css, styled } from 'twin.macro'

import {
  stone,
  mulberry,
  lavender,
  indigo,
  black,
  mote_primary
} from './colors'

export const textStyle = {
  fontFamily: 'DM Sans, sans-serif',
  margin: '0',
  color: black,
  fontWeight: '400',
  '& a': {
    color: mote_primary,
    textDecoration: 'underline'
  }
}

const sharedTransforms = (props) => [
  props.isExtraSmall && tw`text-xs`,
  props.isSmall && tw`text-sm`,
  props.isUnderline && tw`underline`,
  props.isUppercase && tw`uppercase`,
  props.isItalic && tw`italic`,
  props.isLight && tw`font-light`,
  props.isBold && tw`font-bold`,
  props.isPulsing && tw`animate-pulse`,
  props.isCentered && tw`text-center`,
  props.noSelect && tw`select-none`,
  props.colorStone &&
    css`
      color: ${stone};
    `,
  props.colorMulberry &&
    css`
      color: ${mulberry};
    `,
  props.colorLavender &&
    css`
      color: ${lavender};
    `,
  props.colorIndigo &&
    css`
      color: ${indigo};
    `
]

export const P = styled.p(
  {
    ...textStyle
  },
  (props) => [tw`text-lg`, tw`leading-snug`],
  sharedTransforms
)

export const H6 = styled.h6(
  {
    ...textStyle
  },
  (props) => [tw`text-xl`, tw`leading-snug`],
  sharedTransforms
)

export const H5 = styled.h5(
  {
    ...textStyle
  },
  (props) => [tw`text-2xl`, tw`leading-tight`],
  sharedTransforms
)

export const H4 = styled.h4(
  {
    ...textStyle
  },
  (props) => [tw`text-3xl`, tw`leading-tight`],
  sharedTransforms
)

export const H3 = styled.h3(
  {
    ...textStyle
  },
  (props) => [tw`text-4xl`, tw`leading-none`],
  sharedTransforms
)

export const H2 = styled.h2(
  {
    ...textStyle
  },
  (props) => [tw`text-5xl`, tw`leading-none`],
  sharedTransforms
)

export const H1 = styled.h1(
  {
    ...textStyle
  },
  (props) => [tw`text-6xl`, tw`leading-none`],
  sharedTransforms
)

export const MoteColor = styled.span({
  color: mote_primary
})

export const MoteWord = styled.span({
  color: mote_primary,
  fontWeight: '400'
})

export const MoteColor400 = styled.span({
  color: mote_primary,
  fontWeight: '400'
})
